import { actions as approveActions } from "./index";

const initialState = {
  initialLoad: true,
  setDataToApprove: null,
};

const ApproveReducer = (state = initialState, action) => {
  switch (action.type) {
    case approveActions.SEND_DATA_TO_APPROVE: {
      return {
        ...state,
        setDataToApprove: action.payload,
      };
    }
    case approveActions.REMOVE_DATA_TO_APPROVE: {
      return {
        ...state,
        setDataToApprove: null,
      };
    }
    case approveActions.SEND_DATA_TO_EDIT: {
      return {
        ...state,
        setDataToApprove: action.payload,
      };
    }
    default:
      return state;
  }
};

export default ApproveReducer;
