import { actions as developmentGoalActions } from "./index";

const initialState = {
    initialLoad: true,
    isRequestSent: false,
    isError: false,
    message: "",
    initLoad: true,
    isErr: false,
    mes: "",
    isAddGoalRequestSent: false,
};

const goalReducer = (state = initialState, action) => {
    switch (action.type) {
        case developmentGoalActions.SEND_ADD_GOAL_REQUEST: {
            return {
                ...state,
                initialLoad: false,
                isAddGoalRequestSent: true,
            };
        }   

        case developmentGoalActions.SEND_ADD_GOAL_SUCCESS: {
            return {
                ...state,
                isAddGoalRequestSent: false,
                isError: false,
                message: action.payload,
            };
        }

        case developmentGoalActions.SEND_ADD_GOAL_FAILED: {
            return {
                ...state,
                isRequestSent: false,
                isAddGoalRequestSent: false,
                isError: true,
                message: action.error
            };
        }
        default: return state;
    }
};

export default goalReducer;
