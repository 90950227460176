
import React, { useState } from 'react';
import { InputGroup } from "react-bootstrap";
import Select from "react-select";
import Aux from "../../../hoc/_Aux";
import { Row, Col, Card, Form, Button, ButtonGroup, Image, Badge } from "react-bootstrap";
import "./../../../assets/scss/style.scss";
import Modal from 'react-bootstrap/Modal';
import DataTable from "react-data-table-component";
import FormikForm from "../../../components/shared/Forms/FormikForm";
import { Edit, Delete, Auth, CustomViewBtn } from "../../../components/shared/TableButtons";
import customDataTableStyle from "../../../assets/css/customCSS";
import UserRole from '../../../components/shared/UserRoles';
import { FaSave, FaSave as SaveIcon } from "react-icons/fa";
import * as yup from "yup";
import { Rings } from "react-loader-spinner";
import { Formik, setFieldValue } from "formik";
import { event } from 'jquery';

export default (props) => {


  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [defaultStatus, setDefaultStatus] = useState();

  const {
    //developmentgoalSave,
    goalsave,
    devgoals,
    loader,
    data,
    setStatus,
    setselectedStatus,
    //advanceddevdetailsSave
    advancedgoalsave,
    //dev-goals-table-view
    loading,
    totalRows,
    handlePageChange,
    handlePerRowsChange,
    handleSort,
    TableData,
    resetPaginationToggle,
    subHeaderComponentMemo,
    editData,
    setEditData,
    isModelView,
    setIsModelView,
    Options,
    editUserHandler,
    viewHandler,
    editModel,
    onSubmit,
    editViewDevelopmentGoalHandler,
    deleteViewDevelopmentGoalHandler,
    goalstatus_type,
    status,
    goalstatus,
    goalstatusChangeHandler1


  } = props;

  const init = {
    UserID: "",
    GoalID: "",
    index: "",
    GoalTitle: "",
    GoalDescription: "",
    KeyWords: "",
    DesiredOutcome: "",
    CpdgoalStatus: "",
    StartDate: "",
    EndDate: ""

  }
  const schema = yup.object().shape({
    goaltitle: yup.string()
      .nullable()
      .required("This field can not be empty.")
      .min(5, "Please provide a valid goal title (Minimum is 5 characters)")
      .max(50, "Goal title is too long (Maximum is 50 characters)"),

    goaldescription: yup.string()
      .nullable()
      .required("This field can not be empty.")
      .min(10, "Please provide a valid goal description (Minimum is 10 characters)")
      .max(150, "Goal description is too long (maximum is 150 characters)"),

    keywords: yup.string()
      .nullable()
      .required("This field can not be empty.")
      // .min(1, "Please provide a valid keyword (Minimum is 1 characters)")
      .max(30, "Keyword is too long (maximum is 30 characters)"),

    desiredoutcome: yup.string()
      .nullable()
      .required("This field can not be empty.")
      .min(10, "Please provide a valid desired outcome (Minimum is 10 characters)")
      .max(150, "Desired outcome is too long (maximum is 150 characters)"),

    // goalstatus_type: yup.object()
    //   .shape({
    //     label: yup.string().ensure().required(`This field can not be empty.`),
    //     // value: yup.string().ensure().required(),
    //   })
    //   .required("This field is required")
    //   .nullable(),
    // startDate: yup
    // .string()
    // .nullable()
    // .required("This field can not be empty"),

    // endDate: yup
    // .string()
    // .nullable()
    // .required("This field can not be empty"),




  });

  const schema1 = yup.object().shape({
    skills: yup.object()
      .shape({
        label: yup.string().ensure().required(`This field cannot be empty`),
        value: yup.string().ensure().required(),
      })
      .required("This field is required")
      .nullable(),

    notes: yup.string()
      .nullable()
      .required("This field can not be empty.")
      .min(10, "Please provide a valid desired outcome (Minimum is 10 characters)")
      .max(150, "Desired outcome is too long (maximum is 150 characters)"),

    outcome: yup.string()
      .nullable()
      .required("This field can not be empty.")
      .min(10, "Please provide a valid desired outcome (Minimum is 10 characters)")
      .max(150, "Desired outcome is too long (maximum is 150 characters)")

  });

  //dev-goals-table-view
  const column = [
    {
      name: "Index",
      selector: (data) => {
        if (data.index == null) {
          return (
            <Badge bg="danger">
              Not Submitted
            </Badge>)
        } else {
          return data.index
        }
      },
    },
    {
      name: "Goal Title",
      selector: (data) => {
        if (data.GoalTitle == null) {
          return (
            <Badge bg="danger">
              Not Submitted
            </Badge>)
        } else {
          return data.GoalTitle
        }
      },
    },
    {
      name: "Goal Description",
      selector: (data) => {
        if (data.GoalDescription == null) {
          return (
            <Badge bg="danger">
              Not Submitted
            </Badge>)
        } else {
          return data.GoalDescription
        }
      },
    },
    {
      name: "Keywords",
      selector: (data) => {
        if (data.KeyWords == null) {
          return (
            <Badge bg="danger">
              Not Submitted
            </Badge>)
        } else {
          return data.KeyWords
        }
      },
    },
    {
      name: "Desired Outcome",
      selector: (data) => {
        if (data.DesiredOutcome == null) {
          return (
            <Badge bg="danger">
              Not Submitted
            </Badge>)
        } else {
          return data.DesiredOutcome
        }
      },
    },
    {
      name: "Start Date",
      selector: (data) => {
        if (data.StartDate == null) {
          return (
            <Badge bg="danger">
              Not Submitted
            </Badge>)
        } else {
          return data.StartDate
        }
      },
    },

    {
      name: "End Date",
      selector: (data) => {
        if (data.EndDate == null) {
          return (
            <Badge bg="danger">
              Not Submitted
            </Badge>)
        } else {
          return data.EndDate
        }
      },
    },
    {
      name: "Goal Status",
      selector: (data) => {
        if (data.CpdgoalStatus == null) {
          return (
            <Badge bg="danger">
              Not Submitted
            </Badge>)
        } else {
          if (data.CpdgoalStatus == "1") {
            return (
              <Badge bg="danger">
                Pending
              </Badge>
            )
          } else if (data.CpdgoalStatus == "2") {
            return (
              <Badge bg="warning">
                In Progress
              </Badge>
            )
          } else if (data.CpdgoalStatus == "3") {
            return (
              <Badge bg="primary">
                Complete
              </Badge>
            )
          } else {
            return (
              <Badge bg="secondary">
                Unexpected Status
              </Badge>
            )
          }
        }
      },
    },

    {
      name: "Action",
      button: true,
      selector: (data) => {
        return (
          <ButtonGroup>
            <Edit data={data} editHandler={editUserHandler} />
            <CustomViewBtn viewHandler={viewHandler} data={data} />
            <Delete data={data} deleteHandler={deleteViewDevelopmentGoalHandler} />
          </ButtonGroup>
        )

      },
      sortable: false,

    },

  ];




  const CONFIG_DEVGOALS1 = [
    {
      id: "goaltitle",
      type: "textarea",
      name: "goaltitle",
      label: "Goal Title *",
      placeholder: "Define your development goal...",
      contianerProps: {
        xs: 12,
        sm: 4,
        md: 6,
      },
    },
    {
      id: "goaldescription",
      type: "textarea",
      name: "goaldescription",
      label: "Goal Description *",
      placeholder: "A concise description of your development goals...",
      contianerProps: {
        xs: 12,
        sm: 4,
        md: 6,
      },
    },
    {
      id: "keywords",
      type: "textarea",
      name: "keywords",
      label: "Keywords *",
      placeholder: "Add keywords...",
      contianerProps: {
        xs: 12,
        sm: 4,
        md: 6,
      },
    },
    {
      id: "desiredoutcome",
      type: "textarea",
      name: "desiredoutcome",
      label: "Desired Outcome *",
      placeholder: "What do you hope to achieve ?",
      contianerProps: {
        xs: 12,
        sm: 4,
        md: 6,

      },

    },
    {
      id: "startDate",
      type: "datepicker",
      name: "startDate",
      label: "Start Date *",
      placeholder: "DD / MM / YYYY",
      contianerProps: {
        xs: 12,
        sm: 4,
        md: 3,
      },
    },
    {
      id: "endDate",
      type: "datepicker",
      name: "endDate",
      label: "End Date *",
      placeholder: "DD / MM / YYYY",
      contianerProps: {
        xs: 12,
        sm: 4,
        md: 3,
      },
    },
    // {
    //   id: "status",
    //   type: "customeField",
    //   name: "status",
    //   label: "Status *",
    //   contianerProps: {
    //     xs: 12,
    //     sm: 4,
    //     md: 6,
    //   },
    // },
    {
      id: "status",
      isClearable: true,
      isSearchable: true,
      options: goalstatus,
      value: status,
      type: "selectOpImproved",
      name: "status",
      label: "Status *",
      placeholder: "Please select",
      contianerProps: {
        xs: 12,
        sm: 4,
        md: 6,
      },
    },

  ]


  return (
    <Aux>
      <Row>
        <Col>
          <Card>
            <Card.Header>


              <Col>
                <Card.Title as="h5">View Development Goal Details</Card.Title>
                <br></br>
                <Card.Title as="h6">View Development Goal</Card.Title>

              </Col>

            </Card.Header>
            <Card.Body>

              <Row>
                <Col>
                  <DataTable
                    // title="Role List"
                    columns={column}
                    data={data}
                    progressPending={loading}
                    highlightOnHover={true}
                    responsive={true}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onSort={handleSort}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    customStyles={customDataTableStyle}
                  // expandableRows
                  // expandableRowsComponent={ExpanableComponent}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      { /* edit modal */}
      <Modal
        {...props}
        show={isModelView}
        onHide={() => setIsModelView(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
      >
        {editModel ? <>
          <Modal.Header closeButton>
            <Card.Title as="h5">Update Development Goal Details</Card.Title>
          </Modal.Header>
          <Formik
            //validationSchema={schemaUpdate}
            onSubmit={(data) => {
              console.log("submit")
              setIsModelView(false);
              onSubmit(data);
            }}
            initialValues={editData || init}
          >
            {(props) => {

              const {
                handleSubmit,
                handleChange,
                values,
                setValues,
                touched,

                errors,

              } = props
                var goalStatus = values.CpdgoalStatus;
                if(goalStatus == "1"){
                  goalStatus = "Pending";
                }else if(goalStatus == "2"){
                  goalStatus = "In Progress";
                }else if(goalStatus == "3"){
                  goalStatus = "Complete";
                }else{
                  goalStatus = "None";
                }
              return (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Body>
                    <Form.Control
                      type="hidden"
                      name="GoalID *"
                      onChange={handleChange}
                      isValid={touched.GoalID && !errors.GoalID}
                      isInvalid={!!errors.GoalID}
                    />

                    <Form.Control
                      type="hidden"
                      name="UserID *"
                      onChange={handleChange}
                      isValid={touched.UserID && !errors.UserID}
                      isInvalid={!!errors.UserID} />

                    <Row className="mb-3">

                      <Form.Group as={Col} md="6" controlId="address-type">
                        <Form.Label>Index *</Form.Label>
                        <Form.Control
                          type="text"
                          name="index"
                          value={values.index}
                          onChange={handleChange}
                          isValid={touched.index && !errors.index}
                          isInvalid={!!errors.index}
                          disabled={true}
                        />


                      </Form.Group>
                      <Form.Group as={Col} md="6" controlId="address-type">
                        <Form.Label>Status *</Form.Label>
                        <Select
                          options={goalstatus}
                          title={"Status"}
                          isDisabled={false}
                          isLoading={false}
                          isClearable={true}
                          isRtl={false}
                          defaultValue={{ label: goalStatus, value: values.CpdgoalStatus }}
                          isSearchable={true}
                          // onChange={goalstatusChangeHandler1}
                          placeholder={"Please Select"}

                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} md="12" controlId="address-type">
                        <Form.Label>Goal Title *</Form.Label>
                        <Form.Control
                          type="text"
                          name="GoalTitle"
                          value={values.GoalTitle}
                          onChange={handleChange}
                          isValid={touched.GoalTitle && !errors.GoalTitle}
                          isInvalid={!!errors.GoalTitle}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.GoalTitle}
                        </Form.Control.Feedback>
                      </Form.Group></Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} md="12" controlId="address-type">
                        <Form.Label>Goal Description *</Form.Label>
                        <Form.Control
                          type="text"
                          name="GoalDescription"
                          value={values.GoalDescription}
                          onChange={handleChange}
                          isValid={touched.GoalDescription && !errors.GoalDescription}
                          isInvalid={!!errors.GoalDescription}

                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.GoalDescription}
                        </Form.Control.Feedback>
                      </Form.Group></Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} md="12" controlId="address-type">
                        <Form.Label>Keywords *</Form.Label>
                        <Form.Control
                          type="text"
                          name="KeyWords"
                          value={values.KeyWords}
                          onChange={handleChange}
                          isValid={touched.KeyWords && !errors.KeyWords}
                          isInvalid={!!errors.KeyWords}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.KeyWords}
                        </Form.Control.Feedback>
                      </Form.Group></Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} md="12" controlId="address-type">
                        <Form.Label>Desired Outcome *</Form.Label>
                        <Form.Control
                          type="text"
                          name="DesiredOutcome"
                          value={values.DesiredOutcome}
                          onChange={handleChange}
                          isValid={touched.DesiredOutcome && !errors.DesiredOutcome}
                          isInvalid={!!errors.DesiredOutcome}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.DesiredOutcome}
                        </Form.Control.Feedback>
                      </Form.Group></Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} md="6" controlId="address-type">
                        <Form.Label>Start Date *</Form.Label>
                        <Form.Control
                          type="datepicker"
                          name="StartDate"
                          value={values.StartDate}
                          onChange={handleChange}
                          isValid={touched.StartDate && !errors.StartDate}
                          isInvalid={!!errors.StartDate}
                        /></Form.Group>
                      <Form.Group as={Col} md="6" controlId="address-type">
                        <Form.Label>End Date *</Form.Label>
                        <Form.Control
                          type="text"
                          name="EndDate"
                          value={values.EndDate}
                          onChange={handleChange}
                          isValid={touched.EndDate && !errors.EndDate}
                          isInvalid={!!errors.EndDate}
                        /></Form.Group>

                    </Row></Modal.Body>
                  <Modal.Footer>
                    <Button type="button" onClick={() => {
                      console.log(props)

                      props.handleSubmit()
                    }}>Update</Button>{" "}
                    <Button onClick={() => {
                      setEditData(null)
                      setIsModelView(false)
                    }}>Close</Button>
                  </Modal.Footer>
                </Form>
              )
            }}

          </Formik></> : <>
          <Modal.Header closeButton>
            <Card.Title as="h5">View Development Goal Details</Card.Title>

          </Modal.Header>
          <Formik
            onSubmit={(data) => {
              console.log("submit")
              setIsModelView(false);
              onSubmit(data);
            }}
            initialValues={editData || init}
          >
            {(props) => {
              const {
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
              } = props
              var statusBanner = "";
              if (values.CpdgoalStatus == "1") {
                statusBanner = "Pending"
              } else if (values.CpdgoalStatus == "2") {
                statusBanner = "In Progress"
              } else if (values.CpdgoalStatus == "3") {
                statusBanner = "Complete"
              } else {
                statusBanner = "Unexpected Status"
              }


              return (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Body>
                    <Form.Control
                      type="hidden"
                      name="GoalID"
                      onChange={handleChange}
                      isValid={touched.GoalID && !errors.GoalID}
                      isInvalid={!!errors.GoalID} />

                    <Form.Control
                      type="hidden"
                      name="UserID"
                      onChange={handleChange}
                      isValid={touched.UserID && !errors.UserID}
                      isInvalid={!!errors.UserID} />

                    <Row className="mb-3">

                      <Form.Group as={Col} md="6" controlId="address-type">
                        <Form.Label>Index *</Form.Label>
                        <Form.Control
                          type="text"
                          name="index"
                          value={values.index}
                          onChange={handleChange}
                          isValid={touched.index && !errors.index}
                          isInvalid={!!errors.index}
                          disabled={true}
                        /></Form.Group>
                      <Form.Group as={Col} md="6" controlId="address-type">
                        <Form.Label>Status *</Form.Label>
                        <Form.Control
                          type="text"
                          name="CpdgoalStatus"
                          value={statusBanner}
                          onChange={handleChange}
                          isValid={touched.CpdgoalStatus && !errors.CpdgoalStatus}
                          isInvalid={!!errors.CpdgoalStatus}
                          disabled={true}
                        /></Form.Group></Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} md="12" controlId="address-type">
                        <Form.Label>Goal Title *</Form.Label>
                        <Form.Control
                          type="text"
                          name="GoalTitle"
                          value={values.GoalTitle}
                          onChange={handleChange}
                          isValid={touched.GoalTitle && !errors.GoalTitle}
                          isInvalid={!!errors.GoalTitle}
                          disabled={true}
                        /></Form.Group></Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} md="12" controlId="address-type">
                        <Form.Label>Goal Description *</Form.Label>
                        <Form.Control
                          type="text"
                          name="GoalDescription"
                          value={values.GoalDescription}
                          onChange={handleChange}
                          isValid={touched.GoalDescription && !errors.GoalDescription}
                          isInvalid={!!errors.GoalDescription}
                          disabled={true}
                        /></Form.Group></Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} md="12" controlId="address-type">
                        <Form.Label>Keywords *</Form.Label>
                        <Form.Control
                          type="text"
                          name="KeyWords"
                          value={values.KeyWords}
                          onChange={handleChange}
                          isValid={touched.KeyWords && !errors.KeyWords}
                          isInvalid={!!errors.KeyWords}
                          disabled={true}
                        /></Form.Group></Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} md="12" controlId="address-type">
                        <Form.Label>Desired Outcome *</Form.Label>
                        <Form.Control
                          type="text"
                          name="DesiredOutcome"
                          value={values.DesiredOutcome}
                          onChange={handleChange}
                          isValid={touched.DesiredOutcome && !errors.DesiredOutcome}
                          isInvalid={!!errors.DesiredOutcome}
                          disabled={true}
                        /></Form.Group></Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} md="6" controlId="address-type">
                        <Form.Label>Start Date *</Form.Label>
                        <Form.Control
                          type="text"
                          name="StartDate"
                          value={values.StartDate}
                          onChange={handleChange}
                          isValid={touched.StartDate && !errors.StartDate}
                          isInvalid={!!errors.StartDate}
                          disabled={true}
                        /></Form.Group>
                      <Form.Group as={Col} md="6" controlId="address-type">
                        <Form.Label>End Date *</Form.Label>
                        <Form.Control
                          type="text"
                          name="EndDate"
                          value={values.EndDate}
                          onChange={handleChange}
                          isValid={touched.EndDate && !errors.EndDate}
                          isInvalid={!!errors.EndDate}
                          disabled={true}
                        /></Form.Group>

                    </Row>

                  </Modal.Body>

                  <Modal.Footer>
                    <Button onClick={() => {
                      setEditData(null)
                      setIsModelView(false)
                    }}>Close</Button>
                  </Modal.Footer>

                </Form>
              )
            }}
          </Formik>
        </>}
      </Modal>
      <Row>

        <Col md={12}>
          <Card>
            <Card.Header>
              <Card.Title as="h5">Add Development Goal Details</Card.Title>
              <br></br>

              <Card.Title as="h7">Add Development Goal</Card.Title>

            </Card.Header>

            <Card.Body>

              <Row>


                <Col md={12} >

                  <FormikForm
                    config={CONFIG_DEVGOALS1}
                    // initialValues={InitialData.headerData}
                    validationSchema={schema}
                    SubmitTrigger={() => (
                      <Col
                        justify="end"
                        className="mt-3 d-flex w-100 justify-content-end mb-3"
                      >

                        <Button
                          variant="primary"
                          size="sm"
                          type="submit"
                          disabled={loader == true ? true : false}

                        >
                          {loader == true ? (
                            <Rings
                              height="28px"
                              width="28px"
                              color="white"
                            />
                          ) : (
                            <>

                              <FaSave /> Save
                            </>
                          )}
                        </Button>

                        <Button variant="secondary" onClick={handleClose}>
                          Cancel
                        </Button>

                      </Col>
                    )}

                    submitFunction={goalsave}
                  />
                </Col>

              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </Aux>
  );
};


