import React, { useState } from "react";
import { Card, Col, Row, Button, Modal, Form } from "react-bootstrap";
import Aux from "../../../hoc/_Aux";
import { Formik, Field, FieldArray } from "formik";
import * as yup from "yup";
import DataTable from "react-data-table-component";
import { Edit, Delete } from "../../../components/shared/TableButtons";
import Select from "../../../components/shared/Select";
import UserStatus from "../../../components/shared/UserStatus/";
import { IoAdd, IoPencil, IoTrash } from "react-icons/io5";
import customDataTableStyle from "../../../assets/css/customCSS";

function RoleCreation(props) {
  const {
    onSubmit,
    editRoleHandler,
    deleteRoleHandler,
    loading,
    data,
    totalRows,
    handlePageChange,
    handlePerRowsChange,
    handleSort,
    resetPaginationToggle,
    subHeaderComponentMemo,
    editData,
    setEditData,
    isModelView,
    setIsModelView,
    Options,
  } = props;

  // const init = {
  //   userRoleID: "",
  //   userRoleName: "",
  //   userRoleDescription: "",
  //   status: null,
  //   roleOptions: [{
  //     title: "Test",
  //     id: "1",
  //     1: true
  //   }, {
  //     title: "Test2",
  //     id: "2",
  //     2: true
  //   }]
  // };

  const schema = yup.object().shape({
    userRoleName: yup.string()
    .matches(/^[A-Za-z]+$/, "Invalid role name: only letters allowed")
    .required("Role name is required"),

    userRoleDescription: yup.string().required("Role Description Required"),


    status: yup
      .object()
      .shape({
        value: yup.string(),
        label: yup.string(),
      })
      .nullable()
      .required("Status is required."),
  });

  const column = [
    {
      name: "Role Id",
      selector: "userRoleID",
      sortable: true,
    },
    {
      name: "Role Name",
      selector: "userRoleName",
      sortable: true,
    },
    {
      name: "Description",
      selector: "userRoleDescription",
      sortable: true,
    },

    {
      name: "Status",
      selector: "userRoleStatus",
      sortable: true,
    },


    {
      name: "Created  Date",
      selector: "createdAt",
      sortable: true,
    },
    {
      name: "Created By",
      selector: "createdBy",
      sortable: true,
    },

    {
      name: "Action",
      button: true,
      cell: (data) => <Edit editHandler={editRoleHandler} data={data} />,
    },
    // {
    //   name: "Action",
    //   button: true,
    //   cell: (data) => <Delete deleteHandler={deleteRoleHandler} data={data} />,
    // },
  ];
  return (
    <Aux>
      <Row>
        <Col>
          <Card>
            <Card.Header>


              <Col>
                <Card.Title as="h5">Role Creation</Card.Title>
                <Button
                  className="pull-right"
                  bsStyle="danger"
                  bsSize="small"
                  onClick={() => {
                    setIsModelView(true);
                  }}
                >
                  <IoAdd />
                  {' '}
                  Add New
                </Button>
              </Col>

            </Card.Header>
            <Card.Body>

              <Row>
                <Col>
                  <DataTable
                    // title="Role List"
                    columns={column}
                    data={data}
                    progressPending={loading}
                    highlightOnHover={true}
                    responsive={true}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onSort={handleSort}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    customStyles={customDataTableStyle}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal
        {...props}
        show={isModelView}
        onHide={() => setIsModelView(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
      >
        <Modal.Header
          className="d-flex text-white"
          style={{ backgroundColor: "#3F4D67", height: 50 }}
          closeButton onHide={() => setEditData(null)}
        >
          <Modal.Title style={{ flex: 1, textAlign: "center" }}>
            Role Details
          </Modal.Title>
        </Modal.Header>

        <Formik
          validationSchema={schema}
          enableReinitialize={true}
          onSubmit={(data) => {
            console.log(data)
            setIsModelView(false);
            onSubmit(data);
          }}
          initialValues={editData || Options}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Body>
                <Form.Control
                  type="hidden"
                  name="id"
                  value={values.id}
                  onChange={handleChange}
                  isValid={touched.id && !errors.id}
                  isInvalid={!!errors.id}
                />
                <Row className="mb-3">
                <h5>Ticked Menus are allowed for the user</h5>
                <br></br>
                <br></br>
                  <Form.Group as={Col} md="6" controlId="user-RoleName">
                    <Form.Label>Role Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="userRoleName"
                      value={values.userRoleName}
                      onChange={handleChange}
                      isValid={touched.userRoleName && !errors.userRoleName}
                      disabled={editData != null ? true : false}
                      isInvalid={!!errors.userRoleName}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.userRoleName}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="userRole-Description">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      name="userRoleDescription"
                      value={values.userRoleDescription}
                      onChange={handleChange}
                      isValid={
                        touched.userRoleDescription &&
                        !errors.userRoleDescription
                      }
                      isInvalid={!!errors.userRoleDescription}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.userRoleDescription}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Col xs={6}>
                    <Select
                      id="status"
                      name="status"
                      options={props.UserStatus}
                      placeholder={"Select Status"}
                      title={"Status"}

                    />
                  </Col>


                </Row>

                <Row className="mb-3">
                  {/* <h5 style={{ marginTop: 20, textAlign: "center"}}>Select Role Options</h5>
                  <Col xs={6}>                   
                    <h6 style={{ marginTop: 20 }}>Parameter Management </h6>
                    <input type="checkbox" id="topping" name="topping" value="Paneer" /> Brand Details 
                    <br></br>
                    <input type="checkbox" id="topping" name="topping" value="Paneer" /> Currency Details
                  </Col>

                  <Col xs={6}>                    
                    <h6 style={{ marginTop: 20 }}>Customer Manageement</h6>
                    <input type="checkbox" id="topping" name="topping" value="Paneer" /> Customer Details
                  </Col> */}
                  <Col>
                    <Row >
                      <FieldArray name={'roleOptions'}    >
                        {() => (
                          <>
                            {values[`roleOptions`].length > 0 &&
                              values[`roleOptions`].map((data, index) => (

                                <Col xs={6} key={index}>
                                  <Form.Group style={{ marginTop: 10 }}>

                                    <label>
                                      <Field type="checkbox" name={`roleOptions.${index}.${data.id}`} value={values[`roleOptions.${index}.${data.value}`]} />
                                      {' ' + data.title}
                                    </label>

                                  </Form.Group>
                                </Col>
                              ))}
                            {/* {
                            Object.keys(Options).map(item => (
                              Options[item].map((menuItem, index) => {
                                console.log(menuItem)
                                // push(
                                //   {
                                //     title: menuItem.menuName,
                                //     id: menuItem.menuID,
                                //     value: false
                                //   }
                                // )
                              })))
                          } */}
                          </>
                        )}
                      </FieldArray>
                    </Row>
                  </Col>
                </Row>



              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary"
                  onClick={() => {
                    setEditData(null);
                    setIsModelView(false);
                  }}
                >
                  Close
                </Button>
                <Button type="submit">{!editData ? "Add" : "Update"}</Button>{" "}

              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </Aux>
  );
}

export default RoleCreation;
