import React, { useEffect, useState } from "react";
import { Card, Col, Row, Button, Modal, Form, ButtonGroup } from "react-bootstrap";
// import DataGrid from "../../../components/shared/Table";
import Aux from "../../../hoc/_Aux";
import { Formik } from "formik";
import * as yup from "yup";
import { Text } from "../../../components/shared/FormikInput";
import DataTable from "react-data-table-component";
import { Delete, Edit, Menu } from "../../../components/shared/TableButtons";
import { ButtonComponent } from "../../../components/shared/FormikInput";
import { FaSave as SaveIcon, FaPlus } from "react-icons/fa";
import { IoAdd, IoPencil, IoTrash } from "react-icons/io5";
import ReadOnlyInputsWithLabel from "../../../components/shared/Layouts/ReadOnlyInputsWithLabel";
import { GrUpdate as UpdateIcon } from "react-icons/gr";

const GlobalDetails = [
  {
    id: "01FSK2T42EYRRQ5XGRQMGFVZT5",
    title: "frontURL",
    text: "N/A",
  },
  {
    id: "01FSK2T6CNN201X0HV6WQXGTER",
    title: "domainDestription",
    text: "N/A",
  },
  {
    id: "01FSK2T8NXMR7TF0QQMCQCY91J",
    title: "coreURL",
    text: "N/A",
  }, {
    id: "01FSK2TAMXQG9JD3P143XDS6PS",
    title: "date",
    text: "N/A",
  }, {
    id: "01FSK2TBZNDQ9QB5PT0ZDP6ZKR",
    title: "userExpirationDays",
    text: "N/A",
  }, {
    id: "01FSK2TDV5DMZM8AFAQBZP6YTN",
    title: "userLoginAttempts",
    text: "N/A",
  }, {
    id: "01FSK2TFQPR7KV58PYB5DJKT5G",
    title: "encryptKey",
    text: "N/A",
  }, {
    id: "01FSK2THPCDVVYMYS9HDS6HC9X",
    title: "companyName",
    text: "N/A",
  }, {
    id: "01FSK2TKYMSZ09PZ8792KMG2EA",
    title: "footerTitle",
    text: "N/A",
  }, {
    id: "01FSK2TNDNMY99896BWWAQ4JQT",
    title: "versionNumber",
    text: "N/A",
  }, {
    id: "01FSK2TQ5MAMFJDXMK3780ER1T",
    title: "logoPath",
    text: "N/A",
  }, {
    id: "01FSK2TT6C2GF2FXXMKE09W3N8",
    title: "userMalePath",
    text: "N/A",
  }
];

function GloabalData(props) {

  const {
    data,
    editData,
    seteditData,
    handleEdit,
    isModelView,
    setIsModelView,
    Gdata,
    setGdata,
    addHandler
  } = props;

  let schema = yup.object().shape({
  });

  const init = {
    frontURL: "",
    domainDestription: "",
    coreURL: "",
    date: "",
    userExpirationDays: "",
    userLoginAttempts: "",
    encryptKey: "",
    companyName: "",
    footerTitle: "",
    versionNumber: "",
    logoPath: "",
    userMalePath: "",
    userFemalePath: "",
  };

  return (
    <Aux>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title as="h5">Global Details</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Row className="align-items-center">
                  {props.Gdata == null
                    ? GlobalDetails.map(({ id, ...item }) => (
                      <>
                        {/* <ReadOnlyInputsWithLabel xs={12} sm={6} md={3} key={id} {...item} className="mb-3" /> */}
                      </>
                    ))
                    : props.Gdata.map(({ id, ...item }) => (
                      <ReadOnlyInputsWithLabel xs={12} sm={6} md={3} key={id} {...item} className="mb-3" />
                    ))}
                </Row>
                <Col style={{ display: "flex", justifyContent: "right" }}>
                  <Button
                    variant="info"
                    onClick={() => handleEdit(data)}
                  >
                    <SaveIcon />
                    Update Global Details
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal
        {...props}
        show={isModelView}
        onHide={() => setIsModelView(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
      >
        <Modal.Header
          className="d-flex text-white"
          style={{ backgroundColor: "#3F4D67", height: 50 }}
          closeButton
          onHide={() => seteditData(null)}
        >
          <Modal.Title
            style={{ flex: 1, textAlign: "center" }}
            id="contained-modal-title-vcenter">
            {editData != null ? 'Update ' : 'Create '}  Global Details
          </Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(data, formprops) => {
            setIsModelView(false);
            addHandler(data);
          }}
          initialValues={editData || init}
        >
          {(formikprops) => (
            <Form noValidate onSubmit={formikprops.handleSubmit}>
              <Modal.Body>
                <Row>
                  <Col xs={12} sm={6} md={6}>
                    <Text
                      name={"frontURL"}
                      label={"Front Url"}
                      placeholder={"Front Url"}
                      // disabled={editData != null ? true : false}
                      {...formikprops}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={6}>
                    <Text
                      name={"coreURL"}
                      label={"Core Url"}
                      placeholder={"Core Url"}
                      {...formikprops}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={6}>
                    <Text
                      name={"domainDestription"}
                      label={"Domain Description"}
                      placeholder={"Domain Description"}
                      {...formikprops}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={6}>
                    <Text
                      name={"date"}
                      label={"Date"}
                      placeholder={"Date"}
                      {...formikprops}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={6}>
                    <Text
                      name={"userExpirationDays"}
                      label={"User Expiration Days"}
                      placeholder={"User Expiration Days"}
                      {...formikprops}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={6}>
                    <Text
                      name={"userLoginAttempts"}
                      label={"User Login Attempts"}
                      placeholder={"User Login Attempts"}
                      {...formikprops}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={6}>
                    <Text
                      name={"encryptKey"}
                      label={"Encrypt Key"}
                      placeholder={"Encrypt Key"}
                      {...formikprops}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={6}>
                    <Text
                      name={"companyName"}
                      label={"Company Name"}
                      placeholder={"Company Name"}
                      {...formikprops}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={6}>
                    <Text
                      name={"footerTitle"}
                      label={"Footer Title"}
                      placeholder={"Footer Title"}
                      {...formikprops}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={6}>
                    <Text
                      name={"versionNumber"}
                      label={"Version Number"}
                      placeholder={"Version Number"}
                      {...formikprops}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={6}>
                    <Text
                      name={"logoPath"}
                      label={"Logo Path"}
                      placeholder={"Logo Path"}
                      {...formikprops}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={6}>
                    <Text
                      name={"userMalePath"}
                      label={"User Male Path"}
                      placeholder={"User Male Path"}
                      {...formikprops}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={6}>
                    <Text
                      name={"userFemalePath"}
                      label={"User Female Path"}
                      placeholder={"User Female Path"}
                      {...formikprops}
                    />
                  </Col>
                  {/* <Col xs={4} sm={4} md={2} style={{ marginTop: "2%" }}>
                            <ButtonComponent
                              name={"status"}
                              {...formikprops}
                              title={"Status"}
                            />
                          </Col> */}
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => {
                  seteditData(null)
                  setIsModelView(false)
                }}>Close</Button>
                <Button variant="primary" type="submit"> <SaveIcon /> Save Changes</Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </Aux >
  );
}

export default GloabalData;
