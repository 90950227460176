import { actions as userActions } from "./index"

const initialState = {
    initialLoad: true,
    isError: false,
    message: "",
    // ADD
    isAddRequestSent: false,
    // UPDATE
    isUpdateRequestSent: false,
    // UPDATE
    isDeleteRequestSent: false,
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        // ADD
        case userActions.SEND_ADD_USER_REQUEST: {
            return {
                ...state, 
                initialLoad: false,
                isAddRequestSent: true,
            };
        }
        case userActions.SEND_ADD_USER_SUCCESS: {
            return {
                ...state,
                isAddRequestSent: false,
                isError: false,
                message: action.payload,
            };
        }
        case userActions.SEND_ADD_USER_FAILED: {
            return {
                ...state,
                isAddRequestSent: false,
                isError: true,
                message: action.error
            };
        }
        // UPDATE
        case userActions.SEND_UPDATE_USER_REQUEST: {
            return {
                ...state,
                initialLoad: false,
                isUpdateRequestSent: true,
            };
        }
        case userActions.SEND_UPDATE_USER_SUCCESS: {
            return {
                ...state,
                isUpdateRequestSent: false,
                isError: false,
                message: action.payload,
            };
        }
        case userActions.SEND_UPDATE_USER_FAILED: {
            return {
                ...state,
                isUpdateRequestSent: false,
                isError: true,
                message: action.error
            };
        }
        // DELETE
        case userActions.SEND_DELETE_USER_REQUEST: {
            return {
                ...state,
                initialLoad: false,
                isDeleteRequestSent: true,
            };
        }
        case userActions.SEND_DELETE_USER_SUCCESS: {
            return {
                ...state,
                isDeleteRequestSent: false,
                isError: false,
                message: action.payload,
            };
        }
        case userActions.SEND_DELETE_USER_FAILED: {
            return {
                ...state,
                isDeleteRequestSent: false,
                isError: true,
                message: action.error
            };
        }
        default: return state;
    }
};

export default userReducer;