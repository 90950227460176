import React from "react";
import { useFormik } from "formik";
import { Form } from "react-bootstrap";
import FieldsContainer from "./FieldsContainer";
import Modal from 'react-bootstrap/Modal';

export default function FormikForm({
  config = {},
  initialValues = {},
  validationSchema = {},
  submitUrl = "",
  submitFunction = (data) => {
    console.log("function missing", data);
  },
  SubmitTrigger,
  formikProps = {},
  isModel
}) {
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      submitFunction(values);
      console.log("submit to: ", submitUrl);
      console.log("values : ", values);
    },
    ...formikProps,
  });

  if (isModel) {
    return (
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <FieldsContainer
            containerProps={{ md: 12 }}
            config={config}
            formik={formik}
            initialValues={initialValues}
          />
        </Modal.Body>
        <Modal.Footer>
          {SubmitTrigger && <SubmitTrigger formik={formik} />}
        </Modal.Footer>
      </Form>
    )
  }
  return (
    <Form onSubmit={formik.handleSubmit}>
      <FieldsContainer
        containerProps={{ md: 12 }}
        config={config}
        formik={formik}
        initialValues={initialValues}
      />
      {SubmitTrigger && <SubmitTrigger formik={formik} />}
    </Form>
  );
}
