import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "../../../assets/scss/style.scss";
import Aux from "../../../hoc/_Aux";
import Breadcrumb from "../../../App/layout/AdminLayout/Breadcrumb";

//import avatar1 from "../../../../src/assets/images/client/logo.jpg";
//import avatar2 from "../../../../src/assets/images/client/MalibanLogo.jpg";
import avatar2 from "../../../../src/assets/images/client/silwath-logo-resized.png";
// import { FaEyeSlash } from "react-icons/fa";

const LoginView = (props) => {
  const { onChangeHandler, userName, password, loginHandler, badCredentials, showPassword, togglePasswordVisibility } = props;
  const variants = {
    initial: { x: 0, opacity: 0.5 },
    animate: {
      x: [0, 15, 0, -15, 0, 15, 0, -15, 0],
      opacity: 1,
      transition: {
        repeat: Infinity,
        type: "spring",
        stiffness: 10,
        repeatType: "loop",
      },
    },
  };
  const msgVariants = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      scale: 1.5,
      transition: {
        delay: 0,
      },
    },
  };

  console.log(window.sessionStorage.getItem("Global_logo"));

  return (
    <Aux>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
      >
        <Breadcrumb />
        <div className="auth-wrapper">
          <div className="auth-content">
            <div className="auth-bg">
              <span className="r" />
              <span className="r s" />
              <span className="r s" />
              <span className="r" />
            </div>
            <div className="card">
              <div className="card-body text-center">
                <motion.div
                  className="mb-4"
                  variants={variants}
                  initial="initial"
                  animate={badCredentials ? "animate" : "initial"}
                >
                  <i className="feather icon-lock auth-icon" />
                </motion.div>
                <img
                  className=""
                  src={avatar2}
                  // src={require(window.sessionStorage.getItem("Global_logo"))}
                  // src={require("../../../../src/assets/images/client/MalibanLogo.jpg")}
                  //   style={{ width: "40px" }}
                  alt="activity-user"
                />
                <h6 className="mb-2">Virtuous Society</h6>
                <h6 className="mb-4">සිල්වත් සමාජයක්</h6>


                <h3 className="mb-4">Member Login</h3>

                <div className="input-group mb-3">
                  <input
                    type="email"
                    className="form-control"
                    value={userName}
                    onChange={onChangeHandler("userName")}
                    placeholder="Email"
                  />
                </div>
                <div className="input-group mb-4">
                  <input
                    // type="password"
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    value={password}
                    onChange={onChangeHandler("password")}
                    placeholder="Password"
                  />
                  <button
                    className="btn btn-primary shadow-2 mb-4"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
                <div className="form-group text-left">
                  <div className="checkbox checkbox-fill d-inline">
                    <input
                      type="checkbox"
                      name="checkbox-fill-1"
                      id="checkbox-fill-a1"
                    />
                    {/* <label htmlFor="checkbox-fill-a1" className="cr">
                      {" "}
                      Save Credentials
                    </label> */}
                    <p className="mb-2 text-muted">
                  
                  <NavLink to="/signup" style={{ textDecoration: 'none' }}> New Member Sign Up</NavLink>
                </p>
                <p className="mb-2 text-muted">
                  
                  <NavLink to="/signup" style={{ textDecoration: 'none' }}> නව සාමාජික ඉල්ලුම් පත</NavLink>
                </p>
                  </div>
                </div>
                <motion.div
                  variants={msgVariants}
                  initial="initial"
                  animate={badCredentials ? "animate" : "initial"}
                >
                  <label style={{ fontWeight: 900, color: "red" }}>
                    Invalid Credentials
                  </label>
                </motion.div>
                <button
                  className="btn btn-primary shadow-2 mb-4"
                  onClick={loginHandler}
                >
                  Login
                </button>
                {/* <p className="mb-2 text-muted">
                  Forgot password?
                  <NavLink to="/auth/reset-password">Reset</NavLink>
                </p> */}
                <p className="mb-2 text-muted">
                  Forgot password ?
                  <NavLink to="/password-reset-member" style={{ textDecoration: 'none' }}> Reset</NavLink>
                </p>
                <p className="mb-0 text-muted">
                Having trouble logging in?
                  <a href="https://silwath-samajayak.com/contact-us/" style={{ textDecoration: 'none' }}> Click Here</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </Aux>
  );
};
export default LoginView;
