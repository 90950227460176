import cpdService from "../../../services/Cpd.service";

// DELETE - Development Goal DATA TABLE
export const SEND_DELETE_USER_REQUEST = "SEND_DELETE_USER_REQUEST";
export const SEND_DELETE_USER_SUCCESS = "SEND_DELETE_USER_SUCCESS";
export const SEND_DELETE_USER_FAILED = "SEND_DELETE_USER_FAILED";

// DELETE OPTION - Development Goals DATA TABLE
const sendDeleteUserRequest = () => ({
  type: SEND_DELETE_USER_REQUEST,
});

const sendDeleteUserSuccess = (payload) => ({
  type: SEND_DELETE_USER_SUCCESS,
  payload,
});

const sendDeleteUserFailes = (error) => ({
  type: SEND_DELETE_USER_FAILED,
  error,
});

// DELETE OPTION - Development Goals DATA TABLE
export const deleteDevelopmentGoal =
  ({ GoalID, GoalTitle }) =>
  async (dispatch) => {
    dispatch(sendDeleteUserRequest());
    const userResponse = await cpdService.deleteDevelopmentGoal({
      GoalID, GoalTitle
 
    });
    try {
      if (userResponse) {
        const { status, statusCode } = userResponse;

        if (statusCode === "00") {
          dispatch(sendDeleteUserSuccess(status));
        } else if (statusCode === "02") {
          dispatch(sendDeleteUserFailes(status));
        }
      }
    } catch (error) {
      console.log("User catch", error);
      dispatch(sendDeleteUserFailes(error));
    }
  };
