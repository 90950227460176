import React, { memo, useEffect, useState, useRef } from "react";
import { actions as MemberActions } from ".";
import MemberProfile from "./memberProfile.view";
import { useDispatch, useSelector } from "react-redux";
import { actions as memberProfileactions } from ".";
import userService from "../../../services/User.service";
import applicationService from "../../../services/Application.service";
import { toast } from "react-toastify";
import FilterComponent from "../../../components/shared/TableSearch";
import Swal from "sweetalert2";

export default memo(() => {
  const dispatch = useDispatch();
  //Employee Detail
  const {
    initialLoad,
    isError,
    message,
    isAddRequestSent,
    isUpdateRequestSent,
    isDeleteRequestSent,
    isMemberRequestSent,

  } = useSelector((state) => state.memReducer);

  const [model, setmodel] = useState(false);

  //Academic Detail
  const { isAddRequestSent_, isUpdateRequestSent_, isDeleteRequestSent_ } =
    useSelector((state) => state.users);

  //Prof Detail
  const { isAddRequestSent__, isUpdateRequestSent__, isDeleteRequestSent__ } =
    useSelector((state) => state.users);

  const [model_, setmodel_] = useState(false);

  //Table State - Employee Detail
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const [editData, setEditData] = useState(null);
  const [isModelView, setIsModelView] = useState(false);

  //Table State - Academic Detail
  const [academic_data, SetAcademic_data] = useState([]);
  const [loading_, setLoading_] = useState(false);
  const [totalRows_, setTotalRows_] = useState(0);
  const [perPage_, setPerPage_] = useState(10);
  const [sortColumn_, setSortColumn_] = useState(null);
  const [sortDirection_, setSortDirection_] = React.useState("");
  const [filterText_, setFilterText_] = React.useState("");
  const [resetPaginationToggle_, setResetPaginationToggle_] =
    React.useState(false);
  //const [editData_, setEditData_] = useState(null);
  const [isModelView_, setIsModelView_] = useState(false);

  //Table State - Prof Detail
  const [professionalMembership_data, SetProfessionalMembership_data] =
    useState([]);
  const [loading__, setLoading__] = useState(false);
  const [totalRows__, setTotalRows__] = useState(0);
  const [perPage__, setPerPage__] = useState(10);
  const [sortColumn__, setSortColumn__] = useState(null);
  const [sortDirection__, setSortDirection__] = React.useState("");
  const [filterText__, setFilterText__] = React.useState("");
  const [resetPaginationToggle__, setResetPaginationToggle__] =
    React.useState(false);
  //const [editData_, setEditData_] = useState(null);
  const [isModelView__, setIsModelView__] = useState(false);
  const [member, setMember] = useState({});

  const [memberType, setMemberType] = useState([])

  const tbaledata = [
    { sample: "sample", sample: "sample", sample: "sample", sample: "sample" },
  ];
  // setData(tbaledata)
  const [selectedMemberID, setSelectedMemberID] = useState("");

  const [InitialData, setInitialData] = useState({
    headerData: {
      address: "",
    },
    Email: "",
  });

  const [InitialData1, setInitialData1 ] = useState({
    headerData1:{
      // dob:"",
  
    },
  
  });
  

  const editUserHandler = (data) => {
    console.log(data);
    setEditData({
      // nic: data.nicNumber,
      contactNumber: data.mobileNumber,
      addressNo: data.addressNo,
      addressSt: data.addressSt,
      addressCity: data.addressCity,
    });
    setIsModelView(true);
  };

  const handleSearchByMemberNumber = (e) => {
    console.log(e);
  };

const userObjectA = window.sessionStorage.getItem("User");
const newUserA = JSON.parse(userObjectA);
const userid = newUserA.userid;


  const userObjectt = window.sessionStorage.getItem("User");
  console.log(JSON.parse(userObjectt));
  const newUserr = JSON.parse(userObjectt);
  console.log(newUserr.username);
  var newUser = null;
  if (
    window.sessionStorage.getItem("member") == null ||
    window.sessionStorage.getItem("member") === "null"
  ) {
    console.log("not a member, may be an admin");
    const employee = window.sessionStorage.getItem("employee");
    console.log(JSON.parse(employee));
    newUser = JSON.parse(employee);
  } else {
    const userObject = window.sessionStorage.getItem("member");
    console.log(JSON.parse(userObject));
    newUser = JSON.parse(userObject);
    console.log(newUser.memberNo);
  }

  useEffect(() => {
    const call1 = new Promise((resolve, reject) => {
      userService.getMemberDetailsTouUpdate("1").then((data) => {
        resolve(data);
      });
    });

    Promise.all([call1]).then((values) => {
      setInitialData({
        ...InitialData,
        headerData: {
          firstname: values[0].memberData.firstName,
          lastname: values[0].memberData.lastName,
          Email: values[0].memberData.email,
          addressNo: values[0].memberData.addressNo,
          addressSt: values[0].memberData.addressSt,
          addressCity: values[0].memberData.addressCity,
          Designation: values[0].memberData.designation,
          status: values[0].memberData.status,
          NIC: values[0].memberData.nicNumber,
          province: values[0].memberData.province,
          postalCode: values[0].memberData.postalCode,
          district: values[0].memberData.district,
          city: values[0].memberData.city,
          contactNumber: values[0].memberData.mobileNumber,
          title: values[0].memberData.title,
          MemberType: values[0].memberData.memberTypeName,
          memNumber: values[0].memberData.userid,
          DOB: values[0].memberData.dob,
        },
      });
      console.log(values[0]);
    });
    setMember(newUser);

    Promise.all([call1]).then((values) => {
      // setInitialData({
      //   ...InitialData,
      //   headerData: {
      //     userName: values[0].memberData.username,
      //     userRoleId: values[0].memberData.userRoleID,
      //     createdAt: values[0].memberData.createdAt,
      //     expireDate: values[0].memberData.expireDate,
      //     userStatus: values[0].memberData.status,
      //   },
      // });
      // console.log(values[0]);

      setInitialData((prev) => {
        return {
          ...prev,
          headerData: {
            ...prev.headerData,
            userName: values[0].memberData.username,
            userRoleId: values[0].memberData.userRoleID,
            createdAt: values[0].memberData.createdAt,
            expireDate: values[0].memberData.expireDate,
            userStatus: values[0].memberData.status,
          },
        };
      });
    });
    setMember(newUser);
  }, []);


  const prov = (data) =>
    data.map(({ memberTypeId, typeDes }, index) => ({
       value: memberTypeId,
      label: typeDes,
    }));

  useEffect(() => {
    try {
      userService.getMemberType().then((data) => {
        console.log("data", data);
          setMemberType(prov(data));
        });
        // console.log("MemberType", memberType);

    } catch (e) {
      console.log(e);
    }
  }, []);

  const [loader1, setLoader1] = useState(false);

  //Edit Modal
  const updatemember = (value) => {
    console.log(value);
  };

  // Edit Model Details - Save
  const EditMemberProfileDetails_OnSubmit = (value) => {
    console.log("form-on-submit data", value);
    setLoader1(true);
    userService.UserProfileModalUpdateMemberDetails(value).then((res) => {
      console.log(res);

      if (res.status == "00") {
        setLoader1(false);
        Swal.fire({
          text: "Update Successfuly",
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      } else {
        setLoader1(false);
        Swal.fire({
          text: "Update Failed",
          icon: "error",
        });
      }
    });
  };


  // useEffect(() => {
  //   if (!initLoad && !isRequestSent) {
  //     console.log("Academic qualification", mes);
  //     if (mes === "success") {       
  //       Swal.fire(
  //         'Saved!',
  //         'Academic details creation has been success.',
  //         'success'

  //       );
  //     } else if(mes === "pdf required"){
  //       console.log("Academic qualification details pdf required", mes);
  //       Swal.fire(
  //         "Failed!",
  //         "Please upload a PDF file.",
  //         "error"
  //       )
  //     } else {
  //       Swal.fire(
  //         "Failed!",
  //         "Academic qualification details creation has been failed.",
  //         "error"
  //       );
  //     }
  //   }
  // }, [isAddAcademicRequestSent]);

// Edit Details - Designation, Member Type
const [loader3, setLoader3] = useState(false);
const EditMemberProfile_OnSubmit = (data) => {
  console.log(data);

  var bodyFormData = new FormData();

  bodyFormData.append("memberTypeName", data.mem_type.label);
  bodyFormData.append("membertypeid", data.mem_type.value);
  // bodyFormData.append("institutename", data.institute_name.label);
  // bodyFormData.append("institutenameval", data.institute_name.value);
  bodyFormData.append("designation", data.designation);
  bodyFormData.append("remark", data.remark);
  bodyFormData.append("fileupload", data.file);
  // bodyFormData.append("memberType", data.mem_type);

  console.log(data);
  setLoader3(true);
  dispatch(memberProfileactions.EditMemberProfile(bodyFormData));
}
  
useEffect(() => {

  if (!initialLoad && !isMemberRequestSent) {
    
    console.log( "useEffect_message", message);
    if (message === "success") {
      setLoader3(false);
      Swal.fire(
        'Saved!',
        'Request successfully submitted.',
        'success'
      ).then(() => {
        // Do something else after the user clicks "OK" on the alert
      });
    } else if (message === "File size must be less than or equal to 5MB") {
      setLoader3(false);
      console.log("File size must be less than or equal to 5MB", message);
      Swal.fire(
        "Failed!",
        "File size must be less than or equal to 5MB.",
        "error"
      );
    } else if (message === "pdf required") {
      setLoader3(false);
      console.log("Member Detail Request pdf required", message);
      Swal.fire(
        "Failed!",
        "Please upload a PDF.",
        "error"
      );
    } else {
      setLoader3(false);
      Swal.fire(
        "Failed!",
        "Request failed.",
        "error"
      );
    } 
  }
}, [isMemberRequestSent]);


    // Edit Details - Designation, Member Type
  // const EditMemberProfile_OnSubmit = (value) => {
  //   console.log("form-on-submit data", value);
  //   userService.UserProfileUpdate(value).then((res) => {
  //     console.log(res);
  //     if (res.status == "00") {
  //       Swal.fire({
  //         text: "Update Successfully",
  //         icon: "success",
  //       }).then((result) => {
  //         if (result.isConfirmed) {
  //           window.location.reload();
  //         }
  //       });
  //     } else if (res.status == "03") {
  //       console.log("PDF required", message);
  //       Swal.fire(
  //         "Failed!",
  //         "Please upload a PDF file.",
  //         "error"
  //       )
  //     } else {

  //       Swal.fire({
  //         text: "Update Failed",
  //         icon: "error",
  //       });
  //     }
  //   });
  // };

  //Table methods - User Profile - Employee Detail Table
  const fetchUser = async (page) => {
    // setLoading(true);
    // const response =
    //   await applicationService.getViewProfileEmployeeDetailByMemberId({
    //     page,
    //     perPage,
    //     sortColumn,
    //     sortDirection,
    //     filterText,
    //   });

    // setData(response.data);
    // setTotalRows(response.total);
    // setLoading(false);

    setLoading_(true);
    const userObject = window.sessionStorage.getItem("member");
    console.log(JSON.parse(userObject));
    const newUser = JSON.parse(userObject);
    if (newUser) {
      const response =
        await applicationService.getViewProfileEmployeeDetailByMemberId({
          page,
          perPage,
          sortColumn,
          sortDirection,
          filterText,
        });

      console.log(response.data);
      setData(response.data);
      setTotalRows(response.total);
    } else {
      // ToDo
    }

    setLoading_(false);
  };

  const handlePageChange = (page) => {
    fetchUser(page);
  };

  const handlePerRowsChange = (perPage) => {
    setPerPage(perPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortColumn(column.selector);
    setSortDirection(sortDirection);
    fetchUser(1);
  };

  useEffect(() => {
    if (!isAddRequestSent) {
      fetchUser(1);
    }
  }, [
    perPage,
    filterText,
    isAddRequestSent,
    isUpdateRequestSent,
    isDeleteRequestSent,
  ]);

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  //  Table methods - User Profile - Academic Detail
  const fetchMember = async (page_) => {
    setLoading_(true);
    const userObject = window.sessionStorage.getItem("member");
    console.log(JSON.parse(userObject));
    const newUser = JSON.parse(userObject);
    if (newUser) {
      const response =
        await applicationService.getViewProfileAcademicDetailsByMemberId({
          page_,
          perPage_,
          sortColumn_,
          sortDirection_,
          filterText_,
        });

      console.log(response.data);
      SetAcademic_data(response.data);
      setTotalRows_(response.total);
    } else {
      // ToDo
    }

    setLoading_(false);
  };

  const handlePageChangeACC = (page_) => {
    fetchMember(page_);
  };

  const handlePerRowsChangeACC = (perPage_) => {
    setPerPage_(perPage_);
  };

  const handleSortACC = (column1, sortDirection_) => {
    setSortColumn_(column1.selector);
    setSortDirection_(sortDirection_);
    fetchMember(1);
  };

  useEffect(() => {
    if (!isAddRequestSent_) {
      fetchMember(1);
    }
  }, [
    perPage_,
    filterText_,
    isAddRequestSent_,
    isUpdateRequestSent_,
    isDeleteRequestSent_,
  ]);

  const subHeaderComponentMemoACC = React.useMemo(() => {
    const handleClear = () => {
      if (filterText_) {
        setResetPaginationToggle_(!resetPaginationToggle_);
        setFilterText_("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText_(e.target.value)}
        onClear={handleClear}
        filterText={filterText_}
      />
    );
  }, [filterText_, resetPaginationToggle_]);

  //  Table methods - User Profile - Professional Membership Detail
  const fetchMembership = async (page__) => {
    // setLoading__(true);
    // const response =
    //   await applicationService.getViewProfileMemberIdForProfessionalDetails({
    //     page__,
    //     perPage__,
    //     sortColumn__,
    //     sortDirection__,
    //     filterText__,
    //   });

    // SetProfessionalMembership_data(response.data);
    // setTotalRows__(response.total);
    // setLoading__(false);

    setLoading_(true);
    const userObject = window.sessionStorage.getItem("member");
    console.log(JSON.parse(userObject));
    const newUser = JSON.parse(userObject);
    if (newUser) {
      const response =
        await applicationService.getViewProfileMemberIdForProfessionalDetails({
          page__,
          perPage__,
          sortColumn__,
          sortDirection__,
          filterText__,
        });

      console.log(response.data);
      SetProfessionalMembership_data(response.data);
      setTotalRows__(response.total);
    } else {
      // ToDo
    }

    setLoading_(false);
  };

  const handlePageChangeMem = (page__) => {
    fetchMembership(page__);
  };

  const handlePerRowsChangeMem = (perPage__) => {
    setPerPage__(perPage__);
  };

  const handleSortMem = (column2, sortDirection__) => {
    setSortColumn__(column2.selector);
    setSortDirection__(sortDirection__);
    fetchMembership(1);
  };

  useEffect(() => {
    if (!isAddRequestSent__) {
      fetchMembership(1);
    }
  }, [
    perPage__,
    filterText__,
    isAddRequestSent__,
    isUpdateRequestSent__,
    isDeleteRequestSent__,
  ]);

  const subHeaderComponentMemoMem = React.useMemo(() => {
    const handleClear = () => {
      if (filterText__) {
        setResetPaginationToggle__(!resetPaginationToggle__);
        setFilterText__("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText__(e.target.value)}
        onClear={handleClear}
        filterText={filterText__}
      />
    );
  }, [filterText__, resetPaginationToggle__]);

  // Click event for member search -- start
  const [messages, setMessages] = useState("");
  const [updated, setUpdated] = useState(messages);

  const handleChange = (event) => {
    setMessages(event.target.value);
    //   console.log(messages);
  };

  const handleClick = () => {
    // "message" stores input field value
    setUpdated(messages);
    console.log(messages);

    // call service API to get data and set on form
    setSelectedMemberID(messages);

    const clickPromise = new Promise((resolve, reject) => {
      applicationService.viewMemberDetailList(messages).then((data) => {
        resolve(data);
        //  setUserDetails(data);
      });
    });
  };

  const props = {
    // User Profile - Employee Detail - Props
    data,
    loading,
    setmodel,
    model,
    InitialData,
    editUserHandler,
    totalRows,
    handlePageChange,
    handlePerRowsChange,
    handleSort,
    resetPaginationToggle,
    subHeaderComponentMemo,
    editData,
    setEditData,
    isModelView,
    setIsModelView,

    InitialData1,

    // User Profile - Academic Detail - Props
    academic_data,
    setIsModelView_,
    isModelView_,
    totalRows_,
    loading_,
    resetPaginationToggle_,
    handlePageChangeACC,
    handlePerRowsChangeACC,
    handleSortACC,
    subHeaderComponentMemoACC,

    // User Profile - Professional Detail - Props
    professionalMembership_data,
    setIsModelView__,
    isModelView__,
    totalRows__,
    loading__,
    resetPaginationToggle__,
    handlePageChangeMem,
    handlePerRowsChangeMem,
    handleSortMem,
    subHeaderComponentMemoMem,
    memberType,

    // click
    handleChange,
    updated,
    handleClick,
    member,
    newUser,
    newUserr,

    //Edit Modal
    updatemember,
    EditMemberProfileDetails_OnSubmit,
    EditMemberProfile_OnSubmit,
    loader1,
    loader3,

  };

  return MemberProfile(props);
});
