import { actions as applicationDetailActions } from "./index";

const initialState = {
    initLoad: true,
    isErr: false,
    mes: "",
    isAddAcademicRequestSent: false,
    isAddEmploymentRequestSent: false,
    isAddProfRequestSent: false,
};

const appDetailReducer = (state = initialState, action) => { 
    switch (action.type) {
        case applicationDetailActions.SEND_ADD_ACADEMIC_REQUEST: {
            return {
                ...state,
                initLoad: false,
                isAddAcademicRequestSent: true,
            };
        }
        case applicationDetailActions.SEND_ADD_ACADEMIC_SUCCESS: {
            return {
                ...state,
                isAddAcademicRequestSent: false,
                isErr: false,
                mes: action.payload,
            };
        }
        case applicationDetailActions.SEND_ADD_ACADEMIC_FAILED: {
            return {
                ...state,
                isAddAcademicRequestSent: false,
                isErr: true,
                mes: action.error
            };
        }
        case applicationDetailActions.SEND_ADD_EMPLOYMENT_REQUEST: {
            return {
                ...state,
                initLoad: false,
                isAddEmploymentRequestSent: true,
            };
        }
        case applicationDetailActions.SEND_ADD_EMPLOYMENT_SUCCESS: {
            return {
                ...state,
                isAddEmploymentRequestSent: false,
                isErr: false,
                mes: action.payload,
            };
        }
        case applicationDetailActions.SEND_ADD_EMPLOYMENT_FAILED: {
            return {
                ...state,
                isAddEmploymentRequestSent: false,
                isErr: true,
                mes: action.error
            };
        }

        /*** */
        case applicationDetailActions.SEND_ADD_PROFESSIONAL_REQUEST: {
            return {
                ...state,
                initLoad: false,
                isAddProfRequestSent: true,
            };
        }
        case applicationDetailActions.SEND_ADD_PROFESSIONAL_SUCCESS: {
            return {
                ...state,
                isAddProfRequestSent: false,
                isErr: false,
                mes: action.payload,
            };
        }
        case applicationDetailActions.SEND_ADD_PROFESSIONAL_FAILED: {
            return {
                ...state,
                isAddProfRequestSent: false,
                isErr: true,
                mes: action.error
            };
        }
        default: return state;
    }
};

export default appDetailReducer;
