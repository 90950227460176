import cpdService from "../../../services/Cpd.service";

export const SEND_AUTH_CPD_REQUEST = "SEND_AUTH_CPD_REQUEST";
export const SEND_AUTH_CPD_SUCCESS = "SEND_AUTH_CPD_SUCCESS";
export const SEND_AUTH_CPD_FAILED = "SEND_AUTH_CPD_FAILED";
export const SEND_AUTH_CPD_REJECT = "SEND_AUTH_CPD_REJECT";


// ADD
const sendAuthApproveCpdMembershipRequest = () => ({ 
  type: SEND_AUTH_CPD_REQUEST,
});

const sendAuthApproveCpdMembershipSuccess = (payload) => ({
  type: SEND_AUTH_CPD_SUCCESS,
  payload,
});

const sendAuthApproveCpdMembershipReject = (payload) => ({
  type: SEND_AUTH_CPD_REJECT,
  payload,
});

const sendAuthApproveCpdMembershipFailes = (error) => ({
  type: SEND_AUTH_CPD_FAILED,
  error,
});




// /**
//  * This method will be called when sign-in form submitted
//  * @param {string} User
//  * @param {string} Description
//  */
export const authApproveCpdMembership =
  ( data ) =>
  async (dispatch) => {
    dispatch(sendAuthApproveCpdMembershipRequest());
     const cpdResponse = await cpdService.approveMembership(data);
    //  const cpdResponse1 = await cpdService.rejectMembership(data)

    try {
      if (cpdResponse) {
        const { status, statusCode } = cpdResponse;
        console.log(status);

        if (statusCode === "00") {
          dispatch(sendAuthApproveCpdMembershipSuccess({status, statusCode}));
        } else if (statusCode === "02") {
          dispatch(sendAuthApproveCpdMembershipReject({status, statusCode}));
        } else{
          dispatch(sendAuthApproveCpdMembershipFailes({status, statusCode}));
        }
        // else if (status === "error") {
        //   dispatch(sendAuthApproveCpdMembershipFailes({status, statusCode}));
        // }
      }
    } catch (error) {
      console.log("User catch", error);
      dispatch(sendAuthApproveCpdMembershipFailes(error));
    }
  };
  


