import { actions as roleActions } from "./index";

// Initial state ✅
const initialState = {
  initialLoad: true,
  isError: false,
  message: "",
  // ADD
  isAddRequestSent: false,
  // UPDATE
  isUpdateRequestSent: false,
  // UPDATE
  isDeleteRequestSent: false,
};

// Reducer switch ✅
const roleReducer = (state = initialState, action) => {
  switch (action.type) {
    case roleActions.SET_INIT: {
      return {
        ...initialState,
      };
    }

    case roleActions.ADD_ROLES_REQUEST: {
      return {
        ...state,
        initialLoad: false,
        isAddRequestSent: true,
      };
    }

    case roleActions.ADD_ROLES_SUCCESS: {
      return {
        ...state,
        isAddRequestSent: false,
        isError: false,
        message: action.payload,
      };
    }

    case roleActions.ADD_ROLES_FAILED: {
      return {
        ...state,
        isAddRequestSent: false,
        isError: true,
        message: action.error,
      };
    }

    // UPDATE
    case roleActions.UPDATE_ROLES_REQUEST: {
      return {
        ...state,
        initialLoad: false,
        isUpdateRequestSent: true,
      };
    }
    case roleActions.UPDATE_ROLES_SUCCESS: {
      return {
        ...state,
        isUpdateRequestSent: false,
        isError: false,
        message: action.payload,
      };
    }
    case roleActions.UPDATE_ROLES_FAILED: {
      return {
        ...state,
        isUpdateRequestSent: false,
        isError: true,
        message: action.error,
      };
    }
    // DELETE
    case roleActions.DELETE_ROLES_REQUEST: {
      return {
        ...state,
        initialLoad: false,
        isDeleteRequestSent: true,
      };
    }
    case roleActions.DELETE_ROLES_SUCCESS: {
      return {
        ...state,
        isDeleteRequestSent: false,
        isError: false,
        message: action.payload,
      };
    }
    case roleActions.DELETE_ROLES_FAILED: {
      return {
        ...state,
        isDeleteRequestSent: false,
        isError: true,
        message: action.error,
      };
    }

    default:
      return state;
  }
};

export default roleReducer;
