import React, { memo, useEffect, useState } from "react";
import UserCreation from "./UserCreation.view";
import { actions as UserCreationActions } from "./index";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import userRoleService from "./../../../services/User.service";
import parameterService from "../../../services/Parameter.service";
import FilterComponent from "../../../components/shared/TableSearch";
import userService from "./../../../services/User.service";
import applicationService from "./../../../services/Application.service";
import Swal from "sweetalert2";

export default memo(() => {
  //Table State
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);


  const dispatch = useDispatch();
  const userCreationState = useSelector((state) => state.userCreation);

  const [username, setUsername] = useState("");
  const [userRole, setUserRoleName] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [userDescription, setUserDescription] = useState("");
  const [userIsNewEmployee, setIsNewEmployee] = useState(true);
  // React select
  const [userrole, setUserRole] = useState([]);

  const [selectedRole, setselectedRole] = useState(null);
  const [userSwitch, setuserSwitch] = useState(false);

  const [rolePanTitle, setRolePanTitle] = useState(
    "Restricted Menus For this User"
  );
  const [loader, setLoader] = useState(false);

  const [userNames, setUserNames] = useState([])

  const initialButtons = [
    //   { id: 0, name: 'save' },
    { id: 1, name: "submit" },
  ];

  const [addBtn, setAddBtn] = useState(initialButtons[0].name);

  const prov = (data) =>
    data.map(({ userRoleID, userRoleName }, index) => ({
      value: userRoleID,
      label: userRoleName,
    }));

  useEffect(() => {
    try {
      userService.getUserRoleList().then((data) => {
        setUserRoleName(prov(data));
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  const handleOnChange = (type) => (event) => {
    switch (type) {
      case "username":
        setUsername(event.target.value);
        break;

      case "userRoleName":
        setUserRoleName(event.target.value);
        break;

      case "firstName":
        setFirstName(event.target.value);
        break;

      case "lastName":
        setLastName(event.target.value);
        break;

      case "email":
        setEmail(event.target.value);
        break;

      case "userDescription":
        setUserDescription(event.target.value);
        break;

      case "switch":
        setuserSwitch(!userSwitch);
        setRolePanTitle("Access granted menu list");
        if (userSwitch) {
          setRolePanTitle("Restricted menu list");
        }
        break;

      default:
        break;
    }
  };

  const handleUserCreation = () => {
    console.log(
      "Click " +
        username +
        " " +
        firstName +
        " " +
        lastName +
        " " +
        userRole +
        " " +
        userDescription +
        " " +
        email +
        " " +
        userIsNewEmployee
    );
    if (username !== "" || firstName !== "" || username != "" || email !== "") {
      console.log("OK");
      dispatch(
        UserCreationActions.userCreation(
          username,
          userRole.value,
          firstName,
          lastName,
          email,
          userDescription,
          userIsNewEmployee
        )
      );
    } else {
      toast.error("Please enter all details", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log("Empty");
      //toast here
    }
  };

  const roleChangeHandler = (data) => {
    console.log(data);
    // setUserRoleName(data);
    if (data) setselectedRole(data.value);
    else setselectedRole(null);
  };

  //Add Button
  const userdetails = (value) => {
    // const memberId = InitialData.headerData.MemberID; // Retrieve the MemberID value from InitialData
    console.log("userdetails", value);
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoader(true);
        userService.createUser(value).then((res) => {
          console.log(value);

          if (res.status == "05") {
            Swal.fire({
              text: "Missing fields",
              icon: "error",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
                setLoader(false);
              }
            });
          } else if (res.status == "06") {
            Swal.fire({
              text: "Invalid Email Format",
              icon: "error",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
                setLoader(false);
              }
            });
          } else if (res.status == "02") {
            Swal.fire({
              text: "Duplicate email",
              icon: "error",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
                setLoader(false);
              }
            });
          } else if (res.status == "07") {
            Swal.fire({
              text: "Username already taken",
              icon: "error",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
                setLoader(false);
              }
            });
          } else if (res.status == "00") {
            Swal.fire({
              text: "Saved Successfully",
              icon: "success",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
                setLoader(false);
              }
            });
          } else {
            Swal.fire({
              text: "Creation Failed",
              icon: "error",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
                setLoader(false);
              }
            });
          }
        });
      } else {
      }
    });
  };

  const setUserRoleForMember = (value) => {
    // const memberId = InitialData.headerData.MemberID; // Retrieve the MemberID value from InitialData
    console.log("setUserRoleForMember", value);
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        applicationService.setUserRoleForMember(value).then((res) => {
          console.log(value);

          if (res.status == "01") {
            Swal.fire({
              text: "No member number found",
              icon: "error",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
  
              }
            });;
          } else if (res.status == "00") {
            Swal.fire({
              text: "Saved Successfully",
              icon: "success",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
  
              }
            });;
          } else {
            Swal.fire({
              text: "Creation Failed",
              icon: "error",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
  
              }
            });;
          }
        });
      } else {
      }
    });
  };

  //Table methods
  const fetchUser = async (page) => {
    // console.log("Calling")
    setLoading(true);

    const response = await userService.getUserRoleMenuAccessTable({
      page,
      perPage,
      sortColumn,
      sortDirection,
      filterText,
      selectedRole,
      userSwitch,
    });

    setData(response.data);
    setTotalRows(response.total);
    setLoading(false);
  };

  const handlePageChange = (page) => {
    fetchUser(page);
  };

  const handlePerRowsChange = (perPage) => {
    setPerPage(perPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortColumn(column.name); //sorting
    setSortDirection(sortDirection);
    fetchUser(1);
  };

  useEffect(() => {
    console.log("effect", selectedRole);
    if (selectedRole) fetchUser(1);
    else {
      setData([]);
      setTotalRows(0);
    }
  }, [perPage, filterText, selectedRole, userSwitch]);

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  // end of table methods


  // const provUserNames = (data) =>
  //   data.map(({ companyId, companyName }, index) => ({
  //     value: companyId,
  //     label: companyName,
  //   }));

  // useEffect(() => {
  //   try {
  //     userService.getAllUserNames().then((data) => {
  //       setUserNames(provUserNames(data));

  //     });

  //   } catch (e) {
  //     console.log(e);
  //   }
  // }, []);

  const props = {
    UserCreationHandler: handleUserCreation,
    onChangeHandler: handleOnChange,
    username,
    userRole,
    firstName,
    lastName,
    email,
    selectedRole,
    userDescription,
    roleChangeHandler,
    userrole,
    handlePageChange,
    handlePerRowsChange,
    handleSort,
    subHeaderComponentMemo,
    loading,
    data,
    totalRows,
    userSwitch,
    rolePanTitle,
    userdetails,
    loader,
    setAddBtn,
    initialButtons,
    addBtn,
    userNames,
    setUserRoleForMember,
  };
  return <UserCreation {...props} />;
});
