import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers';
import TokenHandler from '../services/TokenHandler.service';

const reduxLogger = createLogger({});

const composeEnhancers = typeof window === 'object'
    && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;

// console.log(TokenHandler.isVerified());
const preloadedState = TokenHandler.isVerified() ? (() => {
  try {
    const user = TokenHandler.decodeToken();
    const userMenu = JSON.parse(window.sessionStorage.getItem("UserMenu"));
    return {
      session: {
        loginInProgress: false,
        isAuthenticated: true,
        user,
        userMenu
      },
    };
  } catch (e) {
    return {};
  }
})() : {};

const store = createStore(
  rootReducer,
  preloadedState,
  composeEnhancers(applyMiddleware(thunk, reduxLogger)),
);

export default store;
