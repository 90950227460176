import { actions as userCreationActions } from "./index"

const initialState = {
    initialLoad: true,
    isRequestSent: false,
    isError: false,
    message: "",
};

const userCreationReducer = (state = initialState, action) => {
    switch (action.type) {
        case userCreationActions.SEND_USER_CREATION_REQUEST: {
            return {
                ...state,
                initialLoad: false,
                isRequestSent: true,
            };
        }
        case userCreationActions.SEND_USER_CREATION_SUCCESS: {
            return {
                ...state,
                isRequestSent: false,
                isError: false,
                message: action.payload,
            };
        }
        case userCreationActions.SEND_USER_CREATION_FAILED: {
            return {
                ...state,
                isRequestSent: false,
                isError: true,
                message: action.error
            };
        }
        default: return state;
    }
};

export default userCreationReducer;