import React, { useState } from "react";
import { Card, Col, Row, Button, Modal, Form,ButtonGroup, Badge} from "react-bootstrap";
import Aux from "../../../hoc/_Aux";
import DataTable from "react-data-table-component";
import customDataTableStyle from "../../../assets/css/customCSS";
import { withRouter } from "react-router-dom";
import {IoEyeOutline} from "react-icons/io5";
import { Edit, Delete,  Approve} from "../../../components/shared/TableButtons";
import { Pencil } from "react-bootstrap-icons";
import { Check2All } from "react-bootstrap-icons";

const viewApplicantConfirm = withRouter((props) => {
  const {
    
    loading,
    totalRows,
    handlePageChange,
    handlePerRowsChange,
    handleSort,
    TableData,
    resetPaginationToggle,
    subHeaderComponentMemo,
    editData,
    setEditData,
    isModelView,
    setIsModelView,
    Options,
    editViewApplicationHandler
  } = props;


  // const init = {
  //   userRoleID: "",
  //   userRoleName: "",
  //   userRoleDescription: "",
  //   status: null,
  //   roleOptions: [{
  //     title: "Test",
  //     id: "1",
  //     1: true
  //   }, {
  //     title: "Test2",
  //     id: "2",
  //     2: true
  //   }]
  // };


  

  const column = [
    {
      name: "Application No",
      // selector: "id",
      // sortable: true,
      selector: (data) => {
        if (data.id == null) {
          return (
          <Badge bg="danger">
          Not Submitted
        </Badge>)   
        }else{
          return data.id
        }
      },
    },
    {
      name: "NIC",
      // selector: "firstName",
      // sortable: true,
      //selector: (data) => data.nic,
      selector: (data) => {
        if (data.nic == null) {
          return (
          <Badge bg="danger">
          Not Submitted
        </Badge>)   
        }else{
          return data.nic
        }
      },
    },
    {
      name: "Title",
      // selector: "firstName",
      // sortable: true,
      //selector: (data) => data.title,
      selector: (data) => {
        if (data.title == null) {
          return (
          <Badge bg="danger">
          Not Submitted
        </Badge>)   
        }else{
          return data.title
        }
      },
    },
    {
      name: "First Name",
      // selector: "firstName",
      // sortable: true,
      //selector: (data) => data.firstName,
      selector: (data) => {
        if (data.firstName == null) {
          return (
          <Badge bg="danger">
          Not Submitted
        </Badge>)   
        }else{
          return data.firstName
        }
      },
    },
    {
      name: "Last Name",
      // selector: "firstName",
      // sortable: true,
      //selector: (data) => data.lastName,
      selector: (data) => {
        if (data.lastName == null) {
          return (
          <Badge bg="danger">
          Not Submitted
        </Badge>)   
        }else{
          return data.lastName
        }
      },
    },
    {
      name: "Mobile No",
      // selector: "firstName",
      // sortable: true,
      //selector: (data) => data.mobilName,
      selector: (data) => {
        if (data.mobilName == null) {
          return (
          <Badge bg="danger">
          Not Submitted
        </Badge>)   
        }else{
          return data.mobilName
        }
      },
    },

    {
      name: "Email",
      // selector: "Email",
      // sortable: true,
      //selector: (data) => data.Email,
      selector: (data) => {
        if (data.Email == null) {
          return (
          <Badge bg="danger">
          Not Submitted
        </Badge>)   
        }else{
          return data.Email
        }
      },
    },
    {
      name: "Address",
      // selector: "createdId",
      // sortable: true,
      //selector: (data) => data.address,
      selector: (data) => {
        if (data.address == null) {
          return (
          <Badge bg="danger">
          Not Submitted
        </Badge>)   
        }else{
          return data.address
        }
      },
    },
    
    {
      name: "Status",
      
      selector: (data) => {
        return (data.statusName) 
        
        // if (data.status == 15) {
        //     return (
        //     <Badge bg="success">
        //     New Applicant 
        //   </Badge>)   
        // }else if (data.status == 4) {
        //     return (
        //     <Badge bg="danger">
        //     Rejected Applicant 
        //   </Badge>)   
        // }else if (data.status == 5){
        //   return (
        //     <Badge bg="primary">
        //     Approved Applicant
        //   </Badge>)  
        // }
      },
    },
    
    {
      name: "Action",
      button: true,
      // cell: (data) =>
      // <ButtonGroup>
      //   <Edit data={data} editHandler={editViewApplicationHandler} />
      //   {/* <Delete data={data} deleteHandler={deleteissueHandler}/> */}
      // </ButtonGroup>
      selector: (data) => {
        if (data.status == 15) {
          return (
            <ButtonGroup>
              <Approve
                data={data}
                approveHandler={editViewApplicationHandler}
              />
              {/* <Delete data={data} deleteHandler={deleteissueHandler}/> */}
            </ButtonGroup>
          );
        } else {
          return (
            <Badge bg="primary">
              <Check2All />
            </Badge>
          );
        }
        // }else if(data.status == 3){
        //   return (
        //     <Badge bg="primary">
        //       New Applicant
        //     </Badge>) 
        // }else if(data.status == 4){
        //   return (
        //     <Badge bg="danger">
        //       Reject Applicant
        //     </Badge>) 
        // }else if(data.status == 5){
        //   return (
        //     <Badge bg="secondary">
        //       Approved Applicant
        //     </Badge>) 
        // }else if(data.status == 6){
        //   return (
        //     <Badge bg="success" >
        //       Active Member
        //     </Badge>) 
        // }else if(data.status == 17){
        //   return (
        //     <Badge bg="warning" text="dark">
        //       Pending Applicant
        //     </Badge>) 
        // }else if(data.status == 18){
        //   return (
        //     <Badge bg="secondary">
        //       Council Member Approval
        //     </Badge>) 
        // }
      }

    },

  ];

  return (
    <Aux>
      <Row>
        <Col>
          <Card>
            <Card.Header>


              <Col>
                <Card.Title as="h5">Approve Application</Card.Title>
               
              </Col>

            </Card.Header>
            <Card.Body>

              <Row>
                <Col>
                  <DataTable
                    // title="Role List"
                    columns={column}
                    data={TableData}
                    progressPending={loading}
                    highlightOnHover={true}
                    responsive={true}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onSort={handleSort}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    customStyles={customDataTableStyle}
                    // expandableRows
                    // expandableRowsComponent={ExpanableComponent}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      </Aux>
   );
  });
  
  export default viewApplicantConfirm;   

      




