//import React from 'react';
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  Table,
  InputGroup,
  FormControl,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import "../../../assets/scss/style.scss";
import Aux from "../../../hoc/_Aux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserRole from "../../../components/shared/UserRoles";
import Select from "react-select";
import DataTable from "react-data-table-component";
import * as yup from "yup";
import { Formik } from "formik";
import FormikForm from "../../../components/shared/Forms/FormikForm";
import { Rings } from "react-loader-spinner";
import { NavLink } from "react-router-dom";
import React, { useState } from "react";

export default (props) => {
  const [show, setShow] = useState(false);
  const [showemail, setShowemail] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [updated, setUpdated] = useState("");
  const [memberNo, setMemberNo] = useState("");

  let schema = yup.object().shape({
    // username: yup
    //   .string()
    //   .nullable()
    //   .matches(/^[aA-zZ\s]+$/, "Only letters are allowed for this field")
    //   .required("Username is Required")
    //   .max(15, "Invalid name"),

    firstName: yup
      .string()
      .nullable()
      .matches(
        /^[aA-zZ\s]+$/,
        "Please enter your first name using only letters"
      )
      .required("First Name is Required")
      .max(15, "Invalid name"),

    lastName: yup
      .string()
      .nullable()
      .matches(/^[aA-zZ\s]+$/, "Only letters are allowed for this field")
      .required("Last Name is Required")
      .max(15, "Invalid name"),

    email: yup
      .string()
      .nullable()
      .required("E-mail cannot be empty")
      .email("Please enter valid e-mail")
      .trim(),

    // userRoleName: yup
    //   .object()
    //   .shape({
    //     label: yup.string().ensure().required(`User Role cannot be empty`),
    //     value: yup.string().ensure().required(),
    //   })
    //   .required("Select User Role  Required")
    //   .nullable(),


      userRoleName: yup
      .object()
      .shape({
        value: yup.string(),
        label: yup.string(),
      })
      .nullable()
      .required("Select User Role  Required"),
  });

  let schema1 = yup.object().shape({

    memberNumber: yup
      .string()
      .nullable()
      .required("Member number is Required")
      .max(5, "Invalid Member number"),

      userRoleName: yup
      .object()
      .shape({
        value: yup.string(),
        label: yup.string(),
      })
      .nullable()
      .required("Select User Role  Required"),
  });

  const {
    onChangeHandler,
    username,
    userRole,
    firstName,
    lastName,
    email,
    loader,
    selectedRole,
    userDescription,
    UserCreationHandler,
    roleChangeHandler,
    InitialData,
    userrole,
    userdetails,
    loading,
    data,
    totalRows,
    handlePageChange,
    handlePerRowsChange,
    handleSort,
    resetPaginationToggle,
    subHeaderComponentMemo,
    userSwitch,
    rolePanTitle,
    setAddBtn,
    initialButtons,
    onSubmit,
    userNames,
    memberNumber,
    setUserRoleForMember,
  } = props;

  const CONFIG = [
    // {
    //   id: "username",
    //   type: "text",
    //   name: "username",
    //   label: "Username *",
    //   placeholder: "Enter Username - Will be use to login",
    //   contianerProps: {
    //     xs: 12,
    //     sm: 10,
    //     md: 10,
    //   },
    // },

    {
      id: "email",
      type: "text",
      name: "email",
      label: "Email *",
      placeholder: "Enter E-mail - Will be use to login",
      contianerProps: {
        xs: 12,
        sm: 10,
        md: 10,
      },
    },
    {
      id: "userRoleName",
      type: "selectOpImproved",
      name: "userRoleName",
      label: "User Role *",
      options: userRole,
      placeholder: "Select User Role",
      // isClearable: true,
      // isSearchable: true,
      onChange: (value, formik) => {
        formik.setFieldValue("userRoleName", value);
        roleChangeHandler(value);
      },
      contianerProps: {
        xs: 12,
        sm: 10,
        md: 10,
      },
    },
    {
      id: "firstName",
      type: "text",
      name: "firstName",
      label: "First Name *",
      placeholder: "Enter First Name",
      contianerProps: {
        xs: 12,
        sm: 10,
        md: 10,
      },
    },
    {
      id: "lastName",
      type: "text",
      name: "lastName",
      label: "Last Name *",
      placeholder: "Enter Last Name",
      contianerProps: {
        xs: 12,
        sm: 10,
        md: 10,
      },
    },
    {
      id: "userDescription",
      type: "text",
      name: "userDescription",
      label: "Comments",
      placeholder: "Enter User Description",
      contianerProps: {
        xs: 12,
        sm: 10,
        md: 10,
      },
    },
    // {
    //   id: "Employement",
    //   type: "customeField",
    //   name: "Employement",
    //   label: "New Employee",
    //   placeholder: "Employement",
    //   contianerProps: {
    //     xs: 12,
    //     sm: 10,
    //     md: 10,
    //   },
    // },
  ];

  const CONFIG_ROLE = [
    
    {
      id: "memberNumber",
      type: "text",
      name: "memberNumber",
      label: "Member Number *",
      placeholder: "Enter Member number",
      contianerProps: {
        xs: 12,
        sm: 10,
        md: 10,
      },
    },

    {
      id: "userRoleName",
      type: "selectOpImproved",
      name: "userRoleName",
      label: "User Role *",
      options: userRole,
      placeholder: "Select User Role",
      // isClearable: true,
      // isSearchable: true,
      // onChange: (value, formik) => {
      //   formik.setFieldValue("userRoleName", value);
      //   roleChangeHandler(value);
      // },
      contianerProps: {
        xs: 12,
        sm: 10,
        md: 10,
      },
    },

  ];

  const column = [
    {
      name: "#",
      selector: (data) => {
        return data.no;
      },
      sortable: true,
    },
    {
      name: "Menu Name",
      selector: (data) => {
        return data.menuName;
      },
      grow: 2,
      sortable: true,
    },
    {
      name: "URL",
      selector: (data) => {
        return data.url;
      },
      sortable: true,
      grow: 2,
    },
  ];

  return (
    <Aux>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title as="h5">User ID Creation</Card.Title>
            </Card.Header>
            <Card.Body>
              <h5>User Details</h5>
              <hr />
              <Row>
                <Col md={5}>
                  <FormikForm
                    config={CONFIG}
                    initialValues={{
                      username: username,
                      userRoleName: [{ label: "", value: "xyz" }], //{label:"Select User Role",value:"xyz"}
                      firstName: firstName,
                      lastName: lastName,
                      email: email,
                      userDescription: userDescription,
                    }}
                    //  initialValues={InitialData.headerData}
                    validationSchema={schema}
                    SubmitTrigger={() => (
                      <Col>
                        {/* <Form.Group className="mt-2">
                          <Form.Check
                            custom
                            type="radio"
                            label="New Employee"
                            name="supportedRadios"
                            id="supportedRadio3"
                            checked
                          />
                          <Form.Check
                            custom
                            type="radio"
                            label="Or Select Exisiting Employee"
                            name="supportedRadios"
                            id="supportedRadio4"
                            disabled
                          />
                        </Form.Group> */}

                        <Form.Group className="mt-2">
                          <Button
                            variant="primary"
                            //variant="primary"
                            disabled={loader == true ? true : false}
                            className="btn btn-primary"
                            size="shadow-2 mb-4"
                            type="submit"
                          >
                            {loader == true ? (
                                <Rings
                                  height="28px"
                                  width="28px"
                                  color="white"
                                />
                              ) : (
                                <>

                                  Submit
                                </>
                              )}
                            
                          </Button>

                          <Col
                            justify="end"
                            className="mt-3 d-flex w-60 justify-content-end mb-8"
                          ></Col>
                        </Form.Group>
                      </Col>
                    )}
                    submitFunction={userdetails}
                  />
                </Col>

                <Col md={7}>
                  {/* <Col md={8}> */}
                  <Card>
                    <Card.Header>
                      <Card.Title as="h5">{rolePanTitle}</Card.Title>
                      {/* <span className="d-block m-t-5">Selected <code>User Role</code> has bellow<code>Feature</code> access</span> */}
                      <Form>
                        <span className="d-block m-t-5">
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            label="See Allowed Features"
                            onChange={onChangeHandler("switch")}
                            checked={userSwitch}
                          />
                        </span>
                      </Form>
                    </Card.Header>
                    <Card.Body>
                      <DataTable
                        //title="User List"
                        columns={column}
                        data={data}
                        progressPending={loading}
                        highlightOnHover={true}
                        responsive={true}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onSort={handleSort}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title as="h5">Set User Role for a Member</Card.Title>
            </Card.Header>
            <Card.Body>
              <h5>Member Details</h5>
              <hr />
              <Row>
                <Col md={7}>
                  <FormikForm
                    config={CONFIG_ROLE}
                    initialValues={{
                      memberNumber: memberNumber,
                      userRoleName: [{ label: "", value: "xyz" }], //{label:"Select User Role",value:"xyz"}
                      
                    }}
                    //  initialValues={InitialData.headerData}
                    validationSchema={schema1}
                    SubmitTrigger={() => (
                      <Col>
                        {/* <Form.Group className="mt-2">
                          <Form.Check
                            custom
                            type="radio"
                            label="New Employee"
                            name="supportedRadios"
                            id="supportedRadio3"
                            checked
                          />
                          <Form.Check
                            custom
                            type="radio"
                            label="Or Select Exisiting Employee"
                            name="supportedRadios"
                            id="supportedRadio4"
                            disabled
                          />
                        </Form.Group> */}

                        <Form.Group className="mt-2">
                          <Button
                            variant="primary"
                            //variant="primary"
                            className="btn btn-primary"
                            size="shadow-2 mb-4"
                            type="submit"
                          >
                            Submit
                          </Button>

                          <Col
                            justify="end"
                            className="mt-3 d-flex w-60 justify-content-end mb-8"
                          ></Col>
                        </Form.Group>
                      </Col>
                    )}
                    submitFunction={setUserRoleForMember}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Aux>
  );
};
