import React, { memo, useEffect, useState } from "react";
import { actions as GloabalDataAction } from "./";
import GloabalData from "./GloabalData.view";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import parameterService from "../../../services/Parameter.service";
import FilterComponent from "../../../components/shared/TableSearch";
import Swal from "sweetalert2";

//Global Data for the Aplication
const P_GLOBAL_ID = process.env.REACT_APP_P_GLOBAL_ID;
const P_GLOBAL_VERSION = process.env.REACT_APP_P_GLOBAL_VERSION;
const P_GLOBAL_USER_EXP = process.env.REACT_APP_P_GLOBAL_USER_EXP;

export default memo(() => {
  const dispatch = useDispatch();

  const {
    initialLoad,
    isError,
    message,
    isAddRequestSent,
    isUpdateRequestSent,
    isDeleteRequestSent,
  } = useSelector((state) => state.gloabalData);

  const [data, setData] = useState([]);
  const [editData, seteditData] = useState(null);
  const [isModelView, setIsModelView] = useState(false);
  const [Gdata, setGdata] = useState(null);

  useEffect(() => {
    (async () => {
      if (!initialLoad && !isUpdateRequestSent) {
        if (message === "success") {
          toast.success("Gmt Update Success", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(`${message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        await dispatch(GloabalDataAction.setInitial());
      }
    })();
  }, [isUpdateRequestSent]);

  useEffect(() => {
    getGlobalData();
  }, []);

  const getGlobalData = () => {
    try {
      parameterService.getGlobalDetails(P_GLOBAL_ID).then((data) => {
        const GDetails = [
          {
            id: "01FSK2T42EYRRQ5XGRQMGFVZT5",
            title: "Front URL",
            text: data.frontURL,
          },
          {
            id: "01FSK2T6CNN201X0HV6WQXGTER",
            title: "Domain Destription",
            text: data.domainDestription,
          },
          {
            id: "01FSK2T8NXMR7TF0QQMCQCY91J",
            title: "Core URL",
            text: data.coreURL,
          }, {
            id: "01FSK2TAMXQG9JD3P143XDS6PS",
            title: "Date",
            text: data.date,
          }, {
            id: "01FSK2TBZNDQ9QB5PT0ZDP6ZKR",
            title: "User Expiration Days",
            text: data.userExpirationDays,
          }, {
            id: "01FSK2TDV5DMZM8AFAQBZP6YTN",
            title: "User Login Attempts",
            text: data.userLoginAttempts,
          }, {
            id: "01FSK2TFQPR7KV58PYB5DJKT5G",
            title: "encryptKey",
            text: data.encryptKey,
          }, {
            id: "01FSK2THPCDVVYMYS9HDS6HC9X",
            title: "Company Name",
            text: data.companyName,
          }, {
            id: "01FSK2TKYMSZ09PZ8792KMG2EA",
            title: "Footer Title",
            text: data.footerTitle,
          }, {
            id: "01FSK2TNDNMY99896BWWAQ4JQT",
            title: "Version Number",
            text: data.versionNumber,
          }, {
            id: "01FSK2TQ5MAMFJDXMK3780ER1T",
            title: "Logo Path",
            text: data.logoPath,
          }, {
            id: "01FSK2TT6C2GF2FXXMKE09W3N8",
            title: "User Male Path",
            text: data.userMalePath,
          }, {
            id: "01FSKT1BMKG347TVY3T4R46VPS",
            title: "User Female Path",
            text: data.userFemalePath,
          }
        ];
        setGdata(GDetails);
        setData(data);
      });
    } catch (error) {
      console.log(error);
    }
  }

  const addHandler = (data) => {
    if (editData) {
      Swal.fire({
        title: "Are you sure?",
        // text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Update it!",
      }).then((result) => {
        if (result.isConfirmed) {
          data.gid = P_GLOBAL_ID;
          dispatch(GloabalDataAction.updateGloabalData(data)).then((data) => {
            getGlobalData();
          });
          seteditData(null);
        }
      });
    } else {
      // dispatch(GloabalDataAction.addGmt(data));
    }
  };


  const handleEdit = (data) => {
    seteditData(data);
    setIsModelView(true);
    console.log(data + "handleEdit")
  };

  const props = {
    data,
    editData,
    handleEdit,
    seteditData,
    isModelView,
    setIsModelView,
    Gdata,
    setGdata,
    addHandler,
  };

  return <GloabalData {...props} />;
});
