import React, { memo, useEffect, useState } from "react";
import { actions as UserActions } from ".";
import User from "./UserAuth.view";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import parameterService from "../../../services/Parameter.service";
import FilterComponent from "../../../components/shared/TableSearch";
import Swal from "sweetalert2";
import userService from "../../../services/User.service";

export default memo(() => {
  const dispatch = useDispatch();

  const {
    initialLoad,
    isError,
    message,
    isAddRequestSent,
    isUpdateRequestSent,
    isDeleteRequestSent,
  } = useSelector((state) => state.userauth);

  //Table State
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const [editData, setEditData] = useState(null);
  const [isModelView, setIsModelView] = useState(false);
// sample
  useEffect(() => {
    if (!initialLoad && !isAddRequestSent) {
      if (message === "success") {
        toast.success("User Creation Success", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(`${message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }, [isAddRequestSent]);
  useEffect(() => {
    if (!initialLoad && !isUpdateRequestSent) {
      if (message === "success") {
        toast.success("User Authorization Success", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(`${message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }, [isUpdateRequestSent]);

  useEffect(() => {
    if (!initialLoad && !isDeleteRequestSent) {
      if (message === "success") {
        toast.success("User Delete Success", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(`${message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }, [isDeleteRequestSent]);

  const onSubmit = (data, onSubmitProps) => {
    console.log("auth",data)

    if(data.approve === 'approve'){
      console.log('approve')
      dispatch(UserActions.updateUser(data));
    }else{
      console.log('reject')
      dispatch(UserActions.updateUser(data));
    }
    // if (editData) {
    //   Swal.fire({
    //     title: "Are you sure?",
    //     // text: "You won't be able to revert this!",
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#3085d6",
    //     cancelButtonColor: "#d33",
    //     confirmButtonText: "Update it!",
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       console.log(data);
    //       dispatch(UserActions.updateUser(data));
    //     }
    //   });
    // } else {
    //   dispatch(UserActions.addUser(data));
    // }
    // onSubmitProps.resetForm();
  };

  const rejectHandler = (data) => {
    console.log(data)
  }

  const editUserHandler = (data) => {
    console.log(data)
    setEditData({
      createdAt: data.createdAt,
      createdBy: data.createdBy,
      expireDate: data.expireDate,
      status: data.status,
      userID: data.userID,
      userRoleID: { value: data.userRoleName, label: data.userRoleID },
      selectedUserRoleID: data.userRoleID.value,
      username: data.username,
      firstName: data.firstName,
      lastName: data.lastName,
      description: data.description,
      userStatusID: { value: data.statusID, label: data.status },
    });
    setIsModelView(true);
  };

  const deleteUserHandler = (data) => {
    console.log(data)
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log(data)
        dispatch(UserActions.deleteUser(data));
      }
    });
  };

  //Table methods
  const fetchUser = async (page) => {
    setLoading(true);

    const response = await userService.getPendingUserAuthorizaion({
      page,
      perPage,
      sortColumn,
      sortDirection,
      filterText,
    });

    setData(response.data);
    setTotalRows(response.total);
    setLoading(false);
  };

  const handlePageChange = (page) => {
    fetchUser(page);
  };

  const handlePerRowsChange = (perPage) => {
    setPerPage(perPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortColumn(column.name); //sorting
    setSortDirection(sortDirection);
    fetchUser(1);
  };

  useEffect(() => {
    if (!isAddRequestSent) {
      fetchUser(1);
    }
  }, [
    perPage,
    filterText,
    isAddRequestSent,
    isUpdateRequestSent,
    isDeleteRequestSent,
  ]);

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const props = {
    onSubmit,
    editUserHandler,
    deleteUserHandler,
    loading,
    data,
    totalRows,
    handlePageChange,
    handlePerRowsChange,
    handleSort,
    resetPaginationToggle,
    subHeaderComponentMemo,
    editData,
    setEditData,
    isModelView,
    setIsModelView,
    rejectHandler
  };

  return <User {...props} />;
});
