import APIService from "./Api.service"
import HttpRequestError from "../common/exceptions/HttpRequestError"
import UserBadCredentialsError from "../common/exceptions/User/UserBadCredentialsError";
import UserUnknownError from "../common/exceptions/User/UserUnknownError";

const API_BASE_URL = process.env.REACT_APP_API_URL

class CpdService extends APIService {
  serviceEndpoint = API_BASE_URL

  static handleError = (error) => {
    if (error instanceof HttpRequestError) {
      console.log(error.statusCode)
      if (error.statusCode === 401) throw new UserBadCredentialsError()
    }
    throw new UserUnknownError()
  }

  // CPD - Add Activities
  async addActivity(val) {
    try {

      const data = await this.post(`CPD/addcpdactivities`, val);
      console.log(val);
      return data;
    } catch (error) {
      return CpdService.handleError(error);
    }
  }

    // CPD - Add development goals
    async addDevelopmentGoal(val) {
      try {
  
        const data = await this.post(`CPD/adddevelopmentgoals`, val);
        console.log(val);
        return data;
      } catch (error) {
        return CpdService.handleError(error);
      }
    }

  //CPD - add advanced development goal details 
  async addAdvancedDetails(val) {
    try {

      const data = await this.post(`CPD/cpdadvancedfields`, val);
      console.log(val);
      return data;
    } catch (error) {
      return CpdService.handleError(error);
    }
  }

  //CPD - add settings
  async setting(val) {
    try {

      const data = await this.post(`CPD/settings`, val);
      return data;
    } catch (error) {
      return CpdService.handleError(error);
    }
  }

  //CPD - view all activity details based on userid and goalId
  async getcpdactivitiesbyuserid(userid) {
    try {
      const data = await this.get(`CPD/actvitydetails/${userid}`);
      return data;
    } catch (error) {
      return cpdService.handleError(error);
    }
  }

  //CPD - view all devgoals details based on member number
  async getcpddevelopmentgoalsbyuserid(userid) {
    try {
      const data = await this.get(`CPD/getdevelopmentgoals/${userid}`);
      return data;
    } catch (error) {
      return cpdService.handleError(error);
    }
  }

  //CPD - view all development goals based on userid (table view)
  async getDevelopmentGoalsByUserId(para) {
    console.log(para);
    const userObject = window.sessionStorage.getItem("User");
    console.log(JSON.parse(userObject));
    const newUser = JSON.parse(userObject);

    try {
      const data = await this.get(
        `CPD/developmentgoals/${newUser.userid}`,
        {},
        para
      );
      return data;
    } catch (error) {
      return cpdService.handleError(error);
    }
  }

  //CPD - view all activities based on userid (table view)
  async getActivitiesByUserId(para) {
    console.log(para);
    const userObject = window.sessionStorage.getItem("User");
    const newUser = JSON.parse(userObject);

    try {
      const data = await this.get(
        `CPD/all-activities/${newUser.userid}`,
        {},
        para
      );
      return data;
    } catch (error) {
      return cpdService.handleError(error);
    }
  }

  //CPD - delete development goals based on userid (table view)
  async deleteDevelopmentGoal({ GoalID, GoalTitle }) {
    try {
      const data = await this.delete(`CPD/developmentgoals/${GoalID}`);
      return data;
    } catch (error) {
      return cpdService.handleError(error);
    }
  }

  //CPD - delete activity based on activityid (table view)
  async deleteActivity({ ActivityID, ActivityTitle }) {
    try {
      const data = await this.delete(`CPD/activities/${ActivityID}`);
      return data;
    } catch (error) {
      return cpdService.handleError(error);
    }
  }

  //Edit Model Details - CPD activity Modal
  async ActivityEditData(activityData) {
    try {

      const data = await this.put(`CPD/activities/update`, activityData);
      console.log("activityData======>", activityData);
      return data;

    } catch (error) {
      return cpdService.handleError(error);
    }
  }

  //Edit Model Details - CPD goals Modal
  async GoalEditData(devgoalData) {
    try {

      const data = await this.put(`CPD/developmentgoals/update`, devgoalData);
      console.log("devgoalData======>", devgoalData);
      return data;

    } catch (error) {
      return cpdService.handleError(error);
    }
  }

  //Edit Model Details - CPD registration
  async RegistrationEditData(settingData) {
    try {

      const data = await this.put(`CPD/settings/update`, settingData);
      console.log("MemberData===>", settingData);
      return data;
    } catch (error) {
      return cpdService.handleError(error);
    }
  }

  //get cpd member details to update - CPD registration
  async getCPDMemberDetailsTouUpdate(userid) {
    try {

      const data = await this.get(`CPD/registration/${userid}`);
      console.log(userid);
      return data;
    } catch (error) {
      return cpdService.handleError(error);
    }
  }
  //view all cpd users (table-view)
  async viewCpdMembers(para) {
    console.log(para);

    try {
      const data = await this.get("CPD/viewallcpdusers", {}, para);
      return data;
    } catch (error) {
      return cpdService.handleError(error);
    }
  }
  //approve cpd member
  async approveMembership(para) {
    try {
      const data = await this.put(`CPD/approve-membership/approve`, para);
      console.log(data);
      return data;
    } catch (error) {
      return cpdService.handleError(error);
    }
  }
  //reject cpd member
  async rejectMembership(para) {
    try {
      const data = await this.put(`CPD/approve-membership/reject`, para);
      console.log(data);
      return data;
    } catch (error) {
      return cpdService.handleError(error);
    }
  }
  //get primary skills values
  async getPrimarySkill() {
    try {
      const data = await this.get("CPD/get-primary-skill", {})
      return data
    } catch (error) {
      return cpdService.handleError(error)
    }
  }
  //get entry level values
  async getEntryLevel() {
    try {
      const data = await this.get("CPD/get-entry-level", {})
      return data
    } catch (error) {
      return cpdService.handleError(error)
    }
  }
  //get goal status values
  async getDevelopmentGoalStatus() {
    try {
      const data = await this.get("CPD/get-developmentgoal-status", {})
      return data
    } catch (error) {
      return cpdService.handleError(error)
    }
  }
  //get goal status values
  async getCpdActivityStatus() {
    try {
      const data = await this.get("CPD/get-cpdactivity-status", {})
      return data
    } catch (error) {
      return cpdService.handleError(error)
    }
  }

  
  //cpdDashboard
  async getCpdCountByStatus(reqBody) {
      console.log(reqBody.userid);
      try {
        const data = await this.get(
          `dashboard/dashboardcpdcounts/${reqBody.userid}`,
          {}
        );
        return data;
      } catch (error) {
        return cpdService.handleError(error);
      }
    }

  //cpd membership approval email
  async getCpdMembershipApprovalStatus(memberno) {
    try {
      console.log(memberno);
      const data = await this.get(`CPD/send-cpd-member-approval-email-to-exisiting-member/${memberno}`);
      console.log(memberno);
      return data;
    } catch (error) {
      return cpdService.handleError(error);
    }
  }

  //cpd membership reject email
  async getCpdMembershipRejectStatus(memberno) {
    try {
        const data = await this.get(`CPD/send-cpd-member-reject-email-to-exisiting-member/${memberno}`);
        console.log(memberno);
        return data;
    } catch (error) {
        return cpdService.handleError(error);
    }
  }


}

const cpdService = new CpdService()
export default cpdService

