import React, { memo, useEffect, useState } from "react";
import RoleCreation from "./RoleCreation.view";
import { useDispatch, useSelector } from "react-redux";
import { actions as RoleActions } from "./";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import userService from "./../../../services/User.service";
import FilterComponent from "../../../components/shared/TableSearch";

export default memo(() => {
  const dispatch = useDispatch();

  const {
    initialLoad,
    isError,
    message,
    isAddRequestSent,
    isUpdateRequestSent,
    isDeleteRequestSent,
  } = useSelector((state) => state.roleCreation);

  //Table State
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const [editData, setEditData] = useState(null);
  const [isModelView, setIsModelView] = useState(false);

  const [UserStatus, setUserStatus] = useState(null);

  const [Options, setOptions] = useState([]);

  const format = (data) =>
    data.map(({ statusCode, statusName }, index) => ({
      value: statusCode,
      label: statusName,
    }));

  useEffect(() => {
    try {
      userService.getUserStatusList().then((data) => {
        setUserStatus(format(data));
        console.log(data);
      });

      userService.getUserRoleOptionsList().then((data) => {
        setOptions(data);
        console.log(data);
      });
    } catch (e) {
      console.log(e);
    }
      return () => {
      dispatch(RoleActions.setInit());
    };
  }, []);

  useEffect(() => {
    if (!initialLoad && !isAddRequestSent) {
      if (message === "success") {
        toast.success("Role Creation Success", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(`${message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }, [isAddRequestSent]);

  useEffect(() => {
    if (!initialLoad && !isUpdateRequestSent) {
      if (message === "success") {
        toast.success("Role Update Success", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(`${message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }, [isUpdateRequestSent]);

  useEffect(() => {
    if (!initialLoad && !isDeleteRequestSent) {
      if (message === "success") {
        toast.success("Role Delete Success", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(`${message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }, [isDeleteRequestSent]);

  const onSubmit = (data, onSubmitProps) => {
    console.log(data);
    if (editData) {
      Swal.fire({
        title: "Are you sure?",
        // text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Update it!",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log(data);
          dispatch(RoleActions.updateRole(data));
        }
      });
    } else {
      dispatch(RoleActions.addRole(data));
    }
    onSubmitProps.resetForm();
  };

  const editRoleHandler = (data) => {
    // setEditData({
    //   userRoleID: data.userRoleID,
    //   userRoleName: data.userRoleName,
    //   userRoleDescription: data.userRoleDescription,
    //   status:data.status
    // });
    console.log(data);
    const isAllowed = true;
    userService.getRoleEditData(data.userRoleID, isAllowed).then((res) => {
      //setInitialData({ ...InitialData, headerData: res });
      console.log(res.roleOptions);
      setEditData(res);
      // setOptions(res.roleOptions);
    });
    setIsModelView(true);
  };

  const deleteRoleHandler = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log(data);
        dispatch(RoleActions.deleteRole(data));
      }
    });
  };

  //Table methods
  const fetchRoleList = async (page) => {
    setLoading(true);

    const response = await userService.roleList({
      page,
      perPage,
      sortColumn,
      sortDirection,
      filterText,
    });

    setData(response.data);
    setTotalRows(response.total);
    setLoading(false);
  };

  const handlePageChange = (page) => {
    fetchRoleList(page);
  };

  const handlePerRowsChange = (perPage) => {
    setPerPage(perPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortColumn(column.selector);
    setSortDirection(sortDirection);
    fetchRoleList(1);
  };

  useEffect(() => {
    if (!isAddRequestSent) {
      fetchRoleList(1);
    }
  }, [
    perPage,
    filterText,
    isAddRequestSent,
    isUpdateRequestSent,
    isDeleteRequestSent,
  ]);

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const props = {
    onSubmit,
    editRoleHandler,
    deleteRoleHandler,
    loading,
    data,
    totalRows,
    handlePageChange,
    handlePerRowsChange,
    handleSort,
    resetPaginationToggle,
    subHeaderComponentMemo,
    editData,
    setEditData,
    isModelView,
    setIsModelView,
    UserStatus,
    Options,
  };

  return <RoleCreation {...props} />;
});
