import React, { useState } from "react";
import { Form, InputGroup, Button, Row, Col } from "react-bootstrap";

const UserPickList = ({ filterText, onFilter, onClear }) => (
  <>
    <Row>
      <Col xs={11}>
      <InputGroup>
          <Form.Control
            as="select"
            id="statusFilter"
            aria-label="Status Filter"
            value={filterText}
            onChange={onFilter}
            
          >
            <option value="">Select Response</option>
            <option value="Pending Applicant">Pending Applicant</option>
            <option value="New Applicant">New Applicant</option>
            <option value="Approval Pending">Approval Pending</option>
            <option value="Active Member">Active Member</option>
            <option value="Rejected User">Rejected User</option>
          </Form.Control>
          <Button variant="outline-success" onClick={onClear}>Clear</Button>
        </InputGroup>
      </Col>
    </Row>
  </>
);


export default UserPickList;
