import React from "react";
import { Button, Col, OverlayTrigger, Popover } from "react-bootstrap";
import { Pencil, Trash, ViewStacked, Journal, Printer,  Check, EyeFill } from "react-bootstrap-icons";
import { BiDotsVerticalRounded } from "react-icons/bi";


export const Delete = ({ deleteHandler, data }) => (
  <>
    <Button
      variant="outline-danger"
      style={{ borderWidth: 0 }}
      className="p-1"
      onClick={() => deleteHandler(data)}
    >
      <Trash />
    </Button>
  </>
);
export const Edit = ({ editHandler, data }) => (
  <>
    <Button
      variant="outline-primary"
      style={{ borderWidth: 0 }}
      className="p-1"
      onClick={() => editHandler(data)}
    >
      <Pencil />
    </Button>
  </>
);
export const ViewNew = ({ editHandler, data }) => (
  <>
    <Button
      variant="outline-primary"
      style={{ borderWidth: 0 }}
      className="p-1"
      onClick={() => editHandler(data)}
    >
      <EyeFill />
    </Button>
  </>
);
export const Menu = (props) => (
  <>
    <OverlayTrigger
      trigger="focus"
      placement="auto"
      overlay={
        <Popover id="popover-basic" style={{ backgroundColor: "#3F4D67" }}>
          {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
          <Popover.Body>
            <props.Component {...props} />
          </Popover.Body>
        </Popover>
      }
    >
      <Button
        variant="outline-secondary"
        style={{ borderWidth: 0 }}
        className="p-1"
      >
        <BiDotsVerticalRounded />
      </Button>
    </OverlayTrigger>
  </>
);

export const Auth = ({ editHandler, data }) => (
  <>
    <Button
      variant="outline-success"
      style={{ borderWidth: 0 }}
      onClick={() => editHandler(data)}
    >
      <Pencil />
    </Button>
  </>
);

export const Approve = ({ approveHandler, data }) => (
  <>
    <Button
      variant="outline-primary"
      style={{ borderWidth: 0 }}
      className="p-1"
      onClick={() => approveHandler(data)}
    >
      <Check />
    </Button>
  </>
);

export const Authorize = ({ authorizeHandler, data }) => (
  <>
    <a
      className="label theme-bg text-white f-12"
      onClick={() => authorizeHandler(data)}
    >
      Approve
    </a>
  </>
);

export const Reject = ({ rejectHandler, data }) => (
  <>
    <a
      className="label theme-bg2 text-white f-12"
      cursor="pointer"
      onClick={() => rejectHandler(data)}
    >
      Reject
    </a>
  </>
);
export const View = ({ viewHandler, data }) => (
  <>
    <Button
      variant="outline-primary"
      style={{ borderWidth: 0 }}
      className="p-1"
      onClick={() => viewHandler}
    >
      <ViewStacked />
    </Button>
  </>
);

export const Copy = ({ copyHandler, data }) => (
  <>
    <Button
      variant="outline-primary"
      style={{ borderWidth: 0 }}
      className="p-1"
      onClick={() => copyHandler(data)}
    >
      <Journal />
    </Button>
  </>
);

export const checkBoxtoPay = ({ copyHandler, data }) => (
  <>
    <Button
      variant="outline-primary"
      style={{ borderWidth: 0 }}
      className="p-1"
      onClick={() => copyHandler(data)}
    >
      <Journal />
    </Button>
  </>
);

export const CustomViewBtn = ({ viewHandler, data }) => (
  <>
    <Button
      variant="outline-primary"
      style={{ borderWidth: 0 }}
      className="p-1"
      onClick={() => viewHandler(data)}
    >
      <ViewStacked />
    </Button>
  </>
);

// Himasha
export const PrintBtn  = ({ printHandler}) => (
  <>
    <Button
      variant="outline-primary"
      style={{ borderWidth: 0 }}
      className="p-1"
      onClick={() => printHandler()}
    >
      <Printer />
    </Button>
  </>
);
