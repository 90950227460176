import React, { memo, useEffect, useState } from 'react'
import ViewProfile from './developmentgoal.view'
import cpdService from '../../../services/Cpd.service';
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import FilterComponent from "../../../components/shared/TableSearch";
import { actions as DevelopmentGoalActions } from ".";
export default memo(() => {
  const dispatch = useDispatch();
  const {
    initialLoad,
    isAddRequestSent,
    isUpdateRequestSent,
    isDeleteRequestSent,
  } = useSelector((state) => state.roleCreation);

  const format = (data) =>
    data.map(({ status }, index) => ({
      value: status,

    }));

  //form states
  const [editData, setEditData] = useState(null);
  const [isModelView, setIsModelView] = useState(false);
  const [UserStatus, setUserStatus] = useState(null);
  const [Options, setOptions] = useState([]);
  const [isModelEdit, setIsModelEdit] = useState(false);
  const [loader, setLoader] = useState(false);
  const [model, setmodel] = useState(false);
  const [devgoals, setDevgoals] = useState(null);
  const [editModel, setEditModel] = useState(true)

  //Table State
  const [TableData, setTableData] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const [status, setStatus] = useState(null);
  const [CpdgoalStatus, setCpdgoalStatus] = useState([]);
  const [selectedCpdStatus, setselectedCpdStatus] = useState(null);
  const [selectedStatus, setselectedStatus] = useState(null);
  // const [goalstatus, setGoalstatus] = useState([]);
  const [goalstatus, setGoalstatus] = useState(null);
  const [developmentgoalstatusName, setDevelopmentgoalstatusName] = useState(null);

  //goal status drop down
  const provgoalStatus = (data) =>
  data.map(({ developmentgoalstatusId, developmentgoalstatusName}, index) =>({
    value: developmentgoalstatusId,
    label: developmentgoalstatusName,
  }));

  // //API for loading options -development goals status drop down
  const goalstatusChangeHandler1 = (data) =>{
    console.log(CpdgoalStatus);
    setStatus({
      developmentgoalstatusName: data.label,
      developmentgoalstatusId: data.value
      });
    console.log(data);
    if(data)
      setselectedStatus(data.label);
    else
      setselectedStatus(data.label);
      console.log(data.label);
  }
  const goalstatusChangeHandler = (data) =>{
    setCpdgoalStatus(data);
    console.log(data);
    if(data){
      setselectedCpdStatus(data);
      
    }
    else{
      setselectedCpdStatus(data);
    }
  }
  // const goalstatusChangeHandler1 = (value) =>{
  //   props.form.setFieldValue(props.field.name, value);
  // }
  useEffect(()=>{
    try {
      cpdService.getDevelopmentGoalStatus()
      .then((data)=>{
        setGoalstatus(provgoalStatus(data));
      });
    }catch(e){
      console.log(e);
    }
  }, []);


  //Table methods
  const fetchUser = async (page) => {
    setLoading(true);

    const response = await cpdService
      .getDevelopmentGoalsByUserId({
        page,
        perPage,
        sortColumn,
        sortDirection,
        filterText,
      },);
    
        setData(response.data);
        // setData(response.data);
        setTotalRows(response.total);
        setLoading(false);
      
  };

  const handlePageChange = (page) => {
    fetchUser(page);
  };

  const handlePerRowsChange = (perPage) => {
    setPerPage(perPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortColumn(column.selector);
    setSortDirection(sortDirection);
    fetchUser(1);
  };
  useEffect(() => {

    if (!isAddRequestSent) {
      fetchUser(1);
    }
  }, [
    perPage,
    filterText,
    isAddRequestSent,
    isUpdateRequestSent,
    isDeleteRequestSent,
  ]);

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

    //goal view handler
    const viewHandler = (data) => {
      setEditModel(false);
      setEditData({
  
        GoalID: data.GoalID,
        index: data.index,
        UserID: data.UserID,
        GoalTitle: data.GoalTitle,
        GoalDescription: data.GoalDescription,
        KeyWords: data.KeyWords,
        DesiredOutcome: data.DesiredOutcome,
        StartDate: data.StartDate,
        EndDate: data.EndDate,
        CpdgoalStatus: data.CpdgoalStatus
      });
      setIsModelView(true);
    };

//goal edit handler
const editUserHandler = (data) => {
  setEditModel(true)
  console.log("data",data)
  console.log(data.CpdgoalStatus);
  // const goalStatus = data.CpdgoalStatus;
  // var statusNew = ""
  // if(goalStatus == "1"){
  //   statusNew = "Pending";
  // }else if(goalStatus == "2"){
  //   statusNew = "In Progress";
  // }else if(goalStatus == "3"){
  //   statusNew = "Complete";
  // }else{
  //   statusNew = "None";
  // }

  setEditData({
    GoalID: data.GoalID,
    index: data.index,
    UserID: data.UserID,
    GoalTitle: data.GoalTitle,
    GoalDescription: data.GoalDescription,
    KeyWords: data.KeyWords,
    DesiredOutcome: data.DesiredOutcome,
    StartDate: data.StartDate,
    EndDate: data.EndDate,
    CpdgoalStatus:data.CpdgoalStatus
  });
  setIsModelView(true);
};


//edit details method call
const onSubmit = (data, onSubmitProps) => {
  console.log("submit", data)
  console.log(data);

    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Update it!",
    }).then((result) => {
      if (result.isConfirmed) {
        cpdService.GoalEditData(data).then((res)=>{
          if(res.status == "00"){
            Swal.fire(
              'Updated!',
              'Your goal has been updated successfully',
              'success'
            ).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
          }else if(res.status == "02"){
            Swal.fire(
              'Error!',
              'This goal details is not available',
              'error'
            ).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
          }else if(res.status == "03"){
            Swal.fire(
              'Error!',
              'Your goal has not been updated',
              'error'
            ).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
          }else{
            Swal.fire(
              'Error!',
              'Something went wrong',
              'error'
            ).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
          }
        })
      }

    });
};

  //development-goal delete handler
  const deleteViewDevelopmentGoalHandler = (data) => {
    console.log(data)
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log(data)
        dispatch(DevelopmentGoalActions.deleteDevelopmentGoal(data));
        Swal.fire(
          'Deleted!',
          'Goal has been deleted.',
          'success'
        ).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });

      }

    });

  };

  //CPD save devGoals API calling
  const goalsave = (value) => {
    console.log(value);
    setLoader(true);
    cpdService.addDevelopmentGoal(value).then((res) => {
      console.log(res);

      let StDate = "";
      let StMonth = "";
      let EnDate = "";
      let EnMonth = "";
      if (value.startDate.day < 10) {
        StDate = '0' + value.startDate.day;
      } else {
        StDate = value.startDate.day;
      }
      if (value.startDate.month < 10) {
        StMonth = '0' + value.startDate.month;
      } else {
        StMonth = value.startDate.month;
      }
      if (value.endDate.day < 10) {
        EnDate = '0' + value.endDate.day;
      } else {
        EnDate = value.endDate.day;
      }
      if (value.endDate.month < 10) {
        EnMonth = '0' + value.endDate.month;
      } else {
        EnMonth = value.endDate.month;
      }
      // console.log(EnDate)
      const StartDate = `${value.startDate.year}-${StMonth}-${StDate}`;
      const EndDate = `${value.endDate.year}-${EnMonth}-${EnDate}`;
      var startDateObj = new Date(StartDate);
      var endDateObj = new Date(EndDate);


      if (res.status == "00" && endDateObj > startDateObj) {
        setLoader(false);
        Swal.fire({
          title: "Success!",
          text: "Your development goal has been saved successfully",
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      } else if (res.status == "03") {
        setLoader(false);
        Swal.fire({
          title: "Failed!",
          text: "Your development goal has not been saved",
          icon: "error",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      } else {
        setLoader(false);
        Swal.fire({
          title: "Error!",
          text: "Your development goal has not been saved",
          icon: "error",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      }
    })
  }

    //API for view all goals
    // useEffect(() => {
    //   const userObject = window.sessionStorage.getItem("User");
    //   console.log(JSON.parse(userObject));
    //   const userObjectItem = JSON.parse(userObject);
    //   console.log(userObjectItem.userid);
    //   const userID = userObjectItem.userid;
    //   const call = new Promise((resolve, reject) => {
    //     cpdService.getcpddevelopmentgoalsbyuserid(userID).then((data) => {
    //       resolve(data);
    //     });
    //   });
    //   Promise.all([call]).then((values) => {
    //     console.log(values[0].statusCode);
    //     const apistatusCode = values[0].statusCode;
    //     if (apistatusCode == "00") {
    //       setDevgoals(values[0]);
    //     } else if (apistatusCode == "02") {
    //       Swal.fire({
    //         title: "Error!",
    //         text: "Error in loading data!",
    //         icon: "warning",
    //       }).then((result) => {
    //         if (result.isConfirmed) {
    //           window.location.reload();
    //         }
    //       });
    //     } else {
    //       Swal.fire({
    //         title: "Error!",
    //         text: "Error",
    //         icon: "error",
    //       }).then((result) => {
    //         if (result.isConfirmed) {
    //           window.location.reload();
    //         }
    //       });
    //     }
    //   });
  
    // }, []);
  //CPD advanced devGoals details API calling
  // const advancedgoalsave = (value) => {
  //   console.log(value);
  //   cpdService.addAdvancedDetails(value).then((res) => {
  //     console.log(res);
  //     if (res.status == "00") {
  //       Swal.fire({
  //         title: "Success!",
  //         text: "Your advanced fields details have been saved successfully",
  //         icon: "success",
  //       })
  //     } else if (res.status == "03") {
  //       Swal.fire({
  //         title: "Failed!",
  //         text: "Your advanced fields details have not been saved",
  //         icon: "error",
  //       })
  //     } else {
  //       Swal.fire({
  //         title: "Error!",
  //         text: "Your advanced fields details have not been saved",
  //         icon: "error",
  //       })
  //     }
  //   })
  // }

  //API for view all development goals
  // useEffect(() => {
  //   const userObject = window.sessionStorage.getItem("User");
  //   console.log(JSON.parse(userObject));
  //   const userObjectItem = JSON.parse(userObject);
  //   console.log(userObjectItem.userid);
  //   const userID = userObjectItem.userid;

  //   const call = new Promise((resolve, reject) => {
  //     cpdService.getcpddevelopmentgoalsbyuserid(userID).then((data) => {
  //       resolve(data);
  //     });
  //   });
  //   Promise.all([call]).then((values) => {
  //     console.log(values[0].statusCode);
  //     const apistatusCode = values[0].statusCode;
  //     if (apistatusCode == "00") {
  //       setDevgoals(values[0]);
  //     } else if (apistatusCode == "01") {
  //       Swal.fire({
  //         text: "User has not found!",
  //         icon: "warning",
  //       }).then((result) => {
  //         if (result.isConfirmed) {
  //           window.location.reload();
  //         }
  //       });
  //     } else if (apistatusCode == "02") {
  //       Swal.fire({
  //         text: "Error in loading data!",
  //         icon: "warning",
  //       }).then((result) => {
  //         if (result.isConfirmed) {
  //           window.location.reload();
  //         }
  //       });
  //     } else {
  //       Swal.fire({
  //         text: "Error",
  //         icon: "error",
  //       }).then((result) => {
  //         if (result.isConfirmed) {
  //           window.location.reload();
  //         }
  //       });
  //     }
  //   });

  // }, []);

  console.log(data.CpdgoalStatus);

  const props = {
    data,
    loading,
    setmodel,
    model,
    devgoals,
    loader,
    //save,
    goalsave,
    //save
    // advancedgoalsave
    //table-view
    loading,
    totalRows,
    handlePageChange,
    handlePerRowsChange,
    handleSort,
    subHeaderComponentMemo,
    editData,
    resetPaginationToggle,
    setEditData,
    isModelView,
    isModelEdit,
    setIsModelEdit,
    setIsModelView,
    viewHandler,
    editUserHandler,
    deleteViewDevelopmentGoalHandler,
    TableData,
    onSubmit,
    editData,
    setEditData,
    isModelView,
    isModelEdit,
    setIsModelEdit,
    setIsModelView,
    UserStatus,
    Options,
    editModel,
    viewHandler,
    setStatus,
    setselectedStatus,
    goalstatus,
    developmentgoalstatusName,
    selectedStatus, 
    goalstatusChangeHandler,
    goalstatusChangeHandler1

  };

  return ViewProfile(props);

}
);
