import React, { useState } from "react";
import { Card, Col, Row, Button, Modal, Form } from "react-bootstrap";
import Aux from "../../../hoc/_Aux";
import { Formik, Field } from "formik";
import * as yup from "yup";
import DataTable from "react-data-table-component";
import { Edit, Delete, Auth } from "../../../components/shared/TableButtons";
import UserRole from '../../../components/shared/UserRoles';
import UserStatus from "../../../components/shared/UserStatus";
import customDataTableStyle from "../../../assets/css/customCSS";

function AllUsers(props) {
  const {
    onSubmit,
    editUserHandler,
    deleteUserHandler,
    loading,
    data,
    totalRows, 
    handlePageChange,
    handlePerRowsChange,
    handleSort,
    resetPaginationToggle,
    subHeaderComponentMemo,
    editData,
    setEditData,
    isModelView,
    setIsModelView,
    rejectHandler
  } = props;

  const init = {
    createdAt: "",
    createdBy: "",
    expireDate: "",
    status: "",
    userID: "",
    userRoleID: {},
    username: "",
    lastName: "",
    firstName: "",
    description: "",
    userStatusID: {},
    approve: ""
  };

  const schema = yup.object().shape({
    firstName: yup.string().required("First Name Required"),
    lastName: yup.string().required("Last Name Required"),
    // userdesctiption: yup.string().required("User description Required"),
    // userdesctiption: yup.string().required("User description Required"),
  });

  const column = [
    {
      name: "Username",
      selector: (data) => {
        return data.username
      },
      sortable: true,
    },
    {
      name: "First Name",
      selector: (data) => {
        return data.firstName
      },
      sortable: true,
    },
    {
      name: "Last Name",
      selector: (data) => {
        return data.lastName
      },
      sortable: true,
    },
    {
      name: "User Role",
      selector: (data) => {
        return data.userRoleID
      },
      sortable: true,
    },
    {
      name: "Create Date",
      selector: (data) => {
        return data.createdAt
      },
      sortable: true,
    },
    {
      name: "Created By",
      selector: (data) => {
        return data.createdBy
      },
      sortable: true,
    },
    {
      name: "Status",
      selector: (data) => {
        return data.status
      },
      sortable: true,
    },
    {
      name: "Expire Date",
      selector: (data) => {
        return data.expireDate
      },
      sortable: true,
    },
    {
      name: "Action",
      button: true,
      cell: (data) => <><Auth editHandler={editUserHandler} data={data} /> </>,
    },
    // {
    //   name: "Action",
    //   button: true,
    //   cell: (data) => (
    //     <Delete deleteHandler={deleteUserHandler} data={data} />
    //   ),
    // },
  ];

  return (
    <Aux>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title as="h5">User Authorization</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  {/* <Button
                    className="pull-right"
                    variant="primary"
                    size="sm"
                    onClick={() => {
                      setIsModelView(true);
                    }}
                  >
                    Create User
                  </Button> */}
                </Col>
              </Row>
              <Row>
                <Col>
                  <DataTable
                    // title="User Authorization"
                    columns={column}
                    data={data}
                    progressPending={loading}
                    highlightOnHover={true}
                    responsive={true}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onSort={handleSort}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    customStyles={customDataTableStyle}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal
        {...props}
        show={isModelView}
        onHide={() => setIsModelView(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            User Authorization
          </Modal.Title>
        </Modal.Header>

        <Formik
          validationSchema={schema}
          onSubmit={(data) => {
            setIsModelView(false);
            onSubmit(data);
          }}
          initialValues={editData || init}
        >
          {(props) => {
             console.log("Props ************** ",props)
            const {
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            } = props
            return (
              <Form noValidate onSubmit={handleSubmit}>
                <Modal.Body>
                  <Form.Control
                    type="hidden"
                    name="id"
                    value={values.id}
                    onChange={handleChange}
                    isValid={touched.id && !errors.id}
                    isInvalid={!!errors.id}
                  />
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="address-type">
                      <Form.Label>Username</Form.Label>
                      <Form.Control
                        type="text"
                        name="username"
                        value={values.username}
                        onChange={handleChange}
                        isValid={touched.username && !errors.username}
                        isInvalid={!!errors.username}
                        disabled
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errors.username}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="userRole">
                      <Form.Label>User Role</Form.Label>
                      {/* <UserRole onChangeHandler={handleChange} componentName={"userRoleID"} value={values.userRoleID}/> */}
                      <Field name='userRoleID' component={UserRole} />
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="address-description">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        isValid={touched.firstName && !errors.firstName}
                        isInvalid={!!errors.firstName}
                        disabled
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errors.firstName}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="address-description">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        isValid={touched.lastName && !errors.lastName}
                        isInvalid={!!errors.lastName}
                        disabled
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errors.lastName}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="address-description">
                      <Form.Label>User Description</Form.Label>
                      <Form.Control
                        type="text"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        isValid={touched.description && !errors.description}
                        isInvalid={!!errors.description}
                        disabled
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errors.description}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="address-description">
                      <Form.Label>Expire Date</Form.Label>
                      <Form.Control
                        type="text"
                        name="expireDate"
                        value={values.expireDate}
                        onChange={handleChange}
                        isValid={touched.expireDate && !errors.expireDate}
                        isInvalid={!!errors.expireDate}
                        disabled
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errors.expireDate}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="4" controlId="address-createdBy">
                      <Form.Label>Created By</Form.Label>
                      <Form.Control
                        type="text"
                        name="createdBy"
                        value={values.createdBy}
                        onChange={handleChange}
                        isValid={touched.createdBy && !errors.createdBy}
                        isInvalid={!!errors.createdBy}
                        disabled
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errors.createdBy}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="4" controlId="address-createdBy">
                      <Form.Label>Create Date</Form.Label>
                      <Form.Control
                        type="text"
                        name="createdAt"
                        value={values.createdAt}
                        onChange={handleChange}
                        isValid={touched.createdAt && !errors.createdAt}
                        isInvalid={!!errors.createdAt}
                        disabled
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errors.createdAt}
                      </Form.Control.Feedback>
                    </Form.Group>

                    {/* <Form.Group as={Col} md="4" controlId="address-createdBy">
                      <Form.Label>Status</Form.Label>
                      
                      <Field name='userStatusID' component={UserStatus} />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errors.status}
                      </Form.Control.Feedback>
                    </Form.Group> */}
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button type="button" onClick={() => {
                    props.setFieldValue('approve', 'approve')
                    props.submitForm()
                  }}>Authorize</Button>{" "}
                  <Button type="button" variant="danger" onClick={() => {
                    props.setFieldValue('approve', 'reject')
                    props.submitForm()

                  }}>Reject</Button>{" "}
                  <Button onClick={() => {
                    setEditData(null)
                    setIsModelView(false)
                  }}>Close</Button>
                </Modal.Footer>
              </Form>
            )
          }}
        </Formik>
      </Modal>
    </Aux>
  );
}

export default AllUsers;
