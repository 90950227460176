import React from "react";
import $ from "jquery";
import { components } from "react-select/dist/react-select.cjs.prod";


window.jQuery = $;
window.$ = $;
// eslint-disable-next-line no-undef
global.jQuery = $;

/**
 *
 * Add external routes here ✅
 *
 *
 */

const SignUp1 = React.lazy(() =>
  import("./Demo/Authentication/SignUp/SignUp1")
);
const Signin1 = React.lazy(() =>
  import("./Demo/Authentication/SignIn/SignIn1")
);
const Login = React.lazy(() => import("./pages/user-management/login"));
const SingUp = React.lazy(() => import("./pages/user-management/sing-up"));
const PasswordResetMember = React.lazy(() => import("./pages/user-management/password-reset-member"));
const ForgotPasswordMember = React.lazy(() => import("./pages/user-management/forgot-password-member"));
const InquiryForm = React.lazy(() => import("./pages/user-management/user-inquiry-form"));

const externalRoutes = [
  {
    path: "/auth/signup-1",
    isPrivate: false,
    exact: true,
    name: "Signup 1",
    component: SignUp1,
  },
  {
    path: "/auth/signin-1",
    isPrivate: false,
    exact: true,
    name: "Signin 1",
    component: Signin1,
  },
  {
    path: "/login",
    isPrivate: false,
    exact: true,
    name: "Login",
    component: Login,
  },
  {
    path: "/",
    isPrivate: false,
    exact: true,
    name: "Login",
    component: Login,
  },
  {
    path: "/signup",
    isPrivate: false,
    exact: true,
    name: "SingUp",
    component: SingUp,
  },
  {
    path: "/password-reset-member",
    isPrivate: false,
    exact: true,
    name: "Password Reset",
    component: PasswordResetMember,
  },
  {
    path: "/user-inquiry-form",
    isPrivate: false,
    exact: true,
    name: "User Inquiry Form",
    component: InquiryForm,
  },
  {
    path: "/forgot-password-member",
    isPrivate: false,
    exact: true,
    name: "Forgot Password Member",
    component: ForgotPasswordMember,
  },
];

// ======================================================================================================

/**
 *
 * Add admin routes here ✅
 *
 *
 */

// const MemberDashboard = React.lazy(() =>
//   import("./Demo/Dashboard/MemberDashboard")
// );
// const AdminDashboard = React.lazy(() =>
//   import("./Demo/Dashboard/AdminDashboard")
// );
const ApplicantDashboard = React.lazy(() => import("./pages/member-management/application-dashboard"));
const AdminDashboard = React.lazy(() => import("./pages/member-management/admin-dashboard"));
const MemberDashboard = React.lazy(() => import("./pages/member-management/member-dashboard"));
const NotFound = React.lazy(() => import("./pages/NotFound/NotFound.js"));
const SelectOption = React.lazy(() => import("./pages/NotFound/OptionSelect.js"));


/* --------------------User Management-----------*/
const ViewProfile = React.lazy(() =>
  import("./pages/user-management/view-profile")
);
const UserCreation = React.lazy(() =>
  import("./pages/user-management/user-creation")
);
const UserAuthorization = React.lazy(() =>
  import("./pages/user-management/user-authorization")
);
const ViewAllUsers = React.lazy(() =>
  import("./pages/user-management/user-viewAll")
);
const Inquiries = React.lazy(() =>
  import("./pages/user-management/inquiries")
);
const PasswordReset = React.lazy(() =>
  import("./pages/user-management/password-reset")
);
const RoleCreation = React.lazy(() => import("./pages/user-management/roles"));
const BlockMenu = React.lazy(() =>
  import("./pages/user-management/block-user-access")
);
const MemberDetails = React.lazy(() =>
  import("./pages/user-management/member-details")
);
const MemberProfile = React.lazy(() =>
  import("./pages/user-management/member-profile")
);
const ChangePassword = React.lazy(() =>
  import("./pages/user-management/change-password")
);
//const RoleAuthorization = React.lazy(() => import('./pages/user-management/role-authorization'));

/************* Member Management *************/
const ApplicationDetails = React.lazy(() =>
  import("./pages/member-management/application-details")
);
const ViewApplications = React.lazy(() =>
  import("./pages/member-management/view-applications")
);
const ViewMembers = React.lazy(() =>
  import("./pages/member-management/member-view")
);
const MembershipFees = React.lazy(() =>
  import("./pages/user-management/membership-fees")
);
const ApproveMember = React.lazy(() =>
  import("./pages/member-management/approve-member-detail")
);
const EDitMember = React.lazy(() =>
  import("./pages/member-management/admin-edit-member")
);
const ApplicantRegistration = React.lazy(()=>
  import("./pages/member-management/admin-applicant-registration")
);
const MemberRenewal = React.lazy(() =>
  import("./pages/member-management/member-renewal")
);
const AcceptPayment = React.lazy(() =>
  import("./pages/member-management/accept-payments")
);
const AcceptPaymentfromNewMember = React.lazy(()=>
  import("./pages/member-management/accept-payment-new-membership")
);
const AcceptPaymentfromMiscellaneous = React.lazy(()=>
  import("./pages/member-management/accept-payment-miscellaneous")
);
// const AdminDashboard = React.lazy(()=>
//   import("./pages/member-management/admin-dashboard")
// );
const AdminViewApplicant = React.lazy(() =>
  import("./pages/member-management/admin-view-applicant")
);
/************* CPD Module *************/
const DevelopmentGoals = React.lazy(() =>
  import("./pages/cpd-module/development-goals")
);
const Activities = React.lazy(() =>
  import("./pages/cpd-module/cpd-activities")
);
const Settings = React.lazy(() => 
import("./pages/cpd-module/cpd-settings")
);
const CpdMemberProfile = React.lazy(() =>
  import("./pages/cpd-module/cpd-member-profile")
);
const Meetings = React.lazy(()=>
  import("./pages/meeting-minutes/upload-meeting-minutes")
);
/*-------------------User Management- End----------*/


//-----------Parameter Management--------------------//

const globaldata = React.lazy(() =>
  import("./pages/parameter-management/global-data")
);

//---------------------------Parameter Management  END----------------//



// ------------------- Admin Silwath---------------------//
const MemberDetailsView = React.lazy(() =>
  import("./pages/member-management/adminapplication-details")
);

const ApproveTransactions = React.lazy(() =>
  import("./pages/member-management/approve-transactions")
);

const ApproveCpdMembership = React.lazy(() =>
  import("./pages/member-management/approve-cpdmembership")
);

const ViewApplicationCouncil = React.lazy(() =>
  import("./pages/member-management/view-applications-council")
);

const ViewMemberDetails = React.lazy(() =>
  import("./pages/member-management/view-member-details")
);

const NewsFeed = React.lazy(() =>
  import("./pages/member-management/news-feed")
);

const adminRoutes = [
  {
    path: "/not-found",
    isPrivate: true,
    exact: true,
    name: "404 Not Found",
    component: NotFound,
  },
  {
    path: "/select-option",
    isPrivate: true,
    exact: true,
    name: "Select an Option",
    component: SelectOption,
  },
  // {
  //   path: "/*",
  //   isPrivate: true,
  //   exact: true,
  //   name: "404 Not Found",
  //   component: NotFound,
  // },
  {
    path: "/member/member-dashboard",
    isPrivate: true,
    exact: true,
    name: "Member",
    component: MemberDashboard,
  },
  {
    path: "/member/admin-dashboard",
    isPrivate: true,
    exact: true,
    name: "admin",
    component: AdminDashboard,
  },
  {
    path: "/member/application-dashboard",
    isPrivate: true,
    exact: true,
    name: "applicant",
    component: ApplicantDashboard,
  },

  /* ----------Kusal-----------*/
  {
    path: "/user/rolecreation",
    isPrivate: true,
    exact: true,
    name: "Role Creation",
    component: RoleCreation,
  },
  /* {
     path: '/user/roleauth', isPrivate: true, exact: true, name: 'Role Authorization', component: RoleAuthorization,
   },*/
  {
    path: "/user/password-reset",
    isPrivate: true,
    exact: true,
    name: "Password Reset",
    component: PasswordReset,
  },

  {
    path: "/user/profile",
    isPrivate: true,
    exact: true,
    name: "View Profile",
    component: ViewProfile,
  },

  {
    path: "/user/usercreation",
    isPrivate: true,
    exact: true,
    name: "User Creation",
    component: UserCreation,
  },
  {
    path: "/user/userauth",
    isPrivate: true,
    exact: true,
    name: "User Creation",
    component: UserAuthorization,
  },
  {
    path: "/user/all",
    isPrivate: true,
    exact: true,
    name: "User Creation",
    component: ViewAllUsers,
  },
  {
    path: "/user/inquiries",
    isPrivate: true,
    exact: true,
    name: "Inquires",
    component: Inquiries,
  },
  {
    path: "/user/blockaccess",
    isPrivate: true,
    exact: true,
    name: "Block Menu",
    component: BlockMenu,
  },
  {
    path: "/user/memberdetails",
    isPrivate: true,
    exact: true,
    name: "MemberDetail",
    component: MemberDetails,
  },
  {
    path: "/user/member-profile",
    isPrivate: true,
    exact: true,
    name: "MemberProfile",
    component: MemberProfile,
  },
  {
    path: "/user/change-password",
    isPrivate: true,
    exact: true,
    name: "ChangePassword",
    component: ChangePassword,
  },



  /*{
    path: '/master/basestyle', isPrivate: true, exact: true, name: 'Basestyle Details', component: BasestyleDetails,
  },
  {
    path: '/master/itemmaster', isPrivate: true, exact: true, name: 'Item Master', component: ItemMaster,
  }*/



  {
    path: "/param/globaldata",
    isPrivate: true,
    exact: true,
    name: "global Details",
    component: globaldata,
  },



  // --------------- kanishka --------------------------

  {
    path: "/member/applicationdetails",
    isPrivate: true,
    exact: true,
    name: "Application Detail",
    component: ApplicationDetails,
  },
  {
    path: "/member/admineditmember",
    isPrivate: true,
    exact: true,
    name: "Edit Member",
    component: EDitMember,
  },
  {
    path: "/member/adminapplicantregistration",
    isPrivate: true,
    exact: true,
    name: "Applicant Registration",
    component: ApplicantRegistration,
  },
  {
    path: "/member/viewapplications",
    isPrivate: true,
    exact: true,
    name: "View Application",
    component: ViewApplications,
  },
  {
    path: "/member/viewmembers",
    isPrivate: true,
    exact: true,
    name: "View Members",
    component: ViewMembers,
  },
  {
    path: "/user/membership-fees",
    isPrivate: true,
    exact: true,
    name: "Membership Fees",
    component: MembershipFees,
  },
  {
    path: "/member/approvemember",
    isPrivate: true,
    exact: true,
    name: "Admin Member Approval",
    component: ApproveMember,
  },
  {
    path: "/member/memberrenewal",
    isPrivate: true,
    exact: true,
    name: "Member Renewal",
    component: MemberRenewal,
  },
  {
    path: "/member/acceptpayment",
    isPrivate: true,
    exact: true,
    name: "Accept Payments",
    component: AcceptPayment,
  },
  {
    path: "/member/accept-payment-new-membership",
    isPrivate: true,
    exact: true,
    name: "Accept Payments New Membership",
    component: AcceptPaymentfromNewMember,
  },
  {
    path: "/member/accept-payment-miscellaneous",
    isPrivate: true,
    exact: true,
    name: "Accept Payments Miscellaneous",
    component: AcceptPaymentfromMiscellaneous
  },
  {
    path: "/member/adminviewmember",
    isPrivate: true,
    exact: true,
    name: "Member Details View",
    component: MemberDetailsView,
  },
  {
    path: "/member/adminviewapplicant",
    isPrivate: true,
    exact: true,
    name: "Admin View Applicant",
    component: AdminViewApplicant,
  },
  {
    path: "/cpd/developmentgoals",
    isPrivate: true,
    exact: true,
    name: "Development Goals",
    component: DevelopmentGoals,
  },
  {
    path: "/cpd/activities",
    isPrivate: true,
    exact: true,
    name: "Activities",
    component: Activities,
  },

  {
    // path: "/cpd/settings",
    path: "/cpd/member-registration",
    isPrivate: true,
    exact: true,
    name: "Settings",
    component: Settings,
  },

  {
    path: "/cpd/cpd-member-profile",
    isPrivate: true,
    exact: true,
    name: "CpdMemberProfile",
    component: CpdMemberProfile,
  },

  {
    path: "/member/approvetransaction",
    isPrivate: true,
    exact: true,
    name: "Approve Transactions",
    component: ApproveTransactions,
  },

  {
    path: "/member/approvecpdmembership",
    isPrivate: true,
    exact: true,
    name: "Approve Cpd Membership",
    component: ApproveCpdMembership,
  },

  {
    path: "/member/view-application-council",
    isPrivate: true,
    exact: true,
    name: "View Application Council",
    component: ViewApplicationCouncil,
  },

  {
    path: "/member/view-member-details",
    isPrivate: true,
    exact: true,
    name: "View Member Details",
    component: ViewMemberDetails,
  },
  
  {
    path: "/member/news-feed",
    isPrivate: true,
    exact: true,
    name: "News Feed",
    component: NewsFeed,
  },
  
  {
    path: "/upload-minutes/view-upload-minutes",
    isPrivate: true,
    exact: true,
    name: "Meetings",
    component: Meetings,
  },
  
];

export { adminRoutes, externalRoutes };
