import base64Url from 'base64-url'
import InvalidTokenError from '../common/exceptions/InvalidTokenError'
import NoTokenExistError from '../common/exceptions/NoTokenExistError'
import CookieHandler from './CookieHandler.service'

class TokenHandler {
    token = false

    setToken(token) {
        this.token = token
        // CookieHandler.set(token, 'auth')
        window.sessionStorage.setItem('auth', token)
    }

    removeToken() {
        // CookieHandler.destroy('auth')
        window.sessionStorage.removeItem('auth')
    }

    isVerified() {
        try {
            let tokenToVerify = false
            if (this.token) tokenToVerify = this.token
            // else if (CookieHandler.get('auth')) tokenToVerify = CookieHandler.get('auth')
            else if (window.sessionStorage.getItem('auth')) tokenToVerify = window.sessionStorage.getItem('auth')
            if (!tokenToVerify) {
                throw new NoTokenExistError()
            }
            else {
                const tokenData = this.decodeToken(tokenToVerify)
                const { exp } = tokenData

                //document url
                const global = JSON.parse(window.sessionStorage.getItem("Global_Data"));
                const docmentUrl = (document.URL).split(global.frontURL);
                // store all UserMenu values into a variable
                const usermenuObject = window.sessionStorage.getItem("URLList");
                // store items in a variable
                const usermenuItems = JSON.parse(usermenuObject);
                console.log((document.URL));
                console.log((global.frontURL)); //http://localhost/3000
                let urlSet = new Set();

                for (let i = 0; i < usermenuItems.length; i++) {
                    if (usermenuItems[i].url !== undefined) {
                        urlSet.add(usermenuItems[i].url)
                        console.log(urlSet);
                    }
                }

                if (urlSet.has(docmentUrl[1])) {
                    console.log(docmentUrl[1]);
                    console.log("Have Access");
                } else {
                    //change
                    console.log(docmentUrl[1]);
                    console.log("Don't Have Access");
                    //   tokenHandler.removeToken();
                    //    sessionStorage.clear();
                    //   window.location.replace('/');
             
                }


                // *1000 to get timestamp in milliseconds
                if (Number(exp) * 1000 < Number(new Date())) {
                    throw new InvalidTokenError()
                }
            }
            this.token = tokenToVerify
            return true
        }
        catch (e) {
            this.token = false
            return false
        }
    }

    decodeToken(token) {
        let tokenToDecode = false
        if (typeof token !== 'undefined') tokenToDecode = token
        else if (this.token) tokenToDecode = this.token
        // else if (CookieHandler.get('auth')) tokenToDecode = CookieHandler.get('auth')
        else if (window.sessionStorage.getItem('auth')) tokenToDecode = window.sessionStorage.getItem('auth')

        if (tokenToDecode) {
            try {
                this.token = tokenToDecode
                return JSON.parse(base64Url.decode(String(tokenToDecode).split('.')[1]))
            }
            catch {
                this.token = false
                throw new InvalidTokenError()
            }
        }
        else {
            this.token = false
            throw new NoTokenExistError()
        }
    }

    getToken() {
        if (this.isVerified()) return this.token
        throw new InvalidTokenError()
    }
}

const tokenHandler = new TokenHandler()
export default tokenHandler
