import React, { memo, useEffect, useState } from "react";
import ApplicationAction from "./viewApplication.view";
import { useDispatch, useSelector } from "react-redux";
import { actions as RoleActions } from "./";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { withRouter } from "react-router-dom";
import userService from "./../../../services/User.service";
import applicationsService from "./../../../services/Application.service";
import FilterComponent from "../../../components/shared/TableSearch";

export default memo(({ history }) => {
  const dispatch = useDispatch();

  const {
    initialLoad,
    isError,
    message,
    isAddRequestSent,
    isUpdateRequestSent,
    isDeleteRequestSent,
  } = useSelector((state) => state.applicationDetails); //work

  //Table State
  const [TableData, setTableData] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortColumn, setSortColumn] = useState("applicationId");
  const [editData, setEditData] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [filterText, setFilterText] = React.useState("");
  const [isModelView, setIsModelView] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  //Table methods
  const fetchViewApplicationLists = async (page) => {
    setLoading(true);

    const response = await applicationsService
      .viewApplicationLists({
        page,
        perPage,
        sortColumn,
        sortDirection,
        filterText,
      })
      .then((response) => {
        setTableData(response.data);
        setData(response.data);
        setTotalRows(response.total);
        setLoading(false);
      });
  };

  const handlePageChange = (page) => {
    fetchViewApplicationLists(page);
  };

  const handlePerRowsChange = (perPage) => {
    setPerPage(perPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortColumn(column.selector);
    setSortDirection(sortDirection);
    fetchViewApplicationLists(1);
  };

  // useEffect(() => {
  //    fetchViewApplicationLists(1);
  // }, [
  //   perPage,
  //   filterText,

  // ]);

  useEffect(() => {
    if (!isAddRequestSent) {
      fetchViewApplicationLists(1);
    }
  }, [
    perPage,
    filterText,
    isAddRequestSent,
    isUpdateRequestSent,
    isDeleteRequestSent,
  ]);

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  // const editViewApplicationHandler = (data) => {
  //   dispatch(RoleActions.sendGRNDataToEdit(data));
  //   history.push("/storage/create-grn");
  // };

  const editViewApplicationHandler = (data) => {
    window.sessionStorage.setItem("applicationApproveData", null);
    window.sessionStorage.setItem("applicationApproveData", JSON.stringify(data));
    dispatch(RoleActions.sendGRNDataToEdit(data));
    history.push("/member/adminviewapplicant");
  };

  const props = {
    loading,
    totalRows,
    handlePageChange,
    handlePerRowsChange,
    handleSort,
    subHeaderComponentMemo,
    editData,
    setEditData,
    isModelView,
    setIsModelView,
    //UserStatus,
    //Options,
    editViewApplicationHandler,
    history,
    TableData,
  };

  return <ApplicationAction {...props} />;
});
//export default viewApplicantConfirmContainer;
