import React, { useState } from "react";

import Aux from "../../../hoc/_Aux";
import { Row, Col, Card, Form, Button, Image, Badge } from "react-bootstrap";
import "./../../../assets/scss/style.scss";
import Modal from "react-bootstrap/Modal";
import DataTable from "react-data-table-component";
import FormikForm from "../../../components/shared/Forms/FormikForm";
import { Edit, Delete, Auth } from "../../../components/shared/TableButtons";
import customDataTableStyle from "../../../assets/css/customCSS";
import UserRole from "../../../components/shared/UserRoles";
import { FaSave, FaSave as SaveIcon } from "react-icons/fa";
import Select from "react-select";
import { QrReader } from "react-qr-reader";
// import "./../../../assets/scss/";
import * as yup from "yup";

export default (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showED, setShowED] = useState(false);
  const handleCloseED = () => setShowED(false);
  const handleShowED = () => setShowED(true);

  const [showAQ, setShowAQ] = useState(false);
  const handleCloseAQ = () => setShowAQ(false);
  const handleShowAQ = () => setShowAQ(true);

  const [showOPB, setShowOPB] = useState(false);
  const handleCloseOPB = () => setShowOPB(false);
  const handleShowOPB = () => setShowOPB(true);

  // click


  const {

    addEmployeeDetails,

    data,
    loading,
    model,
    setmodel,
   // updatemember,
    addEployeeDetails,
    InitialData,
    onSubmit,
    editUserHandler,
    deleteUserHandler,
    totalRows,
    resetPaginationToggle,
    handlePageChange,
    handlePerRowsChange,
    handleSort,
    subHeaderComponentMemo,
    editData,
    setEditData,
    isModelView,
    setIsModelView,

    academic_data,
    loading_,
    totalRows_,
    resetPaginationToggle_,
    handlePageChangeACC,
    handlePerRowsChangeACC,
    handleSortACC,
    subHeaderComponentMemoACC,

    professionalMembership_data,
    loading__,
    totalRows__,
    resetPaginationToggle__,
    handlePageChangeMem,
    handlePerRowsChangeMem,
    handleSortMem,
    subHeaderComponentMemoMem,

    member,
    memberNo,


    //click
    handleChange,
    updated,
    handleClick,
  } = props;

  const schema = yup.object().shape({});

//Admin View Member Employee Detail Table
  const column = [
    {
      name: "Start Date",
      selector: (data) => {
        return data.startdate;
      },
      sortable: true,
    },
    {
      name: "End Date",
      selector: (data) => {
        return data.enddate;
      },
      sortable: true,
    },
    {
      name: "Company Name",
      selector: (data) => {
        return data.companyname;
      },
      sortable: true,
    },
    {
      name: "Designation",
      selector: (data) => {
        return data.desgnation;
      },
      sortable: true,
    },
    {
      name: "Address",
      selector: (data) => {
        return data.adddress;
      },
      sortable: true,
    },
    {
      name: "Status",
      selector: (data) => {

        if (data.status == 2) {
          return (
            <Badge bg="success">
              Approved
            </Badge>)
        } else if (data.status == 1) {
          return (
            <Badge bg="warning" text="dark">
              Pending
            </Badge>)
        } else if (data.status == 16) {
          return (
            <Badge bg="danger">
              Reject
            </Badge>)
        }
      },
      sortable: false,
    },
  ];


  // Admin View Member Academic Detail
  const column1 = [
    {
      name: "Start Date",
      selector: (academic_data) => {
        return academic_data.fromdate;
      },
      sortable: true,
    },
    {
      name: "End Date",
      selector: (academic_data) => {
        return academic_data.todate;
      },
      sortable: true,
    },
    {
      name: "Institute",
      selector: (academic_data) => {
        return academic_data.institutename;
      },
      sortable: true,
    },
    {
      name: "Name of the qualification",
      selector: (academic_data) => {
        return academic_data.qualificationname;
      },
      sortable: true,
    },
    {
      name: "Address",
      selector: (academic_data) => {
        return academic_data.address;
      },
      sortable: true,
    },
    // {
    //   name: "Status",
    //   selector: (academic_data) => {
    //     return academic_data.status;
    //   },
    //   sortable: true,
    // },
    {
      name: "Status",
      selector: (data) => {

        if (data.status == 2) {
          return (
            <Badge bg="success">
              Approved
            </Badge>)
        } else if (data.status == 1) {
          return (
            <Badge bg="warning" text="dark">
              Pending
            </Badge>)
        } else if (data.status == 16) {
          return (
            <Badge bg="danger">
              Reject
            </Badge>)
        }
      },
      sortable: false,
    },
  ];


  //Admin View Member Professional Membership Detail
  const column2 = [
    {
      name: "Start Date",
      selector: (professionalMembership_data) => {
        return professionalMembership_data.startdate;
      },
      sortable: true,
    },
    {
      name: "End Date",
      selector: (professionalMembership_data) => {
        return professionalMembership_data.enddate;
      },
      sortable: true,
    },
    {
      name: "Institute/Body",
      selector: (professionalMembership_data) => {
        return professionalMembership_data.entrymethod;
      },
      sortable: true,
    },
    {
      name: "Name of the membership",
      selector: (professionalMembership_data) => {
        return professionalMembership_data.profession;
      },
      sortable: true,
    },
    {
      name: "Address",
      selector: (professionalMembership_data) => {
        return professionalMembership_data.address;
      },
      sortable: true,
    },
    {
      name: "Status",
      selector: (data) => {

        if (data.status == 2) {
          return (
            <Badge bg="success">
              Approved
            </Badge>)
        } else if (data.status == 1) {
          return (
            <Badge bg="warning" text="dark">
              Pending
            </Badge>)
        } else if (data.status == 16) {
          return (
            <Badge bg="danger">
              Reject
            </Badge>)
        }
      },
      sortable: false,
    },
  ];

  const titels = [
    { label: "Mr", value: "1" },
    { label: "Ms", value: "2" },
    { label: "Mis", value: "3" },
  ];


  const CONFIG_Employee = [
    {
      id: "company_name",
      type: "text",
      name: "company_name",
      label: "Company Name",
      placeholder: "Company Name",
      contianerProps: {
        xs: 12,
        sm: 12,
        md: 12,
      },
    },
    {
      id: "designation",
      type: "text",
      name: "designation",
      label: "Designation",
      placeholder: "Designation",
      contianerProps: {
        xs: 12,
        sm: 12,
        md: 12,
      },
    },
    {
      id: "company_address",
      type: "text",
      name: "company_address",
      label: "Address",
      placeholder: "Address",
      contianerProps: {
        xs: 12,
        sm: 12,
        md: 12,
      },
    },
    {
      id: "company_phone",
      type: "text",
      name: "company_phone",
      label: "Contact No",
      placeholder: "Phone",
      contianerProps: {
        xs: 12,
        sm: 12,
        md: 12,
      },
    },
    {
      id: "date_start",
      type: "datepicker",
      name: "date_start",
      label: "Start Date",
      calendarPopperPosition: "bottom",
      contianerProps: {
        xs: 12,
        sm: 6,
        md: 6,
      },
    },
    {
      id: "date_end",
      type: "datepicker",
      name: "date_end",
      label: "End Date",
      calendarPopperPosition: "bottom",
      contianerProps: {
        xs: 12,
        sm: 6,
        md: 6,
      },
    },
  ];

  return (
    <Aux>
      <Row>
        <Col md={12}>
          <Card>
            <Card.Header>
              <Card.Title as="h5">Member Details</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md={4}>
                  <Row>
                    <Col md={8}>
                      <Form.Group className="mb-3">
                        <Form.Label>Member Number *</Form.Label>
                        <Form.Control
                          type="text"
                          id="messages"
                          name="messages"
                          onChange={handleChange}
                          placeholder="Enter Member Number"
                        />
                        <Form.Text className="text-muted"></Form.Text>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Button
                        variant="primary"
                        type="button"
                        className="mt-4"
                        onClick={handleClick}
                      >
                        Search
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <Card>
            <Card.Header>
              <Card.Title as="h5">Member Details</Card.Title>
            </Card.Header>
            <Card.Body>
            <Row>
                <Row>
                <Col md={2} >
                {<Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="select"
                       value={InitialData.headerData.title}
                      disabled
                    />
                  </Form.Group>}
                </Col>

                <Col md={4} >
                  {<Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Member Name</Form.Label>
                    <Form.Control
                      type="text"
                       value={InitialData.headerData.FirstName}
                      disabled
                    />
                  </Form.Group>}
                </Col>           

                <Col md={3} >                
                  { <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Member Type</Form.Label>
                    <Form.Control
                      type="select"
                       value={InitialData.headerData.MemberType}
                      disabled              
                    />
                  </Form.Group> }
                  </Col>

                  <Col md={2} >
                  {<Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Contact Number</Form.Label>
                    <Form.Control
                      type="text"
                      value={InitialData.headerData.ContactNumber}
                      disabled
                    />
                  </Form.Group>}
                </Col>
                </Row>

                <Row>
                <Col md={2} >                
                  { <Form.Group controlId="exampleForm.ControlInput1" className = "mt-2">
                    <Form.Label>NIC or Passport No</Form.Label>
                    <Form.Control
                      type="text"
                      value={InitialData.headerData.NIC}
                      disabled              
                    />   </Form.Group> }
                    </Col> 

                    <Col md={4} >                
                  { <Form.Group controlId="exampleForm.ControlInput1" className = "mt-2">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      value={InitialData.headerData.address}
                      disabled              
                    />
                  </Form.Group> }
                  </Col>   

                <Col md={3} >                
                  { <Form.Group controlId="exampleForm.ControlInput1" className = "mt-2">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      value={InitialData.headerData.Email}
                      disabled              
                    />
                  </Form.Group> }
                  </Col>                  

                    <Col md={3} >                
                  { <Form.Group controlId="exampleForm.ControlInput1" className = "mt-2">
                    <Form.Label>Designation</Form.Label>
                    <Form.Control
                      type="text"
                      value={InitialData.headerData.Designation}
                      disabled              
                    />   </Form.Group> }
                    </Col>                    
                  </Row>
                                       
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>


      {/* Employment Details Section */}
      <Row>
        <Col md={12}>

        </Col>
      </Row>
    </Aux>
  );
};
