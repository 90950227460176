import APIService from "./Api.service"
import HttpRequestError from "../common/exceptions/HttpRequestError"
import UserBadCredentialsError from "../common/exceptions/User/UserBadCredentialsError";
import UserUnknownError from "../common/exceptions/User/UserUnknownError";
import { preloadAll } from "react-loadable";

const API_BASE_URL = process.env.REACT_APP_API_URL

class UserService extends APIService {
  serviceEndpoint = API_BASE_URL

  static handleError = (error) => {
    if (error instanceof HttpRequestError) {
      console.log(error.statusCode)
      if (error.statusCode === 401) throw new UserBadCredentialsError()
    }
    throw new UserUnknownError()
  }

  async passwordReset(oldPassword, newPassword, confirmPassword) {
    try {
      const data = await this.post("usermanagement/reset/password", {
        oldPassword,
        newPassword,
        confirmPassword
      })
      return data
    } catch (error) {
      return UserService.handleError(error)
    }
  }

  // async createUser(username, userRoleName, firstName, lastName, email, userDescription, userIsNewEmployee) {
  //   try {
  //     const data = await this.post("usermanagement/usercreation", {
  //       username,
  //       userRoleName,
  //       firstName,
  //       lastName,
  //       email,
  //       userDescription,
  //       userIsNewEmployee
  //     })
  //     return data
  //   } catch (error) {
  //     return UserService.handleError(error)
  //   }
  // }


  async createUser(userdetails) {
    try {

      const data = await this.post(`usermanagement/usercreation`, userdetails);
      return data;
    } catch (error) {
      return UserService.handleError(error);
    }
  }

  async usersList(page = 1, rowsPerPage = 10, searchText = 0, sortParameter = -1, sortType = "asc",) {
    try {
      const data = await this.get("usermanagement/getUserListInCompany", {}, {
        searchText,
        sortParameter,
        sortType,
        page,
        rowsPerPage
      })
      return data
    } catch (error) {
      return UserService.handleError(error)
    }
  }

  async roleList(para) {
    try {
      const data = await this.get(
        'usermanagement/alluserroles',
        {},
        para
      );
      return data
    } catch (error) {
      return UserService.handleError(error)
    }
  }

  async unauthorizeRoleList(para) {
    try {
      const data = await this.get(
        'usermanagement/getallunauthorizeroles',
        {},
        para
      );
      return data
    } catch (error) {
      return UserService.handleError(error)
    }
  }

  async addRole(para) {
    console.log("data")
    try {
      const data = await this.post("usermanagement/rolecreation", para)
      console.log(data)
      return data
    } catch (error) {
      return UserService.handleError(error)
    }
  }



  async getUserRoleList() {
    try {
      const data = await this.get("usermanagement/allrolelist", {})
      return data
    } catch (error) {
      return userService.handleError(error)
    }
  }

  async getAllDevelopmentGoals() {
    try {
      const data = await this.get("usermanagement/allDevelopmentGoals", {})
      return data
    } catch (error) {
      return userService.handleError(error)
    }
  }



  async getUserStatusList() {
    try {
      const data = await this.get("usermanagement/alluserstatus", {})
      console.log(data)
      return data
    } catch (error) {
      return userService.handleError(error)
    }
  }



  async updateRole(para) {
    try {
      console.log(para);
      const data = await this.put(`usermanagement/updaterole`, para);
      return data;
    } catch (error) {
      return UserService.handleError(error);
    }
  }

  async deleteRole({ userRoleID, userRoleName, userRoleDescription }) {
    try {
      const data = await this.delete(`usermanagement/deleterole/${userRoleID}`);
      return data;
    } catch (error) {
      return UserService.handleError(error);
    }
  }

  async getPendingUserAuthorizaion(para) {
    try {
      const data = await this.get(
        `usermanagement/userauth`,
        {},
        para
      );
      return data;
    } catch (error) {
      return UserService.handleError(error);
    }
  }

  async getPendingMemberAuthorizaion(para) {
    try {
      const data = await this.get(
        `usermanagement/memberauth`,
        {},
        para
      );
      return data;
    } catch (error) {
      return UserService.handleError(error);
    }
  }

  async updateUser(para) { 
    try {
      const data = await this.put(`usermanagement/userauth`, para);

      return data;
    } catch (error) {
      return UserService.handleError(error);
    }
  }

  async updateMem(para) { 
    try {
      const data = await this.put(`usermanagement/memberauth`, para);

      return data;
    } catch (error) {
      return UserService.handleError(error);
    }
  }

  async getUserRoleMenuAccessTable(para) {
    console.log(para);
    try {
      const data = await this.get(
        `usermanagement/menuaccesstable/allowed/${para.userSwitch}/roleid/${para.selectedRole}`,
        {},
        para
      );
      return data;
    } catch (error) {
      return UserService.handleError(error);
    }
  }

  async getUserRoleOptionsList() {
    console.log("Get Options")
    try {
      const data = await this.get("usermanagement/activemenuitems/all", {})
      return data
    } catch (error) {
      return userService.handleError(error)
    }
  }

  async getRoleEditData(para, isAllowed) {
    try {

      const data = await this.get(`usermanagement/roleaccess/allowed/${isAllowed}/roleid/${para}`);

      return data;
    } catch (error) {
      return userService.handleError(error);
    }
  }

  async getUser(para) {
    try {
      const data = await this.get(`para/users`, {}, para);
      return data;
    } catch (error) {
      return userService.handleError(error);
    }
  }

  async getMenuAccessListForUser(para, isAllowed) {
    try {

      const data = await this.get(`usermanagement/useraccess/allowed/${isAllowed}/userID/${para}`);

      return data;
    } catch (error) {
      return userService.handleError(error);
    }
  }

  async blockMenu(para) {
    try {
      console.log(para);
      const data = await this.put(`usermanagement/blockmenu`, para);
      return data;
    } catch (error) {
      return UserService.handleError(error);
    }
  }
  async quickmemberRegistration(para) {
    try {
      console.log(para);
      const data = await this.post(`usermanagement/member/quickregister`, para);
      return data;
    } catch (error) {
      return UserService.handleError(error);
    }
  }
  async getMemberDetailsTouUpdate(userid) {
    try {

      const data = await this.get(`usermanagement/member/${userid}`);
      console.log(userid);
      return data;
    } catch (error) {
      return UserService.handleError(error);
    }
  }

  async getUserDetailsTouUpdate(userid) {
    try {

      const data = await this.get(`usermanagement/user/${userid}`);
      console.log(userid);
      return data;
    } catch (error) {
      return UserService.handleError(error);
    }
  }

  async getMemberDetailsForMemberRenewal() {
    try {

      const data = await this.get(`usermanagement/member-view-for-member-renewal`);
      return data;
    } catch (error) {
      return UserService.handleError(error);
    }
  }

  
  async getApplicationDetails(userid) {

    console.log(userid);

    const userObject = window.sessionStorage.getItem("User");
    console.log(JSON.parse(userObject));
    const newUser = JSON.parse(userObject);
    console.log(newUser.userid);

    try {

      const data = await this.get(`usermanagement/applicant/${newUser.userid}`);
      return data;
    } catch (error) {
      return UserService.handleError(error);
    }
  }


  async updateMemberDetails(memberData) {
    try {

      const data = await this.post(`usermanagement/member/updatemember`, memberData);
      console.log(memberData);
      return data;
    } catch (error) {
      return UserService.handleError(error);
    }
  }


  async updateApplicantDetails(applicantData) {
    try {

      const data = await this.post(`usermanagement/member/updateApplicantDetail`, applicantData);
      console.log(applicantData);
      return data;
    } catch (error) {
      return UserService.handleError(error);
    }
  }

  async addEmploymentDe(empData) { 
    try { 
      const data = await this.post(`usermanagement/member/workexperience`, empData);
      return data;
    } catch (error) { 
      return UserService.handleError(error);
    }
  }
  
  //Academic Detail

  async addAcademicDe(acData) {
    try {

      const data = await this.post(`usermanagement/member/academicqualification`, acData);
      return data;
    } catch (error) {
      return UserService.handleError(error);
    }
  }

  //Professional Membership  Detail

  async addProfDe(pbData) {
    try {

      const data = await this.post(`usermanagement/member/professionalmembership`, pbData);
      return data;
    } catch (error) {
      return UserService.handleError(error);
    }
  }

  //Submit for approval - confirm

  async submitapprove(applicant) {
    try {

      const data = await this.post(`usermanagement/applicant/approval`, applicant);
      return data;
    } catch (error) {
      return UserService.handleError(error);
    }
  }

  //Delete Handler - Employee Detail Data Table
  async deleteUser({ employerid, companyname }) {
    try {
      const data = await this.delete(`usermanagement/employer/${employerid}`);
      return data;
    } catch (error) {
      return UserService.handleError(error);
    }
  }

   //Delete Handler - Academic Detail Data Table
   async deleteAcademicUser({ academicid, institutename }) {
    try {
      console.log(academicid);
      const data = await this.delete(`usermanagement/academic/${academicid}`);
      console.log(academicid);
      return data;
    } catch (error) {
      return UserService.handleError(error);
    }
  }

  //Delete Handler - professional Detail Data Table
  async deleteProfDetailUser({ id, profession }) {
    try {
      console.log(id);
      const data = await this.delete(`usermanagement/prof/${id}`);
      console.log(id);
      console.log(data);
      return data;
    } catch (error) {
      return UserService.handleError(error);
    }
  }
  
  async sendpwdemail(memberNo) {
    try {

      const data = await this.get(`usermanagement/send-email-to-exisiting-member-password/${memberNo}`);
      console.log(data);
      return data;
      

    } catch (error) {
      return UserService.handleError(error);
    }
  }

  async verifedotpemail(email, otp) {
    try {

      const data = await this.get(`usermanagement/member/verifedotp/${email}/${otp}`);
      return data;

    } catch (error) {
      return UserService.handleError(error);
    }
  }





  async approveAllDetailsFromAdmin(status, memberId, acData) {
    try {

      const data = await this.post(`usermanagement/member/approve-all-details-from-admin/${status}/${memberId}`, acData);
      return data;
    } catch (error) {
      return UserService.handleError(error);
    }
  }


//User Profile - Change Password
  async userProfileChangePassword(oldPassword, newPassword, confirmPassword) {
    try {
      const data = await this.post("usermanagement/reset/password", {
        oldPassword,
        newPassword,
        confirmPassword
      })
      return data
    } catch (error) {
      return UserService.handleError(error)
    }
  }


//Edit Model Details - User Profile Modal
  async UserProfileModalUpdateMemberDetails(memberData) {
    try {

      const data = await this.post(`usermanagement/member/userProfileUpdateMember`, memberData);
      return data;
    } catch (error) {
      return UserService.handleError(error);
    }
  }
  

  //Edit Member Details - Designation & Member Type
  async UserProfileUpdate(memberData) {
    try {

      const data = await this.post(`usermanagement/member/userProfileUpdate`, memberData);
      return data;
    } catch (error) {
      return UserService.handleError(error);
    }
  }
  

  // async rejectAllDetailsFromAdmin(status,memberId,acData) {
  //   try {

  //     const data = await this.post(`usermanagement/member/approve-all-details-from-admin/${status}/${memberId}`,acData);
  //     return data;
  //   } catch (error) {
  //     return UserService.handleError(error);
  //   }
  // }

  async getMemberType() {
    try {
      const data = await this.get("usermanagement/get-member-types", {})
      return data
    } catch (error) {
      return userService.handleError(error)
    }
  }

  async getMemberTitle() {
    try {
      const data = await this.get("usermanagement/get-member-titles", {})
      return data
    } catch (error) {
      return userService.handleError(error)
    }
  }

  async getAllCompanies() {
    try {
      const data = await this.get("usermanagement/get-all-companies", {})
      return data
    } catch (error) {
      return userService.handleError(error)
    }
  }

  async getAllUnivercities() {
    try {
      const data = await this.get("usermanagement/get-all-universities", {})
      return data
    } catch (error) {
      return userService.handleError(error)
    }
  }

  async otpResetPassword(bodyFormData) {
    try {

      const data = await this.post(`usermanagement/otptoresetpassword`, bodyFormData);
      return data;
    } catch (error) {
      return userService.handleError(error);
    }
  }

  async forgotPasswordMember(bodyFormData) {
    try {

      const data = await this.post(`usermanagement/forgotpassword`, bodyFormData);
      return data;
    } catch (error) {
      return userService.handleError(error);
    }
  }

  async getAllUserNames() {
    try {
      const data = await this.get("usermanagement/get-all-usernames", {})
      return data
    } catch (error) {
      return userService.handleError(error)
    }
  }


}




const userService = new UserService()
export default userService

