import { actions as passwordResetActions } from "./index"

const initialState = {
    initialLoad: true,
    isRequestSent: false,
    isError: false,
    message: "",
};

const passwordResetReducer = (state = initialState, action) => {
    switch (action.type) {
        case passwordResetActions.SEND_PASSWORD_RESET_REQUEST: {
            return {
                ...state,
                initialLoad: false,
                isRequestSent: true,
            };
        }
        case passwordResetActions.SEND_PASSWORD_RESET_SUCCESS: {
            return {
                ...state,
                isRequestSent: false,
                isError: false,
                message: action.payload,
            };
        }
        case passwordResetActions.SEND_PASSWORD_RESET_FAILED: {
            return {
                ...state,
                isRequestSent: false,
                isError: true,
                message: action.error
            };
        }
        default: return state;
    }
};

export default passwordResetReducer;