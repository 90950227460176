import React, { useState } from "react";
import { Form, InputGroup, Button, Row, Col } from "react-bootstrap";

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <Row>
      <Col xs={{ order: "last" }}>
        {" "}
        <InputGroup>
          <Form.Control
            id="search"
            type="text"
            placeholder="Search Data"
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
          />
          <Button variant="outline-success" onClick={onClear}>Clear</Button>
          {/* <ClearButton type="button" onClick={onClear}>X</ClearButton> */}
        </InputGroup>
      </Col>
    </Row>
  </>
);

export default FilterComponent;
