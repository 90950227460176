import ParaService from "./../../../services/Parameter.service";

export const SET_INITIAL = "SET_INITIAL";

export const SEND_ADD_GLOBALDATA_REQUEST = "SEND_ADD_GLOBALDATA_REQUEST";
export const SEND_ADD_GLOBALDATA_SUCCESS = "SEND_ADD_GLOBALDATA_SUCCESS";
export const SEND_ADD_GLOBALDATA_FAILED = "SEND_ADD_GLOBALDATA_FAILED";

export const SEND_UPDATE_GLOBALDATA_REQUEST = "SEND_UPDATE_GLOBALDATA_REQUEST";
export const SEND_UPDATE_GLOBALDATA_SUCCESS = "SEND_UPDATE_GLOBALDATA_SUCCESS";
export const SEND_UPDATE_GLOBALDATA_FAILED = "SEND_UPDATE_GLOBALDATA_FAILED";

export const SEND_DELETE_GLOBALDATA_REQUEST = "SEND_DELETE_GLOBALDATA_REQUEST";
export const SEND_DELETE_GLOBALDATA_SUCCESS = "SEND_DELETE_GLOBALDATA_SUCCESS";
export const SEND_DELETE_GLOBALDATA_FAILED = "SEND_DELETE_GLOBALDATA_FAILED";

export const setInitial = () => ({
  type: SET_INITIAL,
});

// ADD
const sendAddGloabalDataRequest = () => ({
  type: SEND_ADD_GLOBALDATA_REQUEST,
});

const sendAddGloabalDataSuccess = (payload) => ({
  type: SEND_ADD_GLOBALDATA_SUCCESS,
  payload,
});

const sendAddGloabalDataFailes = (error) => ({
  type: SEND_ADD_GLOBALDATA_FAILED,
  error,
});

// UPDATE
const sendUpdateGloabalDataRequest = () => ({
  type: SEND_UPDATE_GLOBALDATA_REQUEST,
});

const sendUpdateGloabalDataSuccess = (payload) => ({
  type: SEND_UPDATE_GLOBALDATA_SUCCESS,
  payload,
});

const sendUpdateGloabalDataFailes = (error) => ({
  type: SEND_UPDATE_GLOBALDATA_FAILED,
  error,
});

// DELETE
const sendDeleteGloabalDataRequest = () => ({
  type: SEND_DELETE_GLOBALDATA_REQUEST,
});

const sendDeleteGloabalDataSuccess = (payload) => ({
  type: SEND_DELETE_GLOBALDATA_SUCCESS,
  payload,
});

const sendDeleteGloabalDataFailes = (error) => ({
  type: SEND_DELETE_GLOBALDATA_FAILED,
  error,
});

/**
 * This method will be called when sign-in form submitted
 * @param {string} GloabalData
 * @param {string} Description
 */
// export const addGloabalData =
//   (para) =>
//   async (dispatch) => {
//     dispatch(sendAddGloabalDataRequest());
//     const GloabalDataResponse = await ParaService.addGloabalData(para);
//     try {
//       if (GloabalDataResponse) {
//         const { status, statusCode } = GloabalDataResponse;

//         if (statusCode === "00") {
//           dispatch(sendAddGloabalDataSuccess(status));
//         } else if (statusCode === "02") {
//           dispatch(sendAddGloabalDataFailes(status));
//         }
//       }
//     } catch (error) {
//       console.log("Address type catch", error);
//       dispatch(sendAddGloabalDataFailes(error));
//     }
//   };
export const updateGloabalData =
  (para) =>
    async (dispatch) => {
      dispatch(sendUpdateGloabalDataRequest());
      const GloabalDataResponse = await ParaService.updateGloabalData(para);
      try {
        if (GloabalDataResponse) {
          const { status, statusCode } = GloabalDataResponse;
          if (statusCode === "00") {
            dispatch(sendUpdateGloabalDataSuccess(status));
          } else if (statusCode === "02") {
            dispatch(sendUpdateGloabalDataFailes(status));
          }
        }
      } catch (error) {
        console.log("Address type catch", error);
        dispatch(sendUpdateGloabalDataFailes(error));
      }
    };
// export const deleteGloabalData =
//   (id) =>
//   async (dispatch) => {
//     dispatch(sendDeleteGloabalDataRequest());
//     const GloabalDataResponse = await ParaService.deleteGloabalData(id);
//     try {
//       if (GloabalDataResponse) {
//         const { status, code } = GloabalDataResponse;

//         if (code === "00") {
//           dispatch(sendDeleteGloabalDataSuccess(status));
//         } else if (code === "02") {
//           dispatch(sendDeleteGloabalDataFailes(status));
//         }
//       }
//     } catch (error) {
//       console.log("Address type catch", error);
//       dispatch(sendDeleteGloabalDataFailes(error));
//     }
//   };
