import APIService from "./Api.service"
import HttpRequestError from "../common/exceptions/HttpRequestError"
import AuthUnknownError from "../common/exceptions/Auth/AuthUnknownError"
import AuthBadCredentialsError from "../common/exceptions/Auth/AuthBadCredentialsError"

const API_BASE_URL = process.env.REACT_APP_API_URL

class AuthService extends APIService {
    serviceEndpoint = API_BASE_URL

    static handleError = (error) => {
        console.log("handleError",error)
        if (error instanceof HttpRequestError) {
            if (error.statusCode === 401) throw new AuthBadCredentialsError()
        }
        throw new AuthUnknownError()
    }

    /**
     * Sign-in request
     * @param username
     * @param password
     * @return {Promise<void>}
     */
    async login(username, password) {
        try {
            console.log("login up====>>>")
            const data = await this.post("auth/signin", {
                username,
                password
            })
            console.log("login down====>>>",data)
            return data
        } catch (error) {
            console.log("error====>>>",error)
            return AuthService.handleError(error)
        }
    }

}


const authService = new AuthService()
export default authService
