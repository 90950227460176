import React from "react";
import { Form, Col } from "react-bootstrap";

export default ({ title, text, className, md, sm, xs, style }) => (
    <>
        <Col md={md} sm={sm} xs={xs} className={className} style={style}>
            <Form.Label>{title}</Form.Label>
            <Form.Control type="text" value={text} readOnly />
        </Col>
    </>
)