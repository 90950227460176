import React, { memo, useEffect } from "react";
import { useDispatch } from "react-redux";
import PasswordReset from "./PasswordReset.view";
import { actions as PasswordResetActions } from "./index";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export default memo(() => {
  const dispatch = useDispatch();
  const passwordResetState = useSelector((state) => state.passwordreset);

  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  useEffect(() => {
    if (passwordResetState.isRequestSent == false) {
      console.log(!(passwordResetState.initialLoad))
      console.log(passwordResetState.isError)
      if (!passwordResetState.initialLoad && !passwordResetState.isError) {
        toast.success("Password Reset Successfull", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }else if(!passwordResetState.initialLoad && passwordResetState.isError){
        toast.error(`${passwordResetState.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        
      }

      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
    }
  }, [passwordResetState.isRequestSent]);

  const handleOnChange = (type) => (event) => {
    switch (type) {
      case "oldPassword":
        setOldPassword(event.target.value);
        break;

      case "newPassword":
        setNewPassword(event.target.value);
        break;

      case "confirmPassword":
        setConfirmPassword(event.target.value);
        break;

      default:
        break;
    }
  };

  const handlePasswordReset = () => {
    if (oldPassword !== "" && newPassword !== "" && confirmPassword !== "") {

      dispatch(PasswordResetActions.passwordReset(oldPassword, newPassword,confirmPassword));
    } else {
      // toast.success('🦄 Wow so easy!', {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   });
    }
  };

  const props = {
    passwordResetHandler: handlePasswordReset,
    onChangeHandler: handleOnChange,
    oldPassword,
    newPassword,
    confirmPassword,
  };
  return <PasswordReset {...props} />;
});
