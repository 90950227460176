import { actions as tranActions } from "./index"

const initialState = {
    initialLoad: true,
    isError: false,
    message: "",
    // ADD
    isAuthRequestSent: false,
};

const tranReducer = (state = initialState, action) => {
    switch (action.type) {
        // ADD
        case tranActions.SEND_AUTH_TRAN_REQUEST: {
            return {
                ...state, 
                initialLoad: false,
                isAuthRequestSent: true,
            };
        }
        case tranActions.SEND_AUTH_TRAN_SUCCESS: {
            return {
                ...state,
                isAuthRequestSent: false,
                isError: false,
                message: action.payload.statusCode,
            };
        }

        case tranActions.SEND_AUTH_TRAN_REJECT: {
            return {
                ...state,
                isAuthRequestSent: false,
                isError: false,
                message: action.payload.statusCode,
            };
        }

        case tranActions.SEND_AUTH_TRAN_FAILED: {
            return {
                ...state,
                isAuthRequestSent: false,
                isError: true,
                message: action.error
            };
        }
        default: return state;
    }
};

export default tranReducer;