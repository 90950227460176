import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoginView from "./Login.view";
import { actions as LoginActions } from "./index";
import ParaService from "./../../../services/Parameter.service";

//Global Data for the Aplication
const P_GLOBAL_ID = process.env.REACT_APP_P_GLOBAL_ID;
const P_GLOBAL_VERSION = process.env.REACT_APP_P_GLOBAL_VERSION;
const P_GLOBAL_USER_EXP = process.env.REACT_APP_P_GLOBAL_USER_EXP;

export default memo(() => {
  // Todo: Connect with redux
  const dispatch = useDispatch();
  const [userName, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [badCredentials, setbadCredentials] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const { loginInProgress, isAuthenticated, isBadCredentials, user } = useSelector((state) => state.session);

  useEffect(() => {
    console.log('isBadCredentials',isBadCredentials)
    setbadCredentials(isBadCredentials)
    setTimeout(() => setbadCredentials(false), 3000)
  }, [isBadCredentials]);

  useEffect(() => {
    ParaService.getGlobalDetails(P_GLOBAL_ID).then((data) => {
      console.log(data);

      window.sessionStorage.setItem("Global_Data", JSON.stringify(data));
    });
  }, []);


  const handleSignIn = () => {
    if (userName !== "" && password !== "")
      dispatch(LoginActions.authenticateUser(userName, password));
  };
  const handleOnChange = (type) => (event) => {
    if (type === "password") {
      setPassword(event.target.value);
    } else if (type === "userName") {
      setUsername(event.target.value);
    }
  };
  const props = {
    loginHandler: handleSignIn,
    onChangeHandler: handleOnChange,
    userName,
    password,
    badCredentials,
    showPassword,
    togglePasswordVisibility
  };

  return <LoginView {...props} />;
});

/* Feature allowed
* Redux(global state connect)
* Onchange

// Todo :Check url on export
// ''

 */
