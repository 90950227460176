/*
* created By : Janith Gamage
* on : 2022.002.03
* Desc : custom styles for data table 
*/

//custom style for data table  
const customDataTableStyle = {
    headCells: {
        style: {
            'backgroundColor': '#f4f7fa',
            'fontSize': '14px',
            'fontWeight': 'bold',
            // 'border': '1px solid black'
        },
    },
    // rows: {
    //     style: {
    //         minHeight: '72px', // override the row height
    //     },
    // },
    // cells: {
    //     style: {
    //         paddingLeft: '8px', // override the cell padding for data cells
    //         paddingRight: '8px',
    //     },
    // },
};

export default customDataTableStyle;