import { actions as cpdActions } from "./index"

const initialState = {
    initialLoad: true,
    isError: false,
    message: "",
    // ADD
    isAuthRequestSent: false,
};

const cpdReducer = (state = initialState, action) => {
    switch (action.type) {
        // ADD
        case cpdActions.SEND_AUTH_CPD_REQUEST: {
            return {
                ...state, 
                initialLoad: false,
                isAuthRequestSent: true,
            };
        }
        case cpdActions.SEND_AUTH_CPD_SUCCESS: {
            return {
                ...state,
                isAuthRequestSent: false,
                isError: false,
                message: action.payload.statusCode,
            };
        }

        case cpdActions.SEND_AUTH_CPD_REJECT: {
            return {
                ...state,
                isAuthRequestSent: false,
                isError: false,
                message: action.payload.statusCode,
            };
        }

        case cpdActions.SEND_AUTH_CPD_FAILED: {
            return {
                ...state,
                isAuthRequestSent: false,
                isError: true,
                message: action.error
            };
        }
        default: return state;
    }
};

export default cpdReducer;