import applicationsService from "./../../../services/Application.service";

export const SEND_AUTH_TRAN_REQUEST = "SEND_AUTH_TRAN_REQUEST";
export const SEND_AUTH_TRAN_SUCCESS = "SEND_AUTH_TRAN_SUCCESS";
export const SEND_AUTH_TRAN_FAILED = "SEND_AUTH_TRAN_FAILED";
export const SEND_AUTH_TRAN_REJECT = "SEND_AUTH_TRAN_REJECT";

// ADD
const sendAuthTransactionRequest = () => ({ 
  type: SEND_AUTH_TRAN_REQUEST,
});

const sendAuthTransactionSuccess = (payload) => ({
  type: SEND_AUTH_TRAN_SUCCESS,
  payload,
});

const sendAuthTransactionReject = (payload) => ({
  type: SEND_AUTH_TRAN_REJECT,
  payload,
});

const sendAuthTransactionFailes = (error) => ({
  type: SEND_AUTH_TRAN_FAILED,
  error,
});


/**
 * This method will be called when sign-in form submitted
 * @param {string} User
 * @param {string} Description
 */
export const authTransaction =
  ( data ) =>
  async (dispatch) => {
    dispatch(sendAuthTransactionRequest());
    const tranResponse = await applicationsService.approveTransactions(data);
    try {
      if (tranResponse) {
        const { status, statusCode } = tranResponse;

        if (status === "approved") {
          dispatch(sendAuthTransactionSuccess({status, statusCode}));
        } else if (status === "rejected") {
          dispatch(sendAuthTransactionReject({status, statusCode}));
        } else if (status === "error") {
          dispatch(sendAuthTransactionFailes({status, statusCode}));
        }
      }
    } catch (error) {
      console.log("User catch", error);
      dispatch(sendAuthTransactionFailes(error));
    }
  };


