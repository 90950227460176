import React, { useState } from "react";
import Aux from "../../../hoc/_Aux";
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  Image,
  ModalHeader,
  Badge,
} from "react-bootstrap";
import "./../../../assets/scss/style.scss";
import Modal from "react-bootstrap/Modal";
import DataTable from "react-data-table-component";
import FormikForm from "../../../components/shared/Forms/FormikForm";
// import FormikForm from "../../../components/shared/Forms/FormikForm/FieldsContainer";
import { Formik } from "formik";
import { Edit, Delete, Auth } from "../../../components/shared/TableButtons";
import customDataTableStyle from "../../../assets/css/customCSS";
import UserRole from "../../../components/shared/UserRoles";
import { FaSave, FaSave as SaveIcon } from "react-icons/fa";
// import "./../../../assets/scss/";
import * as yup from "yup";
import { utils } from "react-modern-calendar-datepicker";
import { Rings } from "react-loader-spinner";

export default (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showED, setShowED] = useState(false);
  const handleCloseED = () => setShowED(false);
  const handleShowED = () => setShowED(true);

  const [showAQ, setShowAQ] = useState(false);
  const handleCloseAQ = () => setShowAQ(false);
  const handleShowAQ = () => setShowAQ(true);

  const [showOPB, setShowOPB] = useState(false);
  const handleCloseOPB = () => setShowOPB(false);
  const handleShowOPB = () => setShowOPB(true);

  const [showSubmit, setShowSubmit] = useState(false);
  const handleCloseSubmit = () => setShowSubmit(false);
  const handleShowSubmit = () => setShowSubmit(true);

  const [companyNameee, setCompanyName] = useState(1);

  const {
    firstName,
    lastName,
    password,
    confirmpassword,
    email,
    phone,
    otp_phone,
    //loader,
    loader1,
    loader2,
    handlePrintValidation,
    singupHandler,
    onChangeHandler,
    onBlurHandler,
    applicationId,
    validateEmail,
    //editRoleHandler,

    data,
    loading,
    model,
    model1,
    setmodel1,
    model2,
    setmodel2,
    loader3,
    loader4,
    viewHandler,
    setmodel,
    updatemember,

    addEployeeDetails,
    InitialData,
    onSubmit,
    editUserHandler,
    deleteUserHandler,
    deleteAcaUserHandler,
    deleteProfUserHandler,
    totalRows,
    resetPaginationToggle,
    handlePageChange,
    handlePerRowsChange,
    handleSort,
    subHeaderComponentMemo,
    editData,
    setEditData,
    isModelView,
    setIsModelView,

    onChangeHandler1,

    academic_data,
    loading_,
    totalRows_,
    resetPaginationToggle_,
    handlePageChangeACC,
    handlePerRowsChangeACC,
    handleSortACC,
    subHeaderComponentMemoACC,

    professionalMembership_data,
    loading__,
    totalRows__,
    resetPaginationToggle__,
    handlePageChangeMem,
    handlePerRowsChangeMem,
    handleSortMem,
    subHeaderComponentMemoMem,

    //Bimsara Test Save
    addEmployeeDetails,
    qualificationLevels,
    addAcademicDetail,
    addProfDetail,
    setSaveSubmitState,
    initialButtons,
    submitApproval,
    companySelect,
    memberType,
    isChecked,
    titlee,
     handleChange,
    handleChangedist,
    cities,
    provinces,
    districts,
    postalCodes,
    handleChangedcity,
    universitiess,

    companies,
    saveAndExitBtn,
    newApplicantStatus,

    //companyNameHide,

    hideCompanyNameText,
    hideQualificationNameText,

    InitialDataMem,
    InitialDataMem1,

    academicSelect,
    qualificationSelect,
    InitialDataAcademicVal,
    InitialDataAcademicLabel,
    hideAcedemicText,

    InitialDataQualificationVal,
    InitialDataQualificationLabel,

    submitForm,
    saveAndExit,
  } = props;
  // const init = {
  //   mem_type: {},
  // }

  const column = [
    {
      name: "Start Date",
      selector: (data) => {
        return data.startdate;
      },
      sortable: true,
    },
    {
      name: "End Date",
      selector: (data) => {
        return data.enddate;
      },
      sortable: true,
    },
    {
      name: "Company Name",
      selector: (data) => {
        return data.companyname;
      },
      sortable: true,
    },
    {
      name: "Designation",
      selector: (data) => {
        return data.desgnation;
      },
      sortable: true,
    },
    {
      name: "Address",
      selector: (data) => {
        return data.adddress;
      },
      sortable: true,
    },
    // {
    //   name: "Created By",
    //   selector: (data) => {
    //     return data.createdBy
    //   },
    //   sortable: true,
    // },

    // {
    //   name: "Status",
    //   selector: (data) => {
    //     return data.status
    //   },
    //   sortable: true,
    // },

    // {
    //   name: "Expire Date",
    //   selector: (data) => {
    //     console.log(data)
    //     return data.expireDate
    //   },
    //   sortable: true,
    // },

    // {
    //     name: "Action",
    //     button: true,
    //     cell: (data) => (
    //       <Delete deleteHandler={deleteUserHandler} data={data} />
    //     ),
    //   },

    {
      name: "Action",
      button: true,
      cell: (data) => (
        <>
          {/* <Edit editHandler={editUserHandler} data={data} /> */}
          <Delete deleteHandler={deleteUserHandler} data={data} />
        </>
      ),
    },
  ];

  const column1 = [
    {
      name: "Start Date",
      selector: (academic_data) => {
        return academic_data.fromdate;
      },
      sortable: true,
    },
    {
      name: "End Date",
      selector: (academic_data) => {
        return academic_data.todate;
      },
      sortable: true,
    },
    {
      name: "Institute",
      selector: (academic_data) => {
        return academic_data.institutename;
      },
      sortable: true,
    },
    {
      name: "Name of the qualification",
      selector: (academic_data) => {
        return academic_data.qualificationname;
      },
      sortable: true,
    },
    {
      name: "Address",
      selector: (academic_data) => {
        return academic_data.address;
      },
      sortable: true,
    },
    // {
    //   name: "Status",
    //   selector: (academic_data) => {
    //     return academic_data.status
    //   },
    //   sortable: true,
    // },
    // {
    //   name: "Expire Date",
    //   selector: (data) => {
    //     console.log(data)
    //     return data.expireDate
    //   },
    //   sortable: true,
    // },

    // {
    //   name: "Action",
    //   button: true,
    //   cell: (academic_data) => <><Edit editHandler={editUserHandler} academic_data={academic_data} /> <Delete deleteHandler={deleteUserHandler} academic_data={academic_data} /></>,
    // },

    {
      name: "Action",
      button: true,
      cell: (academic_data) => (
        <Delete deleteHandler={deleteAcaUserHandler} data={academic_data} />
      ),
    },
  ];

  const column2 = [
    {
      name: "Start Date",
      selector: (professionalMembership_data) => {
        return professionalMembership_data.startdate;
      },
      sortable: true,
    },
    {
      name: "End Date",
      selector: (professionalMembership_data) => {
        return professionalMembership_data.enddate;
      },
      sortable: true,
    },
    {
      name: "Institute/Body",
      selector: (professionalMembership_data) => {
        return professionalMembership_data.entrymethod;
      },
      sortable: true,
    },
    {
      name: "Name of the membership",
      selector: (professionalMembership_data) => {
        return professionalMembership_data.membername;
      },
      sortable: true,
    },
    {
      name: "Address",
      selector: (professionalMembership_data) => {
        return professionalMembership_data.address;
      },
      sortable: true,
    },

    {
      name: "Action",
      button: true,
      cell: (professionalMembership_data) => (
        <Delete
          deleteHandler={deleteProfUserHandler}
          data={professionalMembership_data}
        />
      ),
    },
  ];

  const opt = [
    { label: "Part-time", value: "1" },
    { label: "Full-time", value: "2" },
  ];

  const optEntry = [
    { label: "Examination", value: "1" },
    { label: "Exemption", value: "2" },
  ];

  let schema = yup.object().shape({
    firstname: yup
      .string()
      .nullable()
      .matches(
        /^[aA-zZ\s]+$/,
        "Please enter your first name using only letters"
      )
      .required("First Name cannot be empty")
      .max(15, "Invalid name"),

      middlename: yup
      .string()
      .nullable()
      .matches(
        /^[aA-zZ\s]+$/,
        "Please enter your middle name using only letters"
      )
      // .required("Middle Name cannot be empty")
      .max(15, "Invalid name"),

    lastname: yup
      .string()
      .nullable()
      .matches(/^[aA-zZ\s]+$/, "Please enter your last name using only letters")
      .required("Last Name cannot be empty"),

    mem_type: yup
      .object()
      .shape({
        label: yup.string().ensure().required(`Member Type cannot be empty`),
        value: yup.string().ensure().required(),
      })
      .required("This field is required")
      .nullable(),

    title_type: yup
      .object()
      .shape({
        label: yup.string().ensure().required(`Title cannot be empty`),
        // value: yup.string().ensure().required(),
      })
      .required("This field is required")
      .nullable(),

      // province: yup
      // .object()
      // .shape({
      //   label: yup.string().ensure().required(`Province cannot be empty`),
      //   // value: yup.string().ensure().required(),
      // })
      // .required("This field is required")
      // .nullable(),

      // // district: yup
      // // .object()
      // // .shape({
      // //   label: yup.string().ensure().required(`District cannot be empty`),
      // //   // value: yup.string().ensure().required(),
      // // })
      // // .required("This field is required")
      // // .nullable(),

      // city: yup
      // .object()
      // .shape({
      //   label: yup.string().ensure().required(`City cannot be empty`),
      //   // value: yup.string().ensure().required(),
      // })
      // .required("This field is required")
      // .nullable(),

      // postalCode: yup
      // .object()
      // .shape({
      //   label: yup.string().ensure().required(`Postal Code cannot be empty`),
      //   // value: yup.string().ensure().required(),
      // })
      // .required("This field is required")
      // .nullable(),



    nic: yup
      .string()
      .nullable()
      .trim()
      .max(12, "NIC number must be 12 characters long")
      .matches(
        // /^[0-9]{9}[vVxX]$/,
        /^([0-9]{9}[x|X|v|V]|[0-9]{12})$/,
        "Invalid NIC format"
      )
      .required("NIC Number cannot be empty"),

    addres_l1: yup
      .string()
      .nullable()
      .trim()
      .required("Address Line 1 cannot be empty"),
    addres_l2: yup
      .string()
      .nullable()
      .required("Address Line 2 cannot be empty")
      .trim(),

    email: yup
      .string()
      .nullable()
      .required("E-mail cannot be empty")
      .email("Please enter valid e-mail")
      .trim(),

    contactno: yup
      .string()
      .nullable()
      .required("Contact Number cannot be empty")
      .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, "Invalid contact number")
      .min(10, "Invalid contact number")
      .max(12, "Please enter valid number"),

    // dob: yup
    //   .string()
    //   .nullable()
    //   .trim()
    //   .required("This field is required"),
  });

  const CONFIG = [
    // {
    //   id: "mem_type",
    //   type: "selectOpImproved",
    //   name: "mem_type",
    //   label: "Member Type *",
    //   options: memberType,
    //   placeholder: "Select Member Type",
    //   isClearable: true,
    //   isSearchable: true,
    //   contianerProps: {
    //     xs: 12,
    //     sm: 3,
    //     md: 3,
    //   },
    // },
    {
      id: "title_type",
      type: "selectOpImproved",
      name: "title_type",
      label: "Title *",
      placeholder: "Select Title",
      options: titlee,
      isClearable: true,
      isSearchable: true,
      contianerProps: {
        xs: 12,
        sm: 3,
        md: 3,
      },
    },
    {
      id: "firstname",
      type: "text",
      name: "firstname",
      label: "First Name *",
      placeholder: "First Name",
      contianerProps: {
        xs: 12,
        sm: 3,
        md: 3,
      },
    },
    {
      id: "middlename",
      type: "text",
      name: "middlename",
      label: "Middle Name",
      placeholder: "Middle Name",
      contianerProps: {
        xs: 12,
        sm: 3,
        md: 3,
      },
    },
    {
      id: "lastname",
      type: "text",
      name: "lastname",
      label: "Last Name *",
      placeholder: "Last Name",
      contianerProps: {
        xs: 12,
        sm: 3,
        md: 3,
      },
    },

    {
      id: "nic",
      type: "disabletext",
      name: "nic",
      label: "NIC or Passport No *",
      placeholder: "NIC or Passport",
      contianerProps: {
        xs: 12,
        sm: 3,
        md: 3,
      },
      // disabled: (true)
    },

    {
      id: "dob",
      type: "datepicker",
      name: "dob",

      // onChange:(onChangeHandler1("dob")),
      label: "Date of Birth *",
      calendarPopperPosition: "bottom",
      contianerProps: {
        xs: 12,
        sm: 3,
        md: 3,
      },
      maximumDate: utils().getToday(),
    },
    
    {
      id: "contactno",
      type: "number",
      name: "contactno",
      label: "Contact Number *",
      placeholder: "Contact Number",
      onKeyPress: (event) => {
        // doesn't allow the user to enter "e" (to the power)
        if (event.key === "e") {
          event.preventDefault();
        }
      },
      contianerProps: {
        xs: 12,
        sm: 3,
        md: 3,
      },
      

      // disabled: (true)
    },

    {
      id: "email",
      type: "readOnlytext",
      name: "email",
      label: "Email *",
      placeholder: "Email",
      contianerProps: {
        xs: 12,
        sm: 3,
        md: 3,
      },
      // disabled: "true"
    },

    
    {
      id: "addres_l1",
      type: "text",
      name: "addres_l1",
      label: "Address Line 1 *",
      placeholder: "Line 1",
      contianerProps: {
        xs: 12,
        sm: 3,
        md: 3,
      },
    },
    {
      id: "addres_l2",
      type: "text",
      name: "addres_l2",
      label: "Address Line 2 *",
      placeholder: "Line 2",
      contianerProps: {
        xs: 12,
        sm: 3,
        md: 3,
      },
    },

    {
      id: "addres_l3",
      type: "text",
      name: "addres_l3",
      label: "Address Line 3 ",
      placeholder: "Line 3",
      contianerProps: {
        xs: 12,
        sm: 3,
        md: 6,
      },
      },
     {
        id: "province",
        type: "select",
        name: "province",
        label: "Province *",
        placeholder: "Select Province",
        onChange: handleChange,
        isClearable: true,
        isSearchable: true,
        options: provinces, // Add options dynamically if needed
        containerProps: {
          xs: 12,
          sm: 3,
          md: 3
        }
      },
      {
        id: "district",
        type: "select",
        name: "district",
        label: "District *",
        placeholder: "Select District",
        onChange: handleChangedist,
        isClearable: true,
        isSearchable: true,
        options: districts, // Add options dynamically if needed
        containerProps: {
          "xs": 12,
          "sm": 3,
          "md": 3
        }
      },
      {
        id: "city",
        type: "select",
        name: "city",
        label: "City *",
        placeholder: "Select City",
        onChange: handleChangedcity,
        isClearable: true,
        isSearchable: true,
        options: cities, // Add options dynamically if needed
        containerProps: {
          "xs": 12,
          "sm": 3,
          "md": 3
        }
      },
    //   // {
    //   //   id: "GNdivision",
    //   //   type: "select",
    //   //   name: "GNdivision",
    //   //   label: "Grama Niladhari Division *",
    //   //   placeholder: "Select City",
    //   //   options: [], // Add options dynamically if needed
    //   //   containerProps: {
    //   //     "xs": 12,
    //   //     "sm": 3,
    //   //     "md": 3
    //   //   }
    //   // },
      {
        id: "postalCode",
        type: "select",
        name: "postalCode",
        label: "Postal Code *",
        placeholder: "Select Postal Code",
        isClearable: true,
        isSearchable: true,
        options: postalCodes, // Add options dynamically if needed
        containerProps: {
          "xs": 12,
          "sm": 3,
          "md": 3
        }
      },
      {
        id: "comment",
        type: "text",
        name: "comment",
        label: "Comment / Suggestions",
        placeholder: "Comment / Suggestions",
        contianerProps: {
          xs: 12,
          sm: 3,
          md: 12,
        },
      }
  ];

  const EmploymentInitialValues = {
    company_name: {
      value: InitialDataMem,
      label: InitialDataMem1,
    },
    companyName: "",
    designation: "",
    company_address: "",
    company_phone: "",

    startDate: "",
    endDate: "",
    file: null,
  };

  const AcademicInitialValues = {
    institute_name: {
      value: InitialDataAcademicVal,
      label: InitialDataAcademicLabel,
    },
    academicuni: " ",
    qualification: {
      value: InitialDataQualificationVal,
      label: InitialDataQualificationLabel,
    },
    qualname: "",
    address: "",
    parttimefulltime: "",
    todate: "",
    admitdate: "",
    fromdate: "",
    // aq_from_date: null,
    // aq_to_date: null,
    // aq_recived_date: null,
    certificate: null,
  };

  const ProfInitialValues = {
    member_name: "",
    profession: "",
    grade: "",
    entrymethod: "",
    address: "",
    startdate: null,
    enddate: null,
    admit_date: null,
    certificates: null,
  };

  //1024bytes ----> 1KB
  //1024KB    ----> 1MB
  const MAX_FILE_SIZE = 5242880; //5MB
  const validFileExtensions = { file: ["pdf"] };

  function isValidFileType(fileName, fileType) {
    return (
      fileName &&
      validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
    );
  }

  const EmploymentValidationSchema = yup.object().shape({
    company_name: yup
      .object()
      .shape({
        value: yup.string().ensure().required(),
        label: yup.string().ensure().required(`Comapany name is required`),
      })
      .nullable()
      .required("Company name is required"),
    // company_name: yup.string()
    //   .required("Company name is required"),

    designation: yup.string().required("Designation is required"),
    company_address: yup.string().required("Address is required"),
    company_phone: yup
      .string()
      .required("Contact number is required")
      .matches(
        /^(\+\d{1,3}[- ]?)?\d{10}$/,

        "Please enter valid contact number"
      )
      .min(10, "Invalid phone number")
      .max(12, "Please enter valid number"),

    startDate: yup
      .object()

      .nullable()
      .required("Start date is required"),

    // endDate: yup.object().shape({
    //   value: yup.string().ensure().required(),
    //   label: yup.string().ensure().required(`End date is required`),
    //   })
    //   .nullable()
    //   .required("End date is required"),
    file: yup
      .mixed()
      .required("Service letter is required")
      .test(
        "is-valid-type",
        "Only file with the following extension is allowed: pdf",
        (value) => isValidFileType(value && value.name.toLowerCase(), "file")
      )
      .test(
        "is-valid-size",
        "The file is too large. Allowed maximum size is 5MB",
        (value) => value && value.size <= MAX_FILE_SIZE
      ),

    // file: yup.mixed().required('Service letter is required')

    // .test('fileFormat', 'Allowed file types: pdf ', (value) => {
    //   console.log(value); return value && ['application/pdf'].includes(value.type);
    // })
    // .test('fileSize', "File Size must be less than 100KB", value => value.size <= FILE_SIZE),
  });

  const AcademicValidationSchema = yup.object().shape({
    qualification: yup
      .object()
      .shape({
        label: yup.string().ensure().required(`Qualification is required`),
        value: yup.string().ensure().required(),
      })
      .required("Qualification is required")
      .nullable(),

    institute_name: yup
      .object()
      .shape({
        value: yup.string().ensure().required(),
        label: yup.string().ensure().required(`Institute name is required`),
      })
      .nullable()
      .required("Institute name is required"),

    parttimefulltime: yup
      .object()
      .shape({
        value: yup.string().ensure().required(),
        label: yup.string().ensure().required(`Parttime/Fulltime is required`),
      })
      .nullable()
      .required("Parttime/Fulltime is required"),

    address: yup.string().nullable(),

    fromDate: yup
      .object()

      .nullable()
      .required("From date is required"),

    toDate: yup
      .object()

      .nullable()
      .required("To date is required"),

    admitDate: yup.object().nullable().required("Received date is required"),

    //   fromDate: yup.object().shape({
    //     value: yup.string().ensure().required(),
    //     label: yup.string().ensure().required(`From date is required`),
    //   })
    //     .nullable()
    //     .required("From date is required"),

    // toDate: yup.object().shape({
    //     value: yup.string().ensure().required(),
    //     label: yup.string().ensure().required(`To date is required`),
    //     })
    //     .nullable()
    //     .required("To date is required"),

    // admitDate: yup.object().shape({
    //   value: yup.string().ensure().required(),
    //   label: yup.string().ensure().required(`Received date is required`),
    //   })
    //   .nullable()
    //   .required("Received date is required"),

    certificate: yup
      .mixed()
      .required("Certificate is required")
      .test(
        "is-valid-type",
        "Only file with the following extension is allowed: pdf",
        (value) => isValidFileType(value && value.name.toLowerCase(), "file")
      )
      .test(
        "is-valid-size",
        "The file is too large. Allowed maximum size is 5MB",
        (value) => value && value.size <= MAX_FILE_SIZE
      ),
  });

  const ProfValidationSchema = yup.object().shape({
    member_name: yup
      .string()
      .matches(/^[aA-zZ\s]+$/, "Only letters are allowed for this field ")
      .required("Membership type is required"),

    //profession: yup.string().required("Profession is required"),

    grade: yup.string().required("Grade/Membership level is required"),

    entrymethod: yup
      .object()
      .shape({
        value: yup.string().ensure().required(),
        label: yup.string().ensure().required(`Entry method is required`),
      })
      .nullable()
      .required("Entry method is required"),

    address: yup.string().nullable(),
    startdate: yup
      .object()

      .nullable()
      .required("Start date is required"),
    enddate: yup
      .object()

      .nullable()
      .required("End date is required"),

    // startdate: yup.mixed().required("Start date is required"),
    // enddate: yup.mixed().required("End date is required"),
    // admit_date: yup.mixed().required("Admit Date Required"),
    // certificates: yup.mixed().required('Certificate is required'),

    certificates: yup
      .mixed()
      .required("Certificate is required")
      .test(
        "is-valid-type",
        "Only file with the following extension is allowed: pdf",
        (value) => isValidFileType(value && value.name.toLowerCase(), "file")
      )
      .test(
        "is-valid-size",
        "The file is too large. Allowed maximum size is 5MB",
        (value) => value && value.size <= MAX_FILE_SIZE
      ),
  });

  //Submit to approval

  // const Config_approval = [
  //   {
  //     id: "comment",
  //     type: "text",
  //     name: "comment",
  //     label: "Comment",
  //     contianerProps: {
  //       xs: 12,
  //       sm: 5,
  //       md: 5,
  //     },
  //   },
  // ]
  // const minimumDate = {
  //   year: 2019,
  //   month: 4,
  //   day: 10
  // };

  //employee form
  const [enddated, setEnddated] = useState(true);
  const handleChange1 = (event) => {
    setEnddated(event.target.checked);
  };

  const checkedBox = (event) => {
    console.log("==============>>>", event);
  };

  const [selectedValue, setSelectedValue] = useState("");

  const CONFIG_Employee = [
    {
      id: "company_name",

      onChange: (value, formik) => {
        // formik.setFieldValue("company_name", value.label)
        // console.log('epf value----', value)
        companySelect(value);
      },
      label: "Company Name * (If not found, please select Other)",
      placeholder: "Company Name ",
      type: "selectOpImproved2",
      name: "company_name",
      placeholder: "Select Company Name",
      options: companies,

      isClearable: true,
      isSearchable: true,
      contianerProps: {
        xs: 12,
        sm: 12,
        md: 12,
      },
    },
    // {
    //   type: "customeFieldCheck",
    //   name: "companynamechecked",
    //   label: "Current Employer",
    //   id: "companynamechecked",
    //   onChange:{checkedBox},
    //   contianerProps: {
    //     xs: 12,
    //     sm: 12,
    //     md: 12,
    //   },
    // },
    {
      id: "companyName",
      type: hideCompanyNameText,
      name: "companyName",
      //label: "Company Name (Please enter if not in select box)",
      placeholder: "Company Name (Please enter if not in select box)",
      contianerProps: {
        xs: 12,
        sm: 12,
        md: 12,
      },
    },
    {
      id: "designation",

      type: "text",
      name: "designation",
      label: "Designation *",
      placeholder: "Designation",
      contianerProps: {
        xs: 12,
        sm: 12,
        md: 12,
      },
    },

    {
      id: "company_address",
      type: "text",
      name: "company_address",
      label: "Address *",
      placeholder: "Address",
      contianerProps: {
        xs: 12,
        sm: 12,
        md: 12,
      },
    },
    {
      id: "company_phone",
      type: "number",
      name: "company_phone",
      label: "Contact Number *",
      placeholder: "Phone",
      onKeyPress: (event) => {
        // doesn't allow the user to enter "e" (to the power)
        if (event.key === "e") {
          event.preventDefault();
        }
      },
      contianerProps: {
        xs: 12,
        sm: 12,
        md: 12,
      },
    },
    {
      type: "label",
      label: "I am currently working in this role"

    },
    {
      type: "customeFieldCheck",
      name: "currentemp",
      // label: "Yes",
      id: "currentemp",

      contianerProps: {
        xs: 12,
        sm: 12,
        md: 12,
      },
    },
    {
      id: "startDate",
      type: "datepicker",
      name: "startDate",

      label: "Start Date *",
      calendarPopperPosition: "bottom",
      contianerProps: {
        xs: 12,
        sm: 6,
        md: 6,
      },
    },
    {
      id: "endDate",
      type: "datepicker",
      name: "endDate",
      label: "End Date ",
      maximumDate: utils().getToday(),
      //  minimumDate: {minimumDate},
      calendarPopperPosition: "bottom",
      contianerProps: {
        xs: 12,
        sm: 6,
        md: 6,
      },

      // min:{startDate ? new Date(startDate).toISOString().split("T")[0]: ""},
    },
    {
      id: "file",
      type: "file",
      name: "file",
      label: "Upload Service Letter (PDF only. Maximum file size is 5MB) *",
      contianerProps: {
        xs: 12,
        sm: 12,
        md: 12,
      },
    },
  ];

  const handleChangeDropDown = (event) => {
    //setEnddated(event.target.checked);
    //alert(event.target.value)
    console.log("handleChangeDropDown=====>>>", event);
    //setSelectedValue(event.label)
  };
  // onChange={e => setText(e.target.value)}

  // const handleChange3 = (e) => {
  //   if (setCompany_name(e.target.value)) {
  //     console.log("Checked");
  //     // setCompany_name(event.target.value);
  //   }else{
  //     console.log("Checked!")
  //   }

  // };
  // const handleChange3 = () => {
  //   if (checked) {
  //     console.log("checkedddddddddddddd!");
  //     // setText('')
  //   }else{
  //     console.log("uncheckeddddddddddd!");
  //   }
  //   //setChecked(!checked);

  // }
  //academic form

  const Academic = [
    {
      id: "institute_name",
      type: "selectOpImproved",
      name: "institute_name",
      label: "Institute Name * (If not found, please select Other)",
      placeholder: "Select Institute Name",
      options: universitiess,
      onChange: (value, formik) => {
        academicSelect(value);
      },
      isClearable: true,
      isSearchable: true,
      contianerProps: {
        xs: 12,
        sm: 12,
        md: 12,
      },
    },
    {
      id: "academicuni",
      type: hideAcedemicText,
      name: "academicuni",
      //label: "Institute Name (Please enter if not in select box)",
      placeholder: "Institute Name (Please enter if not in select box)",
      contianerProps: {
        xs: 12,
        sm: 12,
        md: 12,
      },
    },
    {
      id: "qualification",
      type: "selectOpImproved",
      name: "qualification",
      label: "Qualification *",
      placeholder: "Select Qualification",
      isClearable: true,
      isSearchable: true,
      options: qualificationLevels,
      onChange: (value, formik) => {
        qualificationSelect(value);
      },
      contianerProps: {
        xs: 12,
        sm: 12,
        md: 12,
      },
      styles: {
        option: (provided, state) => ({
          ...provided,
          height: '30px', 
        }),
        menuList: (provided) => ({
          ...provided,
          maxHeight: '220px', 
        }),
      },
    },
    {
      id: "qualname",
      type: hideQualificationNameText,
      name: "qualname",
      //label: "Company Name (Please enter if not in select box)",
      placeholder: "Qualification Name (Please enter if not in select box)",
      contianerProps: {
        xs: 12,
        sm: 12,
        md: 12,
      },
      
    },

    {
      id: "parttimefulltime",
      type: "selectOpImproved",
      name: "parttimefulltime",
      label: "Part time Or Full time *",
      placeholder: "Select Part Time/Full Time",
      isClearable: true,
      isSearchable: true,
      options: opt,
      contianerProps: {
        xs: 12,
        sm: 12,
        md: 12,
      },

    },
    {
      id: "address",
      type: "text",
      name: "address",
      label: "Institute Address (Optional)",
      placeholder: "Address",
      contianerProps: {
        xs: 12,
        sm: 12,
        md: 12,
      },
    },

    {
      id: "fromDate",
      type: "datepicker",
      name: "fromDate",
      label: "From Date *",

      calendarPopperPosition: "bottom",
      contianerProps: {
        xs: 12,
        sm: 4,
        md: 4,
      },
    },
    {
      id: "toDate",
      type: "datepicker",
      name: "toDate",
      label: "To Date *",
      calendarPopperPosition: "bottom",
      contianerProps: {
        xs: 12,
        sm: 4,
        md: 4,
      },
    },

    {
      id: "admitDate",
      type: "datepicker",
      name: "admitDate",
      label: "Date Received *",
      calendarPopperPosition: "bottom",
      contianerProps: {
        xs: 12,
        sm: 4,
        md: 4,
      },
    },
    {
      id: "certificate",
      type: "file",
      name: "certificate",
      label: "Upload certificate (PDF only. Maximum file size is 5MB) *",
      contianerProps: {
        xs: 12,
        sm: 12,
        md: 12,
      },
    },
  ];

  //prof details form
  const ProfDetail = [
    {
      id: "member_name",
      type: "text",
      name: "member_name",
      label: "Membership Type*",
      placeholder: "Membership Type",
      contianerProps: {
        xs: 12,
        sm: 12,
        md: 12,
      },
    },
    // {
    //   id: "profession",
    //   type: "text",
    //   name: "profession",
    //   label: "Profession *",
    //   placeholder: "Profession",
    //   contianerProps: {
    //     xs: 12,
    //     sm: 12,
    //     md: 12,
    //   },
    // },
    {
      id: "grade",
      type: "text",
      name: "grade",
      label: "Grade / Membership Level *",
      placeholder: "Grade / Memership Level",
      contianerProps: {
        xs: 12,
        sm: 12,
        md: 12,
      },
    },

    {
      id: "entrymethod",
      type: "selectOpImproved",
      name: "entrymethod",
      label: "Method of Entry *",
      options: optEntry,
      isClearable: true,
      isSearchable: true,
      placeholder: "Select Entry Method",
      contianerProps: {
        xs: 12,
        sm: 12,
        md: 12,
      },
    },
    {
      id: "address",
      type: "text",
      name: "address",
      label: "Address (Optional)",
      placeholder: "Address",
      contianerProps: {
        xs: 12,
        sm: 12,
        md: 12,
      },
    },

    {
      id: "startdate",
      type: "datepicker",
      name: "startdate",
      label: "Start Date *",
      dateFormat: "DD/MM/YYYY",
      calendarPopperPosition: "bottom",
      contianerProps: {
        xs: 12,
        sm: 6,
        md: 6,
      },
    },
    {
      id: "enddate",
      type: "datepicker",
      name: "enddate",
      label: "End Date *",
      dateFormat: "DD/MM/YYYY",
      calendarPopperPosition: "bottom",
      contianerProps: {
        xs: 12,
        sm: 6,
        md: 6,
      },
    },

    // {
    //   id: "admit_date",
    //   type: "datepicker",
    //   name: "admit_date",
    //   label: "Admit Date",
    //   calendarPopperPosition: "bottom",
    //   contianerProps: {
    //     xs: 12,
    //     sm: 6,
    //     md: 6,
    //   },
    // },
    {
      id: "certificates",
      type: "file",
      name: "certificates",
      label: "Upload certificate (PDF only. Maximum file size is 5MB) *",
      contianerProps: {
        xs: 12,
        sm: 12,
        md: 12,
      },
    },
  ];

  return (
    <Aux>
      <Row>
        <Col md={12}></Col>
      </Row>

      <Row>
        <Col md={12}>
          <Card>
            <Card.Header>
              <Card.Title as="h5">Registration Form</Card.Title>
            </Card.Header>
            <Card.Body>
              {/* <h5>Password Reset</h5>
              <hr /> */}
              <Row>
                <Col md={12}>
                  <FormikForm
                    config={CONFIG}
                    initialValues={InitialData.headerData}
                    validationSchema={schema}
                    submitFunction={updatemember}
                    SubmitTrigger={() => (
                      <Col>
                        {saveAndExitBtn ? (
                          <Form.Group>
                            {newApplicantStatus ? (
                            <div>
                              {/* <Button
                                className="shadow-2 mb-4"
                                onClick={() => {
                                  setmodel(true);
                                }}
                              >
                                Add Employement Details
                              </Button>
                              <Button
                                className="shadow-2 mb-4"
                                onClick={() => {
                                  setmodel1(true);
                                }}
                              >
                                Add Academic Qualifications
                              </Button>
                              <Button
                                className="shadow-2 mb-4"
                                onClick={() => {
                                  setmodel2(true);
                                }}
                              >
                                Add Professional Bodies
                              </Button> */}
                            </div>
                            ) : (
                              <></>
                            )}
                            {newApplicantStatus ? (
                            <div>

                              <Row>
                                <Col md={12}>

                                {/* <Card>
                                  <Card.Header>
                                    <Card.Title as="h5">Employment Details</Card.Title>
                                    <br></br>
                                    <Card.Title as="h7"> View Employement Details</Card.Title>
                                  </Card.Header>
                                  <Card.Body>
                                    <Row>
                                      <Col></Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <DataTable
                                          columns={column}
                                          data={data}
                                          progressPending={loading}
                                          highlightOnHover={true}
                                          responsive={true}
                                          pagination
                                          paginationServer
                                          paginationTotalRows={totalRows}
                                          // onSort={handleSort}
                                          onChangeRowsPerPage={handlePerRowsChange}
                                          onChangePage={handlePageChange}
                                          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                                          subHeader
                                          subHeaderComponent={subHeaderComponentMemo}
                                          customStyles={customDataTableStyle}
                                        />
                                      </Col>
                                    </Row>
                                  </Card.Body>
                                </Card> */}

                                </Col>
                              </Row>
                              <Row>
                                <Col md={12}>

                                
                                  {/* <Card>
                                    <Card.Header>
                                      <Card.Title as="h5">Academic Qualification Details</Card.Title>
                                      <br></br>
                                      <Card.Title as="h7">
                                        {" "}
                                        View Academic Qualification Details
                                      </Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                      <Row>
                                        <Col></Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <DataTable
                                            columns={column1}
                                            data={academic_data}
                                            progressPending={loading_}
                                            highlightOnHover={true}
                                            responsive={true}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={totalRows_}
                                            // onSort={handleSortACC}
                                            onChangeRowsPerPage={handlePerRowsChangeACC}
                                            onChangePage={handlePageChangeACC}
                                            paginationResetDefaultPage={resetPaginationToggle_} // optionally, a hook to reset pagination to page 1
                                            subHeader
                                            subHeaderComponent={subHeaderComponentMemoACC}
                                            customStyles={customDataTableStyle}
                                          />
                                        </Col>
                                      </Row>
                                    </Card.Body>
                                  </Card> */}
                                

                                </Col>
                              </Row>
                              <Row>
                                <Col md={12}>

                                  {/* <Card>
                                    <Card.Header>
                                      <Card.Title as="h5">Professional Membership Details</Card.Title>
                                      <br></br>
                                      <Card.Title as="h7">
                                        {" "}
                                        View Professional Membership Details
                                      </Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                      <Row>
                                        <Col></Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <DataTable
                                            //title="Professional Membership Detail List"
                                            columns={column2}
                                            data={professionalMembership_data}
                                            progressPending={loading__}
                                            highlightOnHover={true}
                                            responsive={true}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={totalRows__}
                                            // onSort={handleSortMem}
                                            onChangeRowsPerPage={handlePerRowsChangeMem}
                                            onChangePage={handlePageChangeMem}
                                            paginationResetDefaultPage={resetPaginationToggle__} // optionally, a hook to reset pagination to page 1
                                            subHeader
                                            subHeaderComponent={subHeaderComponentMemoMem}
                                            customStyles={customDataTableStyle}
                                          />
                                        </Col>
                                      </Row>
                                    </Card.Body>
                                  </Card> */}

                                </Col>
                              </Row>

                            </div>): (
                              <></>
                            )}
                            
                            <Col
                              justify="end"
                              className="mt-3 d-flex w-60 justify-content-end mb-8"
                            >
                              <Button
                                //variant="primary"
                                className="btn btn-secondary"
                                size="shadow-2 mb-4"
                                type="submit"
                                id="saveSubmitbtn"
                                // disabled={loader == true ? true : false}

                                onClick={() => {
                                  setSaveSubmitState(initialButtons[0].name);
                                  // saveAndExit(CONFIG);
                                }}
                              >
          
                                <FaSave /> Save Details

                              </Button>

                                

                                <div>
                                    <Button
                                      className="btn btn-success"
                                      type="submit"
                                      size="shadow-2 mb-4"
                                      disabled={!newApplicantStatus}
                                      onClick={() => {
                                        setSaveSubmitState(initialButtons[1].name);
                                        // submitForm(CONFIG);
                                      }}
                                    >
                                      {loader1 == true ? (
                                        <Rings
                                          height="28px"
                                          width="28px"
                                          color="white"
                                        />
                                      ) : (
                                        <>
                                          <FaSave /> Submit
                                        </>
                                      )}
                                    </Button>
                                </div>

                            </Col>
                          </Form.Group>
                        ) : (
                          <Col>
                            <Badge bg="success">Awaiting Approval</Badge>
                          </Col>
                        )}
                      </Col>
                    )}
                    // submitFunction={updatemember}
                  />

                  <Row>
                    <Col md={12}></Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      {/* <Card>
                        <Card.Header>
                          <Card.Title as="h5">Academic Qualification</Card.Title>
                        </Card.Header>
                        <Card.Body>
                          <Row>
                            <Form.Group>
                              <Button className="mb-0" onClick={handleShowAQ}>Add Academic Qualification</Button>
                            </Form.Group>
                          </Row>
                          <Row>
                          </Row>
                        </Card.Body>
                      </Card> */}
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}></Col>
                  </Row>
                </Col>
                <Row>
                  <Row>
                    <Col md={12}>
                      {/* <Form.Group>
                        <Button className="mb-0">Submit to Approval</Button>
                      </Form.Group> */}
                    </Col>
                  </Row>
                </Row>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Formik
        validationSchema={schema}
        onSubmit={(data, formprops) => {
          setIsModelView(false);
          onSubmit(data, formprops);
        }}
        // initialValues={editData || init}
      ></Formik>

      {/* Employment Details Section */}
      <Row>
        <Col md={12}>
          
        </Col>
      </Row>

      {/* Academic Qualification Details Section */}

      <Row>
        
      </Row>

      {/* Professional Membership Details Section */}

      <Row>
        <Col md={12}>
          
        </Col>
      </Row>

      {/* ====================Employement Details Modal===================================== */}
      <Modal
        show={model}
        onHide={() => {
          setmodel(false);
        }}
      >
        {/* <ModalHeader> */}

        <Modal.Header closeButton>
          <Modal.Title>Work Experience</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Card.Body>
            <div style={{ textAlign: "center", fontWeight: "bold" }}>
              <p>Please enter your work experience details one by one and save each experience seperately.</p>
            </div>
            <FormikForm
              isModel={true}
              config={CONFIG_Employee}
              validationSchema={EmploymentValidationSchema}
              submitFunction={addEmployeeDetails}
              SubmitTrigger={() => {
                return (
                  <>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setmodel(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={loader2 == true ? true : false}
                    >
                      {loader2 == true ? (
                        <Rings height="28px" width="28px" color="white" />
                      ) : (
                        <>Save</>
                      )}
                    </Button>
                  </>
                );
              }}
              initialValues={EmploymentInitialValues}
            />
          </Card.Body>
        </Modal.Body>
      </Modal>

      {/* --------------- Acadamic Qualification model --------------------- */}

      <Modal
        show={model1}
        onHide={() => {
          setmodel1(false);
        }}
      >
        {/* <ModalHeader> */}
        <Modal.Header closeButton>
          <Modal.Title>Academic Qualification</Modal.Title>
        </Modal.Header>
        <FormikForm
          isModel={true}
          config={Academic}
          validationSchema={AcademicValidationSchema}
          submitFunction={addAcademicDetail}
          SubmitTrigger={() => {
            return (
              <>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setmodel1(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={loader3 == true ? true : false}
                >
                  {loader3 == true ? (
                    <Rings height="28px" width="28px" color="white" />
                  ) : (
                    <>Save</>
                  )}
                </Button>
              </>
            );
          }}
          initialValues={AcademicInitialValues}
        />
      </Modal>

      {/* --------------------------------- Memberships of other Professional bodies ----------------------- */}
      <Modal
        show={model2}
        onHide={() => {
          setmodel2(false);
        }}
      >
        {/* <ModalHeader> */}
        <Modal.Header closeButton>
          <Modal.Title>Professional Membership</Modal.Title>
        </Modal.Header>
        <FormikForm
          isModel={true}
          config={ProfDetail}
          validationSchema={ProfValidationSchema}
          submitFunction={addProfDetail}
          SubmitTrigger={() => {
            return (
              <>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setmodel2(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={loader4 == true ? true : false}
                >
                  {loader4 == true ? (
                    <Rings height="28px" width="28px" color="white" />
                  ) : (
                    <>Save</>
                  )}
                </Button>
              </>
            );
          }}
          initialValues={ProfInitialValues}
        />
      </Modal>

      {/* /********************** Submit ********************/}

      <Modal show={showSubmit} onHide={handleCloseSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Submit for Approval</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Your application will be submit for Council Approval. Please make sure
          you have updated all the nessasary details as true and accurate. Once
          you have submit, you will no longer be able to update this.
          <br></br>
          Please confirm your action?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseSubmit}>
            Cancel
          </Button>
          <Button variant="primary" onClick={submitApproval}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      {/* </Card>
        </Col>
      </Row> */}
    </Aux>
  );
};
