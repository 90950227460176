import APIService from "./Api.service";
import HttpRequestError from "../common/exceptions/HttpRequestError";
import UserBadCredentialsError from "../common/exceptions/User/UserBadCredentialsError";
import UserUnknownError from "../common/exceptions/User/UserUnknownError";
import { data } from "jquery";

const API_BASE_URL = process.env.REACT_APP_API_URL;

class ParameterService extends APIService {
  serviceEndpoint = API_BASE_URL;

  static handleError = (error) => {
    if (error instanceof HttpRequestError) {
      console.log(error.statusCode);
      if (error.statusCode === 401) throw new UserBadCredentialsError();
    }
    throw new UserUnknownError();
  };

  async branchesList(
    page = 1,
    rowsPerPage = 10,
    searchText = 0,
    sortParameter = -1,
    sortType = "asc"
  ) {
    try {
      const data = await this.get(
        "cdffbranch/table",
        {},
        {
          searchText,
          sortParameter,
          sortType,
          page,
          rowsPerPage,
        }
      );
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async addGmtType(gmtType, Description = "") {
    try {
      const data = await this.post("para/addgarmenttypes", {
        GmtType: gmtType,
        GarmentDesc: Description,
      });
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getGmtType(para) {
    try {
      const data = await this.get("para/garmenttypes", {}, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async updateGmtType(gmtType, GarmentDesc) {
    try {
      const data = await this.put(`updateP_GmtType/${gmtType}`, {
        GarmentDesc,
      });
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async deleteGmtType(gmtType) {
    try {
      const data = await this.delete(`deleteP_GmtType/${gmtType}`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async addFabCategory(para) {
    try {
      const data = await this.post("para/addfabriccategory", para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getFabCategory(para) {
    try {
      const data = await this.get("para/viewfabriccategory", {}, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async updateFabCategory(para) {
    try {
      const data = await this.put(`para/updatefabriccategory`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getFabCategoryAll() {
    try {
      const data = await this.get(`para/fabriccategory/all`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async deleteFabCategory(fabCategory) {
    try {
      const data = await this.delete(`para/fabriccategory/${fabCategory}`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async addAddressType(para) {
    try {
      const data = await this.post("para/address-type", para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getAddressType(para) {
    try {
      const data = await this.get(`para/address-type`, {}, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getAddressTypeAll() {
    try {
      const data = await this.get(`para/address-type/all`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async updateAddressType(para) {
    try {
      const data = await this.put(`para/address-type`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async deleteAddressType({ id, addresstype, description }) {
    try {
      const data = await this.delete(`para/address-type/${id}`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async addCounty(para) {
    try {
      const data = await this.post("para/country", para);

      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getCounty(para) {
    try {
      const data = await this.get(`para/country`, {}, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getCountyAll() {
    try {
      const data = await this.get(`para/country/all`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async updateCounty(para) {
    try {
      const data = await this.put(`para/country`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async deleteCounty({ countryId }) {
    try {
      const data = await this.delete(`para/country/${countryId}`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  // async getSeasonCodeList() {
  //   try {
  //     const data = await this.get("getP_SeasonCodes");
  //     return data;
  //   } catch (error) {
  //     return ParameterService.handleError(error);
  //   }
  // }

  // async addSeasonCodes(seasoncode, Description = "") {
  //   try {
  //     const data = await this.post("addP_SeasonCode", {
  //       SeasonCode: seasoncode,
  //       SeasonCodeDesc: Description,
  //     });
  //     return data;
  //   } catch (error) {
  //     return ParameterService.handleError(error);
  //   }
  // }

  // async updateSeasonCodes(seasonCode, SeasonCodeDesc) {
  //   try {
  //     const data = await this.put(`updateP_SeasonCode/${seasonCode}`, {
  //       SeasonCodeDesc,
  //     });
  //     return data;
  //   } catch (error) {
  //     return ParameterService.handleError(error);
  //   }
  // }

  // async deleteSeasonCodes(seasonCode) {
  //   try {
  //     const data = await this.delete(`deleteP_SeasonCode/${seasonCode}`);
  //     console.log(data);
  //     return data;
  //   } catch (error) {
  //     return ParameterService.handleError(error);
  //   }
  // }

  // User Management -- to be move to User Management @Ruwanditha

  async getUser(para) {
    try {
      const data = await this.get(`usermanagement/allusers`, {}, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getInquiries(para) {
    try{
      const data = await this.get('para/inquiries',{},para);
      return data;
    }
    catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getMemberFees(para) {
    try{
      const data = await this.get('para/memberfees',{},para);
      return data;
    }
    catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async postMemberFees(para) {
    try{
      const data = await this.post('para/addmemberfees',para);
      return data;
    }
    catch (error) {
      return ParameterService.handleError(error);
    }
  }


  async inquiry(para) {
    try {
      const data = await this.post(`para/inquiry`, para);

      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async inquirycomment(para) {
    try {
      const data = await this.put(`para/inquirycomment`, para);

      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }


  async getDivision(para) {
    try {
      const data = await this.get(`para/division`, {}, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getDivisionAll() {
    try {
      const data = await this.get(`para/division/all`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async addDivision(para) {
    try {
      const data = await this.post(`para/division`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async updateDivision(para) {
    try {
      const data = await this.put(`para/division`, para);

      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async updateUser(para) {
    try {
      const data = await this.put(`para/user`, para);

      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async deleteDivision({ divisionid }) {
    try {
      const data = await this.delete(`para/division/${divisionid}`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getBrand(para) {
    try {
      const data = await this.get(`para/brand`, {}, para);

      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async deleteUser({ userID, username }) {
    try {
      const data = await this.delete(`para/user/${userID}`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getBrandAll() {
    try {
      const data = await this.get(`para/brand/all`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async addBrand(para) {
    try {
      const data = await this.post("para/brand", para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async updateBrand(para) {
    try {
      const data = await this.put(`para/brand`, para);

      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async deleteBrand({ brandid }) {
    try {
      const data = await this.delete(`para/brand/${brandid}`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getCurrency(para) {
    try {
      const data = await this.get(`para/currency`, {}, para);

      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getCurrencyAll() {
    try {
      const data = await this.get(`para/currency/all`);

      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async addCurrency(para) {
    try {
      const data = await this.post("para/currency", para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async updateCurrency(para) {
    try {
      const data = await this.put(`para/currency`, para);

      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async deleteCurrency({ currencydetailsID }) {
    try {
      const data = await this.delete(`para/currency/${currencydetailsID}`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async addDepartment(para) {
    try {
      const data = await this.post("para/departmenttypes", para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getSeasonAll() {
    try {
      const data = await this.get("para/season/all");
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async updateDepartment(para) {
    try {
      const data = await this.put(
        `para/departmenttypes/${para.departmentTypeID}`,
        para
      );
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }
  async getFabricTypeAll() {
    try {
      const data = await this.get("para/fabrictype/all");
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getDepartment(para) {
    try {
      const data = await this.get("para/departmenttypes", {}, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getGenderAll() {
    try {
      const data = await this.get("para/gender/all");
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getDepartmentAll() {
    try {
      const data = await this.get("para/departmenttypes/all", {});
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getColorCodeAll() {
    try {
      const data = await this.get("para/colorcard/all");
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getSizeCardAll() {
    try {
      const data = await this.get("para/sizecard/all");
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getStorageUnitAll() {
    try {
      const data = await this.get("para/unit/all");
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async addColorCards(para) {
    try {
      const data = await this.post(`para/colorcard`, para);

      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getMeasurementAll() {
    try {
      const data = await this.get("para/measurement/all");
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getColorCodes(para) {
    try {
      const data = await this.get("para/colorcard", {}, para);

      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getWashProcessTypeAll() {
    try {
      const data = await this.get("para/washprocesstype/all");
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async updateColorCards(para) {
    try {
      const data = await this.put(
        `para/updatecolorcard/${para.colorCardID}`,
        para
      );
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getWashTypeAll() {
    try {
      const data = await this.get("para/washtype/all");
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async deleteColorCodes(id) {
    try {
      const data = await this.delete(`para/colorcard/${id}`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getDepartmentAll() {
    try {
      const data = await this.get("para/departmenttypes/all");
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async addWashTypes(para) {
    try {
      const data = await this.post(`para/washtypes`, para);

      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getWashTypes(para) {
    try {
      const data = await this.get("para/washtypes", {}, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async updateWashTypes(paratemp) {
    try {
      console.log(paratemp);
      const data = await this.put(
        `para/washtypes/${paratemp.washtypeID}`,
        paratemp
      );
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async deleteWashTypes(id) {
    try {
      const data = await this.delete(`para/washtypes/${id}`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }
  //-------wash process

  async addWashProcess(para) {
    try {
      const data = await this.post(`para/washprocesstype`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getWashProcess(para) {
    try {
      const data = await this.get("para/washprocesstype", {}, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async updateWashProcess(paratemp) {
    try {
      console.log(paratemp);
      const data = await this.put(
        `para/washprocesstype/${paratemp.washProcessID}`,
        paratemp
      );
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async deleteWashProcess(id) {
    try {
      const data = await this.delete(`para/washprocesstype/${id}`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  //-----------Gender
  async addGender(para) {
    try {
      const data = await this.post(`para/gender`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getGender(para) {
    try {
      const data = await this.get("para/gender", {}, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async updateGender(paratemp) {
    try {
      console.log(paratemp);

      const data = await this.put(`para/gender`, paratemp);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async deleteGender(id) {
    try {
      const data = await this.delete(`para/gender/${id}`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  //created by: Janith Gamage on : 2021.10.14
  //get data for the table
  async getSeasonCode(para) {
    try {
      const data = await this.get(`para/season`, {}, para);

      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  //get Season all
  async getSeasonCodeAll() {
    try {
      const data = await this.get(`para/season/all`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  //add season
  async addSeasonCode(para) {
    try {
      const data = await this.post("para/season", para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  //update season
  async updateSeason(para) {
    try {
      const data = await this.put(`para/season`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }
  async deleteDepartment(id) {
    try {
      const data = await this.delete(`para/departmenttypes/${id}`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  //delete season
  async deleteSeason({ code }) {
    try {
      const data = await this.delete(`para/season/${code}`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  // --------- order type --------------

  async addOrderType(para) {
    try {
      const data = await this.post("para/addordertypes", para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  //-----------Measurement
  async addMeasurement(para) {
    try {
      const data = await this.post(`para/measurement`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async updateOrderType(para) {
    try {
      const data = await this.put(`para/updateordertype`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getMasurement(para) {
    try {
      const data = await this.get("para/measurement", {}, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getOrderType(para) {
    try {
      const data = await this.get("para/ordertypes", {}, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async updateMeasurement(paratemp) {
    try {
      console.log(paratemp);
      const data = await this.put(
        `para/measurement/${paratemp.measurementID}`,
        paratemp
      );
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getOrderTypeAll() {
    try {
      const data = await this.get("para/ordertype/all", {});
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async deleteMeasurement(id) {
    try {
      const data = await this.delete(`para/measurement/${id}`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }
  async deleteOrderType(id) {
    try {
      const data = await this.delete(`para/ordertypes/${id}`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  //-----------addPaymentTerm
  async addPaymentTerm(para) {
    try {
      const data = await this.post(`para/paymentterm`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getPaymentTerm(para) {
    try {
      const data = await this.get("para/paymentterm", {}, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async updatePaymentTerm(paratemp) {
    try {
      console.log(paratemp);
      const data = await this.put(
        `para/paymentterm/${paratemp.paymentTermsID}`,
        paratemp
      );
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async deletePaymentTerm(id) {
    try {
      const data = await this.delete(`para/paymentterm/${id}`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  //created by: Janith Gamage on : 2021.10.19
  //get data for the table
  async getSampleIsuue(para) {
    try {
      const data = await this.get(`para/sampleissue`, {}, para);

      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  //get Sample Issue all
  async getSampleIssueAll() {
    try {
      const data = await this.get(`para/sampleissue/all`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  //add Sample Issue
  async addSampleIssue(para) {
    try {
      const data = await this.post("para/sampleissue", para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  // //update Sample Issue
  // async updateSampleIssue({id}) {
  //   try {
  //     const data = await this.put(`para/sampleissue/${id}`);
  //     return data;
  //   } catch (error) {
  //     return ParameterService.handleError(error);
  //   }
  // }

  // //delete Sample Issue
  // async deleteSampleIssue({id}) {
  //   try {
  //     const data = await this.delete(`para/sampleissue/${id}`);
  //     return data;
  //   } catch (error) {
  //     return ParameterService.handleError(error);
  //   }
  // }

  async updateSampleIssue(para) {
    try {
      const data = await this.put(`para/sampleissue`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async deleteSampleIssue(id) {
    try {
      const data = await this.delete(`para/sampleissue/${id}`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  //created by: Janith Gamage on : 2021.10.21
  //sales Agent
  async addSalesAgent(para) {
    try {
      const data = await this.post(`para/salesagent`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getSalesAgent(para) {
    try {
      const data = await this.get("para/salesagent", {}, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async updateSalesAgent(para) {
    try {
      const data = await this.put(`para/salesagent/${para.salesAgentID}`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async deleteSalesAgent(id) {
    try {
      const data = await this.delete(`para/salesagent/${id}`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getSalesOrderTypeSelect() {
    try {
      const data = await this.get("para/salesordertype/all");
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getPaymentTermSelect() {
    try {
      const data = await this.get("para/paymentterm/all");
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getProcesstypeSelect() {
    try {
      const data = await this.get("para/processtype/all");
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getGarmenttypeSelect() {
    try {
      const data = await this.get("para/garmenttype/all");
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getSpecialOperationsSelect() {
    try {
      const data = await this.get("para/speacialoperationtypes/all");
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getSampleIssueSelect() {
    try {
      const data = await this.get("para/sampleissue/all");
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getSalesAgentSelect() {
    try {
      const data = await this.get("para/salesagent/all");
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getOrderTypeSelect() {
    try {
      const data = await this.get("para/ordertype/all");
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getSalesCategoryAll() {
    try {
      const data = await this.get("para/salesccategory/all");
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  // async getSalesAgentAll() {
  //   try {
  //     const data = await this.get("master/merchandizer/all", {});
  //     return data;
  //   } catch (error) {
  //     return ParameterService.handleError(error);
  //   }
  // }

  //Unit
  async addUnit(para) {
    try {
      const data = await this.post(`para/unit`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getUnit(para) {
    try {
      const data = await this.get("para/unit", {}, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async updateUnit(para) {
    try {
      const data = await this.put(`para/unit/${para.storageunitsID}`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async deleteUnit(id) {
    try {
      const data = await this.delete(`para/unit/${id}`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  // async getUnitAll() {
  //   try {
  //     const data = await this.get("para/unit/all", {});
  //     return data;
  //   } catch (error) {
  //     return ParameterService.handleError(error);
  //   }
  // }

  //Sales Order Type
  async addSalesOrderType(para) {
    try {
      const data = await this.post(`para/salesordertype`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getSalesOrderType(para) {
    try {
      const data = await this.get("para/salesordertype", {}, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async updateSalesOrderType(para) {
    try {
      const data = await this.put(
        `para/salesordertype/${para.salesOrdertypeID}`,
        para
      );
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async deleteSalesOrderType(id) {
    try {
      const data = await this.delete(`para/salesordertype/${id}`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  // async getSalesOrderTypeAll() {
  //   try {
  //     const data = await this.get("para/salesordertype/all", {});
  //     return data;
  //   } catch (error) {
  //     return ParameterService.handleError(error);
  //   }
  // }

  //Special Operation Type
  async addSpecialOperationType(para) {
    try {
      const data = await this.post(`para/speacialoperationtypes`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getSpecialOperationType(para) {
    try {
      const data = await this.get("para/speacialoperationtypes", {}, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async updateSpecialOperationType(para) {
    try {
      const data = await this.put(
        `para/speacialoperationtypes/${para.specialOperationID}`,
        para
      );
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async deleteSpecialOperationType(id) {
    try {
      const data = await this.delete(`para/speacialoperationtypes/${id}`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  // async getSpecialOperationTypeAll() {
  //   try {
  //     const data = await this.get("para/speacialoperationtypes/all", {});
  //     return data;
  //   } catch (error) {
  //     return ParameterService.handleError(error);
  //   }
  // }

  //Size Card
  async getSizeCard(para) {
    try {
      const data = await this.get("para/viewsizecard", {}, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async addSizeCard(para) {
    try {
      const data = await this.post(`para/addsizecard`, para);

      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async updateSizeCard(para) {
    try {
      const data = await this.put(`para/updatesizecard`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async deleteSizeCard(id) {
    try {
      const data = await this.delete(`para/deletesizecard/${id}`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  // Sales-Category-- Piumal

  async getSalesCategory(para) {
    try {
      const data = await this.get("para/salescategorysizeview", {}, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async addSalesCategory(para) {
    try {
      const data = await this.post(`para/addsalescategory`, para);

      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async updateSalesCategory(para) {
    try {
      const data = await this.put(`para/updatesalescategory`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async deleteSalesCategory(id) {
    try {
      const data = await this.delete(`para/deletesalescategory/${id}`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  //Dye Type
  async addDyeType(para) {
    try {
      const data = await this.post(`para/dyetype`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getDyeType(para) {
    try {
      const data = await this.get("para/dyetype", {}, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async updateDyeType(para) {
    try {
      const data = await this.put(`para/dyetype/${para.dyeTypeID}`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async deleteDyeType(id) {
    try {
      const data = await this.delete(`para/dyetype/${id}`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  // async getDyeTypeAll() {
  //   try {
  //     const data = await this.get("para/dyetype/all", {});
  //     return data;
  //   } catch (error) {
  //     return ParameterService.handleError(error);
  //   }
  // }

  //ProcessType
  async addProcessType(para) {
    try {
      const data = await this.post(`para/processtype`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getProcessType(para) {
    try {
      const data = await this.get("para/processtype", {}, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async updateProcessType(para) {
    try {
      const data = await this.put(
        `para/processtype/${para.processtypeID}`,
        para
      );
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async deleteProcessType(id) {
    try {
      const data = await this.delete(`para/processtype/${id}`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  // Process - Piumal

  async getProcess(para) {
    try {
      const data = await this.get("para/processview", {}, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async addProcess(para) {
    try {
      const data = await this.post(`para/addprocess`, para);

      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async updateProcess(para) {
    try {
      const data = await this.put(`para/updateprocess`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async deleteProcess(id) {
    try {
      const data = await this.delete(`para/deleteprocess/${id}`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  // Process To Chemical - Piumal

  async getProcessToChemical(para) {
    try {
      const data = await this.get("para/processtochemicalview", {}, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }
  // FabricType - Kanishka
  async getFabrictype(para) {
    try {
      const data = await this.get("para/fabrictype", {}, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async addProcessToChemical(para) {
    try {
      const data = await this.post(`para/addprocesstochemical`, para);

      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async updateProcessToChemical(para) {
    try {
      const data = await this.put(`para/updateprocesstochemical`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async deleteProcessToChemical(id) {
    try {
      const data = await this.delete(`para/deleteprocesstochemical/${id}`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getAllProcess() {
    try {
      const data = await this.get(`para/process/all`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async addFabrictype(para) {
    try {
      const data = await this.post(`para/fabrictype`, para);

      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async updateFabrictype(para) {
    try {
      const data = await this.put(`para/fabrictype`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async deleteFabrictype(id) {
    try {
      const data = await this.delete(`para/fabrictype/${id}`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  //Machine
  async addMachine(para) {
    try {
      const data = await this.post(`para/addmachinetype`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getMachine(para) {
    try {
      const data = await this.get("para/viewmachinetype", {}, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async updateMachine(para) {
    try {
      // const data = await this.put(`para/updatemachinetype/${para.machinetypeid}`, para);
      const data = await this.put(`para/updatemachinetype`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async deleteMachine(id) {
    try {
      const data = await this.delete(`para/machinetype/${id}`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getMachineAll() {
    try {
      const data = await this.get("para/machinetype/all", {});
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  // ------------ size ---- kanishka ------

  async getSize(para) {
    try {
      const data = await this.get("para/sizeview", {}, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async addSize(para) {
    try {
      const data = await this.post(`para/sizeadd`, para);

      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async updateSize(para) {
    try {
      const data = await this.put(`para/sizeupdate`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async deleteSize(sizeid) {
    try {
      const data = await this.delete(`para/sizedelete/${sizeid}`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  ///Color
  async addColor(para) {
    try {
      const data = await this.post(`para/color`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getColor(para) {
    try {
      const data = await this.get("para/color", {}, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async updateColor(para) {
    try {
      const data = await this.put(`para/color/${para.colorID}`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async deleteColor(id) {
    try {
      const data = await this.delete(`para/color/${id}`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  //Machine next
  async addMachineNext(para) {
    try {
      const data = await this.post(`para/addmachine`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getMachineNext(para) {
    try {
      const data = await this.get("para/viewmachine", {}, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async updateMachineNext(para) {
    try {
      // const data = await this.put(`para/updateMachineNexttype/${para.MachineNexttypeid}`, para);
      const data = await this.put(`para/updatemachine`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async deleteMachineNext(id) {
    try {
      const data = await this.delete(`para/machine/${id}`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  // async getMachineNextAll() {
  //   try {
  //     const data = await this.get("para/machine/all", {});
  //     return data;
  //   } catch (error) {
  //     return ParameterService.handleError(error);
  //   }
  // }

  async getSizeAll(SizeCardId) {
    try {
      const data = await this.get(`para/size/${SizeCardId}/sizelist`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  // ----------- kanishka ---- GMT type new service -------

  async getGmt(para) {
    try {
      const data = await this.get("para/garmenttypes", {}, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async addGmt(para) {
    try {
      const data = await this.post(`para/addgarmenttypes`, para);

      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async updateGmt(para) {
    try {
      const data = await this.put(`para/updategarmenttype`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async deleteGmt(sizeid) {
    try {
      const data = await this.delete(`para/garmenttype/${sizeid}`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getColorAll(colorcardID) {
    try {
      const data = await this.get(`para/color/${colorcardID}/colorlist`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getWashStandedAll() {
    try {
      const data = await this.get(`para/washstand/all`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getWashStand(para) {
    try {
      const data = await this.get("para/washstand", {}, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async addWashStand(para) {
    try {
      const data = await this.post(`para/washstand`, para);

      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async updateWashStand(para) {
    try {
      const data = await this.put(`para/washstand`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  //Size Card Allocation
  async addSizeCardAllocation(para) {
    try {
      const data = await this.post(`para/addUpdateSizeCardAllocation`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getSizeCardAllocation(para) {
    try {
      const data = await this.get("para/viewallsizecardallocation", {}, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async updateSizeCardAllocation(para) {
    try {
      const data = await this.put(`para/addUpdateSizeCardAllocation`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  // async getSizeCardAllocationAll() {
  //   try {
  //     const data = await this.get("para/dyetype/all", {});
  //     return data;
  //   } catch (error) {
  //     return ParameterService.handleError(error);
  //   }
  // }

  //get all sizes (import in getting data into size card allocation size "select") -- janith
  async getnewSizeAll() {
    try {
      const data = await this.get("para/size/all", {});
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  //Color Card Allocation
  async addColorCardAllocation(para) {
    try {
      const data = await this.post(`para/addUpdateColorCardAllocation`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getColorCardAllocation(para) {
    try {
      const data = await this.get("para/viewAllColorCardAllocation", {}, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async updateColorCardAllocation(para) {
    try {
      const data = await this.put(`para/addUpdateColorCardAllocation`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  // async getColorCardAllocationAll() {
  //   try {
  //     const data = await this.get("para/dyetype/all", {});
  //     return data;
  //   } catch (error) {
  //     return ParameterService.handleError(error);
  //   }
  // }

  //get all colors (import in getting data into color card allocation size "select") -- janith
  async getnewColorAll() {
    try {
      const data = await this.get("para/color/all", {});
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  //Recipe Order mapping
  async addRecipeOrder(para) {
    try {
      const data = await this.post(`recipe/addRecipeMapping`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getRecipeOrder(para) {
    try {
      const data = await this.get("recipe/recipingMappingView", {}, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async updateRecipeOrder(para) {
    try {
      const data = await this.put(`recipe/updateRecipeMapping`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  // async getRecipeOrderAll() {
  //   try {
  //     const data = await this.get("para/recipingMapping/all", {});
  //     return data;
  //   } catch (error) {
  //     return ParameterService.handleError(error);
  //   }
  // }

  //get all orderlist (import in getting data into recipe order mapping order ref "select") -- janith
  async getneworderlistall() {
    try {
      const data = await this.get("recipe/orderlistall", {});
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  //get all style list (import in getting data into recipe order mapping style code "select") -- janith
  async getnewstyleall() {
    try {
      const data = await this.get("recipe/styleall", {});
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  //get all recipe list (import in getting data into recipe order mapping recipe code "select") -- janith
  async getnewrecipeall() {
    try {
      const data = await this.get("recipe/recipeall", {});
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  //Recipie Utility
  async addUtility(para) {
    try {
      const data = await this.post(`para/addupdateutlity`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getUtility(para) {
    try {
      const data = await this.get("para/getutlityfortable", {}, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async updateUtility(para) {
    try {
      const data = await this.post(`para/addupdateutlity`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async deleteUtility(id) {
    try {
      const data = await this.delete(`para/utlity/${id}`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getStyleByCustomer(cusId) {
    try {
      const data = await this.get(`recipe/customer/${cusId}/style`, {});
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getCustomerOrderRefByStyle(styleId) {
    try {
      const data = await this.get(`recipe/style/${styleId}/customer-order-ref`, {});
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getColorByStyle(styleId) {
    try {
      const data = await this.get(`para/color/${styleId}/getColorBydtyel`, {});
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }


  async getIssueProductTypeAll() {
    try {
      const data = await this.get("para/goodissuetype/all", {});
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getUnitTypeByStyle(styleId) {
    try {
      const data = await this.get(`storage/styleId/${styleId}/getUnittype`);

      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getGlobalDetails(id) {
    try {
      const data = await this.get(`para/globaldetails/${id}`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async updateGloabalData(para) {
    try {
      const data = await this.put(`para/globaldetails`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  //Machine to Process start
  async addMachineProcess(para) {
    try {
      const data = await this.post(`para/addmachinetoprocess`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getMachineProcess(para) {
    try {
      const data = await this.get("para/machinetoprocessview", {}, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async updateMachineProcess(para) {
    try {
      const data = await this.put(`para/updatemachinetoprocess`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }
  //Machine to Process end

  //process secation all 
  async getAllProcessSection() {
    try {
      const data = await this.get(`para/processSectionAll`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }


  async getOrderRefByStyle(styleId) {
    try {
      const data = await this.get(`tran/style/${styleId}/order-ref`, {});
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }
  //Product Defination start
  async getProductDefination(para) {
    try {
      const data = await this.get("para/productDefinationView", {}, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }


  async getProductdefinition() {
    try {
      const data = await this.get(`para/productDefination/All`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  //Product Defination end

  //get Utility details according to utilityID
  async getUtilityDetailsAccordingToUtility(utilityID) {
    try {
      const data = await this.get(`para/utilityDetails/${utilityID}`, {});

      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getIssueProductIdAll(styleId) {
    try {
      const data = await this.get(`tran/goodissueid/all`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  //add process to prodcutdefination header
  async addProdcutdefinationHeader(para) {
    try {
      const data = await this.post(`tran/prodcutdefination/header`, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  //add process to prodcutdefination
  async addProdcutdefination(para, PDheaderid) {
    try {
      const data = await this.post(`tran/prodcutdefination/header/${PDheaderid}/details  `, para);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getProductDefinationView(para) {
    try {
      const data = await this.get(`tran/prodcutdefination/${para}`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  //get MachineNo According To Machine Type
  async getMachineNo(mtID) {
    try {
      const data = await this.get(`para/machineNo/${mtID}`, {});
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }


  async recipeallforissue(styleId) {
    console.log(styleId);
    try {
      const data = await this.get(`recipe/recipeallforissue/${styleId}`, {});
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async recipeData(recipeheaderid) {
    try {
      const data = await this.get(`recipe/recipe/${recipeheaderid}`, {});
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }

  async getCreditNotetypeAll() {
    try {
      const data = await this.get(`para/creditnotetypes/all`);
      return data;
    } catch (error) {
      return ParameterService.handleError(error);
    }
  }


  async getProvinces() {
    try {
      const data = await this.get('location/province/all', {});
      return data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async getPostalCodes() {
    try {
      const data = await this.get('location/postalcode/all', {});
      return data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async getDistrictsByProvince(provinceId) {
    try {
      const data = await this.get(`location/district/get_by_province/${provinceId}`, {});
      return data;
    } catch (error) {
      throw this.handleError(error);
    }
  }
  
  async getCitiesByDistrict(districtId) {
    try {
      const data = await this.get(`location/city/get_by_district/${districtId}`, {});
      return data;
    } catch (error) {
      throw this.handleError(error);
    }
  }


}
const parameterService = new ParameterService();
export default parameterService;
