import React, { useState } from "react";
import { Form, InputGroup, Button, Row, Col } from "react-bootstrap";

const FilterPicklist = ({ filterText, onFilter, onClear }) => (
  <>
    <Row>
      <Col xs={11}>
      <InputGroup>
          <Form.Control
            as="select"
            id="statusFilter"
            aria-label="Status Filter"
            value={filterText}
            onChange={onFilter}
            
          >
            <option value="">Select Response</option>
            <option value="Transaction Approved">Transaction Approved</option>
            <option value="Not Available">Not Available</option>
            <option value="Transaction Rejected">Transaction Rejected</option>
          </Form.Control>
          <Button variant="outline-success" onClick={onClear}>Clear</Button>
        </InputGroup>
      </Col>
    </Row>
  </>
);

export default FilterPicklist;
