import React, {useEffect} from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"
import "../../../assets/scss/style.scss";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import Aux from "../../../hoc/_Aux";


export default (props) => {
  const {
    onChangeHandler,
    oldPassword,
    newPassword,
    confirmPassword,
    passwordResetHandler,
  } = props;


  return (
    <Aux>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title as="h5">Password Reset</Card.Title>
            </Card.Header>
            <Card.Body>
              {/* <h5>Password Reset</h5>
              <hr /> */}
              <Row>
                <Col md={6}>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Current Password</Form.Label>
                    <Form.Control
                      type="password"
                      value={oldPassword}
                      onChange={onChangeHandler("oldPassword")}
                      placeholder="Current Password"
                    />
                  </Form.Group>

                  <Form.Group controlId="exampleForm.ControlInput1" className = "mt-2">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                      type="password"
                      value={newPassword}
                      onChange={onChangeHandler("newPassword")}
                      placeholder="New Password"
                    />
                  </Form.Group>
                  <Form.Group controlId="exampleForm.ControlInput1" className = "mt-2">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      type="password"
                      value={confirmPassword}
                      onChange={onChangeHandler("confirmPassword")}
                      placeholder="Confirm Password"
                    />
                  </Form.Group>

                  <Form.Group className = "mt-3">
                    <Button onClick={passwordResetHandler} className="mb-0">
                      Reset
                    </Button>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
    </Aux>
  );
};
