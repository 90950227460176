import React from "react";
import Select from "react-select";

function UserRoles(props) {

  const handleChange = (value) => {
    props.form.setFieldValue(props.field.name, value);
  };

  return (
    <>
      <Select
        {...props.field}
        className="basic-single"
        classNamePrefix="select"
        isDisabled={false}
        isLoading={false}
        isClearable={true}
        isRtl={false}
        isSearchable={true}
        options={props.options}
        onChange={handleChange}
      />
    </>
  );
}

export default UserRoles;
