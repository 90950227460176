import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { actions as ApplicationDetailActions } from ".";
import FilterComponent from "../../../components/shared/TableSearch";
import applicationService from "../../../services/Application.service";
import userService from "../../../services/User.service";
import { dateConvertToString } from "../../../utils/dateConvert";
import ViewProfile from "./applicationDetail.view";
import * as yup from "yup";
import parameterService from "../../../services/Parameter.service";

export default memo(() => {
  const dispatch = useDispatch();

  const [savedDistrict, setSavedDistrict] = useState({});
  const [savedCity, setSavedCity] = useState({});
  const [savedProvince, setSavedProvince] = useState({});

  const {
    initLoad,
    isErr,
    mes,
    isAddEmploymentRequestSent,
    isAddAcademicRequestSent,
    // isDeleteAcademicRequestSent,
    isAddProfRequestSent,
    isSubmitApprovaltRequestSent,
  } = useSelector((state) => state.apDetails);

  //employee condition for future dates disabled
  const [isChecked, setIsChecked] = useState("");
  // const [company, setCompany] = useState(null);

  const [InitialDataMem, setInitialDataMem] = useState();
  const [InitialDataMem1, setInitialDataMem1] = useState();

  const [InitialDataAcademicVal, setInitialDataAcademicVal] = useState();
  const [InitialDataAcademicLabel, setInitialDataAcademicLabel] = useState();
  const [hideAcedemicText, setHideAcedemicText] = useState("hidden");

  const [InitialDataQualificationVal, setInitialDataQualificationVal] =
    useState();
  const [InitialDataQualificationLabel, setInitialDataQualificationLabel] =
    useState();

  const companySelect = (e) => {
    console.log("==============>>>", e);
    setInitialDataMem(e.value);
    setInitialDataMem1(e.label);
    if (e.label == "Other") {
      console.log("not in list");
      setHideCompanyNameText("text");
    } else {
      console.log("in list");
      setHideCompanyNameText("hidden");
    }
  };

  const qualificationLevels = [
    { label: "Other", value: "1" },
    { label: "Certificate (GCE O/L or Equivalent)", value: "2" },
    { label: "Advanced Certificate (GCE A/L or Equivalent)", value: "3" },
    { label: "Diploma", value: "4" },
    { label: "Higher Diploma", value: "5" },
    { label: "Bachelors", value: "6" },
    { label: "Bachelors Honours", value: "7" },
    { label: "Postgraduate Certificate", value: "8" },
    { label: "Postgraduate Diploma", value: "9" },
    { label: "Masters by Course Work", value: "10" },
    { label: "Masters by Course Work and a Research Component", value: "11" },
    { label: "Master of Philosophy", value: "12" },
    {
      label:
        "Doctor of Philosophy/ MD with Board Certification/ Doctor of Letters/ Doctor of Science",
      value: "13",
    },
  ];

  const academicSelect = (e) => {
    setInitialDataAcademicVal(e.value);
    setInitialDataAcademicLabel(e.label);
    if (e.label == "Other") {
      console.log("not in list");
      setHideAcedemicText("text");
    } else {
      console.log("in list");
      setHideAcedemicText("hidden");
    }
  };

  const qualificationSelect = (e) => {
    console.log("==============>>>", e);
    setInitialDataQualificationVal(e.value);
    setInitialDataQualificationLabel(e.label);
    if (e.label == "Other") {
      console.log("not in list");
      setHideQualificationNameText("text");
    } else {
      console.log("in list");
      setHideQualificationNameText("hidden");
    }
  };

  //Employee Detail
  const {
    initialLoad,
    isError,
    message,
    isAddRequestSent,
    isUpdateRequestSent,
    isDeleteRequestSent,
  } = useSelector((state) => state.users);

  const [model, setmodel] = useState(false);

  //Academic Detail
  const {
    initialLoad_,
    isError_,
    msg,
    isAddRequestSent_,
    isUpdateRequestSent_,
    // isDeleteAcademicRequestSent,
    isDeleteRequestSent_,
  } = useSelector((state) => state.users);
  const [model1, setmodel1] = useState(false);

  //Prof Detail
  const {
    initialLoad__,
    isError__,
    message__,
    isAddRequestSent__,
    isUpdateRequestSent__,
    isDeleteRequestSent__,
  } = useSelector((state) => state.users);
  const [model2, setmodel2] = useState(false);

  //Table State - Employee Detail
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const [editData, setEditData] = useState(null);
  const [isModelView, setIsModelView] = useState(false);
  const [editModel, setEditModel] = useState(true);
  //Table State - Academic Detail
  const [academic_data, SetAcademic_data] = useState([]);
  const [loading_, setLoading_] = useState(false);
  const [totalRows_, setTotalRows_] = useState(0);
  const [perPage_, setPerPage_] = useState(10);
  const [sortColumn_, setSortColumn_] = useState(null);
  const [sortDirection_, setSortDirection_] = React.useState(null);
  const [filterText_, setFilterText_] = React.useState("");
  const [resetPaginationToggle_, setResetPaginationToggle_] =
    React.useState(false);
  //const [editData_, setEditData_] = useState(null);
  const [isModelView_, setIsModelView_] = useState(false);
  // const [editModel_, setEditModel_] = useState(true);
  //Table State - Prof Detail
  const [professionalMembership_data, SetProfessionalMembership_data] =
    useState([]);
  const [loading__, setLoading__] = useState(false);
  const [totalRows__, setTotalRows__] = useState(0);
  const [perPage__, setPerPage__] = useState(10);
  const [sortColumn__, setSortColumn__] = useState(null);
  const [sortDirection__, setSortDirection__] = React.useState("");
  const [filterText__, setFilterText__] = React.useState("");
  const [resetPaginationToggle__, setResetPaginationToggle__] =
    React.useState(false);

  //const [editData_, setEditData_] = useState(null);
  const [isModelView__, setIsModelView__] = useState(false);
  const [isDisabled, setIsDisabled] = useState();
  const tbaledata = [
    { sample: "sample", sample: "sample", sample: "sample", sample: "sample" },
  ];
  // setData(tbaledata)

  const [show, setShow] = useState(false);

  const [memberType, setMemberType] = useState([]);
  const [titlee, setTitle] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [postalCodes, setPostalCodes] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [cities, setCities] = useState([]);
  const [universitiess, setUniverssities] = useState([]);

  const [companies, setCompanies] = useState([]);
  const [saveAndExitBtn, setSaveAndExitBtn] = useState(true);
  const [newApplicantStatus, setnewApplicantStatus] = useState(true);
  const [submitButtonDisabled, setsubmitButtonDisabled] = useState(true);

  const [dob, setDob] = useState("");
  const [nic, setNic] = useState("");
  const [lastname, setLastname] = useState("");
  const [InitialData, setInitialData] = useState({
    // titel: [],
    headerData: {},
  });

  const [applicationData, setApplicationData] = useState(null);
  useEffect(() => {
    const call1 = new Promise((resolve, reject) => {
      userService.getMemberDetailsTouUpdate("1").then((data) => {
        console.log("forButtonHideCheck====>>>", data);
        console.log("forButtonHideCheck222====>>>", data.memberData);
        console.log("forButtonHideCheck333====>>>", data.memberData.status);
        setApplicationData(data.memberData);
        if (data.memberData != "No Member") {
          if (data.memberData.status == 17) {
            setSaveAndExitBtn(true);
            setnewApplicantStatus(true);
          } else if (data.memberData.status == 3) {
            setSaveAndExitBtn(true);
            setnewApplicantStatus(false);
          } else if (data.memberData.status == 15) {
            setSaveAndExitBtn(false);
            setnewApplicantStatus(false);
          } else {
            setSaveAndExitBtn(false);
            setnewApplicantStatus(false);
          }
          resolve(data);
        } else {
          userService.getUserDetailsTouUpdate("1").then((data) => {
            // window.location.reload();
            console.log("forButtonHideCheck====>>>", data);
            console.log("forButtonHideCheck222====>>>", data.memberData);
            console.log("forButtonHideCheck333====>>>", data.memberData.status);
            if (data.memberData != "No Member") {
              if (data.memberData.status == 17) {
                setSaveAndExitBtn(true);
                setnewApplicantStatus(true);
              } else if (data.memberData.status == 3) {
                setSaveAndExitBtn(true);
                setnewApplicantStatus(false);
              } else if (data.memberData.status == 15) {
                setSaveAndExitBtn(false);
                setnewApplicantStatus(false);
              } else {
                setSaveAndExitBtn(false);
                setnewApplicantStatus(false);
              }
              resolve(data);
            }
          });
        }
      });
    });

    Promise.all([call1]).then((values) => {
      /**
       * Title Value assign option
       * desc : since get API dosen't provide titleId
       */
      // let titleValue = null;
      // if (typeof values[0].memberData.title != "undefined") {
      //   switch (values[0].memberData.title) {
      //     case "Dr":
      //       titleValue = 1;
      //       break;
      //     case "Mr":
      //       titleValue = 2;
      //       break;
      //     case "Miss":
      //       titleValue = 3;
      //       break;
      //     case "Ms":
      //       titleValue = 4;
      //       break;
      //     case "Mrs":
      //       titleValue = 5;
      //       break;
      //     default:
      //       titleValue = null;
      //       break;
      //   }
      // }
      const tpNo = values[0].memberData.mobileNo;
      const firstLetter = typeof tpNo === "string" ? tpNo.charAt(0) : null;
      var tp = null;
      if (firstLetter == "0") {
        tp = values[0].memberData.mobileNo;
      } else {
        tp = "0" + values[0].memberData.mobileNo;
      }
      const provinceData = {
        label: values[0].memberData.province, // Assuming this is the province name
        value: values[0].memberData.provinceId, // Assuming this is the province ID
      };

      setInitialData({
        ...InitialData,
        headerData: {
          firstname: values[0].memberData.firstName,
          middlename: values[0].memberData.middleName,
          lastname: values[0].memberData.lastName,
          contactno: tp,
          email: values[0].memberData.email,
          addres_l1: values[0].memberData.addressNo,
          addres_l2: values[0].memberData.addressSt,
          addres_l3: values[0].memberData.addressCity,
          nic: values[0].memberData.nicNumber,
          dob: values[0].memberData.dob,
          comment: values[0].memberData.userComment,
          mem_type: {
            // label: values[0].memberData.memberTypeName,
            // value: values[0].memberData.membertypeid
            label: "Professional",
            value: 1,
          },
          title_type: {
            label: values[0].memberData.title,
            // value: values[0].memberData.titleID
          },
          province: values[0].memberData.province
            ? {
                label: values[0].memberData.province,
                value: values[0].memberData.provinceId,
              }
            : undefined,
          postalCode: values[0].memberData.postalCode
            ? {
                label: values[0].memberData.postalCode,
                value: values[0].memberData.postalCodeId,
              }
            : undefined,
          district: values[0].memberData.district
            ? {
                label: values[0].memberData.district,
                value: values[0].memberData.districtId,
              }
            : undefined,
          city: values[0].memberData.city
            ? {
                label: values[0].memberData.city,
                value: values[0].memberData.cityId,
              }
            : undefined,
        },
      });
    });
  }, []);

  // const applicationObj = window.sessionStorage.getItem("application");
  // console.log(JSON.parse(applicationObj));
  // const newApplication = JSON.parse(applicationObj);
  // if(newApplication == null){
  //   console.log("ifffffffffffffffffffffffffffffffffffffffffffffffffffffffffff")
  //   window.sessionStorage.setItem("application", JSON.stringify(applicationData));
  // }

  const initialButtons = [
    { id: 0, name: "save" },
    { id: 1, name: "submit" },
  ];

  const [saveSubmitState, setSaveSubmitState] = useState(
    initialButtons[0].name
  );
  // const [saveSubmitState, setSaveSubmitState] = useState()
  const [submitFormData, setSubmitFormData] = useState();
  // const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);

  const [checkboxValForButton, setCheckboxValForButton] = useState("inlist");
  const [hideCompanyNameText, setHideCompanyNameText] = useState("hidden");
  const [hideQualificationNameText, setHideQualificationNameText] =
    useState("hidden");
  // const checkboxVal = (event) => {
  //   if(event.target.value =="notinlist"){
  //     console.log("not in list")

  //     Swal.fire({
  //       text: "Please select Other option in above dropdown list",
  //       icon: "info",
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         setHideCompanyNameText("text")
  //       }
  //     });

  //   }else{
  //     console.log("in list")
  //     setHideCompanyNameText("hidden")
  //   }
  //   setCheckboxValForButton(event.target.value);
  //   console.log("checkbox val===============>>>>",event.target.value)
  // };

  const [loader2, setLoader2] = useState(false);
  const [loader3, setLoader3] = useState(false);
  const [loader4, setLoader4] = useState(false);

  const submitForm = (value) => {
    console.log("on-submit-submitForm", value);
  };

  const saveAndExit = (value) => {
    console.log("on-submit-saveAndExit", value);
  };
  //save and exit button
  const updatemember = (value) => {
    console.log("you clicked me");
    console.log(
      "initialButtons[0].name===============>>>",
      initialButtons[0].name
    );
    console.log(
      "initialButtons[1].name===============>>>",
      initialButtons[1].name
    );
    console.log("saveSubmitState===============>>>", saveSubmitState);
    console.log("on-submit", value);
    console.log(value.dob);
    if (saveSubmitState == initialButtons[0].name) {
      // setLoader(true);
      let birthDate = "";
      let birthMonth = "";
      const birthDay = parseInt(value.dob.day);
      const birthMon = parseInt(value.dob.month);
      const birthyr = parseInt(value.dob.year);
      const tenValue = parseInt(10);

      console.log(value.dob.day);
      if (birthDay < tenValue) {
        birthDate = "0" + value.dob.day;
        console.log("BirthDate=============> ", birthDate);
      } else {
        birthDate = value.dob.day;
      }
      if (birthMon < tenValue) {
        birthMonth = "0" + value.dob.month;
        console.log("BirthMonth=============> ", birthMonth);
      } else {
        birthMonth = value.dob.month;
      }

      const DOB = `${value.dob.year}-${birthMonth}-${birthDate}`;
      // const DOB = `${value.dob.year}/${birthMonth}/${birthDate}`;
      console.log(DOB);
      value.dob = DOB;
      console.log(value.dob);

      const updatedValue = {
        ...value, // Assuming 'value' is defined somewhere in your code
        savedDistrict,
        savedProvince,
        savedCity,
      };

      userService.updateMemberDetails({ ...updatedValue }).then((res) => {
        console.log(res);

        if (res.status == "00") {
          // setLoader(true);
          // setLoader(false);
          window.sessionStorage.setItem(
            "application",
            JSON.stringify(res.memberData)
          );
          Swal.fire({
            text: "Update Successfuly",
            icon: "success",
            html:
              `
                  <b>Update Successfuly</b><br>
                  Your Application id is - ` +
              res.memberData.applicationId +
              `
                `,
          }).then((result) => {
            // setLoader(false);
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        } else if (res.status == "03") {
          //setLoader(false);
          Swal.fire({
            text: "Update Failed",
            icon: "error",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        } else if (res.status == "04") {
          //setLoader(false);
          Swal.fire({
            text: "Invalid NIC number count",
            icon: "error",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        } else if (res.status == "05") {
          //setLoader(false);
          Swal.fire({
            text: "Invalid NIC number Last letter",
            icon: "error",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        } else if (res.status == "06") {
          //setLoader(false);
          Swal.fire({
            text: "Invalid NIC number",
            icon: "error",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        } else if (res.status == "07") {
          //setLoader(false);
          Swal.fire({
            text: "Date of birth not fill",
            icon: "error",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        } else if (res.status == "08") {
          //setLoader(false);
          Swal.fire({
            text: "Your birthday and NIC number does not match",
            icon: "error",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        } else if (res.status == "09") {
          //setLoader(false);
          Swal.fire({
            text: "Invalid NIC number",
            icon: "error",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        } else if (res.status == "10") {
          //setLoader(false);

          Swal.fire({
            text: "Update Successfuly",
            icon: "success",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        } else if (res.status == "11") {
          // setLoader(false);

          Swal.fire({
            text: "Duplicate NIC",
            icon: "error",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        } else if (res.status == "12") {
          // setLoader(false);

          Swal.fire({
            text: "Invalid Phone number",
            icon: "error",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        } else if (res.status == "13") {
          //  setLoader(false);

          Swal.fire({
            text: "Invalid Email address",
            icon: "error",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        } else {
          // setLoader(false);
          Swal.fire({
            icon: "error",

            text: "Please try again !",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        }
      });
    }
    //submit button
    if (saveSubmitState == initialButtons[1].name) {
      console.log("testttttt");

      Swal.fire({
        title: "Are you sure?",
        text:
          "Your application is about to be submitted for Council Approval. Please ensure that all necessary details have been updated. Once submitted, updates will no longer be possible.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm",
      }).then((result) => {
        if (result.isConfirmed) {
          // setLoader1(true);

          console.log("Confirmed.....");
          setLoader1(true);

          userService.submitapprove(value).then((res) => {
            console.log(res);

            if (res.status == "00") {
              setLoader1(false);
              Swal.fire({
                text: "Submit Successfully",
                icon: "success",
                html:
                  `
                  <b>Submit Successfully</b><br>
                  Your Application id is - ` +
                  res.memberData.applicationId +
                  `
                `,
              }).then((result) => {
                if (result.isConfirmed) {
                  setShow(!show);
                  window.location.reload();
                }
              });

              //button hide after submit
            } else if (res.status == "04") {
              setLoader1(false);
              Swal.fire({
                text: "This NIC number associates with an existing member.",
                icon: "warning",
              }).then((result) => {
                if (result.isConfirmed) {
                  // window.location.reload();
                }
              });
            } else if (res.status == "05") {
              setLoader1(false);
              // }else if (res.status == "05") {

              Swal.fire({
                text: "Enter your academic qualifications before submit the application",
                // text: "No saved academic details.",
                icon: "warning",
              }).then((result) => {
                if (result.isConfirmed) {
                  // window.location.reload();
                }
              });
            }
            // else {
            // }
            else {
              setLoader1(false);

              Swal.fire({
                text: "Submit Failed",
                icon: "error",
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                }
              });
            }
          });
        } else {
          console.log("sbmit pass!");
        }
      });
    }
  };

  // Employee Detail - Save
  const addEmployeeDetails = (data) => {
    var bodyFormData = new FormData();
    let StDate = "";
    let StMonth = "";
    let EnDate = "";
    let EnMonth = "";
    if (data.startDate.day < 10) {
      StDate = "0" + data.startDate.day;
    } else {
      StDate = data.startDate.day;
    }
    if (data.startDate.month < 10) {
      StMonth = "0" + data.startDate.month;
    } else {
      StMonth = data.startDate.month;
    }
    if (data.endDate.day < 10) {
      EnDate = "0" + data.endDate.day;
    } else {
      EnDate = data.endDate.day;
    }
    if (data.endDate.month < 10) {
      EnMonth = "0" + data.endDate.month;
    } else {
      EnMonth = data.endDate.month;
    }
    // console.log(EnDate)
    const StartDate = `${data.startDate.year}-${StMonth}-${StDate}`;
    const EndDate = `${data.endDate.year}-${EnMonth}-${EnDate}`;

    let empCheck = null;
    if (
      data.currentemp == undefined ||
      data.currentemp == "undefined" ||
      data.currentemp == "" ||
      data.currentemp == null
    ) {
      empCheck = "off";
    } else {
      empCheck = "on";
    }
    // bodyFormData.append("companyname", data.company_name);
    bodyFormData.append("companyname", data.company_name.label);
    bodyFormData.append("companynameval", data.company_name.value);
    bodyFormData.append("companynameother", data.companyName);
    bodyFormData.append("desgnation", data.designation);
    bodyFormData.append("adddress", data.company_address);
    bodyFormData.append("currentemp", empCheck);
    bodyFormData.append("phonenumber", parseInt(data.company_phone));
    bodyFormData.append("startDate", StartDate);
    bodyFormData.append("endDate", EndDate);

    bodyFormData.append("file", data.file);
    setLoader2(true);
    dispatch(ApplicationDetailActions.addEmployment(bodyFormData));
    // setmodel(false)
  };

  useEffect(() => {
    if (!initLoad && !isAddEmploymentRequestSent) {
      console.log("Employee details", mes);
      if (mes === "success") {
        setLoader2(false);
        Swal.fire({
          title: "Saved!",
          text: "Employee details creation has been success.",
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      } else if (mes === "pdf required") {
        setLoader2(false);
        console.log("Employee details pdf required", mes);
        Swal.fire("Failed!", "Please upload a PDF file.", "error");
      } else if (mes === "You can't select same date for 2 jobs") {
        console.log("You can't select same date for 2 jobs", mes);
        Swal.fire(
          "Failed!",
          "You can't select same Start date for 2 jobs.",
          "error"
        );
      } else {
        setLoader2(false);
        Swal.fire({
          title: "Failed!",
          text: "Employee details creation has been failed.",
          icon: "error",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      }
    }
  }, [isAddEmploymentRequestSent]);

  //Academic Detail -Save
  const addAcademicDetail = (data) => {
    var bodyFormData = new FormData();
    let SDate = "";
    let SMonth = "";
    let TDate = "";
    let TMonth = "";
    let EDate = "";
    let EMonth = "";
    if (data.fromDate.day < 10) {
      SDate = "0" + data.fromDate.day;
    } else {
      SDate = data.fromDate.day;
    }
    if (data.fromDate.month < 10) {
      SMonth = "0" + data.fromDate.month;
    } else {
      SMonth = data.fromDate.month;
    }
    if (data.toDate.day < 10) {
      TDate = "0" + data.toDate.day;
    } else {
      TDate = data.toDate.day;
    }
    if (data.toDate.month < 10) {
      TMonth = "0" + data.toDate.month;
    } else {
      TMonth = data.toDate.month;
    }
    if (data.admitDate.day < 10) {
      EDate = "0" + data.admitDate.day;
    } else {
      EDate = data.admitDate.day;
    }
    if (data.admitDate.month < 10) {
      EMonth = "0" + data.admitDate.month;
    } else {
      EMonth = data.admitDate.month;
    }
    const StartDate = `${data.fromDate.year}-${SMonth}-${SDate}`;

    const ToDate = `${data.toDate.year}-${TMonth}-${TDate}`;
    const EndDate = `${data.admitDate.year}-${EMonth}-${EDate}`;
    console.log("START====>", StartDate);
    // `${value.dob.day}.${value.dob.month}.${value.dob.year}
    bodyFormData.append("institutename", data.institute_name.label);
    bodyFormData.append("institutenameval", data.institute_name.value);
    bodyFormData.append("qualificationname", data.qualification.label);
    bodyFormData.append("qualname", data.qualname);
    bodyFormData.append("parttimefulltime", data.parttimefulltime.label);
    bodyFormData.append("academicuni", data.academicuni);
    bodyFormData.append("address", data.address);
    // bodyFormData.append("phonenumber", data.pt_ft);
    bodyFormData.append("fromDate", StartDate);
    bodyFormData.append("toDate", ToDate);
    bodyFormData.append("admitDate", EndDate);
    bodyFormData.append("fileupload", data.certificate);

    console.log(data);
    setLoader3(true);
    dispatch(ApplicationDetailActions.addAcademic(bodyFormData));
    // setmodel1(false)
  };
  useEffect(() => {
    if (!initLoad && !isAddAcademicRequestSent) {
      console.log("Academic qualification", mes);
      if (mes === "success") {
        setLoader3(false);
        Swal.fire({
          title: "Saved!",
          text: "Academic details creation has been success.",
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      } else if (mes === "pdf required") {
        setLoader3(false);
        console.log("Academic qualification details pdf required", mes);
        Swal.fire({
          title: "Failed!",
          text: "Please upload a PDF file.",
          icon: "error",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      } else {
        setLoader3(false);
        Swal.fire({
          title: "Failed!",
          text: "Academic qualification details creation has been failed.",
          icon: "error",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      }
    }
  }, [isAddAcademicRequestSent]);

  //professional Bodies save

  const addProfDetail = (data) => {
    var bodyFormData = new FormData();
    let SttMonth = "";
    let SttDate = "";
    let EddDate = "";
    let EddMonth = "";
    if (data.startdate.day < 10) {
      SttDate = "0" + data.startdate.day;
    } else {
      SttDate = data.startdate.day;
    }
    if (data.startdate.month < 10) {
      SttMonth = "0" + data.startdate.month;
    } else {
      SttMonth = data.startdate.month;
    }
    if (data.enddate.day < 10) {
      EddDate = "0" + data.enddate.day;
    } else {
      EddDate = data.enddate.day;
    }
    if (data.enddate.month < 10) {
      EddMonth = "0" + data.enddate.month;
    } else {
      EddMonth = data.enddate.month;
    }
    console.log(EddDate);
    const StarttDate = `${data.startdate.year}-${SttMonth}-${SttDate}`;
    const EnddDate = `${data.enddate.year}-${EddMonth}-${EddDate}`;
    bodyFormData.append("membername", data.member_name);
    bodyFormData.append("profession", data.profession);
    bodyFormData.append("grade", data.grade);
    bodyFormData.append("address", data.address);
    bodyFormData.append("entrymethod", data.entrymethod.label);
    bodyFormData.append("startdate", StarttDate);
    bodyFormData.append("enddate", EnddDate);
    // bodyFormData.append("admitdate", dateConvertToString(data.admit_date));

    bodyFormData.append("proffileupload", data.certificates);

    setLoader4(true);
    dispatch(ApplicationDetailActions.addProfessional(bodyFormData));
    // setmodel2(false)
  };

  useEffect(() => {
    if (!initLoad && !isAddProfRequestSent) {
      console.log("Professional details", mes);
      if (mes === "success") {
        setLoader4(false);
        Swal.fire({
          title: "Saved!",
          text: "Professional details creation has been success.",
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      } else if (mes === "pdf required") {
        setLoader4(false);
        console.log("Professional details pdf required", mes);
        Swal.fire({
          title: "Failed",
          text: "Please upload a PDF file.",
          icon: "error",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      } else {
        setLoader4(false);
        Swal.fire({
          title: "Failed!",
          text: "Professional details creation has been failed.",
          icon: "error",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      }
    }
  }, [isAddProfRequestSent]);

  //  Submit to approval

  const submitApproval = (value) => {
    console.log(value);
    console.log(submitFormData);
  };

  const addEployeeDetails = (value) => {
    console.log(value);
  };

  useEffect(() => {
    if (!initialLoad && !isUpdateRequestSent) {
      if (message === "success") {
        toast.success("User Update Success", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(`${message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }, [isUpdateRequestSent]);

  const onSubmit = (data, onSubmitProps) => {
    console.log("Submit------->", data);
    if (editData) {
      Swal.fire({
        title: "Are you sure?",
        // text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Update it!",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log(data);
          dispatch(ApplicationDetailActions.updateUser(data));
        }
      });
    } else {
      dispatch(ApplicationDetailActions.addUser(data));
    }
    onSubmitProps.resetForm();
  };

  // Edit & Delete Handlers in Employee Detail Table

  const editUserHandler = (data) => {
    console.log("editEmp", data);
    setEditModel(true);
    setEditData({
      company_name: data.companyname,
      designation: data.desgnation,
      company_address: data.adddress,
    });
    setmodel(true);
  };

  const deleteUserHandler = (data) => {
    console.log("before alert", data);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log(data);
        dispatch(ApplicationDetailActions.deleteUser(data));

        Swal.fire(
          "Deleted!",
          "Your employee detail has been deleted.",
          "success"
        );
      }
    });
  };

  // Edit & Delete Handlers in Academic Detail Table
  const editProUserHandler = (academic_data) => {
    console.log("Academic Data==>", academic_data);
    // setEditModel(true)
    setEditData({
      // startdate: data.startdate ,
      // enddate: data.enddate,
      // companyname:   data.companyname,
      // desgnation: data.desgnation,
      // adddress: data.adddress,
      // employerid: data.employerid,
      // phonenumber: data.phonenumber,
      // firstname:values[0].memberData.firstName,
      // userRoleID:{value:data.userRoleName, label: data.userRoleID},
      // userStatusID : {value:data.statusID, label: data.status},
    });
    setIsModelView_(true);
  };

  const deleteAcaUserHandler = (academic_data) => {
    console.log(academic_data);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log(data);
        dispatch(ApplicationDetailActions.deleteAcademicUser(academic_data));
        Swal.fire(
          "Deleted!",
          "Your academic detail has been deleted.",
          "success"
        ).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
        // dispatch(ApplicationDetailActions.deleteAcaUserHandler(academic_data));
      }
    });
  };

  // Edit & Delete Handlers in Proffessional Bodies Data Table

  const editProfUserHandler = (professionalMembership_data) => {
    console.log(professionalMembership_data);
    setEditData({
      professionalid: professionalMembership_data.professionalid,
      profession: professionalMembership_data.profession,

      // firstname:values[0].memberData.firstName,
      //userRoleID:{value:data.userRoleName, label: data.userRoleID},
      //userStatusID : {value:data.statusID, label: data.status},
    });
    setIsModelView(true);
  };

  const deleteProfUserHandler = (professionalMembership_data) => {
    console.log("professionalMembership_data", professionalMembership_data);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          ApplicationDetailActions.deleteProfDetailUser(
            professionalMembership_data
          )
        );
        Swal.fire(
          "Deleted!",
          "Your professional detail has been deleted.",
          "success"
        ).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      }
    });
  };

  //Table methods - Employee Detail Table
  const fetchUser = async (page) => {
    setLoading(true);

    const response = await applicationService.getEmployeeDetailByMemberId({
      page,
      perPage,
      sortColumn,
      sortDirection,
      filterText,
    });

    setData(response.data);
    console.log(response.data);
    setTotalRows(response.total);
    setLoading(false);
  };

  const handlePageChange = (page) => {
    fetchUser(page);
  };

  const handlePerRowsChange = (perPage) => {
    setPerPage(perPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortColumn(column.selector);
    setSortDirection(sortDirection);
    fetchUser(1);
  };

  useEffect(() => {
    if (!isAddRequestSent && !isAddEmploymentRequestSent) {
      fetchUser(1);
    }
  }, [
    perPage,
    filterText,
    isAddRequestSent,
    isUpdateRequestSent,
    isDeleteRequestSent,
    isAddEmploymentRequestSent,
  ]);

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  //  Table methods - Academic Detail

  const fetchMember = async (page_) => {
    setLoading_(true);

    const response1 = await applicationService.getAccadamicDetailByMemberId({
      page_,
      perPage_,
      sortColumn_,
      sortDirection_,
      filterText_,
    });

    console.log(response1.data);
    SetAcademic_data(response1.data);
    setTotalRows_(response1.total);
    setLoading_(false);
  };

  const handlePageChangeACC = (page_) => {
    fetchMember(page_);
  };

  const handlePerRowsChangeACC = (perPage_) => {
    setPerPage_(perPage_);
  };

  const handleSortACC = (column1, sortDirection_) => {
    setSortColumn_(column1.selector);
    setSortDirection_(sortDirection_);
    fetchMember(1);
  };

  useEffect(() => {
    if (!isAddRequestSent_ && !isAddAcademicRequestSent) {
      fetchMember(1);
    }
  }, [
    perPage_,
    filterText_,
    isAddRequestSent_,
    isUpdateRequestSent_,
    // isDeleteAcademicRequestSent
    isDeleteRequestSent_,
    isAddAcademicRequestSent,
  ]);

  const subHeaderComponentMemoACC = React.useMemo(() => {
    const handleClear = () => {
      if (filterText_) {
        setResetPaginationToggle_(!resetPaginationToggle_);
        setFilterText_("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText_(e.target.value)}
        onClear={handleClear}
        filterText={filterText_}
      />
    );
  }, [filterText_, resetPaginationToggle_]);

  //  Table methods - Professional Membership Detail

  const fetchMembership = async (page__) => {
    setLoading__(true);

    const response = await applicationService.getMemberIdForProfessionalDetails(
      {
        page__,
        perPage__,
        sortColumn__,
        sortDirection__,
        filterText__,
      }
    );

    SetProfessionalMembership_data(response.data);
    console.log(response.data);
    setTotalRows__(response.total);
    setLoading__(false);
  };

  const handlePageChangeMem = (page__) => {
    fetchMembership(page__);
  };

  const handlePerRowsChangeMem = (perPage__) => {
    setPerPage__(perPage__);
  };

  const handleSortMem = (column2, sortDirection__) => {
    setSortColumn__(column2.selector);
    setSortDirection__(sortDirection__);
    fetchMembership(1);
  };

  useEffect(() => {
    if (!isAddRequestSent__ && !isAddProfRequestSent) {
      fetchMembership(1);
    }
  }, [
    perPage__,
    filterText__,
    isAddRequestSent__,
    isUpdateRequestSent__,
    isDeleteRequestSent__,
    isAddProfRequestSent,
  ]);

  const subHeaderComponentMemoMem = React.useMemo(() => {
    const handleClear = () => {
      if (filterText__) {
        setResetPaginationToggle__(!resetPaginationToggle__);
        setFilterText__("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText__(e.target.value)}
        onClear={handleClear}
        filterText={filterText__}
      />
    );
  }, [filterText__, resetPaginationToggle__]);

  const prov = (data) =>
    data.map(({ memberTypeId, typeDes }, index) => ({
      value: memberTypeId,
      label: typeDes,
    }));

  useEffect(() => {
    try {
      userService.getMemberType().then((data) => {
        setMemberType(prov(data));
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  const provTitle = (data) =>
    data.map(({ titleId, title }, index) => ({
      value: titleId,
      label: title,
    }));

  useEffect(() => {
    try {
      userService.getMemberTitle().then((data) => {
        setTitle(provTitle(data));
        console.log("titlee", titlee);
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  const postal = (data) =>
    data.map(({ id, postalCode, description }, index) => ({
      value: id,
      label: `${postalCode} - ${description}`,
    }));

  useEffect(() => {
    try {
      parameterService.getPostalCodes().then((data) => {
        setPostalCodes(postal(data));
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  const provs = (data) =>
    data.map(({ id, provinceCode }, index) => ({
      value: id,
      label: provinceCode,
    }));

  const fetchProvinces = async () => {
    try {
      const data = await parameterService.getProvinces();
      setProvinces(provs(data));
    } catch (error) {
      console.error("Error fetching provinces:", error);
    }
  };

  const dist = (data) =>
    data.map(({ id, districtCode }, index) => ({
      value: id,
      label: districtCode,
    }));

  const handleChange = async (selectedOption) => {
    try {
      const data = await parameterService.getDistrictsByProvince(
        selectedOption.value
      );
      const provinceId = selectedOption.value;
      const provinceName = selectedOption.label;
      console.log("Selected province id:", provinceId);
      console.log("Selected province name:", provinceName);
      setSavedProvince({
        id: provinceId,
        name: provinceName,
      });

      setDistricts(dist(data));
    } catch (error) {
      console.error("Error fetching districts:", error);
    }
  };

  const cit = (data) =>
    data.map(({ id, cityCode }, index) => ({
      value: id,
      label: cityCode,
    }));

  const handleChangedist = async (selectedOption) => {
    try {
      const data = await parameterService.getCitiesByDistrict(
        selectedOption.value
      );
      const districtId = selectedOption.value;
      const districtName = selectedOption.label;
      console.log("Selected district id:", districtId);
      console.log("Selected district name:", districtName);
      setSavedDistrict({
        id: districtId,
        name: districtName,
      });

      setCities(cit(data));
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  const handleChangedcity = async (selectedOption) => {
    const cityId = selectedOption.value;
    const cityName = selectedOption.label;

    console.log("Selected city id:", cityId);
    console.log("Selected city name:", cityName);

    // Save the values in the variable
    setSavedCity({
      id: cityId,
      name: cityName,
    });
  };

  useEffect(() => {
    fetchProvinces();
  }, []);

  const provInstitue = (data) =>
    data.map(({ universityId, universityName }, index) => ({
      value: universityId,
      label: universityName,
    }));

  useEffect(() => {
    try {
      userService.getAllUnivercities().then((data) => {
        setUniverssities(provInstitue(data));
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  const provCompanies = (data) =>
    data.map(({ companyId, companyName }, index) => ({
      value: companyId,
      label: companyName,
    }));

  useEffect(() => {
    try {
      userService.getAllCompanies().then((data) => {
        setCompanies(provCompanies(data));
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  // const companyNameHide = (event) => {
  //   console.log("companyNameHide=====>>>",event)
  // };
  // console.log(data.setCompanies);
  //   console.log(data.setCompanies);

  const props = {
    //Employee Detail - Props
    data,
    loading,
    setmodel,
    model,
    setmodel1,
    model1,
    setmodel2,
    model2,
    titlee,
    provinces,
    districts,
    postalCodes,
    cities,
    handleChange,
    handleChangedist,
    handleChangedcity,
    editModel,
    InitialData,
    updatemember,
    addEployeeDetails,
    onSubmit,
    editUserHandler,
    deleteUserHandler,
    deleteAcaUserHandler,
    deleteProfUserHandler,
    editProUserHandler,
    editProfUserHandler,
    // onChangeHandler,
    totalRows,
    handlePageChange,
    handlePerRowsChange,
    handleSort,
    resetPaginationToggle,
    subHeaderComponentMemo,
    editData,
    setEditData,
    isModelView,
    setIsModelView,

    // Academic Detail - Props
    academic_data,
    setIsModelView_,
    isModelView_,
    totalRows_,

    loading_,
    resetPaginationToggle_,
    handlePageChangeACC,
    handlePerRowsChangeACC,
    handleSortACC,
    subHeaderComponentMemoACC,

    // Prof. Detail - Props
    professionalMembership_data,
    setIsModelView__,
    isModelView__,
    totalRows__,
    loading__,
    resetPaginationToggle__,
    handlePageChangeMem,
    handlePerRowsChangeMem,
    handleSortMem,
    subHeaderComponentMemoMem,
    //fileValidationSchema,
    //loader,
    loader1,
    loader2,
    loader3,
    loader4,
    //Bimsara Test
    addEmployeeDetails,

    addAcademicDetail,
    addProfDetail,

    submitApproval,
    setSaveSubmitState,
    initialButtons,
    memberType,
    universitiess,
    companySelect,
    companies,
    saveAndExitBtn,
    newApplicantStatus,
    // onChangeHandler1: handleOnChange,
    //companyNameHide,
    //checkboxVal,
    checkboxValForButton,
    hideCompanyNameText,
    hideQualificationNameText,
    InitialDataMem,
    InitialDataMem1,

    qualificationLevels,
    academicSelect,
    qualificationSelect,
    InitialDataAcademicVal,
    InitialDataAcademicLabel,
    hideAcedemicText,

    InitialDataQualificationVal,
    InitialDataQualificationLabel,

    submitForm,
    saveAndExit,
  };

  return ViewProfile(props);
});
