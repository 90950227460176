import React, { memo } from "react";
import { ButtonGroup, Button, Form, Row, Col } from "react-bootstrap";

function ButtonComponent(props) {
  const { name, title } = props;
  return (
    <>
      <Form.Group controlId="brand">
        <Row style={{ textAlign: "center" }}>
          <Col>
            <Form.Label>{`${title}`}</Form.Label>
          </Col>
        </Row>
        <Row style={{ textAlign: "center" }}>
          <Col>
            <ButtonGroup className="me-2" aria-label="First group">
              <Button
                variant={
                  props.values[`${name}`] === true
                    ? "success"
                    : "outline-dark"
                }
                onClick={() => {
                  props.setFieldValue(`${name}`, true);
                }}
              >
                Active
              </Button>{" "}
              <Button
                variant={
                  props.values[`${name}`] === false
                    ? "danger"
                    : "outline-dark"
                }
                onClick={() => {
                  props.setFieldValue(`${name}`, false);
                }}
              >
                Deactive
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </Form.Group>
    </>
  );
}

export default memo(ButtonComponent);
