import UserService from "../../../services/User.service";

//POST EMPLOYMENT details
export const SEND_ADD_EMPLOYMENT_REQUEST = "SEND_ADD_EMPLOYMENT_REQUEST";
export const SEND_ADD_EMPLOYMENT_SUCCESS = "SEND_ADD_EMPLOYMENT_SUCCESS";
export const SEND_ADD_EMPLOYMENT_FAILED = "SEND_ADD_EMPLOYMENT_FAILED";

//POST ACADEMIC details
export const SEND_ADD_ACADEMIC_REQUEST = "SEND_ADD_ACADEMIC_REQUEST";
export const SEND_ADD_ACADEMIC_SUCCESS = "SEND_ADD_ACADEMIC_SUCCESS";
export const SEND_ADD_ACADEMIC_FAILED = "SEND_ADD_ACADEMIC_FAILED";

//POST PROFESSIONAL details
export const SEND_ADD_PROFESSIONAL_REQUEST = "SEND_ADD_PROFESSIONAL_REQUEST";
export const SEND_ADD_PROFESSIONAL_SUCCESS = "SEND_ADD_PROFESSIONAL_SUCCESS";
export const SEND_ADD_PROFESSIONAL_FAILED = "SEND_ADD_PROFESSIONAL_FAILED";

// DELETE - EMPLOYEE DATA TABLE
export const SEND_DELETE_USER_REQUEST = "SEND_DELETE_USER_REQUEST";
export const SEND_DELETE_USER_SUCCESS = "SEND_DELETE_USER_SUCCESS";
export const SEND_DELETE_USER_FAILED = "SEND_DELETE_USER_FAILED";

// DELETE - ACADEMIC DATA TABLE
export const SEND_DELETE_ACADEMIC_REQUEST = "SEND_DELETE_ACADEMIC_REQUEST";
export const SEND_DELETE_ACADEMIC_SUCCESS = "SEND_DELETE_ACADEMIC_SUCCESS";
export const SEND_DELETE_ACADEMIC_FAILED = "SEND_DELETE_ACADEMIC_FAILED";

// DELETE - PROF DATA TABLE
export const SEND_DELETE_PROF_REQUEST = "SEND_DELETE_PROF_REQUEST";
export const SEND_DELETE_PROF_SUCCESS = "SEND_DELETE_PROF_SUCCESS";
export const SEND_DELETE_PROF_FAILED = "SEND_DELETE_PROF_FAILED";

//POST Submit Approval
// export const SEND_SUBMIT_APPROVAL_REQUEST = "SEND_SUBMIT_APPROVAL_REQUEST";
// export const SEND_SUBMIT_APPROVAL_SUCCESS = "SEND_SUBMIT_APPROVAL_SUCCESS";
// export const SEND_SUBMIT_APPROVAL_FAILED = "SEND_SUBMIT_APPROVAL_FAILED";

// POST EMPLOYMENT Details
const sendAddEmploymentRequest = () => ({
  type: SEND_ADD_EMPLOYMENT_REQUEST,
});

const sendAddEmploymentSuccess = (payload) => ({
  type: SEND_ADD_EMPLOYMENT_SUCCESS,
  payload,
});

const sendAddEmploymentFailed = (error) => ({
  type: SEND_ADD_EMPLOYMENT_FAILED,
  error,
});

// POST ACADEMIC Details
const sendAddAcademicRequest = () => ({
  type: SEND_ADD_ACADEMIC_REQUEST,
});

const sendAddAcademicSuccess = (payload) => ({
  type: SEND_ADD_ACADEMIC_SUCCESS,
  payload,
});

const sendAddAcademicFailed = (error) => ({
  type: SEND_ADD_ACADEMIC_FAILED,
  error,
});

// POST PROFESSIONAL Details
const sendAddProfessionalRequest = () => ({
  type: SEND_ADD_PROFESSIONAL_REQUEST,
});

const sendAddProfessionalSuccess = (payload) => ({
  type: SEND_ADD_PROFESSIONAL_SUCCESS,
  payload,
});

const sendAddProfessionalFailed = (error) => ({
  type: SEND_ADD_PROFESSIONAL_FAILED,
  error,
});

// DELETE OPTION - EMPLOYEE DATA TABLE
const sendDeleteUserRequest = () => ({
  type: SEND_DELETE_USER_REQUEST,
});

const sendDeleteUserSuccess = (payload) => ({
  type: SEND_DELETE_USER_SUCCESS,
  payload,
});

const sendDeleteUserFailes = (error) => ({
  type: SEND_DELETE_USER_FAILED,
  error,
});

// DELETE OPTION - ACADEMIC DATA TABLE
const sendDeleteAcaUserRequest = () => ({
  type: SEND_DELETE_ACADEMIC_REQUEST,
});

const sendDeleteAcaUserSuccess = (payload) => ({
  type: SEND_DELETE_ACADEMIC_SUCCESS,
  payload,
});

const sendDeleteAcaUserFailes = (error) => ({
  type: SEND_DELETE_ACADEMIC_FAILED,
  error,
});

// DELETE OPTION - PROF DATA TABLE
const sendDeletePofUserRequest = () => ({
  type: SEND_DELETE_PROF_REQUEST,
});

const sendDeletePofUserSuccess = (payload) => ({
  type: SEND_DELETE_PROF_SUCCESS,
  payload,
});

const sendDeletePofUserFailes = (error) => ({
  type: SEND_DELETE_PROF_FAILED,
  error,
});

//submit to approval

// const sendSubmitToApprovalRequest = () => ({
//   type: SEND_SUBMIT_APPROVAL_REQUEST,
// });

// const sendSubmitToApprovalSuccess = (payload) => ({
//   type: SEND_SUBMIT_APPROVAL_SUCCESS,
//   payload,
// });

// const sendSubmitToApprovalFailed = (error) => ({
//   type: SEND_SUBMIT_APPROVAL_FAILED,
//   error,
// });

/**
 * This method is  for EMPLOYMENT POST request
 */

export const addEmployment = (data) => async (dispatch) => {
  dispatch(sendAddEmploymentRequest());
  const res = await UserService.addEmploymentDe(data);
  try {
    if (res) {
      const { message, status } = res;
      console.log("status======>>>",status)
      if (status === "00") {
        dispatch(sendAddEmploymentSuccess(message));
      } else if (status === "02") {
        dispatch(sendAddEmploymentFailed(message));
      } else if (status === "03") {
        dispatch(sendAddEmploymentFailed(message));
      } else if (status === "04") {
        dispatch(sendAddEmploymentFailed(message));
      } else if (status === "05") {
        dispatch(sendAddEmploymentFailed(message));
      } else if (status === "06") {
        dispatch(sendAddEmploymentFailed(message));
      }
    }
  } catch (error) {
    dispatch(sendAddEmploymentFailed(error));
  }
};

/**
 * This method is  for ACADEMIC POST request
 */

export const addAcademic = (data) => async (dispatch) => {
  dispatch(sendAddAcademicRequest());
  const res = await UserService.addAcademicDe(data);
  try {
    if (res) {
      const { message, status } = res;
      if (status === "00") {
        dispatch(sendAddAcademicSuccess(message));
      } else if (status === "02") {
        dispatch(sendAddAcademicFailed(message));
      } else if (status === "03") {
        dispatch(sendAddAcademicFailed(message));
      } else if (status === "04") {
        dispatch(sendAddAcademicFailed(message));
      } else if (status === "05") {
        console.log("status======>>>", status);
        dispatch(sendAddAcademicFailed(message));
      }
    }
  } catch (error) {
    dispatch(sendAddAcademicFailed(error));
  }
};

/**
 * This method is  for PROFESSIONAL POST request
 */

export const addProfessional = (data) => async (dispatch) => {
  dispatch(sendAddProfessionalRequest());
  const res = await UserService.addProfDe(data);
  console.log("res======>>>", res);
  try {
    if (res) {
      const { message, status } = res;
      if (status === "00") {
        dispatch(sendAddProfessionalSuccess(message));
      } else if (status === "02") {
        dispatch(sendAddProfessionalFailed(message));
      } else if (status === "03") {
        dispatch(sendAddProfessionalFailed(message));
      } else if (status === "04") {
        dispatch(sendAddProfessionalFailed(message));
      } else if (status === "05") {
        console.log("status======>>>", status);
        dispatch(sendAddProfessionalFailed(message));
      }
    }
  } catch (error) {
    dispatch(sendAddProfessionalFailed(error));
  }
};

//submit to approval

// export const submitapprove =
// (data) =>
//   async (dispatch) => {
//     dispatch(sendSubmitToApprovalRequest());
//     const res = await UserService.submitapprove(data);
//     try {
//       if (res) {
//         const { message, status } = res;
//         if (status === "00") {
//           dispatch(sendSubmitToApprovalSuccess(message));
//         } else if (status === "02") {
//           dispatch(sendSubmitToApprovalFailed(message));
//         }
//       }
//     } catch (error) {
//       dispatch(sendSubmitToApprovalFailed(error));
//     }
//   };

//Delete - Employee Detail Table

export const deleteUser =
  ({ employerid, companyname }) =>
  async (dispatch) => {
    console.log("employerid=======>>>", employerid);
    console.log("companyname=======>>>", companyname);
    dispatch(sendDeleteUserRequest());
    const userResponse = await UserService.deleteUser({
      employerid,
      companyname,
    });
    try {
      if (userResponse) {
        const { status, statusCode } = userResponse;

        if (statusCode === "00") {
          dispatch(sendDeleteUserSuccess(status));
        } else if (statusCode === "02") {
          dispatch(sendDeleteUserFailes(status));
        }
      }
    } catch (error) {
      console.log("User catch", error);
      dispatch(sendDeleteUserFailes(error));
    }
  };

//Delete - Academic Detail Table

export const deleteAcademicUser =
  ({ academicid, institutename }) =>
  async (dispatch) => {
    console.log(academicid);
    dispatch(sendDeleteAcaUserRequest());
    const userResponse = await UserService.deleteAcademicUser({
      academicid,
      institutename,
    });
    try {
      if (userResponse) {
        const { status, statusCode } = userResponse;
        console.log("User catch", status, statusCode);
        if (statusCode === "00") {
          dispatch(sendDeleteAcaUserSuccess(status));
        } else if (statusCode === "02") {
          dispatch(sendDeleteAcaUserFailes(status));
        }
      }
    } catch (error) {
      console.log("User catch", error);
      dispatch(sendDeleteAcaUserFailes(error));
    }
  };

//Delete - Prof Detail Table

export const deleteProfDetailUser =
  ({ id, profession }) =>
  async (dispatch) => {
    console.log(id);
    dispatch(sendDeletePofUserRequest());
    const userResponse = await UserService.deleteProfDetailUser({
      id,
      profession,
    });
    try {
      if (userResponse) {
        const { status, statusCode } = userResponse;

        if (statusCode === "00") {
          dispatch(sendDeletePofUserSuccess(status));
        } else if (statusCode === "02") {
          dispatch(sendDeletePofUserFailes(status));
        }
      }
    } catch (error) {
      console.log("User catch", error);
      dispatch(sendDeletePofUserFailes(error));
    }
  };
