import { combineReducers } from "redux";

import { reducer as gloabalData } from "../pages/parameter-management/global-data";
import { reducer as passwordreset } from "../pages/user-management/password-reset";
import { reducer as roleAuthorization } from "../pages/user-management/role-authorization";
import { reducer as roleCreation } from "../pages/user-management/roles";
import { reducer as userauth } from "../pages/user-management/user-authorization";
import { reducer as userCreation } from "../pages/user-management/user-creation";
import { reducer as users } from "../pages/user-management/user-viewAll";
import { reducer as memReducer } from "../pages/user-management/member-profile";
import demo from "../store/reducer";
import session from "./session.reducer";
// import { reducer as recipeview } from "../pages/recipe-management/recipe-view";
import { reducer as memberDetailsView } from "../pages/member-management/adminapplication-details";
import { reducer as apDetails } from "../pages/member-management/application-details";
import { reducer as goal } from "../pages/cpd-module/development-goals";
import { reducer as applicationDetails } from "../pages/member-management/view-applications";
import { reducer as approveTransactions } from "../pages/member-management/approve-transactions";
import { reducer as approveCpdMembership } from "../pages/member-management/approve-cpdmembership";

export default combineReducers({
  session,
  demo,
  users,
  memReducer,
  userauth,
  passwordreset,
  roleCreation,
  roleAuthorization,
  userCreation,
  gloabalData,
  // recipeview
  applicationDetails,
  memberDetailsView,
  apDetails,
  goal,
  approveTransactions,
  approveCpdMembership
});

// 💡 Notes: Add all upcoming reducers within combine reducers block
