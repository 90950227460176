import UserService from "./../../../services/User.service";



export const SEND_USER_CREATION_REQUEST = '@USER_CREATION/SEND_USER_CREATION_REQUEST';
export const SEND_USER_CREATION_SUCCESS = '@USER_CREATION/SEND_USER_CREATION_SUCCESS';
export const SEND_USER_CREATION_FAILED = '@USER_CREATION/SEND_USER_CREATION_FAILED';

const sendUserCreationRequest = () => ({
  type: SEND_USER_CREATION_REQUEST,
});

const sendUserCreationSuccess = (payload) => ({
  type: SEND_USER_CREATION_SUCCESS,
  payload,
});

const sendUserCreationFailes = (error) => ({
  type: SEND_USER_CREATION_FAILED,
  error,
});

/**
 * This method will be called when sign-in form submitted
 * @param {string} username
 * @param {string} userRoleID
 * @param {string} firstname
 */
export const userCreation = (username, userRoleName, firstName, lastName, email, userDescription, userIsNewEmployee) => async (dispatch) => {
 dispatch(sendUserCreationRequest()); // Piumal
 const userCreationResponse = await UserService.createUser(
  username,
  userRoleName,
  firstName,
  lastName,
  email,
  userDescription,
  userIsNewEmployee
);

try {
  if (userCreationResponse) {
    console.log(userCreationResponse);
    const { msg } = userCreationResponse;

    if (msg === "Success") {
      console.log('Success')
      dispatch(sendUserCreationSuccess(msg));
    } else {
      console.log('failed')
      dispatch(sendUserCreationFailes(msg));
    }
  }
} catch (error) {
  console.log("user creation catch", error);
  dispatch(sendUserCreationFailes(error));
}

};
