import React, { useState } from "react";
import { Card, Col, Row} from "react-bootstrap";
import Aux from "../../../hoc/_Aux";
import { Formik } from "formik";
import * as yup from "yup";
import DataTable from "react-data-table-component";
import { Authorize, Reject } from "../../../components/shared/TableButtons";


function RoleAuthorization(props) {
  const {
    loading,
    data,
    totalRows,
    handlePageChange,
    handlePerRowsChange,
    handleSort,
    resetPaginationToggle,
    subHeaderComponentMemo,
    editData,
    setEditData,
    editRoleHandler,
    deleteRoleHandler,
   
  } = props;

  const init = {
    userRoleID: "",
    userRoleName: "",
    userRoleDescription: "",
  };

//   const rowSelectCritera = row => () => {

//     console.log(row)
//     return row.fat > 6;
//   }


  const column = [
    {
      name: "Role Id",
      selector: "userRoleID",
      sortable: true,
    },
    {
      name: "Role Name",
      selector: "userRoleName",
      sortable: true,
    },
    {
      name: "Description",
      selector: "userRoleDescription",
      sortable: true,
    },
    {
        name: "Create Date",
        selector: "createdAt",
        sortable: true,
      },
      {
        name: "Action",
        button: true,
        cell: (data) => <Authorize authorizeHandler={editRoleHandler} data={data} />,
      },
      {
        name: "Action",
        button: true,
        cell: (data) => <Reject deleteHandler={deleteRoleHandler} data={data} />,
      },
  ];

  const handleChange = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    console.log('Selected Rows: ', state.selectedRows);
  };
  return (
    <Aux>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title as="h5">Role Authorization</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <DataTable
                   
                    columns={column}
                    data={data}
                    progressPending={loading}
                    highlightOnHover={true}
                    responsive={true}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onSort={handleSort}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    selectableRows
                    // selectableRowSelected={rowSelectCritera}
                    onSelectedRowsChange={handleChange}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </Aux>
  );
}

export default RoleAuthorization;
