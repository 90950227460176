import UserService from "../../../services/User.service"

// Action types ✅
// Action types ✅
export const SET_INIT = "@ROLES/SET_INIT"

export const ADD_ROLES_REQUEST = "@ROLES/ADD_ROLES_REQUEST"
export const ADD_ROLES_SUCCESS = "@ROLES/ADD_ROLES_SUCCESS"
export const ADD_ROLES_FAILED = "@ROLES/ADD_ROLES_FAILED"

export const UPDATE_ROLES_REQUEST = "@ROLES/UPDATE_ROLES_REQUEST"
export const UPDATE_ROLES_SUCCESS = "@ROLES/UPDATE_ROLES_SUCCESS"
export const UPDATE_ROLES_FAILED = "@ROLES/UPDATE_ROLES_FAILED"

export const DELETE_ROLES_REQUEST = "@ROLES/DELETE_ROLES_REQUEST"
export const DELETE_ROLES_SUCCESS = "@ROLES/DELETE_ROLES_SUCCESS"
export const DELETE_ROLES_FAILED = "@ROLES/DELETE_ROLES_FAILED"

// Actions ✅
const setInitial = () => ({
  type : SET_INIT
  });

const addRoleRequest = () => ({
    type : ADD_ROLES_REQUEST
    
    });

const addRoleSuccess = (payload) =>({
    type: ADD_ROLES_SUCCESS,
    payload,
})

const addRoleFailure =(error) => ({
    type: ADD_ROLES_FAILED,
    error,
})

//UPDATE
const sendUpdateRoleRequest = () => ({
  type:UPDATE_ROLES_REQUEST,
});

const sendUpdateRoleSuccess = (payload) => ({
  type: UPDATE_ROLES_SUCCESS,
  payload,
});

const sendUpdateRoleFailes = (error) => ({
  type: UPDATE_ROLES_FAILED,
  error,
});

// DELETE
const sendDeleteRoleRequest = () => ({
  type: DELETE_ROLES_REQUEST,
});

const sendDeleteRoleSuccess = (payload) => ({
  type: DELETE_ROLES_SUCCESS,
  payload,
});

const sendDeleteRoleFailes = (error) => ({
  type: DELETE_ROLES_FAILED,
  error,
});

//----------------------------------------------


/**
 * This method will be called when sign-in form submitted
 * @param {string} userRoleName
 * @param {string} userRoleDescription
 
 */
export const setInit = 
 () => 
 async (dispatch) => {
    dispatch(setInitial());
  };

 export const addRole = 
 (para) => 
 async (dispatch) => {
    console.log("22")
    dispatch(addRoleRequest());
    const addRoleResponse = await UserService.addRole(para);
    try {
      if (addRoleResponse) {
        const { status, statusCode } = addRoleResponse;
            
        if (statusCode === "00") {
          dispatch(addRoleSuccess(status));
        } else if (statusCode === "02") {
          dispatch(addRoleFailure(status));
        }else if (statusCode === "03") {
            dispatch(addRoleFailure(status));
          }
      }
    } catch (error) {
      console.log("add role catch", error);
      dispatch(addRoleFailure(error));
    }
  };
  
  export const updateRole =
  (para) =>
  async (dispatch) => {
    console.log(para);
    dispatch(sendUpdateRoleRequest());
    const roleResponse = await UserService.updateRole(para);
    try {
      if (roleResponse) {
        const { status, statusCode } = roleResponse;

        if (statusCode === "00") {
          dispatch(sendUpdateRoleSuccess(status));
        } else if (statusCode === "02") {
          dispatch(sendUpdateRoleFailes(status));
        }
      }
    } catch (error) {
      console.log("Role catch", error);
      dispatch(sendUpdateRoleFailes(error));
    }
  };

  export const deleteRole =
  ({ userRoleID, userRoleName, userRoleDescription }) =>
  async (dispatch) => {
    console.log(userRoleID)
    dispatch(sendDeleteRoleRequest());
    const roleResponse = await UserService.deleteRole({
        userRoleID,
        userRoleName,
        userRoleDescription
    });
    try {
      if (roleResponse) {
        const { status, statusCode } = roleResponse;

        if (statusCode === "00") {
          dispatch(sendDeleteRoleSuccess(status));
        } else if (statusCode === "02") {
          dispatch(sendDeleteRoleFailes(status));
        }
      }
    } catch (error) {
      console.log("Role catch", error);
      dispatch(sendDeleteRoleFailes(error));
    }
  };