import React, { memo, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { Field, ErrorMessage, getIn } from "formik";

function Index(props) {
  // console.log(props)
  const { options, placeholder, title, name, isMulti, disableMenu, onChange = (value, setFieldValue) => setFieldValue(name, value), ...rest } = props;

  const select = (props) => {
    // console.log(props);
    return (
      <Select
        {...props.field}
        styles={getStyles(props.form, `${name}`)}
        isMulti={isMulti ? isMulti : false}
        className="basic-single"
        classNamePrefix="select"
        isDisabled={false}
        isLoading={false}
        isClearable={true}
        isRtl={false}
        isSearchable={true}
        options={options}
        placeholder={placeholder}
        // menuIsOpen={disableMenu?true: false}
        // value={value}
        onChange={(value) => onChange(value, props.form.setFieldValue)}
        {...rest}
      />
    );
  };

  function getStyles(form, fieldName) {
    if (form.errors[fieldName]) {
      if (getIn(form.errors, fieldName) && getIn(form.touched, fieldName)) {
        return {
          control: (provided) => ({
            ...provided,
            borderColor: "red",
          }),
        };
      } else if (getIn(form.touched, fieldName)) {
        return {
          control: (provided) => ({
            ...provided,
            borderColor: "green",
          }),
        };
      }
    } else if (fieldName.includes(".")) {
      if (getIn(form.errors, fieldName) && getIn(form.touched, fieldName)) {
        return {
          control: (provided) => ({
            ...provided,
            borderColor: "red",
          }),
        };
      } else if (getIn(form.touched, fieldName)) {
        return {
          control: (provided) => ({
            ...provided,
            borderColor: "green",
          }),
        };
      }
    }
  }

  return (
    <>
      <Form.Group controlId="currency" style={{ marginTop: 10 }}>
        <Form.Label>{title}</Form.Label>
        <Field name={`${name}`} component={select} />
        <ErrorMessage name={`${name}`}>
          {(msg) => <div style={{ color: "red", fontSize: 12 }}>{msg}</div>}
        </ErrorMessage>
        {false ? <div>test</div> : null}
      </Form.Group>
    </>
  );
}

export default memo(Index);
