import React, { memo, useEffect, useState } from "react";
import UserRole from "./userrole.view";
import userRoleService from "../../../services/User.service";

export default memo((FieldProps) => {

  // const { onChangeHandler, value, componentName } = props;

  const [userrole, setUserRole] = useState([])

  const prov = (data) =>
    data.map(({ userRoleID, userRoleName }, index) => ({
      value: userRoleID,
      label: userRoleName,
    }));

    // console.log(prov);

  useEffect(() => {
    try {
      userRoleService
        .getUserRoleList()
        .then((data) => {
         setUserRole(prov(data));
        });
      
    } catch (e) {
      console.log(e);
    }
  }, []);


  return <UserRole { ...FieldProps} options={userrole}/>;
});
