import { actions as loginActions } from '../pages/user-management/login';

// Initial state ✅
const initialState = {
  loginInProgress: false,
  isAuthenticated: false,
  isBadCredentials: false,
  user: {},
  userMenu:{
    items:[]
  }
};

// Reducer switch ✅
const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case loginActions.SEND_LOGIN_REQUEST:
      return {
        ...state,
        loginInProgress: true,
        isBadCredentials: false
      };
    case loginActions.SEND_LOGIN_SUCESS: {
      const { payload:{User,UserMenu} } = action;
      console.log(UserMenu)
      return {
        ...state,
        loginInProgress: false,
        isAuthenticated: true,
        user: User,
        userMenu:UserMenu
      };
    }
    case loginActions.SEND_LOGIN_FAILED: {
      return {
        ...state,
        loginInProgress: false,
        isBadCredentials: true
      };
    }

    default: return state;
  }
};

export default sessionReducer;
