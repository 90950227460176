import React, { memo } from "react";
import { Form } from "react-bootstrap";

function TextArea(props) {
  const { label, placeholder, name, values, handleChange, touched, errors, rows } =
    props;

  const singlename = name.split(".");
  const nameLength = name.split(".").length;
  return (
    <>
      <>
        {nameLength === 1 && (
          <Form.Group controlId={name} style={{ marginTop: 10 }}>
            <Form.Label>{label}</Form.Label>
            <Form.Control
              as="textarea"
              rows={rows? rows: 3}
              name={singlename}
              placeholder={placeholder}
              value={values[`${singlename}`]}
              onChange={handleChange}
              isValid={touched[`${name}`] && !errors[`${name}`]}
              isInvalid={!!errors[`${name}`] && touched[`${name}`]}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              {errors[`${name}`]}
            </Form.Control.Feedback>
          </Form.Group>
        )}
        {nameLength !== 1 && (
          <Form.Group controlId={name} style={{ marginTop: 10 }}>
            <Form.Label>{label}</Form.Label>
            <Form.Control
              as="textarea"
              rows={rows? rows: 3}
              name={`${singlename[0]}[${singlename[1]}][${singlename[2]}]`}
              placeholder={placeholder}
              value={
                values[`${singlename[0]}`][`${singlename[1]}`][
                  `${singlename[2]}`
                ]
              }
              onChange={handleChange}
              isValid={
                touched[`${singlename[0]}`] &&
                touched[`${singlename[0]}`][`${singlename[1]}`] &&
                touched[`${singlename[0]}`][`${singlename[1]}`][
                  `${singlename[2]}`
                ] &&
                !(
                  errors[`${singlename[0]}`] &&
                  errors[`${singlename[0]}`][`${singlename[1]}`] &&
                  errors[`${singlename[0]}`][`${singlename[1]}`][
                    `${singlename[2]}`
                  ]
                )
              }
              isInvalid={
                !!errors[`${singlename[0]}`] &&
                !!errors[`${singlename[0]}`][`${singlename[1]}`] &&
                !!errors[`${singlename[0]}`][`${singlename[1]}`][
                  `${singlename[2]}`
                ] &&
                touched[`${singlename[0]}`] &&
                touched[`${singlename[0]}`][`${singlename[1]}`] &&
                touched[`${singlename[0]}`][`${singlename[1]}`][
                  `${singlename[2]}`
                ]
              }
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              {errors[`${singlename[0]}`] &&
                errors[`${singlename[0]}`][`${singlename[1]}`] &&
                errors[`${singlename[0]}`][`${singlename[1]}`][
                  `${singlename[2]}`
                ]}
            </Form.Control.Feedback>
          </Form.Group>
        )}
      </>
    </>
  );
}

export default memo(TextArea);
