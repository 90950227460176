import React, { useMemo, useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import Select from "react-select";
import LabledText from "../../Layouts/LabledText";
import DatePicker from "react-modern-calendar-datepicker";
import SelectSearch from 'react-select-search';
import { Field, ErrorMessage, getIn } from "formik";
import "react-modern-calendar-datepicker/lib/DatePicker.css";

// import 'react-select/dist/react-select.css';


const getSelectStyles = (form, fieldName) => {

  if (getIn(form.errors, fieldName) && getIn(form.touched, fieldName)) {
    return {
      control: (provided) => ({
        ...provided,
        borderColor: "#DC3545",
      }),
    };
  } else if (getIn(form.touched, fieldName)) {
    return {
      control: (provided) => ({
        ...provided,
        borderColor: "#198754",
      }),
    };
  }
};

// const [enddated, setEnddated] = useState(true);
// const handleChange1 = (event) => {
//   setEnddated(event.target.checked);
// }

export default function FieldsContainer({ config, formik, initialValues }) {
  // const renderCustomInput = ({ ref }) => (
  //   <input
  //     ref={ref} // necessary
  //     style={{
  //       width: '100%'
  //     }}
  //   />
  // )

  const builder = ({
    id,
    type,
    label,
    name,
    placeholder,
    contianerProps = {},
    appendText,
    onChange = (value) => formik.setFieldValue(name, value),
    ...rest
  }) => {
    switch (type) {
      case "text":
        return (
          <Col md={3} className="mb-3" {...contianerProps}>
            <Form.Label htmlFor={id}>{label}</Form.Label>
            <InputGroup>
              <Form.Control
                name={name}
                id={id}
                onChange={(event) => onChange(event.target.value, formik)}
                placeholder={placeholder}
                value={formik.values[name]}
                isValid={formik.touched[name] && !formik.errors[name]}
                isInvalid={formik.errors[name] && formik.touched[name]}
                style={{ backgroundColor: "white" }}
                {...rest}
              />
              {appendText && <InputGroup.Text>{appendText}</InputGroup.Text>}
              {/* <Form.Control.Feedback style={{ backgroundColor: "white" }}>
                Looks good!
              </Form.Control.Feedback> */}
              <Form.Control.Feedback
                type="invalid"
                style={{ backgroundColor: "white" }}
              >
                {formik.errors[name]}
              </Form.Control.Feedback>
            </InputGroup>
          </Col>
        );
      case "hidden":
        return (
          <Col md={3} className="mb-3" {...contianerProps}>
            <Form.Label htmlFor={id}>{label}</Form.Label>
            <InputGroup>
              <Form.Control
                type="hidden"
                name={name}
                id={id}
                onChange={(event) => onChange(event.target.value, formik)}
                placeholder={placeholder}
                value={formik.values[name]}
                isValid={formik.touched[name] && !formik.errors[name]}
                isInvalid={formik.errors[name] && formik.touched[name]}
                style={{ backgroundColor: "white" }}
                {...rest}
              />
              {appendText && <InputGroup.Text>{appendText}</InputGroup.Text>}
              {/* <Form.Control.Feedback style={{ backgroundColor: "white" }}>
                  Looks good!
                </Form.Control.Feedback> */}
              <Form.Control.Feedback
                type="invalid"
                style={{ backgroundColor: "white" }}
              >
                {formik.errors[name]}
              </Form.Control.Feedback>
            </InputGroup>
          </Col>
        );
      case "readOnlytext":
        return (
          <Col md={3} className="mb-3" {...contianerProps}>
            <Form.Label htmlFor={id}>{label}</Form.Label>
            <InputGroup>
              <Form.Control
                name={name}
                id={id}
                onChange={(event) => onChange(event.target.value, formik)}
                placeholder={placeholder}
                value={formik.values[name]}
                isValid={formik.touched[name] && !formik.errors[name]}
                isInvalid={formik.errors[name] && formik.touched[name]}
                disabled
                // style={{ backgroundColor: "white" }}
                {...rest}
              />
              {appendText && <InputGroup.Text>{appendText}</InputGroup.Text>}
              {/* <Form.Control.Feedback style={{ backgroundColor: "white" }}>
                  Looks good!
                </Form.Control.Feedback> */}
              <Form.Control.Feedback
                type="invalid"
                style={{ backgroundColor: "white" }}
              >
                {formik.errors[name]}
              </Form.Control.Feedback>
            </InputGroup>
          </Col>
        );
      case "disabletext":
        if (initialValues?.nic != null) {
          return (
            <Col md={3} className="mb-3" {...contianerProps}>
              <Form.Label htmlFor={id}>{label}</Form.Label>
              <InputGroup>
                <Form.Control
                  name={name}
                  id={id}
                  onChange={(event) => onChange(event.target.value, formik)}
                  placeholder={placeholder}
                  isValid={formik.touched[name] && !formik.errors[name]}
                  isInvalid={formik.errors[name] && formik.touched[name]}
                  value={initialValues?.nic}
                  disabled
                  {...rest}

                />
                {appendText && <InputGroup.Text>{appendText}</InputGroup.Text>}
                <Form.Control.Feedback
                  type="invalid"
                  style={{ backgroundColor: "white" }}
                >
                  {formik.errors[name]}
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
          );
        }
        else {
          return (
            <Col md={3} className="mb-3" {...contianerProps}>
              <Form.Group>
                <Row>
                  <Form.Label>{label}</Form.Label>
                </Row>
                <Form.Control
                  name={name}
                  id={id}
                  placeholder={placeholder}
                  isValid={formik.touched[name] && !formik.errors[name]}
                  isInvalid={formik.errors[name] && formik.touched[name]}
                  style={{ backgroundColor: "white" }}
                  onChange={(event) => onChange(event.target.value, formik)}
                  value={initialValues?.nic}
                  {...rest}
                />
                {appendText && <InputGroup.Text>{appendText}</InputGroup.Text>}
                <Form.Control.Feedback
                  type="invalid"
                  style={{ backgroundColor: "white" }}
                >
                  {formik.errors[name]}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          );
        }
      case "number":
        return (
          <Col md={3} className="mb-3" {...contianerProps}>
            <Form.Label htmlFor={id}>{label}</Form.Label>
            <InputGroup>
              <Form.Control
                name={name}
                id={id}
                type="number"
                onChange={(event) => onChange(event.target.value, formik)}
                placeholder={placeholder}
                value={formik.values[name]}
                isValid={formik.touched[name] && !formik.errors[name]}
                isInvalid={formik.errors[name] && formik.touched[name]}
                style={{ backgroundColor: "white" }}
                {...rest}
              />
              {appendText && <InputGroup.Text>{appendText}</InputGroup.Text>}
              {/* <Form.Control.Feedback style={{ backgroundColor: "white" }}>
                  Looks good!
                </Form.Control.Feedback> */}
              <Form.Control.Feedback
                type="invalid"
                style={{ backgroundColor: "white" }}
              >
                {formik.errors[name]}
              </Form.Control.Feedback>
            </InputGroup>
          </Col>
        );
      case "textarea":
        return (
          <Col md={6} className="mb-3" {...contianerProps}>
            <Form.Group>
              <Form.Label>{label}</Form.Label>
              <Form.Control
                as="textarea"
                placeholder={placeholder}
                style={{ height: "100px", backgroundColor: "white" }}
                onChange={formik.handleChange}
                name={name}
                id={id}
                value={formik.values[name]}
                isValid={formik.touched[name] && !formik.errors[name]}
                isInvalid={formik.errors[name] && formik.touched[name]}
              />
              <Form.Control.Feedback style={{ backgroundColor: "white" }}>
                Looks good!
              </Form.Control.Feedback>
              <Form.Control.Feedback
                type="invalid"
                style={{ backgroundColor: "white" }}
              >
                {formik.errors[name]}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        );
      case "select":
        return (
          <Col md={3} className="mb-3" {...contianerProps}>
            <Form.Group>
              <Form.Label>{label}</Form.Label>
              <Select
                styles={getSelectStyles(formik, name)}
                placeholder={placeholder}
                onChange={(value) => onChange(value, formik)}
                value={formik.values[name]}
                options={formik.values[name]}
                {...rest}
              />
              {formik.errors[name] ? (
                <div style={{ color: "#DC3545", fontSize: 12 }}>
                  {formik.errors[name]}
                </div>
              ) : (
                ""
              )}
            </Form.Group>
          </Col>
        );

      case "selectOpImproved":
        return (
          <Col md={3} className="mb-3" {...contianerProps}>
            <Form.Group>
              <Form.Label>{label}</Form.Label>
              <Select
                styles={getSelectStyles(formik, name)}
                placeholder={placeholder}
                onChange={(value) => onChange(value, formik)}
                value={formik.values[name]}
                {...rest}
              />
              {typeof formik.errors != "undefined" && <>
                {formik.errors[name] ? (
                  <div style={{ color: "#DC3545", fontSize: 12 }}>
                    {formik.errors[name].label}
                  </div>
                ) : (
                  ""
                )}
              </>}
            </Form.Group>
          </Col>
        );

        case "selectOpImproved2":
        return (
          <Col md={3} className="mb-3" {...contianerProps}>
            <Form.Group>
              <Form.Label>{label}</Form.Label>
              <Select
                styles={getSelectStyles(formik, name)}
                placeholder={placeholder}
                onChange={(value) => onChange(value, formik)}
                value={formik.values[name]}
                {...rest}
              />
              {typeof formik.errors != "undefined" && <>
                {formik.errors[name] ? (
                  <div style={{ color: "#DC3545", fontSize: 12 }}>
                    {formik.errors[name].label}
                  </div>
                ) : (
                  ""
                )}
              </>}
            </Form.Group>
          </Col>
        );
      case "datepicker":
        if (initialValues?.dob != null) {
          return (
            <Col md={3} className="mb-3" {...contianerProps}>
              <Form.Label htmlFor={id}>{label}</Form.Label>
              <InputGroup>
                <Form.Control
                  name={name}
                  id={id}
                  value={initialValues?.dob}
                  disabled
                  {...rest}
                />


              </InputGroup>
            </Col>
          );
        }
        else {
          return (
            <Col md={3} className="mb-3" {...contianerProps}>
              <Form.Group>
                <Row>
                  <Form.Label htmlFor={id}>{label}</Form.Label>
                </Row>
                <Row>
                  <DatePicker
                    value={formik.values[name]}
                    onChange={(value) => onChange(value, formik)}
                    inputPlaceholder={placeholder}
                    shouldHighlightWeekends
                    // styles={getSelectStyles(formik, name)}
                    {...rest}
                  />

                  {/* {typeof formik.errors != "undefined" && <>
                    {formik.errors[name] ? (
                      <div style={{ color: "#DC3545", fontSize: 12 }}>
                        {formik.errors[name].label}
                      </div>
                    ) : (
                      ""
                    )}
                  </>} */}
                </Row>
              </Form.Group>
            </Col>
          );
        }
      case "datepickerforcpd":
        if (initialValues?.cpdYearStartDate != null) {
          return (
            <Col md={3} className="mb-3" {...contianerProps}>
              <Form.Group>
                <Row>
                  <Form.Label htmlFor={id}>{label}</Form.Label>
                </Row>
                <Row>
                  <InputGroup>
                <Form.Control
                    name={name}
                    id={id}
                    value={initialValues?.cpdYearStartDate}
                    disabled
                    {...rest}
                  /></InputGroup>
                </Row>
              </Form.Group>
              {/* <Form.Label htmlFor={id}>{label}</Form.Label>
                <InputGroup>
                  <Form.Control
                    name={name}
                    id={id}
                    value={initialValues?.cpdYearStartDate}
                    disabled
                    {...rest}
                  />
                  
  
                </InputGroup> */}
            </Col>
          );
        }
        else {
          return (
            <Col md={3} className="mb-3" {...contianerProps}>
              <Form.Group>
                <Row>
                  <Form.Label htmlFor={id}>{label}</Form.Label>
                </Row>
                <Row>
                  <DatePicker
                    value={formik.values[name]}
                    onChange={(value) => onChange(value, formik)}
                    inputPlaceholder={placeholder}
                    shouldHighlightWeekends
                    {...rest}
                  />
                </Row>
              </Form.Group>
            </Col>
          );
        }
      // ----- kanishka ---- labele component -------
      case "label":
        return (
          // <Row md={12} className="mb-3" >
          <Col md={12} alignItems="center" {...contianerProps}>
            <Form.Group>
              <LabledText
                title={label}
                text={formik.values[name] ? formik.values[name] : placeholder}
              />
            </Form.Group>
          </Col>
          // </Row>
        );

      // --------------------------------------------


      case "customeFieldCheck":
        // console.log("Formik",formik.values[name]
        // );
        return (
          <Col md={3} className="mb-3" {...contianerProps}>

            <Form.Group className="mb-3" controlId={id}>
              <Form.Label>{label}</Form.Label>
              <Form.Check
                type="checkbox"
                label={label}
                name={name}
                onChange={formik.handleChange}
                // checked={formik.values[name]}

                // checked={formik.values[name]}

                {...rest}
              />
            </Form.Group>
          </Col>
        );

        case "customeFieldCheck2":
          console.log("Formik",formik.values[name]
          );
          // console.log("FormikhandleChange",formik.handleChange
          // );
          return (
            <Col md={3} className="mb-3" {...contianerProps}>
  
              <Form.Group className="mb-3" controlId={id}>
                <Form.Label>{label}</Form.Label>
                <Form.Check
                  type="checkbox"
                  label={label}
                  name={name}
                  onChange={e => {
                    formik.handleChange(e);
                    //handleCheckboxChange(e.target.checked);
                  }}
                  value={formik.values[name]}
                  //checked={formik.values[name]}
  
                  // checked={formik.values[name]}
  
                  {...rest}
                />
              </Form.Group>
            </Col>
          );

      //Radio button set component for CPD activity status
      case "customeField":
        return (
          <Col md={3} className="mb-3 d-flex" {...contianerProps}>
            <Col md={4}>
              <Form.Label>{label}</Form.Label>
              <Col md={12} className="mb-3 mt-2">
                <Form.Group className="mb-3" controlId="a">
                  <Form.Check
                    type="radio"
                    label="Pending"
                    name="status"
                    value="pending"
                    onChange={formik.handleChange}
                    // isValid={formik.touched[name] && !formik.errors[name]}
                    // isInvalid={formik.errors[name] && formik.touched[name]}
                    {...rest}
                  />
                  <Form.Check
                    type="radio"
                    label="In Progress"
                    name="status"
                    // isValid={formik.touched[name] && !formik.errors[name]}
                    // isInvalid={formik.errors[name] && formik.touched[name]}
                    onChange={formik.handleChange}
                    value="progress"
                    {...rest}
                  />
                  <Form.Check
                    type="radio"
                    label="Complete"
                    // isValid={formik.touched[name] && !formik.errors[name]}
                    // isInvalid={formik.errors[name] && formik.touched[name]}
                    name="status"
                    onChange={formik.handleChange}
                    value="complete"
                    {...rest}
                  />
                </Form.Group>
                {/* <Form.Control.Feedback style={{ backgroundColor: "white" }}>
                Looks good!
              </Form.Control.Feedback>
                <Form.Control.Feedback
                type="invalid"
                style={{ backgroundColor: "white" }}
              >
                {formik.errors[name]}
              </Form.Control.Feedback> */}
              </Col>
            </Col>
          </Col>

        );
      case "decimalpointnumber":
        return (
          <Col md={3} className="mb-3" {...contianerProps}>
            <Form.Label htmlFor={id}>{label}</Form.Label>
            <InputGroup>
              <InputGroup.Text style={{ fontSize: "15px", color: "#3C4048", fontWeight: "normal" }}>LKR</InputGroup.Text>
              <Form.Control
                name={name}
                id={id}
                type="number"
                onChange={(event) => onChange(event.target.value, formik)}
                placeholder={placeholder}
                value={formik.values[name]}
                isValid={formik.touched[name] && !formik.errors[name]}
                isInvalid={formik.errors[name] && formik.touched[name]}
                style={{ backgroundColor: "white" }}
                {...rest}
              /><InputGroup.Text style={{ fontSize: "15px", color: "#3C4048", fontWeight: "normal" }}>.00</InputGroup.Text>
              {appendText && <InputGroup.Text>{appendText}</InputGroup.Text>}
              {/* <Form.Control.Feedback style={{ backgroundColor: "white" }}>
                    Looks good!
                  </Form.Control.Feedback> */}
              <Form.Control.Feedback
                type="invalid"
                style={{ backgroundColor: "white" }}
              >
                {formik.errors[name]}
              </Form.Control.Feedback>
            </InputGroup>
          </Col>
        );


      case "space":
        return (
          <Col md={6} className="mb-3" {...contianerProps}>
            {/* <Form.Label htmlFor={id}>{label}</Form.Label>
            <InputGroup>
              <Form.Control
                name={name}
                id={id}
                onChange={formik.handleChange}
                placeholder={placeholder}
                value={formik.values[name]}
                isValid={formik.touched[name] && !formik.errors[name]}
                isInvalid={formik.errors[name] && formik.touched[name]}
                style={{ backgroundColor: "white" }}
              />
              {appendText && <InputGroup.Text>{appendText}</InputGroup.Text>}
              {/* <Form.Control.Feedback style={{ backgroundColor: "white" }}>
                  Looks good!
                </Form.Control.Feedback> */}
            {/* <Form.Control.Feedback
                type="invalid"
                style={{ backgroundColor: "white" }}
              >
                {formik.errors[name]}
              </Form.Control.Feedback>
            </InputGroup> */}
          </Col>
        );
      case "file":
        return (
          <Col md={3} className="mb-3" {...contianerProps}>
            <Form.Label htmlFor={id}>{label}</Form.Label>
            <InputGroup>
              <Form.Control
                name={name}
                id={id}
                type="file"
                onChange={(event) => {
                  onChange(event.target.files[0], formik);
                }}
                // value={formik.values[name]}
                isValid={formik.touched[name] && !formik.errors[name]}
                isInvalid={formik.errors[name] && formik.touched[name]}
                {...rest}
              />
              {appendText && <InputGroup.Text>{appendText}</InputGroup.Text>}
              <Form.Control.Feedback
                type="invalid"
                style={{ backgroundColor: "white" }}
              >
                {formik.errors[name]}
              </Form.Control.Feedback>
            </InputGroup>
          </Col>
        );
      // --------------------------------------------
      case "colorcard":
        // console.log(formik.values[rest.set])
        return (
          <Col md={4} className="mb-3 d-flex" {...contianerProps}>
            <Col md={4} style={{ marginRight: ".8rem" }}>
              <Col md={12} className="mb-3 mt-2">
                <Form.Group className="mb-3" controlId="a">
                  <Form.Check
                    type="checkbox"
                    label="Standard Card"
                    name="Standardcard"
                    onChange={formik.handleChange}
                    checked={
                      formik.values[rest.set] == 1 ? true : formik.values[name]
                    }
                    {...rest}
                  />
                </Form.Group>
              </Col>
              <Col md={12} className="mb-3">
                <Form.Group className="mb-3" controlId="l">
                  <Form.Check
                    type="checkbox"
                    label="Color Card"
                    name="Colorcard"
                    onChange={formik.handleChange}
                    checked={
                      formik.values[rest.set] == 2 ? true : formik.values[name]
                    }
                    {...rest}
                  />
                </Form.Group>
              </Col>
            </Col>
          </Col>
        );

      default:
        return <div>Unsupported Field</div>;
    }
  };

  return (
    <Row>{config.map((individualConfig) => builder(individualConfig))}</Row>
  );
}