import { Formik } from "formik";
import React from "react";
import { Badge, Button, Card, Col, Form, Modal, Row, Image, ButtonGroup } from "react-bootstrap";
import DataTable from "react-data-table-component";
import * as yup from "yup";
import customDataTableStyle from "../../../assets/css/customCSS";
import { CustomViewBtn, Edit, View } from "../../../components/shared/TableButtons";
import Aux from "../../../hoc/_Aux";

function ApproveCpdMembership(props) {
  const {
    onSubmit,
    editRoleHandler,
    deleteRoleHandler,
    loading,
    data,
    totalRows,
    handlePageChange,
    handlePerRowsChange,
    handleSort,
    resetPaginationToggle,
    subHeaderComponentMemo,
    editData,
    setEditData,
    isModelView,
    setIsModelView,
    Options,
    editUserHandler,
    viewHandler,
    editModel,
    handleSendApproval
  } = props;

  const init = {
    index: "",
    EntryLevel: "",
    CPDStartDate: "",
    UserID: "",
    PrimarySkills: "",
    SettingID: "",
    CreatedAt: "",
    CreatedBy: "",
    MemberNo: "",
    Title: "",
    FirstName: "",
    LastName: "",
    Status: "",
    approve: "",
    reject: "",
    modifiedBy: "",
    comment: ""
  };

  const schema = yup.object();

  const column = [
    {
      name: "Index",
      selector: (data) => {

        return data.index
      },
      sortable: true,
    },
    {
      name: "Member No",
      selector: (data) => {
        if (data.MemberNo == "") {
          return (
            <Badge bg="danger">
              Not Submitted
            </Badge>)
        } else {
          return data.MemberNo
        }

      },
      sortable: true,
    },
    {
      name: "Title",
      selector: (data) => {
        if (data.Title == "") {
          return (
            <Badge bg="danger">
              Not Submitted
            </Badge>)
        } else {
          return data.Title
        }

      },
      sortable: true,
    },
    {
      name: "First Name",
      selector: (data) => {
        if (data.FirstName == "") {
          return (
            <Badge bg="danger">
              Not Submitted
            </Badge>)
        } else {
          return data.FirstName
        }

      },
      sortable: true,
    },
    {
      name: "Last Name",
      selector: (data) => {
        if (data.LastName == "") {
          return (
            <Badge bg="danger">
              Not Submitted
            </Badge>)
        } else {
          return data.LastName
        }

      },
      sortable: true,
    },
    {
      name: "Start Date",
      selector: (data) => {
        if (data.CPDStartDate == "") {
          return (
            <Badge bg="danger">
              Not Submitted
            </Badge>)
        } else {
          return data.CPDStartDate
        }

      },
      sortable: true,
    },
    {
      name: "Primary Skills",
      selector: (data) => {
        console.log(data.PrimarySkills)
        if (data.PrimarySkills == "") {
          return (
            <Badge bg="danger">
              Not Submitted
            </Badge>)
        } else {

          return data.PrimarySkills
        }

      },
      sortable: true,
    },
    {
      name: "Entry Level",
      selector: (data) => {
        if (data.EntryLevel == "") {
          return (
            <Badge bg="danger">
              Not Submitted
            </Badge>)
        } else {
          return data.EntryLevel;
        }
      },
      sortable: true,
    },
    {
      name: "Status",
      selector: (data) => {
        //return data.Status
        if (data.Status == 2) {
          return (
            <Badge bg="success">
              Approved
            </Badge>)
        } else if (data.Status == 1) {
          return (
            <Badge bg="warning" text="dark">
              Pending
            </Badge>)
        } else if (data.Status == 3) {
          return (
            <Badge bg="danger">
              Rejected
            </Badge>)
        }
      },
      //sortable: true,
    },

    {
      name: "Action",
      button: true,
      selector: (data) => {
        if (data.Status == 2) {
          return (
            <CustomViewBtn viewHandler={viewHandler} data={data} />
          )
        } else if (data.Status == 1) {
          return (
            <>
              <Edit editHandler={editUserHandler} data={data} />
              <CustomViewBtn viewHandler={viewHandler} data={data} /></>)
        } else if (data.Status == 3) {
          return (
            <CustomViewBtn viewHandler={viewHandler} data={data} />)
        }

      },
      sortable: false,
    },

  ];
  return (
    <Aux>
      <Row>
        <Col>
          <Card>
            <Card.Header>


              <Col>
                <Card.Title as="h5">CPD Member View / CPD Membership Approve</Card.Title>

              </Col>

            </Card.Header>
            <Card.Body>

              <Row>
                <Col>
                  <DataTable
                    // title="Role List"
                    columns={column}
                    data={data}
                    progressPending={loading}
                    highlightOnHover={true}
                    responsive={true}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onSort={handleSort}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    customStyles={customDataTableStyle}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal
        {...props}
        show={isModelView}
        onHide={() => setIsModelView(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
      >
        {editModel ? <>
          <Modal.Header closeButton>
            <Modal.Title as="h5">
              CPD Member Registration Details and Authorization
            </Modal.Title>
          </Modal.Header>
          <Formik
            validationSchema={schema}
            onSubmit={(data) => {
              console.log("submit")
              setIsModelView(false);
              onSubmit(data);
            }}
            initialValues={editData || init}
          >
            {(props) => {
              const {
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
              } = props

              var memstatusString = "";
              if ((values.Status) == 1) {
                memstatusString = "Pending"
              } else if ((values.Status) == 2) {
                memstatusString = "Approved"
              } else if ((values.Status) == 3) {
                memstatusString = "Rejected"
              } else {
                memstatusString = "Not Submit"
              }
              return (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Body>
                    <Form.Control
                      type="hidden"
                      name="id"
                      value={values.id}
                      onChange={handleChange}
                    />
                    <Row className="mb-3">
                      <Form.Group as={Col} md="4" controlId="address-type">
                        <Form.Label>Index *</Form.Label>
                        <Form.Control
                          type="text"
                          name="index"
                          value={values.index}
                          onChange={handleChange}
                          disabled
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="4" controlId="address-type">
                        <Form.Label>Member No *</Form.Label>
                        <Form.Control
                          type="text"
                          name="MemberNo"
                          value={values.MemberNo}
                          onChange={handleChange}
                          disabled
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="4" controlId="address-type">
                        <Form.Label>Title *</Form.Label>
                        <Form.Control
                          type="text"
                          name="Title"
                          value={values.Title}
                          onChange={handleChange}
                          disabled
                        />
                      </Form.Group></Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} md="6" controlId="address-type">
                        <Form.Label>First Name *</Form.Label>
                        <Form.Control
                          type="text"
                          name="FirstName"
                          value={values.FirstName}
                          onChange={handleChange}
                          disabled
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="6" controlId="address-type">
                        <Form.Label>Last Name *</Form.Label>
                        <Form.Control
                          type="text"
                          name="LastName"
                          value={values.LastName}
                          onChange={handleChange}
                          disabled
                        />
                      </Form.Group></Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} md="6" controlId="address-type">
                        <Form.Label>Start Date *</Form.Label>
                        <Form.Control
                          type="text"
                          name="CPDStartDate"
                          value={values.CPDStartDate}
                          onChange={handleChange}
                          disabled
                        /></Form.Group>

                      <Form.Group as={Col} md="6" controlId="address-type">
                        <Form.Label>Primary Skill *</Form.Label>
                        <Form.Control
                          type="text"
                          name="PrimarySkills"
                          value={values.PrimarySkills}
                          onChange={handleChange}
                          disabled
                        />
                      </Form.Group></Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} md="6" controlId="address-type">
                        <Form.Label>Entry Level *</Form.Label>
                        <Form.Control
                          type="text"
                          name="EntryLevel"
                          value={values.EntryLevel}
                          onChange={handleChange}
                          disabled
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="6" controlId="address-type">
                        <Form.Label>Status *</Form.Label>
                        <Form.Control
                          type="text"
                          name={memstatusString}
                          value={memstatusString}
                          onChange={handleChange}

                          disabled
                        />
                      </Form.Group></Row>
                      <hr/>
                      <Row className = "mb-3">
                      <Form.Group as={Col} md="12" controlId="address-type">
                        <Form.Label>Comment *</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder= "Comment"
                          id="comment"
                          value={values.comment}
                          name="comment"
                          onChange={handleChange}
                        
                        />
                      </Form.Group>

                      </Row>
                    <Row className="mb-3">

                      <Form.Group as={Col} md="6" controlId="address-type">
                        <Form.Control
                          type="hidden"
                          name="SettingID"
                          value={values.SettingID}
                          onChange={handleChange}
                          disabled
                        />
                      </Form.Group>
                    </Row>
                  </Modal.Body>

                  <Modal.Footer>
                    <Button type="button" onClick={() => {
                      console.log(props)
                      props.setFieldValue('approve', 'approve')
                      props.handleSubmit()
                    }}>Approve</Button>{" "}

                    <Button type="button" variant="danger" onClick={() => {
                      props.setFieldValue('approve', 'reject')
                      // props.submitForm()
                      props.handleSubmit()

                    }}>Reject</Button>{" "}

                    <Button onClick={() => {
                      setEditData(null)
                      setIsModelView(false)
                    }}>Close</Button>
                  </Modal.Footer></Form>)
            }}</Formik>
        </> :
          <>
            <Modal.Header closeButton>
              <Modal.Title as="h5">
                <Card.Title as="h5">View CPD Member Registration Details</Card.Title>
              </Modal.Title>
            </Modal.Header>
            <Formik
              validationSchema={schema}
              onSubmit={(data) => {
                console.log("submit")
                setIsModelView(false);
                onSubmit(data);
              }}
              initialValues={editData || init}
            >
              {(props) => {
                const {
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
                } = props

                var memstatusString = "";
                if ((values.Status) == 1) {
                  memstatusString = "Pending"
                } else if ((values.Status) == 2) {
                  memstatusString = "Approved"
                } else if ((values.Status) == 3) {
                  memstatusString = "Rejected"
                } else {
                  memstatusString = "Not Submit"
                }
                
                return (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Modal.Body>
                      <Form.Control
                        type="hidden"
                        name="id"
                        value={values.id}
                        onChange={handleChange}
                      />
                      <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="address-type">
                          <Form.Label>Index *</Form.Label>
                          <Form.Control
                            type="text"
                            name="index"
                            value={values.index}
                            onChange={handleChange}
                            disabled
                          />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="address-type">
                          <Form.Label>Member No *</Form.Label>
                          <Form.Control
                            type="text"
                            name="MemberNo"
                            value={values.MemberNo}
                            onChange={handleChange}
                            disabled
                          />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="address-type">
                          <Form.Label>Title *</Form.Label>
                          <Form.Control
                            type="text"
                            name="Title"
                            value={values.Title}
                            onChange={handleChange}
                            disabled
                          />
                        </Form.Group></Row>
                      <Row className="mb-3">
                        <Form.Group as={Col} md="6" controlId="address-type">
                          <Form.Label>First Name *</Form.Label>
                          <Form.Control
                            type="text"
                            name="FirstName"
                            value={values.FirstName}
                            onChange={handleChange}
                            disabled
                          />
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="address-type">
                          <Form.Label>Last Name *</Form.Label>
                          <Form.Control
                            type="text"
                            name="LastName"
                            value={values.LastName}
                            onChange={handleChange}
                            disabled
                          />
                        </Form.Group></Row>
                      <Row className="mb-3">
                        <Form.Group as={Col} md="6" controlId="address-type">
                          <Form.Label>Start Date *</Form.Label>
                          <Form.Control
                            type="text"
                            name="CPDStartDate"
                            value={values.CPDStartDate}
                            onChange={handleChange}
                            disabled
                          /></Form.Group>

                        <Form.Group as={Col} md="6" controlId="address-type">
                          <Form.Label>Primary Skill *</Form.Label>
                          <Form.Control
                            type="text"
                            name="PrimarySkills"
                            value={values.PrimarySkills}
                            onChange={handleChange}
                            disabled
                          />
                        </Form.Group></Row>
                      <Row className="mb-3">
                        <Form.Group as={Col} md="6" controlId="address-type">
                          <Form.Label>Entry Level *</Form.Label>
                          <Form.Control
                            type="text"
                            name="EntryLevel"
                            value={values.EntryLevel}
                            onChange={handleChange}
                            disabled
                          />
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="address-type">
                          <Form.Label>Status *</Form.Label>
                          <Form.Control
                            type="text"
                            name={memstatusString}
                            value={memstatusString}
                            onChange={handleChange}

                            disabled
                          />
                        </Form.Group></Row>
                        <Row className = "mb-3">
                      <Form.Group as={Col} md="12" controlId="address-type">
                        <Form.Label>Comment * </Form.Label>
                        <Form.Control
                          type="text"
                          // placeholder= "Comment"
                          value={values.comment}
                          name="comment"
                          // value={memstatusString}
                          onChange={handleChange}
                          disabled
                        
                        />
                      </Form.Group>

                      </Row>
                      {/* <hr/> */}
                      <Row className="mb-3">

                        <Form.Group as={Col} md="6" controlId="address-type">
                          <Form.Control
                            type="hidden"
                            name="SettingID"
                            value={values.SettingID}
                            onChange={handleChange}
                            disabled
                          />
                        </Form.Group>
                      </Row>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button onClick={() => {
                        setEditData(null)
                        setIsModelView(false)
                      }}>Close</Button>
                    </Modal.Footer>

                  </Form>
                )
              }}
            </Formik>
          </>}
      </Modal>
    </Aux>
  );
}

export default ApproveCpdMembership;
