import React, { useState } from "react";
import { Card, Col, Row, Button, Modal, Form,Badge } from "react-bootstrap";
import Aux from "../../../hoc/_Aux";
import { Formik, Field } from "formik";
import * as yup from "yup";
import DataTable from "react-data-table-component";
import { Edit, Delete } from "../../../components/shared/TableButtons";
import UserRole from '../../../components/shared/UserRoles';
import UserStatus from "../../../components/shared/UserStatus/";
import customDataTableStyle from "../../../assets/css/customCSS";

function AllUsers(props) {
  const {
    onSubmit,
    editUserHandler,
    deleteUserHandler,
    loading,
    data,
    totalRows,
    handlePageChange,
    handlePerRowsChange,
    handleSort,
    resetPaginationToggle,
    subHeaderComponentMemo,
    editData,
    setEditData,
    isModelView,
    setIsModelView,
  } = props;

  const init = {
    createdAt: "",
    createdBy: "",
    expireDate: "",
    status: "",
    userID: "",
    userRoleID: {},
    username: "",
    lastName: "",
    firstName: "",
    description: "",
    userStatusID: {}
  };

  const schema = yup.object().shape({
    firstName: yup.string().required("First Name Required"),
    lastName: yup.string().required("Last Name Required"),
    // userdesctiption: yup.string().required("User description Required"),
    // userdesctiption: yup.string().required("User description Required"),
  });

  const column = [
    {
      name: "#",
      selector: (data) => {
        return data.userid
      },
      sortable: true,
    },
    {
      name: "Username",
      selector: (data) => {
        return data.username
      },
      sortable: true,
    },
    {
      name: "First Name",
      selector: (data) => {
        return data.firstName
      },
      sortable: true,
    },
    {
      name: "Last Name",
      selector: (data) => {
        return data.lastName
      },
      sortable: true,
    },
    {
      name: "Contact Number",
      selector: (data) => {
        return data.mobileNo
      },
      sortable: true,
    },
    {
      name: "Create Date",
      selector: (data) => {
        const date = new Date(data.createdAt);
        return date.toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' });
      },
      sortable: true,
    },
    // {
    //   name: "Created By",
    //   selector: (data) => {
    //     return data.createdBy
    //   },
    //   sortable: true,
    // },
    {
      name: "Status",
      selector: (data) => {
        if (data.status == 15) {
          return (
            <Badge bg="info">
              Approval Pending
            </Badge>)   
        }else if(data.status == 3){
          return (
            <Badge bg="primary">
              New Applicant
            </Badge>) 
        }else if(data.status == 4){
          return (
            <Badge bg="danger">
              Reject Applicant
            </Badge>) 
        }else if(data.status == 6){
          return (
            <Badge bg="success" >
              Active Member
            </Badge>) 
        }else if(data.status == 17){
          return (
            <Badge bg="warning" text="dark">
              Pending Applicant
            </Badge>) 
        }
      },
      sortable: false,
    },
    // {
    //   name: "Expire Date",
    //   selector: (data) => {
    //     console.log(data)
    //     return data.expireDate
    //   },
    //   sortable: true,
    // },
    // {
    //   name: "Action",
    //   button: true,
    //   cell: (data) => <><Edit editHandler={editUserHandler} data={data} /> <Delete deleteHandler={deleteUserHandler} data={data} /></>,
    // },
    // {
    //   name: "Action",
    //   button: true,
    //   cell: (data) => (
    //     <Delete deleteHandler={deleteUserHandler} data={data} />
    //   ),
    // },
  ];

  return (
    <Aux>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title as="h5">View All Users</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  {/* <Button
                    className="pull-right"
                    variant="primary"
                    size="sm"
                    onClick={() => {
                      setIsModelView(true);
                    }}
                  >
                    Create User
                  </Button> */}
                </Col>
              </Row>
              <Row>
                <Col>
                  <DataTable
                    //title="User List"
                    columns={column}
                    data={data}
                    progressPending={loading}
                    highlightOnHover={true}
                    responsive={true}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onSort={handleSort}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    customStyles={customDataTableStyle}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal
        {...props}
        show={isModelView}
        onHide={() => setIsModelView(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update User Details
          </Modal.Title>
        </Modal.Header>

        <Formik
          validationSchema={schema}
          onSubmit={(data) => {
            setIsModelView(false);
            onSubmit(data);
          }}
          initialValues={editData || init}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Body>
                <Form.Control
                  type="hidden"
                  name="id"
                  value={values.id}
                  onChange={handleChange}
                  isValid={touched.id && !errors.id}
                  isInvalid={!!errors.id}
                />
                <Row className="mb-3">
                  <Form.Group as={Col} md="6" controlId="address-type">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                      type="text"
                      name="username"
                      value={values.username}
                      onChange={handleChange}
                      isValid={touched.username && !errors.username}
                      isInvalid={!!errors.username}
                      disabled
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.username}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="6" controlId="userRole">
                    <Form.Label>User Role</Form.Label>
                    {/* <UserRole onChangeHandler={handleChange} componentName={"userRoleID"} value={values.userRoleID}/> */}
                    <Field name='userRoleID' component={UserRole} />
                  </Form.Group>

                  <Form.Group as={Col} md="6" controlId="address-description" className = "mt-2">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      isValid={touched.firstName && !errors.firstName}
                      isInvalid={!!errors.firstName}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.firstName}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="6" controlId="address-description" className = "mt-2">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      isValid={touched.lastName && !errors.lastName}
                      isInvalid={!!errors.lastName}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.lastName}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="6" controlId="address-description" className = "mt-2">
                    <Form.Label>User Description</Form.Label>
                    <Form.Control
                      type="text"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      isValid={touched.description && !errors.description}
                      isInvalid={!!errors.description}
                      disabled
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.description}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="6" controlId="address-description" className = "mt-2">
                    <Form.Label>Expire Date</Form.Label>
                    <Form.Control
                      type="text"
                      name="expireDate"
                      value={values.expireDate}
                      onChange={handleChange}
                      isValid={touched.expireDate && !errors.expireDate}
                      isInvalid={!!errors.expireDate}
                      disabled
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.expireDate}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="4" controlId="address-createdBy" className = "mt-2">
                    <Form.Label>Created By</Form.Label>
                    <Form.Control
                      type="text"
                      name="createdBy"
                      value={values.createdBy}
                      onChange={handleChange}
                      isValid={touched.createdBy && !errors.createdBy}
                      isInvalid={!!errors.createdBy}
                      disabled
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.createdBy}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="4" controlId="address-createdBy" className = "mt-2">
                    <Form.Label>Create Date</Form.Label>
                    <Form.Control
                      type="text"
                      name="createdAt"
                      value={values.createdAt}
                      onChange={handleChange}
                      isValid={touched.createdAt && !errors.createdAt}
                      isInvalid={!!errors.createdAt}
                      disabled
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.createdAt}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="4" controlId="address-createdBy" className = "mt-2">
                    <Form.Label>Status</Form.Label>
                    {/* <Form.Control
                      type="text"
                      name="status"
                      value={values.status}
                      onChange={handleChange}
                      isValid={touched.createdBy && !errors.status}
                      isInvalid={!!errors.status}
                      disabled
                    /> */}

                    <Field name='userStatusID' component={UserStatus} />




                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.status}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button type="submit">{!editData ? 'Add' : "Update"}</Button>{" "}
                <Button onClick={() => {
                  setEditData(null)
                  setIsModelView(false)
                }}>Close</Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </Aux>
  );
}

export default AllUsers;
