import React, { memo, useEffect, useState } from 'react';
import ApproveCpdMembership from './approveCpdMembership.view';
import { useDispatch, useSelector } from "react-redux";
import { actions as CPApproveAction } from ".";
import Swal from 'sweetalert2';
import cpdService from "../../../services/Cpd.service";
import FilterComponent from "../../../components/shared/TableSearch";

export default memo(() => {
  const dispatch = useDispatch();

  const {
    initialLoad,
    message,
    isAuthRequestSent,
  } = useSelector((state) => state.approveCpdMembership);

  //Table State
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const [editData, setEditData] = useState(null);
  const [isModelView, setIsModelView] = useState(false);
  const [editModel, setEditModel] = useState(true)
  const [UserStatus, setUserStatus] = useState(null);

  const [Options, setOptions] = useState([])

  const format = (data) =>
    data.map(({ status }, index) => ({
      value: status,

    }));


  useEffect(() => {
    if (!initialLoad && !isAuthRequestSent) {
      console.log(message);
      console.log(data.statusCode);
      console.log(initialLoad);
      if (message === "00") {
        Swal.fire(
          "Approved!",
          "Member has been approved successfully",
          "success"
        );
      } else if (message === "02") {
        Swal.fire(
          'Failed!',
          "Member has not been approved",
          'error'

        );
      } else {
        Swal.fire(
          'Warning!',
          'Please add a comment before approve',
          'warning'
        );

      }
    }
  }, [isAuthRequestSent]);

  //Table methods
  const fetchRoleList = async (page) => {
    setLoading(true);

    const response = await cpdService.viewCpdMembers({
      page,
      perPage,
      sortColumn,
      sortDirection,
      filterText,
    });

    setData(response.data);
    setTotalRows(response.total);
    setLoading(false);
  };

  const handlePageChange = (page) => {
    fetchRoleList(page);
  };

  const handlePerRowsChange = (perPage) => {
    setPerPage(perPage);
  };

  const handleSort = (column, sortDirection) => {
    console.log("sort column------>", column.name)
    console.log("sort sortDirection------>", sortDirection)
    setSortColumn(column.name);
    setSortDirection(sortDirection);
    fetchRoleList(1);
  };

  useEffect(() => {
    if (!isAuthRequestSent) {
      console.log("table useeffect")
      fetchRoleList(1);
    }
  }, [
    perPage,
    filterText,
    isAuthRequestSent,

  ]);

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const editUserHandler = (data) => {
    console.log("data", data)
    setEditModel(true)
    setEditData({
      index: data.index,
      EntryLevel: data.EntryLevel,
      CPDStartDate: data.CPDStartDate,
      UserID: data.UserID,
      PrimarySkills: data.PrimarySkills,
      SettingID: data.SettingID,
      CreatedAt: data.CreatedAt,
      CreatedBy: data.CreatedBy,
      Status: data.Status,
      MemberNo: data.MemberNo,
      Title: data.Title,
      FirstName: data.FirstName,
      LastName: data.LastName,
      comment: data.comment
    });
    setIsModelView(true);
  };

  const viewHandler = (data) => {
    setEditModel(false)

    setEditData({
      index: data.index,
      EntryLevel: data.EntryLevel,
      CPDStartDate: data.CPDStartDate,
      UserID: data.UserID,
      PrimarySkills: data.PrimarySkills,
      SettingID: data.SettingID,
      CreatedAt: data.CreatedAt,
      CreatedBy: data.CreatedBy,
      Status: data.Status,
      MemberNo: data.MemberNo,
      Title: data.Title,
      FirstName: data.FirstName,
      LastName: data.LastName,
      comment: data.comment
    });
    setIsModelView(true);
  };
  const onSubmit = (data, onSubmitProps) => {
    console.log("submit", data)
    console.log(data.MemberNo);
    if (data.approve === 'approve') {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Approve",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log(data);
          dispatch(CPApproveAction.authApproveCpdMembership(data));
          console.log(data.MemberNo);
          cpdService.getCpdMembershipApprovalStatus(data.MemberNo).then((res) => {
            console.log(res.statusCode);
        if(res.statusCode=="00"){
          console.log("email has been sent!");
        }else if(res.statusCode=="02"){
          console.log("error!");
        }else{
          console.log("something happen");
        }
          })
          
        } else {
          console.log('cancel');
        }

      });
      
      console.log('approve')

    }
    else {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Reject",
      }).then((result) => {
        if (result.isConfirmed) {
          cpdService.rejectMembership(data).then((res) => {
            console.log(res.statusCode);
            if (res.statusCode == "00") {
              Swal.fire(
                'Rejected!',
                'Member has been rejected successfully',
                'success'
              ).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                }
              });
              console.log(data.MemberNo);
              console.log("You are rejected!");
              cpdService.getCpdMembershipRejectStatus(data.MemberNo).then((res) => {
                console.log(res.statusCode);
            if(res.statusCode=="00"){
              console.log("email has been sent!");
            }else if(res.statusCode=="02"){
              console.log("error!");
            
            }else{
              console.log("something went wrong!")
            }
              })

            } else if (res.statusCode == "02") {
              Swal.fire(

                'Failed!',
                'Member has not been rejected',
                'error'
              ).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                }
              });
            } else if (res.statusCode == "03") {
              Swal.fire(
                'Warning!',
                'Please add a comment before reject',
                'warning'
              ).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                }
              });
            } else {
              Swal.fire(
                'Error!',
                'Something went wrong',
                'error'
              ).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                }
              });
            }
          })
        }
      });
    }
    

  };


  const props = {
    loading,
    data,
    totalRows,
    handlePageChange,
    handlePerRowsChange,
    handleSort,
    resetPaginationToggle,
    subHeaderComponentMemo,
    editData,
    onSubmit,
    setEditData,
    isModelView,
    setIsModelView,
    UserStatus,
    Options,
    editUserHandler,
    viewHandler,
    editModel,
    setEditModel,

  };

  return <ApproveCpdMembership {...props} />;

});


