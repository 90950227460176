import React, { memo, useEffect, useState, useRef } from "react";
import { actions as ApplicationDetailActions } from ".";
import ViewProfile from "./adminApplicationDetail.view";
import { useDispatch, useSelector } from "react-redux";
import userService from "../../../services/User.service";
import applicationService from "../../../services/Application.service";
import { toast } from "react-toastify";
import FilterComponent from "../../../components/shared/TableSearch";
import Swal from "sweetalert2";

export default memo(() => {
  const dispatch = useDispatch();

  //Employee Detail
  const {
    initialLoad,
    isError,
    message,
    isAddRequestSent,
    isUpdateRequestSent,
    isDeleteRequestSent,
  } = useSelector((state) => state.users);

  const [model, setmodel] = useState(false);

  //Academic Detail
  const {
    // initialLoad_,
    // isError_,
    // message_,
    isAddRequestSent_,
    isUpdateRequestSent_,
    isDeleteRequestSent_,
  } = useSelector((state) => state.users);

  //Prof Detail
  const {
    // initialLoad_,
    // isError_,
    // message_,
    isAddRequestSent__,
    isUpdateRequestSent__,
    isDeleteRequestSent__,
  } = useSelector((state) => state.users);

  const [model_, setmodel_] = useState(false);

  //Table State - Employee Detail
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const [editData, setEditData] = useState(null);
  const [isModelView, setIsModelView] = useState(false);

  //Table State - Academic Detail
  const [academic_data, SetAcademic_data] = useState([]);
  const [loading_, setLoading_] = useState(false);
  const [totalRows_, setTotalRows_] = useState(0);
  const [perPage_, setPerPage_] = useState(10);
  const [sortColumn_, setSortColumn_] = useState(null);
  const [sortDirection_, setSortDirection_] = React.useState("");
  const [filterText_, setFilterText_] = React.useState("");
  const [resetPaginationToggle_, setResetPaginationToggle_] =
    React.useState(false);
  //const [editData_, setEditData_] = useState(null);
  const [isModelView_, setIsModelView_] = useState(false);

  //Table State - Prof Detail
  const [professionalMembership_data, SetProfessionalMembership_data] =
    useState([]);
  const [loading__, setLoading__] = useState(false);
  const [totalRows__, setTotalRows__] = useState(0);
  const [perPage__, setPerPage__] = useState(10);
  const [sortColumn__, setSortColumn__] = useState(null);
  const [sortDirection__, setSortDirection__] = React.useState("");
  const [filterText__, setFilterText__] = React.useState("");
  const [resetPaginationToggle__, setResetPaginationToggle__] =
    React.useState(false);
  //const [editData_, setEditData_] = useState(null);
  const [isModelView__, setIsModelView__] = useState(false);
  const [member, setMember] = useState({});
  const tbaledata = [
    { sample: "sample", sample: "sample", sample: "sample", sample: "sample" },
  ];
  // setData(tbaledata)
  const [selectedMemberID, setSelectedMemberID] = useState("");

  const [InitialData, setInitialData] = useState({
    // titel: [],
    headerData: {
      address: "",
    },
    Email: "",
  });

  // const updatemember = (value) => {
  //   console.log(value);
  //   userService.updateMemberDetails(value).then((res) => {
  //     console.log(res);
  //     if (res.status == "00") {
  //       Swal.fire({
  //         text: "Update Successfuly",
  //         icon: "success",
  //       });
  //     } else {
  //       Swal.fire({
  //         text: "Update Failed",
  //         icon: "error",
  //       });
  //     }
  //   });
  // };

  // const addEployeeDetails = (value) => {
  //   console.log(value);
  // };

  // useEffect(() => {
  //   if (!initialLoad && !isAddRequestSent) {
  //     if (message === "success") {
  //       toast.success("User Creation Success", {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     } else {
  //       toast.error(`${message}`, {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     }
  //   }
  // }, [isAddRequestSent]);

  // useEffect(() => {
  //   if (!initialLoad && !isUpdateRequestSent) {
  //     if (message === "success") {
  //       toast.success("User Update Success", {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     } else {
  //       toast.error(`${message}`, {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     }
  //   }
  // }, [isUpdateRequestSent]);

  // useEffect(() => {
  //   if (!initialLoad && !isDeleteRequestSent) {
  //     if (message === "success") {
  //       toast.success("User Delete Success", {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     } else {
  //       toast.error(`${message}`, {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     }
  //   }
  // }, [isDeleteRequestSent]);

  // const onSubmit = (data, onSubmitProps) => {
  //   if (editData) {
  //     Swal.fire({
  //       title: "Are you sure?",
  //       // text: "You won't be able to revert this!",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Update it!",
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         console.log(data);
  //         dispatch(ApplicationDetailActions.updateUser(data));
  //       }
  //     });
  //   } else {
  //     dispatch(ApplicationDetailActions.addUser(data));
  //   }
  //   onSubmitProps.resetForm();
  // };

  const editUserHandler = (data) => {
    console.log(data);
    setEditData({
      firstname: data.firstName,
      lastname: data.lastName,
      nic: data.nicNumber,
    });
    setIsModelView(true);
  };

  const handleSearchByMemberNumber = (e) => {
    console.log(e);
  };

  // const deleteUserHandler = (data) => {
  //   console.log(data);
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You won't be able to revert this!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Delete it!",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       console.log(data);
  //       dispatch(ApplicationDetailActions.deleteUser(data));
  //     }
  //   });
  // };

  //Table methods - Admin View Member Employee Detail Table
  const [userDetails, setUserDetails] = useState([]);
  const fetchUser = async (page) => {
    setLoading(true);
    console.log(selectedMemberID);
    console.log("featch user API call", userDetails.data[0].memberID);
    const tableMemberID = userDetails.data[0].memberID;
    const response =
      await applicationService.getAdminViewMemEmployeeDetailByMemberId(
        {
          page,
          perPage,
          sortColumn,
          sortDirection,
          filterText,
        },
        tableMemberID
      );

    setData(response.data);
    setTotalRows(response.total);
    setLoading(false);
  };

  const handlePageChange = (page) => {
    fetchUser(page);
  };

  const handlePerRowsChange = (perPage) => {
    setPerPage(perPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortColumn(column.name);
    setSortDirection(sortDirection);
    fetchUser(1);
  };

  useEffect(() => {
    if (!isAddRequestSent && userDetails.length != 0) {
      fetchUser(1);
    }
  }, [
    perPage,
    filterText,
    isAddRequestSent,
    isUpdateRequestSent,
    isDeleteRequestSent,
  ]);

  useEffect(() => {
    if (typeof userDetails == "undefined" || userDetails == null) return;
    if (userDetails.length == 0) return;

    console.log("user-details", userDetails.data[0].memberID);
    fetchUser(1);
    fetchMember(1);
    fetchMembership(1)
  }, [userDetails]);

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  //  Table methods - Admin View Member Academic Detail
  const fetchMember = async (page_) => {
    setLoading_(true);
  //  const selectedMemberID = 1;
  console.log("featch member API call", userDetails.data[0].memberID);
  const selectedMemberID = userDetails.data[0].memberID;
    const response =
      await applicationService.getAdminViewMemAccadamicDetailByMemberId(
        {
          page_,
          perPage_,
          sortColumn_,
          sortDirection_,
          filterText_,
        },
        selectedMemberID
      );

    SetAcademic_data(response.data);
    setTotalRows_(response.total);
    setLoading_(false);
  };

  const handlePageChangeACC = (page_) => {
    fetchMember(page_);
  };

  const handlePerRowsChangeACC = (perPage_) => {
    setPerPage_(perPage_);
  };

  const handleSortACC = (column1, sortDirection_) => {
    setSortColumn_(column1.name);
    setSortDirection_(sortDirection_);
    fetchMember(1);
  };

  useEffect(() => {
    if (!isAddRequestSent_ && userDetails.length != 0) {
      fetchMember(1);
    }
  }, [
    perPage_,
    filterText_,
    isAddRequestSent_,
    isUpdateRequestSent_,
    isDeleteRequestSent_,
  ]);

  const subHeaderComponentMemoACC = React.useMemo(() => {
    const handleClear = () => {
      if (filterText_) {
        setResetPaginationToggle_(!resetPaginationToggle_);
        setFilterText_("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText_(e.target.value)}
        onClear={handleClear}
        filterText={filterText_}
      />
    );
  }, [filterText_, resetPaginationToggle_]);


  //  Table methods - Admin View Member Professional Membership Detail
  const fetchMembership = async (page__) => {
    setLoading__(true);
  //  const selectedMemberID = 1;
  console.log("featch membership API call", userDetails.data[0].memberID);
  const selectedProfMemberID = userDetails.data[0].memberID;
    const response =
      await applicationService.getAdminViewMemMemberIdForProfessionalDetails(
        {
          page__,
          perPage__,
          sortColumn__,
          sortDirection__,
          filterText__,
        },
        selectedProfMemberID
      );

    SetProfessionalMembership_data(response.data);
    setTotalRows__(response.total);
    setLoading__(false);
  };

  const handlePageChangeMem = (page__) => {
    fetchMembership(page__);
  };

  const handlePerRowsChangeMem = (perPage__) => {
    setPerPage__(perPage__);
  };

  const handleSortMem = (column2, sortDirection__) => {
    setSortColumn__(column2.name);
    setSortDirection__(sortDirection__);
    fetchMembership(1);
  };

  useEffect(() => {
    if (!isAddRequestSent__ && userDetails.length != 0) {
      fetchMembership(1);
    }
  }, [
    perPage__,
    filterText__,
    isAddRequestSent__,
    isUpdateRequestSent__,
    isDeleteRequestSent__,
  ]);

  const subHeaderComponentMemoMem = React.useMemo(() => {
    const handleClear = () => {
      if (filterText__) {
        setResetPaginationToggle__(!resetPaginationToggle__);
        setFilterText__("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText__(e.target.value)}
        onClear={handleClear}
        filterText={filterText__}
      />
    );
  }, [filterText__, resetPaginationToggle__]);

  // Click event for member search -- start
  const [messages, setMessages] = useState("");
  const [updated, setUpdated] = useState(messages);

  const handleChange = (event) => {
    setMessages(event.target.value);
    //   console.log(messages);
  };

  const handleClick = () => {
    // "message" stores input field value
    setUpdated(messages);
    console.log(messages);

    // call service API to get data and set on form
    setSelectedMemberID(messages);

    const clickPromise = new Promise((resolve, reject) => {
      applicationService.viewMemberDetailList(messages).then((data) => {
        resolve(data);
        setUserDetails(data);
      });
    });

    Promise.all([clickPromise]).then((values) => {
      console.log(values[0].data[0]?.email);
      setInitialData({
        ...InitialData,
        headerData: {
          FirstName:
            values[0].data[0]?.firstName + " " + values[0].data[0]?.lastName,
          title: values[0].data[0]?.title,
          ContactNumber: values[0].data[0]?.contactNumber,
          Email: values[0].data[0]?.email, // ok
          NIC: values[0].data[0]?.NIC,
          Designation: values[0].data[0]?.designation,
          address: values[0].data[0]?.address,
          MemberType: values[0].data[0]?.memberType,

          titel: [
            { label: "Mr", value: "1" },
            { label: "Ms", value: "2" },
            { label: "Miss", value: "3" },
          ],
        },
      });
      console.log(values[0]);
    });
  };
  // Click event for member search -- end

  const props = {
    // Admin View Member - Employee Detail - Props
    data,
    loading,
    setmodel,
    model,
    InitialData,
    //  updatemember,
    // addEployeeDetails,
    //  onSubmit,
    editUserHandler,
    // deleteUserHandler,
    totalRows,
    handlePageChange,
    handlePerRowsChange,
    handleSort,
    resetPaginationToggle,
    subHeaderComponentMemo,
    editData,
    setEditData,
    isModelView,
    setIsModelView,

    // Admin View Member - Academic Detail - Props
    academic_data,
    setIsModelView_,
    isModelView_,
    totalRows_,
    loading_,
    resetPaginationToggle_,
    handlePageChangeACC,
    handlePerRowsChangeACC,
    handleSortACC,
    subHeaderComponentMemoACC,

    // Admin View Member -  Prof. Detail - Props
    professionalMembership_data,
    setIsModelView__,
    isModelView__,
    totalRows__,
    loading__,
    resetPaginationToggle__,
    handlePageChangeMem,
    handlePerRowsChangeMem,
    handleSortMem,
    subHeaderComponentMemoMem,

    // click
    handleChange,
    updated,
    handleClick,
    member,
  };

  return ViewProfile(props);
});
