import { actions as gloabalDataActions } from "./index"

const initialState = {
    initialLoad: true,
    isError: false,
    message: "",
    // ADD
    isAddRequestSent: false,
    // UPDATE
    isUpdateRequestSent: false,
    // DELETE
    isDeleteRequestSent: false,
};

const gloabalDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case gloabalDataActions.SET_INITIAL: {
            return {
                ...state,
                initialLoad: true,
            };
        }
        // ADD
        case gloabalDataActions.SEND_ADD_GLOBALDATA_REQUEST: {
            return {
                ...state,
                initialLoad: false,
                isAddRequestSent: true,
            };
        }
        case gloabalDataActions.SEND_ADD_GLOBALDATA_SUCCESS: {
            return {
                ...state,
                isAddRequestSent: false,
                isError: false,
                message: action.payload,
            };
        }
        case gloabalDataActions.SEND_ADD_GLOBALDATA_FAILED: {
            return {
                ...state,
                isAddRequestSent: false,
                isError: true,
                message: action.error
            };
        }
        // UPDATE
        case gloabalDataActions.SEND_UPDATE_GLOBALDATA_REQUEST: {
            return {
                ...state,
                initialLoad: false,
                isUpdateRequestSent: true,
            };
        }
        case gloabalDataActions.SEND_UPDATE_GLOBALDATA_SUCCESS: {
            return {
                ...state,
                isUpdateRequestSent: false,
                isError: false,
                message: action.payload,
            };
        }
        case gloabalDataActions.SEND_UPDATE_GLOBALDATA_FAILED: {
            return {
                ...state,
                isUpdateRequestSent: false,
                isError: true,
                message: action.error
            };
        }
        // DELETE
        case gloabalDataActions.SEND_DELETE_GLOBALDATA_REQUEST: {
            return {
                ...state,
                initialLoad: false,
                isDeleteRequestSent: true,
            };
        }
        case gloabalDataActions.SEND_DELETE_GLOBALDATA_SUCCESS: {
            return {
                ...state,
                isDeleteRequestSent: false,
                isError: false,
                message: action.payload,
            };
        }
        case gloabalDataActions.SEND_DELETE_GLOBALDATA_FAILED: {
            return {
                ...state,
                isDeleteRequestSent: false,
                isError: true,
                message: action.error
            };
        }
        default: return state;
    }
};

export default gloabalDataReducer;