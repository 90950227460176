import ParaService from "../../../services/Parameter.service";

export const SEND_ADD_USER_REQUEST = "SEND_ADD_USER_REQUEST";
export const SEND_ADD_USER_SUCCESS = "SEND_ADD_USER_SUCCESS";
export const SEND_ADD_USER_FAILED = "SEND_ADD_USER_FAILED";

export const SEND_UPDATE_USER_REQUEST = "SEND_UPDATE_USER_REQUEST";
export const SEND_UPDATE_USER_SUCCESS = "SEND_UPDATE_USER_SUCCESS";
export const SEND_UPDATE_USER_FAILED = "SEND_UPDATE_USER_FAILED";

export const SEND_DELETE_USER_REQUEST = "SEND_DELETE_USER_REQUEST";
export const SEND_DELETE_USER_SUCCESS = "SEND_DELETE_USER_SUCCESS";
export const SEND_DELETE_USER_FAILED = "SEND_DELETE_USER_FAILED";

// ADD
const sendAddUserRequest = () => ({
  type: SEND_ADD_USER_REQUEST,
});

const sendAddUserSuccess = (payload) => ({
  type: SEND_ADD_USER_SUCCESS,
  payload,
});

const sendAddUserFailes = (error) => ({
  type: SEND_ADD_USER_FAILED,
  error,
});

// UPDATE
const sendUpdateUserRequest = () => ({
  type: SEND_UPDATE_USER_REQUEST,
});

const sendUpdateUserSuccess = (payload) => ({
  type: SEND_UPDATE_USER_SUCCESS,
  payload,
});

const sendUpdateUserFailes = (error) => ({
  type: SEND_UPDATE_USER_FAILED,
  error,
});

// DELETE
const sendDeleteUserRequest = () => ({
  type: SEND_DELETE_USER_REQUEST,
});

const sendDeleteUserSuccess = (payload) => ({
  type: SEND_DELETE_USER_SUCCESS,
  payload,
});

const sendDeleteUserFailes = (error) => ({
  type: SEND_DELETE_USER_FAILED,
  error,
});

/**
 * This method will be called when sign-in form submitted
 * @param {string} User
 * @param {string} Description
 */
export const addUser =
  ({ user, description }) =>
  async (dispatch) => {
    dispatch(sendAddUserRequest());
    const userResponse = await ParaService.addUser(
      user,
      description
    );
    try {
      if (userResponse) {
        const { status, statusCode } = userResponse;

        if (statusCode === "00") {
          dispatch(sendAddUserSuccess(status));
        } else if (statusCode === "02") {
          dispatch(sendAddUserFailes(status));
        }
      }
    } catch (error) {
      console.log("User catch", error);
      dispatch(sendAddUserFailes(error));
    }
  };
export const updateUser =
  (data) =>
  async (dispatch) => {
    dispatch(sendUpdateUserRequest());
    const userResponse = await ParaService.updateUser(data);
    try {
      if (userResponse) {
        const { status, statusCode } = userResponse;

        if (statusCode === "00") {
          dispatch(sendUpdateUserSuccess(status));
        } else if (statusCode === "02") {
          dispatch(sendUpdateUserFailes(status));
        }
      }
    } catch (error) {
      console.log("User catch", error);
      dispatch(sendUpdateUserFailes(error));
    }
  };
export const deleteUser =
  ({ userID, username }) =>
  async (dispatch) => {
    console.log(userID)
    dispatch(sendDeleteUserRequest());
    const userResponse = await ParaService.deleteUser({
      userID,
      username
    });
    try {
      if (userResponse) {
        const { status, statusCode } = userResponse;

        if (statusCode === "00") {
          dispatch(sendDeleteUserSuccess(status));
        } else if (statusCode === "02") {
          dispatch(sendDeleteUserFailes(status));
        }
      }
    } catch (error) {
      console.log("User catch", error);
      dispatch(sendDeleteUserFailes(error));
    }
  };
