import React, { memo, useEffect, useState } from "react";
import ApproveTransactions from "./approveTransactions.view";
import { useDispatch, useSelector } from "react-redux";
import { actions as TransactionApproveAction } from "./";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import applicationsService from "./../../../services/Application.service";
import userService from "./../../../services/User.service";
import FilterComponent from "../../../components/shared/TableSearch";
import FilterPicklist from "../../../components/shared/TransactionFilter";

export default memo(() => {
  const dispatch = useDispatch();

  const { initialLoad, isError, message, isAuthRequestSent } = useSelector(
    (state) => state.approveTransactions
  );

  //Table State
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const [editData, setEditData] = useState(null);
  const [isModelView, setIsModelView] = useState(false);
  const [editModel, setEditModel] = useState(true);

  const [UserStatus, setUserStatus] = useState(null);

  const [Options, setOptions] = useState([]);

  const format = (data) =>
    data.map(({ status }, index) => ({
      value: status,
    }));

  // useEffect(() => {

  //   try {
  //     userService
  //     .getUserStatusList()
  //     .then((data) => {
  //       setUserStatus(format(data));
  //       console.log(data);
  //     });

  //     userService
  //     .getUserRoleOptionsList()
  //     .then((data) => {
  //       setOptions(data);
  //       console.log(data)
  //     });

  //   } catch (e) {
  //     console.log(e);
  //   }

  // }, []);

  useEffect(() => {
    if (!initialLoad && !isAuthRequestSent) {
      if (message === "Transaction Approved") {
        toast.success("Transaction Approved", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (message === "Transaction Rejected") {
        toast.success("Transaction Rejected", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(`${message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }, [isAuthRequestSent]);

  const editRoleHandler = (data) => {
    // setEditData({
    //   userRoleID: data.userRoleID,
    //   userRoleName: data.userRoleName,
    //   userRoleDescription: data.userRoleDescription,
    //   status:data.status
    // });
    console.log(data);
    const isAllowed = true;
    userService.getRoleEditData(data.userRoleID, isAllowed).then((res) => {
      //setInitialData({ ...InitialData, headerData: res });
      console.log(res.roleOptions);
      setEditData(res);
      // setOptions(res.roleOptions);
    });
    setIsModelView(true);
  };

  // const deleteRoleHandler = (data) => {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You won't be able to revert this!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Delete it!",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       console.log(data)
  //       dispatch(RoleActions.deleteRole(data));
  //     }
  //   });
  // };

  //Table methods
  const fetchRoleList = async (page) => {
    const params = {};

    setLoading(true);

    const response = await applicationsService.viewTransactions({
      page,
      perPage,
      sortColumn,
      sortDirection,
      filterText,
      fromdate: null,
      todate: null,
      paymentMode: null,
      paymentType: null,
    });

    setData(response.data);
    setTotalRows(response.total);
    setLoading(false);
  };

  const handlePageChange = (page) => {
    fetchRoleList(page);
  };

  const handlePerRowsChange = (perPage) => {
    setPerPage(perPage);
  };

  const handleSort = (column, sortDirection) => {
    console.log("sort column------>", column.name);
    console.log("sort sortDirection------>", sortDirection);
    setSortColumn(column.name);
    setSortDirection(sortDirection);
    fetchRoleList(1);
  };

  useEffect(() => {
    if (!isAuthRequestSent) {
      console.log("table useeffect");
      fetchRoleList(1);
    }
  }, [perPage, filterText, isAuthRequestSent]);

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <>
        <FilterPicklist
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
        />
        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
        />
      </>
    );
  }, [filterText, resetPaginationToggle]);

  const editUserHandler = (data) => {
    console.log("data", data);
    setEditModel(true);
    setEditData({
      userID: data.userId,
      lastRenewalYear: data.lastRenewalYear,
      paymentId: data.paymentId,
      firstName: data.firstName,
      lastName: data.lastName,
      paymentType: data.paymentType,
      transactionDes: data.transactionDes,
      paymentMode: data.paymentMode,
      referenceNum: data.referenceNumber,
      payedDate: data.paidDate,
      amount: data.amount,
      responseText: data.responseText,
      cashDepositsImgPath: data.cashDepositsImgPath,
      nic: data.nic,
      //userStatusID: { value: data.statusID, label: data.status },
      // userRoleID: { value: data.userRoleName, label: data.userRoleID },
      // selectedUserRoleID: data.userRoleID.value,
    });
    setIsModelView(true);
  };

  const viewHandler = (data) => {
    setEditModel(false);

    setEditData({
      userID: data.userId,
      lastRenewalYear: data.lastRenewalYear,
      paymentId: data.paymentId,
      firstName: data.firstName,
      lastName: data.lastName,
      nic: data.nic,
      paymentType: data.paymentType,
      transactionDes: data.transactionDes,
      paymentMode: data.paymentMode,
      referenceNum: data.referenceNumber,
      payedDate: data.paidDate,
      amount: data.amount,
      responseText: data.responseText,
      //userStatusID: { value: data.statusID, label: data.status },
      // userRoleID: { value: data.userRoleName, label: data.userRoleID },
      // selectedUserRoleID: data.userRoleID.value,
    });
    setIsModelView(true);
  };

  const printHandler = (value) => {
    // console.log(value);
    // applicationsService.getPaymentReceipt().then((res)=>{
    //   console.log(res);
    //   if(res=="success"){
    //     Swal.fire({
    //       title: "Success",
    //       icon: "success",
    //       confirmButtonColor: "#3085d6",
    //       confirmButton: true,
    //       text: "Payment Receipt has been printed successfully"
    //     })
    //   }else{
    //     Swal.fire({
    //       title: "Error",
    //       icon: "error",
    //       confirmButtonColor: "#3085d6",
    //       confirmButton: true,
    //       text: "Payment Receipt cannot print"
    //     })
    //   }
    //   })
  };
  const onSubmit = (data, onSubmitProps) => {
    console.log("submit", data);

    if (data.approve === "approve") {
      Swal.fire({
        title: "Are you sure?",
        // text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Approve it!",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log(data);
          dispatch(TransactionApproveAction.authTransaction(data));
        }
      });
      console.log("approve");
    } else {
      Swal.fire({
        title: "Are you sure?",
        // text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Reject it!",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log(data);
          dispatch(TransactionApproveAction.authTransaction(data));
        }
      });
    }
  };

  const Global_Data = window.sessionStorage.getItem("Global_Data");
  const Global_Data1 = JSON.parse(Global_Data);
  console.log("Global_Data=====>>>", Global_Data1);

  const viewBankSlip = (data) => {
    console.log("Global_Data====>>>", Global_Data1.coreURL);
    window.open(
      Global_Data1.coreURL +
        "/application/bank-deposit-slip-view/" +
        data.paymentId
    );
  };

  const updateTransactionTable = async (value) => {
    console.log("=====updateTransactionTable=====", value);
    // const params = {
    //   page: para.page_,
    //   perPage: para.perPage_,
    //   sortColumn: para.sortColumn_,
    //   sortDirection: para.sortDirection_,
    //   filterText: para.filterText_,
    // };

    var bodyFormData = new FormData();
    let StDate = "";
    let StMonth = "";
    let EnDate = "";
    let EnMonth = "";
    if (value.fromDate.day < 10) {
      StDate = "0" + value.fromDate.day;
    } else {
      StDate = value.fromDate.day;
    }
    if (value.fromDate.month < 10) {
      StMonth = "0" + value.fromDate.month;
    } else {
      StMonth = value.fromDate.month;
    }
    if (value.toDate.day < 10) {
      EnDate = "0" + value.toDate.day;
    } else {
      EnDate = value.toDate.day;
    }
    if (value.toDate.month < 10) {
      EnMonth = "0" + value.toDate.month;
    } else {
      EnMonth = value.toDate.month;
    }
    // console.log(EnDate)
    const fromDate = `${value.fromDate.year}-${StMonth}-${StDate}`;
    const toDate = `${value.toDate.year}-${EnMonth}-${EnDate}`;

    const params = {
      fromdate: fromDate,
      todate: toDate,
      paymentMode: value.paymentMode.value,
      paymentType: value.paymentType.value,
    };

    setLoading(true);

    const response = await applicationsService.viewTransactions({
      page: 1,
      perPage: 30,
      sortColumn: null,
      sortDirection: null,
      filterText,
      fromdate: fromDate,
      todate: toDate,
      paymentMode: value.paymentMode.value,
      paymentType: value.paymentType.value,
    });

    setData(response.data);
    setTotalRows(response.total);
    setLoading(false);
  };

  const props = {
    onSubmit,
    editRoleHandler,
    //deleteRoleHandler,
    loading,
    data,
    totalRows,
    handlePageChange,
    handlePerRowsChange,
    handleSort,
    resetPaginationToggle,
    subHeaderComponentMemo,
    editData,
    setEditData,
    isModelView,
    setIsModelView,
    UserStatus,
    Options,
    editUserHandler,
    viewHandler,
    printHandler,
    editModel,
    setEditModel,
    viewBankSlip,
    updateTransactionTable,
  };

  return <ApproveTransactions {...props} />;
});
