import UserService from "./../../../services/User.service";


export const SEND_PASSWORD_RESET_REQUEST = "SEND_PASSWORD_RESET_REQUEST";
export const SEND_PASSWORD_RESET_SUCCESS = "SEND_PASSWORD_RESET_SUCCESS";
export const SEND_PASSWORD_RESET_FAILED = "SEND_PASSWORD_RESET_FAILED";

const sendPasswordResetRequest = () => ({
  type: SEND_PASSWORD_RESET_REQUEST,
});

const sendPasswordResetSuccess = (payload) => ({
  type: SEND_PASSWORD_RESET_SUCCESS,
  payload,
});

const sendPasswordResetFailes = (error) => ({
  type: SEND_PASSWORD_RESET_FAILED,
  error,
});

/**
 * This method will be called when sign-in form submitted
 * @param {string} oldPassword
 * @param {string} newPassword
 * @param {string} confirmPassword
 */
export const passwordReset = (oldPassword, newPassword,confirmPassword) => async (dispatch) => {
  dispatch(sendPasswordResetRequest());
  const passwordResetResponse = await UserService.passwordReset(
    oldPassword,
    newPassword,
    confirmPassword
  );
  try {
    if (passwordResetResponse) {
      const { status, statusCode } = passwordResetResponse;

      if (statusCode === "00") {
        dispatch(sendPasswordResetSuccess(status));
      } else if (statusCode === "02") {
        dispatch(sendPasswordResetFailes(status));
      }
    }
  } catch (error) {
    console.log("password reset catch", error);
    dispatch(sendPasswordResetFailes(error));
  }
};
