import AuthService from "../../../services/Auth.service";
import TokenHandler from "../../../services/TokenHandler.service";
import { redirect } from "../../../services/history";
import ParaService from "./../../../services/Parameter.service";

//Global Data for the Aplication
// const P_GLOBAL_ID = process.env.REACT_APP_P_GLOBAL_ID;
// const P_GLOBAL_VERSION = process.env.REACT_APP_P_GLOBAL_VERSION;
// const P_GLOBAL_USER_EXP = process.env.REACT_APP_P_GLOBAL_USER_EXP;

// Action types ✅

export const SEND_LOGIN_REQUEST = "SEND_LOGIN_REQUEST";
export const SEND_LOGIN_SUCESS = "SEND_LOGIN_SUCESS";
export const SEND_LOGIN_FAILED = "SEND_LOGIN_FAILED";

// Actions ✅

const sendLoginRequest = () => ({
  type: SEND_LOGIN_REQUEST,
});

const sendLoginSuccess = (payload) => ({
  type: SEND_LOGIN_SUCESS,
  payload,
});

const sendLoginFailes = (error) => ({
  type: SEND_LOGIN_FAILED,
  error,
});

// Action creators ✅

/**
 * This method will be called when sign-in form submitted
 * @param {string} userName
 * @param {string} password
 */
export const authenticateUser = (userName, password) => async (dispatch) => {
  try {
    dispatch(sendLoginRequest());
    const loginResponse = await AuthService.login(userName, password);
    console.log("loginResponse====>>>",loginResponse)
    if (loginResponse) {
      const { token, User, UserMenu, member, membership, URLList, employee, application } = loginResponse;
      console.log("login responce", loginResponse);
      if (User !== null) {
        TokenHandler.setToken(token);
        window.sessionStorage.setItem("UserMenu", JSON.stringify(UserMenu));
        window.sessionStorage.setItem("User", JSON.stringify(User));
        window.sessionStorage.setItem("member", JSON.stringify(member));
        window.sessionStorage.setItem("employee", JSON.stringify(employee));
        window.sessionStorage.setItem("membership", JSON.stringify(membership));
        window.sessionStorage.setItem("URLList", JSON.stringify(URLList));
        window.sessionStorage.setItem("application", JSON.stringify(application));
        // ParaService.getGlobalDetails(P_GLOBAL_ID).then((data) => {
        // ParaService.getGlobalDetails(P_GLOBAL_ID).then((data) => {
        //   console.log(data);
        //   window.sessionStorage.setItem("Global_Data", JSON.stringify(data));
        // });
        dispatch(sendLoginSuccess({ User, UserMenu }));
        const { userRoleID } = User;
        //change
        if (userRoleID == 1) {
          redirect("member/admin-dashboard");
          window.location.reload();
        } else if (userRoleID == 21) {
          redirect("member/application-dashboard");
          window.location.reload();
        } else if (userRoleID == 22) {
          redirect("member/member-dashboard");
          window.location.reload();
        }
      } else {
        dispatch(sendLoginFailes(loginResponse));
      }
    } else {
      
      dispatch(sendLoginFailes(loginResponse));
    }
  } catch (e) {
    console.log("feom catch", e);
    dispatch(sendLoginFailes(e));
  }

  // 💡 Todo: Integrate login service integration here
};
