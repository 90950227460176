import APIService from "./Api.service";
import HttpRequestError from "../common/exceptions/HttpRequestError";
import UserBadCredentialsError from "../common/exceptions/User/UserBadCredentialsError";
import UserUnknownError from "../common/exceptions/User/UserUnknownError";

const API_BASE_URL = process.env.REACT_APP_API_URL;

class ApplicationsService extends APIService {
  serviceEndpoint = API_BASE_URL;

  static handleError = (error) => {
    if (error instanceof HttpRequestError) {
      console.log(error.statusCode);
      if (error.statusCode === 401) throw new UserBadCredentialsError();
    }
    throw new UserUnknownError();
  };

  async professionalDetails() {
    try {
      const data = await this.post("/application/professionalDetails", {});
      // console.log("Prof========>", data);
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  //Professional Membership Details - data table url
  async getMemberIdForProfessionalDetails(para) {
    // console.log(para);
    // const userObject = window.sessionStorage.getItem("member");
    // console.log(JSON.parse(userObject));
    // const newUserProf = JSON.parse(userObject);
    // console.log(newUserProf.memberID);
    console.log(para);
    const userObject = window.sessionStorage.getItem("application");
    console.log(JSON.parse(userObject));
    const newUser = JSON.parse(userObject);
    try {
      const data = await this.get(
        `application/professionalDetails/${newUser.applicationId}`,
        {},
        para
      );
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  async getMemberIdForProfessionalDetails1(para) {
    console.log(para);
    // const userObject = window.sessionStorage.getItem("member");
    // console.log(JSON.parse(userObject));
    // const newUserProf = JSON.parse(userObject);
    // console.log(newUserProf.memberID);
    // const params = {
    //   page: para.page__,
    //   perPage: para.perPage___,
    //   sortColumn: para.sortColumn__,
    //   sortDirection: para.sortDirection__,
    //   filterText: para.filterText__,
    // }
    const params = {
      page: 1,
      perPage: 10,
      sortColumn: para.sortColumn__,
      sortDirection: para.sortDirection__,
      filterText: para.filterText__,
    };
    try {
      const data = await this.get(
        `application/professionalDetails/${para.userId}`,
        {},
        params
      );
      console.log("Prof=======>", data);
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  // Employee Detail - data table url
  async getEmployeeDetailByMemberId(para) {
    // console.log(para);
    // const userObject = window.sessionStorage.getItem("member");
    // console.log(JSON.parse(userObject));
    // const newUser = JSON.parse(userObject);

    console.log(para);
    const userObject = window.sessionStorage.getItem("application");
    console.log(JSON.parse(userObject));
    const newUser = JSON.parse(userObject);
    try {
      const data = await this.get(
        `application/employeeDetails/${newUser.applicationId}`,
        {},
        para
      );
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  async getEmployeeDetailByMemberId1(para) {
    console.log(para);
    console.log("para.memberid", para.userId);
    const params = {
      page: para.page,
      perPage: para.perPage,
      sortColumn: para.sortColumn,
      sortDirection: para.sortDirection,
      filterText: para.filterText,
    };
    try {
      const data = await this.get(
        `application/employeeDetails/${para.userId}`,
        {},
        params
      );
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  // Accademic Detail - data table url
  async getAccadamicDetailByMemberId(para) {
    console.log(para);
    const params = {
      page: para.page_,
      perPage: para.perPage_,
      sortColumn: para.sortColumn_,
      sortDirection: para.sortDirection_,
      filterText: para.filterText_,
    };
    // const userObject = window.sessionStorage.getItem("member");
    // console.log(JSON.parse(userObject));
    // const newUser = JSON.parse(userObject);
    // console.log(newUser.memberID);
    console.log(para);
    const userObject = window.sessionStorage.getItem("application");
    console.log(JSON.parse(userObject));
    const newUser = JSON.parse(userObject);
    try {
      const dataa = await this.get(
        `application/accadamicQualification/${newUser.applicationId}`,
        {},
        params
      );
      return dataa;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  async getAccadamicDetailByMemberId1(para) {
    console.log(para);
    const params = {
      page: para.page_,
      perPage: para.perPage_,
      sortColumn: para.sortColumn_,
      sortDirection: para.sortDirection_,
      filterText: para.filterText_,
    };
    // const userObject = window.sessionStorage.getItem("member");
    // console.log(JSON.parse(userObject));
    // const newUser = JSON.parse(userObject);
    // console.log(newUser.memberID);
    try {
      const dataa = await this.get(
        `application/accadamicQualification/${para.userId}`,
        {},
        params
      );
      return dataa;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  async getMemberIdForPaymentDetails(para) {
    console.log(para);
    const params = {
      page: 1,
      perPage: 10,
      sortColumn: para.sortColumn___,
      sortDirection: para.sortDirection___,
      filterText: para.filterText___,
    };
    try {
      const data = await this.get(
        `application/payment-details-for-approve-application/${para.userId}`,
        {},
        params
      );
      console.log("Prof=======>", data);
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  async acadamicDetails() {
    try {
      const data = await this.post("application/acadamicDetails", {});
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  //Professional Membership Details - data table url
  async getMemberIdForProfessionalDetails(para) {
    console.log(para);
    const params = {
      page: para.page__,
      perPage: para.perPage__,
      sortColumn: para.sortColumn__,
      sortDirection: para.sortDirection__,
      filterText: para.filterText__,
    };
    const userObject = window.sessionStorage.getItem("application");
    console.log(JSON.parse(userObject));
    const newUser = JSON.parse(userObject);
    // console.log(newUser.memberID);
    try {
      const dataaa = await this.get(
        `application/professionalDetails/${newUser.applicationId}`,
        {},
        params
      );
      console.log("Prof=========>", dataaa);
      return dataaa;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  async acadamicDetails() {
    try {
      const data = await this.post("application/acadamicDetails", {});
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  async updateroleApplicationApprovalOrReject(para) {
    try {
      console.log(para);
      const data = await this.put(
        `application/updateroleapplicationApprovalOrReject/applicationId/${para.selectedApplicationId}/status/${para.selectedStatus}`,
        para
      );
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  //view application list based on status

  async viewApplicationLists(para) {
    console.log(para);
    try {
      const data = await this.get(`application/viewApplication/`, {}, para);
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  //view member details by ids
  // async viewMemberDetailList(para) {
  //   console.log(para);
  //   try {
  //     const data = await this.get(
  //       `application/getMemberDetails/M951`,
  //       {},
  //       para
  //     );
  //     return data;
  //   } catch (error) {
  //     return ApplicationsService.handleError(error);
  //   }
  // }
  //get member details by member id
  async viewMemberDetailList(memberNo) {
    try {
      // const data = await this.get(`application/getMemberDetails/M951`);
      const data = await this.get(`application/getMemberDetails/${memberNo}`);
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  //get applicant details by nic number
  async viewApplicantDetailList(nicNumber) {
    try {
      // const data = await this.get(`application/getMemberDetails/M951`);
      const data = await this.get(`application/getApplicantDetails/${nicNumber}`);
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  //create accept payment for member renewal
  async createAcceptPayment(val, memberID) {
    try {
      console.log(val, memberID);
      const data = await this.post(`application/payment/${memberID}`, val);
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  //create accept payment for new membership
  async postMembershipPayment(val, nicNumber) {
    try {
      console.log(val, nicNumber);
      const data = await this.post(`application/membership-payment/${nicNumber}`, val);
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  //create accept payment for miscellaneous
  async postMiscellaneousPayment(val, nicNumber) {
    try {
      console.log(val, nicNumber);
      const data = await this.post(`application/miscellaneous-payment/${nicNumber}`, val);
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  //getmemberpendingpayments
  async getPendingPaymentDetails(details) {
    console.log(details);
    console.log(details.memberType);
    try {
      // const data = await this.get(`/application/getPaymentFee/associate/1991/3`);
      console.log(data);
      const data = await this.get(
        "application/getPaymentFee/" +
        details.memberType +
        "/" +
        details.lastRenewalYear +
        "/" +
        details.memberID
      );
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  async getCalAmount(details) {
    console.log(details);
    console.log("totalDueAmount===", details.totalDueAmount);
    console.log("memberID===", details.memberID);
    console.log("memberNo===", details.memberNo);
    try {

      const data = await this.get(
        "application/get-payment-amount-cal/" +
        details.totalDueAmount +
        "/" +
        details.memberID +
        "/" +
        details.memberNo
      );
      console.log("Return data===>>> ", data)
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  async getPendingPaymentForMemberValidation(details) {
    console.log(details);
    console.log("totalDueAmount===", details.totalDueAmount);
    console.log("memberID===", details.memberID);
    console.log("memberNo===", details.memberNo);
    try {

      const data = await this.get(
        "application/get-pending-payment-for-member-validation/"+details.memberID);
      console.log("Return data===>>> ", data)
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  //create accept payment
  // async createAcceptPayment(val) {
  //   try {
  //     const data = await this.post(`application/payment`, val);
  //     return data;
  //   } catch (error) {
  //     return ApplicationsService.handleError(error);
  //   }
  // }

  async viewMemberDetails(para) {
    console.log(para);

    try {
      const data = await this.get(
        "application/viewMemeberByStatus/status/3",
        {},
        para
      );
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  //Payment Received - data table url
  async paymentReceivedDetails(para) {
    console.log(para);

    const userObject = window.sessionStorage.getItem("membership");
    // console.log(JSON.parse(userObject));
    const newUser = JSON.parse(userObject);
    // console.log(newUser.membershipid);

    const memberObject = window.sessionStorage.getItem("member");
    //  console.log(JSON.parse(memberObject));
    const memberObjectItem = JSON.parse(memberObject);
    //  console.log(memberObjectItem.memberID);

    try {
      const data = await this.get(
        `application/getPaymentDetails/${memberObjectItem.memberID}`,
        {},
        para
      );
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  // Membership Subscription - data table url
  async viewMembershipSubscription(para, memberId) {
    const params = {
      page: para.page__,
      perPage: para.perPage__,
      sortColumn: para.sortColumn__,
      sortDirection: para.sortDirection__,
      filterText: para.filterText__,
    };

    // const userObject = window.sessionStorage.getItem("membership");
    // console.log(JSON.parse(userObject));
    // const newUser = JSON.parse(userObject);
    // console.log(newUser.membershipid);

    try {
      const subdata = await this.get(
        `application/getSubscription/${memberId}`,
        {},
        params
      );
      return subdata;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  // Admin View Member -  Member Details List
  async viewMemberDetailList(memberId) {
    try {
      const data = await this.get(
        `application/getMemberDetails/${memberId}`,
        {}
      );
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  // Admin View Member - Employee Detail - data table url
  async getAdminViewMemEmployeeDetailByMemberId(para, memberId) {
    try {
      const data = await this.get(
        `application/employeeDetails/${memberId}`,
        {},
        para
      );
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  // Admin View Member - Accademic Detail - data table url
  async getAdminViewMemAccadamicDetailByMemberId(para, memberId) {
    const params = {
      page: para.page_,
      perPage: para.perPage_,
      sortColumn: para.sortColumn_,
      sortDirection: para.sortDirection_,
      filterText: para.filterText_,
    };
    try {
      const academic_data = await this.get(
        `application/accadamicQualification/${memberId}`,
        {},
        params
      );
      return academic_data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  // Admin View Member - Professional Membership Details - data table url
  async getAdminViewMemMemberIdForProfessionalDetails(para, memberId) {
    const params = {
      page: para.page__,
      perPage: para.perPage__,
      sortColumn: para.sortColumn__,
      sortDirection: para.sortDirection__,
      filterText: para.filterText__,
    };
    try {
      const data = await this.get(
        `application/professionalDetails/${memberId}`,
        {},
        params
      );
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  // Modal - Pay to Renewal
  async addToPaytoRenewal(para) {
    console.log(para);
    console.log("para");
    try {
      const data = await this.post("application/membershiprenewal", para);
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  //get pending payment details
  async getPendingPaymentDetails(details) {
    console.log(details);
    console.log(details.memberType);
    try {
      const data = await this.get(
        "application/getPaymentFee/" +
        details.memberType +
        "/" +
        details.lastRenewalYear +
        "/" +
        details.memberID
      );
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  async viewTransactions(para) {
    console.log(para);

    try {
      const data = await this.get("application/view-transactions", {}, para);
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  async approveTransactions(para) {
    try {
      const data = await this.put(`application/approve-transactions`, para);

      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }
  // Membership Subscription - data table url
  async viewMembershipSubscription(para) {
    console.log(para);

    const params = {
      page: para.page__,
      perPage: para.perPage__,
      sortColumn: para.sortColumn__,
      sortDirection: para.sortDirection__,
      filterText: para.filterText__,
    };

    const userObject = window.sessionStorage.getItem("membership");
    console.log(JSON.parse(userObject));
    const newUser = JSON.parse(userObject);
    console.log(newUser.membershipid);

    try {
      const subdata = await this.get(
        `application/getSubscription/${newUser.membershipid}`,
        {},
        params
      );
      return subdata;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  //AdminDashboard
  async getApplicationCountByStatus() {
    console.log();
    try {
      const data = await this.get(`dashboard/dashboardApplicationcounts`, {});
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  //MemberDashboard
  async viewMemberDashDetail(reqBody) {
    console.log(reqBody.memberId);
    try {
      const data = await this.get(
        `dashboard/memberDashboard/${reqBody.memberId}`,
        {}
      );
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  //memberdashboard- pending payment summary
  async viewMemberPayDashDetail(reqBody) {
    // console.log(reqBody.memberId);
    try {
      const data = await this.get(
        `dashboard/getPaymentFee/${reqBody.memberId}`,
        {}
      );
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  //ApplicationDashboard
  async viewApplicationDashDetail(reqBody) {
    console.log(reqBody.applicationId);
    try {
      const data = await this.get(
        `dashboard/applicantDashboard/${reqBody.applicationId}`,
        {}
      );
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  // Admin View Member -  Member Details List
  async viewMemberDetailList(memberId) {
    try {
      const data = await this.get(
        `application/getMemberDetails/${memberId}`,
        {}
      );
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  // Admin View Member - Employee Detail - data table url
  async employeeDetails(para, memberId) {
    try {
      const data = await this.get(
        `application/employeeDetails/${memberId}`,
        {},
        para
      );
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  // Admin View Member - Accademic Detail - data table url
  async getAdminViewMemAccadamicDetailByMemberId(para, memberId) {
    const params = {
      page: para.page_,
      perPage: para.perPage_,
      sortColumn: para.sortColumn_,
      sortDirection: para.sortDirection_,
      filterText: para.filterText_,
    };
    try {
      const academic_data = await this.get(
        `application/accadamicQualification/${memberId}`,
        {},
        params
      );
      return academic_data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  // Admin View Member - Professional Membership Details - data table url
  async getAdminViewMemMemberIdForProfessionalDetails(para, memberId) {
    const params = {
      page: para.page__,
      perPage: para.perPage__,
      sortColumn: para.sortColumn__,
      sortDirection: para.sortDirection__,
      filterText: para.filterText__,
    };
    try {
      const data = await this.get(
        `application/professionalDetails/${memberId}`,
        {},
        params
      );
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  //User Profile - Employee Detail
  async getViewProfileEmployeeDetailByMemberId(para) {
    console.log(para);
    const userObject = window.sessionStorage.getItem("member");
    console.log(JSON.parse(userObject));
    const newUser = JSON.parse(userObject);
    // console.log(newUser.memberID);
    try {
      const data = await this.get(
        `application/employeeDetails/${newUser.memberID}`,
        {},
        para
      );
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  // User Profile - Academic Details
  async getViewProfileAcademicDetailsByMemberId(para) {
    console.log("getViewProfileAcademicDetailsByMemberId--->", para);
    const params = {
      page: para.page_,
      perPage: para.perPage_,
      sortColumn: para.sortColumn_,
      sortDirection: para.sortDirection_,
      filterText: para.filterText_,
    };
    const userObject = window.sessionStorage.getItem("member");
    console.log(JSON.parse(userObject));
    const newUser = JSON.parse(userObject);
    console.log(newUser);
    try {
      const dataa = await this.get(
        `application/accadamicQualification/${newUser.memberID}`,
        {},
        params
      );
      return dataa;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  // User Profile - Professional Details
  async getViewProfileMemberIdForProfessionalDetails(para) {
    const params = {
      page: para.page__,
      perPage: para.perPage__,
      sortColumn: para.sortColumn__,
      sortDirection: para.sortDirection__,
      filterText: para.filterText__,
    };
    console.log("getViewProfileMemberIdForProfessionalDetails---->", para);
    const userObject = window.sessionStorage.getItem("member");
    console.log(JSON.parse(userObject));
    const newUserProf = JSON.parse(userObject);
    // console.log(newUserProf.memberID);
    try {
      const data = await this.get(
        `application/professionalDetails/${newUserProf.memberID}`,
        {},
        params
      );
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  // // Modal - Pay to Renewal
  // async addToPaytoRenewal() {
  //   try {
  //     const data = await this.post("application/membershiprenewal", {});
  //     return data;
  //   } catch (error) {
  //     return ApplicationsService.handleError(error);
  //   }
  // }

  // //getmemberpendingpayments
  // async getPendingPaymentDetails(details) {
  //   console.log(details);
  //   console.log(details.memberType);
  //   try {
  //     const data = await this.get("application/getPaymentFee/"+details.memberType+"/"+details.lastRenewalYear+"/"+details.memberID);
  //     return data;
  //   } catch (error) {
  //     return ApplicationsService.handleError(error);
  //   }
  // }

  //Edit member - get member detail
  async MemberDetailList(memberNo) {
    try {
      // const data = await this.get(`application/getMemberDetails/M951`);
      const data = await this.get(`application/memberdetail/${memberNo}`);
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  //Member Types  - get member detail
  async MemberTypeList(userid) {
    try {
      // const data = await this.get(`application/getMemberDetails/M951`);
      const data = await this.get(`application/memberdetails/${userid}`);
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  //Edit Member - Update
  async updateEditMemberDetails(memberNo, values) {
    try {
      const data = await this.post(
        `application/updatemember/${memberNo}`,
        values
      );
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  async addMemberDetails(values) {
    try {
      const data = await this.post(
        `application/member`,
        values
      );
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  async ipgReturn() {

    try {
      const data = await this.get("application/get-ipg-return-val");
      console.log("get-ipg-return-val====>>>", data);
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  async getIpgReturnFromSSE(encryptcode) {
    try {
      const data = await this.get("application/events/" + encryptcode, {})
      console.log("dataaaa===>>>", JSON.stringify(data))
      return data
    } catch (error) {
      return ApplicationsService.handleError(error)
    }
  }

  async getIpgReturnFromSSEStop() {
    try {
      const data = await this.get("application/stop", {})
      return data
    } catch (error) {
      return ApplicationsService.handleError(error)
    }
  }

  async getResponseForPayment(encryptcode) {
    try {
      const data = await this.get("application/get-response-for-payment/" + encryptcode, {})
      return data
    } catch (error) {
      return ApplicationsService.handleError(error)
    }
  }

  async getIdFromPaymentLog() {
    try {
      const data = await this.get("application/get-id-from-payment-log/" , {})
      return data
    } catch (error) {
      return ApplicationsService.handleError(error)
    }
  }

  async bankdeposits(bankdep) {
    try {

      const data = await this.post(`application/bank-deposit-save`, bankdep);
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  async getPaymentFee() {
    try {

      console.log(data);
      const data = await this.get(
        "application/getPaymentFee"
      );
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  async getMemberDetailsForDashboard() {

    try {
      const data = await this.get(
        `dashboard/get-member-details-for-dashboard`,
        {}
      );
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  async getResponseForPaymentButtonHide() {

    try {
      const data = await this.get(
        `dashboard/get-response-for-payment-button-hide`,
        {}
      );
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  async getCommitteeReviews(para) {
    console.log(para);
    const params = {
      page: 1,
      perPage: 10,
      sortColumn: para.sortColumn____,
      sortDirection: para.sortDirection____,
      filterText: para.filterText____,
    };
    try {
      const data = await this.get(
        `application/committee-member-list/${para.userId}`,
        {},
        params
      );
      console.log("Prof=======>", data);
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }


  async setUserRoleForMember(userdetails) {
    try {

      const data = await this.post(`application/set-user-role-from-member`, userdetails);
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  //member renewal email after payment
  async getMembershipRenewalPaymentStatus(memberno) {
    try {
      console.log(memberno);
      const data = await this.get(`application/send-member-renewal-payment-status-mail/${memberno}`);
      console.log(memberno);
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  //new membership email after payment
  async getNewMembershipPaymentStatus(nicNumber) {
    try {
      console.log(nicNumber);
      const data = await this.get(`application/send-membership-payment-status-mail/${nicNumber}`);
      console.log(nicNumber);
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

    //cpd pdf generate test
    async getPaymentReceipt() {
      try {
          const data = await this.post(`application/generatePdf`,{});
          return data;
      } catch (error) {
          return ApplicationsService.handleError(error);
      }
    }

    async newsFeedAdd(details) {
      try {
  
        const data = await this.post(`application/news-feed-add`, details);
        console.log(details);
        return data;
      } catch (error) {
        return ApplicationsService.handleError(error);
      }
    }

    async getNewsFeed() {
      try {
        const data = await this.get(
          `dashboard/news-feed`,
          {}
        );
        return data;
      } catch (error) {
        return ApplicationsService.handleError(error);
      }
    }

  async getAllNewsFeed(para) {
    const params = {
      page: 1,
      perPage: 10,
      sortColumn: para.sortColumn__,
      sortDirection: para.sortDirection__,
      filterText: para.filterText__,
    };
    try {
      const data = await this.get(
        `application/get-all-news-feed`,
        {},
        params
      );
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  async deleteNewsFeed(id) {
    try {
      console.log(id);
      const data = await this.delete(`application/delete-news-feed/${id}`);
      console.log(id);
      console.log(data);
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

  async viewCalenderDetails() {
    try {
      const data = await this.get(
        `application/get-for-calender`,
        {}
      );
      return data;
    } catch (error) {
      return ApplicationsService.handleError(error);
    }
  }

}

const applicationsService = new ApplicationsService();
export default applicationsService;
