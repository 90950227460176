import React, { useState } from "react";
import { Badge, Button, Card, Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Rings } from "react-loader-spinner";
import DataTable from "react-data-table-component";
import customDataTableStyle from "../../../assets/css/customCSS";
import FormikForm from "../../../components/shared/Forms/FormikForm";
import Aux from "../../../hoc/_Aux";
import "./../../../assets/scss/style.scss";
import "react-toastify/dist/ReactToastify.css";
import * as yup from "yup";

export default (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showED, setShowED] = useState(false);
  const handleCloseED = () => setShowED(false);
  const handleShowED = () => setShowED(true);

  const [showAQ, setShowAQ] = useState(false);
  //  const handleCloseAQ = () => setShowAQ(false);
  const handleShowAQ = () => setShowAQ(true);

  const [showOPB, setShowOPB] = useState(false);
  const handleCloseOPB = () => setShowOPB(false);
  const handleShowOPB = () => setShowOPB(true);

  // var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  // var today  = new Date();

  // console.log(today.toLocaleDateString("en-US", options)); // Saturday, September 17, 2016

  // const event = new Date(Date.UTC(2012, 11, 20, 3, 0, 0));

  // console.log(event.toLocaleDateString(undefined, options));
  // var date = new Date(Date.UTC(newUserr.createdAt.year, newUserr.createdAt.month, newUserr.createdAt.day, 3, 0, 0));
  // const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  // console.log(date.toLocaleDateString(undefined, options));
  // // console.log(newUserr.createdAt);

  const {
    data,
    loading,
    model,
    setmodel,

    updatemember,
    addEployeeDetails,
    InitialData,
    onSubmit,
    editUserHandler,
    deleteUserHandler,
    totalRows,
    resetPaginationToggle,
    handlePageChange,
    handlePerRowsChange,
    handleSort,
    subHeaderComponentMemo,
    editData,
    setEditData,
    isModelView,
    setIsModelView,
    memberType,

    academic_data,
    loading_,
    totalRows_,
    resetPaginationToggle_,
    handlePageChangeACC,
    handlePerRowsChangeACC,
    handleSortACC,
    subHeaderComponentMemoACC,

    professionalMembership_data,
    loading__,
    totalRows__,
    resetPaginationToggle__,
    handlePageChangeMem,
    handlePerRowsChangeMem,
    handleSortMem,
    subHeaderComponentMemoMem,

    member,
    newUser,
    newUserr,
    memberNo,
    loader1,
    loader3,

    //click
    handleChange,
    updated,
    handleClick,
    EditMemberProfileDetails_OnSubmit,
    EditMemberProfile_OnSubmit,
    handleCloseAQ,
  } = props;

  var date = new Date(newUserr.createdAt);
  var new_date = date.toLocaleDateString();
  console.log(new_date);

  var date = new Date(newUserr.expireDate);
  var expire_date = date.toLocaleDateString();
  console.log(expire_date);

  // Employment Details
  const column = [
    {
      name: "Start Date",
      selector: (data) => {
        return data.startdate;
      },
      sortable: true,
    },
    {
      name: "End Date",
      selector: (data) => {
        return data.enddate;
      },
      sortable: true,
    },
    {
      name: "Company Name",
      selector: (data) => {
        return data.companyname;
      },
      sortable: true,
    },
    {
      name: "Designation",
      selector: (data) => {
        return data.desgnation;
      },
      sortable: true,
    },
    {
      name: "Address",
      selector: (data) => {
        return data.adddress;
      },
      sortable: true,
    },

    // {
    //   name: "Status",
    //   selector: (data) => {
    //     if (data.status == 2) {
    //       return <Badge bg="success">Approved</Badge>;
    //     } else if (data.status == 1) {
    //       return <Badge bg="primary">Pending</Badge>;
    //     } else if (data.status == 16) {
    //       return <Badge bg="danger">Reject</Badge>;
    //     }
    //   },
    //   sortable: false,
    // },
  ];

  //Academic Qualification
  const column1 = [
    {
      name: "Start Date",
      selector: (academic_data) => {
        return academic_data.fromdate;
      },
      sortable: true,
    },
    {
      name: "End Date",
      selector: (academic_data) => {
        return academic_data.todate;
      },
      sortable: true,
    },
    {
      name: "Institute",
      selector: (academic_data) => {
        return academic_data.institutename;
      },
      sortable: true,
    },
    {
      name: "Name of the qualification",
      selector: (academic_data) => {
        return academic_data.qualificationname;
      },
      sortable: true,
    },
    {
      name: "Address",
      selector: (academic_data) => {
        return academic_data.address;
      },
      sortable: true,
    },
  ];

  //Professional Membership
  const column2 = [
    {
      name: "Start Date",
      selector: (professionalMembership_data) => {
        return professionalMembership_data.startdate;
      },
      sortable: true,
    },
    {
      name: "End Date",
      selector: (professionalMembership_data) => {
        return professionalMembership_data.enddate;
      },
      sortable: true,
    },
    {
      name: "Institute/Body",
      selector: (professionalMembership_data) => {
        return professionalMembership_data.entrymethod;
      },
      sortable: true,
    },
    {
      name: "Name of the membership",
      selector: (professionalMembership_data) => {
        return professionalMembership_data.profession;
      },
      sortable: true,
    },
    {
      name: "Address",
      selector: (professionalMembership_data) => {
        return professionalMembership_data.address;
      },
      sortable: true,
    },
  ];
  const titels = [
    { label: "Mr", value: "1" },
    { label: "Ms", value: "2" },
    { label: "Mis", value: "3" },
  ];

  //Edit Modal (Member Profile Details)
  const init_EditMemberProfileDetails = {
    designation: "",
    remark: "",
    file: null,
  };

  const init_EditMemberProfile = {
    contactNumber: "",
    addressNo: "",
    addressSt: "",
    addressCity: "",
  };

  const CONFIG_EditMemberProfileDetails = [
    {
      id: "contactNumber",
      type: "text",
      name: "contactNumber",
      label: "Contact Number",
      placeholder: "Contact Number",
      contianerProps: {
        xs: 12,
        sm: 12,
        md: 12,
      },
    },
    {
      id: "addressNo",
      type: "text",
      name: "addressNo",
      label: "Address Line 1",
      placeholder: "Address Line 1",
      contianerProps: {
        xs: 12,
        sm: 12,
        md: 12,
      },
    },
    {
      id: "addressSt",
      type: "text",
      name: "addressSt",
      label: "Address Line 2",
      placeholder: "Address Line 2",
      contianerProps: {
        xs: 12,
        sm: 12,
        md: 12,
      },
    },
    {
      id: "addressCity",
      type: "text",
      name: "addressCity",
      label: "Address Line 3",
      placeholder: "Address Line 3",
      contianerProps: {
        xs: 12,
        sm: 12,
        md: 12,
      },
    },
  ];

  //   const memberType=[

  //     { "label": "Student", "value": 1 },
  //     { "label": "Affiliate", "value": 2 },
  //     { "label": "Assocaite", "value": 3 },
  //     { "label": "Professional", "value": 4 },
  //     { "label": "Member", "value": 5 },
  // ]

  const CONFIG_EditMemberProfile = [
    {
      id: "designation",
      type: "text",
      name: "designation",
      label: "Designation",
      contianerProps: {
        xs: 4,
        sm: 4,
        md: 6,
      },
    },
    {
      id: "mem_type",
      type: "selectOpImproved",
      name: "mem_type",
      label: "Member Type *",
      options: memberType,
      placeholder: "Select Member Type",
      isClearable: true,
      isSearchable: true,
      contianerProps: {
        xs: 12,
        sm: 3,
        md: 3,
      },
    },
    {
      id: "remark",
      type: "text",
      name: "remark",
      label: "Remark",
      contianerProps: {
        xs: 4,
        sm: 4,
        md: 6,
      },
    },

    {
      id: "file",
      type: "file",
      name: "file",
      label: "Upload Service Letter *",
      contianerProps: {
        xs: 12,
        sm: 4,
        md: 4,
      },
    },
  ];

  const schema_EditMemberProfileDetails = yup.object().shape({
    contactNumber: yup
      .string()
      .nullable()
      .required("Contact Number cannot be empty")
      .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, "Invalid contact number")
      .min(10, "Invalid contact number")
      .max(12, "Please enter valid number"),
    addressNo: yup.string().required("Address Line 1 Required").trim(),
    addressSt: yup.string().required("Address Line 2 Required").trim(),
    //  addressCity: yup.string().required("Address Line 3 Required"),
  });

  const schema_EditMemberProfile = yup.object().shape({
    designation: yup
      .string()
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
      .required("Designation Required"),
    // mem_type: yup.string()
    // .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
    // .required("Member Type is Required"),
  });

  return (
    <Aux>
      <Row>
        <Col md={12}>
          <Card>
            <Card.Header>
              <Card.Title as="h5">User Account Details</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Row>
                  <Col md={3}>
                    {
                      <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Label>User Name</Form.Label>
                        <Form.Control
                          type="text"
                          value={newUserr.username}
                          disabled
                        />
                      </Form.Group>
                    }
                  </Col>

                  <Col md={3}>
                    {
                      <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Label>Created At</Form.Label>
                        <Form.Control type="text" value={new_date} disabled />
                      </Form.Group>
                    }
                  </Col>

                  <Col md={3}>
                    {
                      <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Label>Expire Date</Form.Label>
                        <Form.Control
                          type="text"
                          value={expire_date}
                          disabled
                        />
                      </Form.Group>
                    }
                  </Col>
                </Row>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <Card>
            <Card.Header>
              <Card.Title as="h5">Member Profile Details</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md={2}>
                  {
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label>Member ID</Form.Label>
                      <Form.Control
                        type="text"
                        value={InitialData.headerData.memNumber}
                        disabled
                      />
                    </Form.Group>
                  }
                </Col>
                <Col md={2}>
                  {
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        type="text"
                        value={InitialData.headerData.title}
                        disabled
                      />
                    </Form.Group>
                  }
                </Col>
                <Col md={4}>
                  {
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        //  value={member.firstName + " " + member.lastName}
                        value={
                          InitialData.headerData.firstname +
                          " " +
                          InitialData.headerData.lastname
                        }
                        disabled
                      />
                    </Form.Group>
                  }
                </Col>
                <Col md={4}>
                  {
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label>Member Type</Form.Label>
                      <Form.Control
                        type="text"
                        value={
                          InitialData.headerData.status === 15
                            ? "Provisional Member"
                            : InitialData.headerData.status === 6
                            ? "Member"
                            : InitialData.headerData.status === 4
                            ? "Rejected"
                            : ""
                        }
                        disabled
                      />
                    </Form.Group>
                  }
                </Col>
                <Col md={2}>
                  {
                    <Form.Group
                      controlId="exampleForm.ControlInput1"
                      className="mt-2"
                    >
                      <Form.Label>Date of Birth</Form.Label>
                      <Form.Control
                        type="text"
                        value={InitialData.headerData.DOB}
                        disabled
                      />
                    </Form.Group>
                  }
                </Col>
                <Col md={2}>
                  {
                    <Form.Group
                      controlId="exampleForm.ControlInput1"
                      className="mt-2"
                    >
                      <Form.Label>NIC or Passport No</Form.Label>
                      <Form.Control
                        type="text"
                        value={InitialData.headerData.NIC}
                        disabled
                      />{" "}
                    </Form.Group>
                  }
                </Col>
                <Col md={4}>
                  {
                    <Form.Group
                      controlId="exampleForm.ControlInput1"
                      className="mt-2"
                    >
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        type="text"
                        value={
                          InitialData.headerData.addressNo +
                          " " +
                          InitialData.headerData.addressSt +
                          " " +
                          InitialData.headerData.addressCity
                        }
                        disabled
                      />
                    </Form.Group>
                  }
                </Col>
                <Col md={4}>
                  {
                    <Form.Group
                      controlId="exampleForm.ControlInput1"
                      className="mt-2"
                    >
                      <Form.Label>Province</Form.Label>
                      <Form.Control
                        type="text"
                        value={InitialData.headerData.province}
                        disabled
                      />
                    </Form.Group>
                  }
                </Col>
                <Col md={4}>
                  {
                    <Form.Group
                      controlId="exampleForm.ControlInput1"
                      className="mt-2"
                    >
                      <Form.Label>District</Form.Label>
                      <Form.Control
                        type="text"
                        value={InitialData.headerData.district}
                        disabled
                      />
                    </Form.Group>
                  }
                </Col>
                <Col md={4}>
                  {
                    <Form.Group
                      controlId="exampleForm.ControlInput1"
                      className="mt-2"
                    >
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        type="text"
                        value={InitialData.headerData.city}
                        disabled
                      />
                    </Form.Group>
                  }
                </Col>
                <Col md={4}>
                  {
                    <Form.Group
                      controlId="exampleForm.ControlInput1"
                      className="mt-2"
                    >
                      <Form.Label>Postal Code</Form.Label>
                      <Form.Control
                        type="text"
                        value={InitialData.headerData.postalCode}
                        disabled
                      />
                    </Form.Group>
                  }
                </Col>
                <Col md={4}>
                  {
                    <Form.Group
                      controlId="exampleForm.ControlInput1"
                      className="mt-2"
                    >
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="text"
                        value={InitialData.headerData.Email}
                        disabled
                      />
                    </Form.Group>
                  }
                </Col>
                {/* <Col md={4}>
                  {
                    <Form.Group
                      controlId="exampleForm.ControlInput1"
                      className="mt-2"
                    >
                      <Form.Label>Designation</Form.Label>
                      <Form.Control
                        type="text"
                        value={InitialData.headerData.Designation}
                        disabled
                      />{" "}
                    </Form.Group>
                  }
                </Col> */}
                <Col md={4}>
                  {
                    <Form.Group
                      controlId="exampleForm.ControlInput1"
                      className="mt-2"
                    >
                      <Form.Label>Contact Number</Form.Label>
                      <Form.Control
                        type="text"
                        value={InitialData.headerData.contactNumber}
                        disabled
                      />{" "}
                    </Form.Group>
                  }
                </Col>
              </Row>
              <Row>
                <Col md={9}></Col>
                <Col md={3}>
                  <div style={{ float: "right", marginTop: "2%" }}>
                    <Button
                      onClick={() => {
                        setmodel(true);
                      }}
                      //    style={{ width: "100%", float: "right" }}
                      //   size="sm"
                    >
                      Edit
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Edit Modal (Member Profile Details)*/}
      {/* {model && ( */}
      <Modal
        show={model}
        onHide={() => {
          setmodel(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Member Profile Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormikForm
            config={CONFIG_EditMemberProfileDetails}
            initialValues={
              InitialData.headerData || init_EditMemberProfileDetails
            }
            validationSchema={schema_EditMemberProfileDetails}
            SubmitTrigger={() => {
              return (
                <div style={{ float: "right", marginTop: "2%" }}>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={loader1 == true ? true : false}
                  >
                    {loader1 == true ? (
                      <Rings height="28px" width="28px" color="white" />
                    ) : (
                      <>Update</>
                    )}
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setmodel(false);
                    }}
                  >
                    Close
                  </Button>
                </div>
              );
            }}
            submitFunction={EditMemberProfileDetails_OnSubmit}
          />
        </Modal.Body>
      </Modal>
      {/* )} */}

      {/* Edit Member detail - Designation , Mem type */}

      <Row>
        <Col md={12}></Col>
      </Row>
    </Aux>
  );
};
