import React, {memo, useEffect,useState} from 'react';
import RoleAuthorization from './roleAuthorization.view';
import { useDispatch, useSelector } from "react-redux";
import { actions as RoleAuthorizationAction } from "./";
import { toast } from "react-toastify";
import Swal from 'sweetalert2';
import userService from "./../../../services/User.service";
import FilterComponent from "../../../components/shared/TableSearch";

export default memo(() => {
  const dispatch = useDispatch();

//   const {

//     isAddRequestSent,
//     isUpdateRequestSent,
//     isDeleteRequestSent,
//   } = useSelector((state) => state.roleAuthorization);

    //Table State
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState(null);
    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] =
      React.useState(false);
      const [editData, setEditData] = useState(null);
   
      const editRoleHandler = (data) => {
        setEditData({
       
        });
     
      };
    
      const deleteRoleHandler = (data) => {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            console.log(data)
            //dispatch(RoleActions.deleteRole(data));
          }
        });
      };

     //Table methods
  const fetchRoleList = async (page) => {
    setLoading(true);

    const response = await userService.unauthorizeRoleList({
      page,
      perPage,
      sortColumn,
      sortDirection,
      filterText,
    });

    setData(response.data);
    setTotalRows(response.total);
    setLoading(false);
  };

  const handlePageChange = (page) => {
    fetchRoleList(page);
  };

  const handlePerRowsChange = (perPage) => {
    setPerPage(perPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortColumn(column.selector);
    setSortDirection(sortDirection);
    fetchRoleList(1);
  };

  useEffect(() => {
    // if (!isAddRequestSent) {
      fetchRoleList(1);
    // }
  }, [
    perPage,
    filterText,
    /*isAddRequestSent,
    isUpdateRequestSent,
    isDeleteRequestSent,*/
  ]);

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const props = {
    editRoleHandler,
    deleteRoleHandler,
    loading,
    data,
    totalRows,
    handlePageChange,
    handlePerRowsChange,
    handleSort,
    resetPaginationToggle,
    subHeaderComponentMemo,
    editData,
    setEditData,
   
  };

  return <RoleAuthorization {...props} />;

});


