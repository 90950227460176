import { Formik } from "formik";
import React from "react";
import { Badge, Button, Card, Col, Form, Modal, Row,Image,ButtonGroup } from "react-bootstrap";
import DataTable from "react-data-table-component";
import * as yup from "yup";
import customDataTableStyle from "../../../assets/css/customCSS";
import { CustomViewBtn, Edit, View, PrintBtn } from "../../../components/shared/TableButtons";
import Aux from "../../../hoc/_Aux";
import FormikForm from "../../../components/shared/Forms/FormikForm";
import { utils } from "react-modern-calendar-datepicker";

function ApproveTransactions(props) {
  const {
    onSubmit,
    editRoleHandler,
    deleteRoleHandler,
    loading,
    data,
    totalRows,
    handlePageChange,
    handlePerRowsChange,
    handleSort,
    resetPaginationToggle,
    subHeaderComponentMemo,
    editData,
    setEditData,
    isModelView,
    setIsModelView,
    Options,
    editUserHandler,
    viewHandler,
    printHandler,
    editModel,
    setEditModel,
    viewBankSlip,
    updateTransactionTable,
  } = props;

  // const init = {
  //   userRoleID: "",
  //   userRoleName: "",
  //   userRoleDescription: "",
  //   status: null,
  //   roleOptions: [{
  //     title: "Test",
  //     id: "1",
  //     1: true
  //   }, {
  //     title: "Test2",
  //     id: "2",
  //     2: true
  //   }]
  // };

  const init = {
    createdAt: "",
    createdBy: "",
    expireDate: "",
    status: "",
    userID: "",
    userRoleID: {},
    username: "",
    lastName: "",
    firstName: "",
    nic:"",
    description: "",
    userStatusID: {},
    approve: ""
  };

  const schema = yup.object();

  const column = [
    {
      name: "Payment ID",
      selector: (data) => {
        return data.paymentId
      },
      sortable: true,
    },
    {
      name: "Payment Type",
      selector: (data) => {
        return data.paymentType
      },
      sortable: true,
    },
    {
      name: "Transaction Description",
      selector: (data) => {
        return data.transactionDes
      },
      sortable: true,
    },
    {
      name: "Member No",
      selector: (data) => {
        return data.memberNo
      },
      sortable: true,
    },
    {
      name: "NIC/Passport No",
      selector: (data)=>{
        return data.nic
      },
      sortable: true,
    },
    {
      name: "Payment Mode",
      selector: (data) => {
        return data.paymentMode
      },
      sortable: true,
    },
    {
      name: "Reference Number",
      selector: (data) => {
        return data.referenceNumber
      },
      sortable: true,
    },
    {
      name: "Amount",
      selector: (data) => {
        return data.amount
      },
      sortable: true,
    },
    {
      name: "Paid Date",
      selector: (data) => {
        return data.paidDate
      },
      sortable: true,
    },
    {
      name: "Bank Response",
      selector: (data) => {

        if (data.responseText == "TRANSACTION APPROVED") {
          return (
            <Badge bg="success">
              TRANSACTION APPROVED
            </Badge>)
        }else if (data.responseText == null) {
          return (
            <Badge bg="warning">
              None
            </Badge>)
        }else if (data.responseText == "Not available") {
          return (
            <Badge bg="warning">
              Not Available
            </Badge>)
        } else{
          return (   
            data.responseText
            )
        }
      },
      sortable: false,
    },
    {
      name: "View Bank Slip",
      selector: (data) => {
        if (data.paymentMode == "Payment Gateway") {
          return (
            <Badge bg="warning">
              None
            </Badge>)
        } else{
          return (<ButtonGroup>
            <Edit data={data} editHandler={viewBankSlip} />
            </ButtonGroup>)
        }
        
      },
      //sortable: true,
    },
    {
      name: "Status",
      selector: (data) => {

        if (data.status == 2) {
          return (
            <Badge bg="success">
              Approved
            </Badge>)
        } else if (data.status == 1) {
          return (
            <Badge bg="warning" text="dark">
              Pending
            </Badge>)
        } else if (data.status == 16) {
          return (
            <Badge bg="danger">
              Reject
            </Badge>)
        }
      },
      sortable: false,
    },
    {
      name: "Action",
      button: true,
      //cell: (data) => <><Auth editHandler={editUserHandler} data={data} /> </>,
      selector: (data) => {

        if (data.status == 1) {
          return (
            <>
              <Edit editHandler={editUserHandler} data={data} />
              <CustomViewBtn viewHandler={viewHandler} data={data} />
            </>
          )
        } else if (data.status == 2) {
          return (
            <Badge bg="success">
              Edit Disabled
            </Badge> && 
            <>
            <CustomViewBtn viewHandler={viewHandler} data={data} />
            <PrintBtn printHandler={printHandler}/>
            {/* printHandler={printHandler}/> */}
            </>
            )
        } else if (data.status == 16) {
          return (
            <Badge bg="danger">
              Edit Disabled
            </Badge> &&
            <CustomViewBtn viewHandler={viewHandler} data={data} />
            )
        }
      },
      sortable: false,
    },
    // {
    //   name: "NIC",
    //   selector: (data) => {

    //     if (data.nic == null) {
    //       return (
    //       <Badge bg="danger">
    //       Not Submitted
    //     </Badge>)   
    //     }else{
    //       return data.nic 
    //     }
    //   },
    //   sortable: false,
    // },
    // {
    //   name: "Title",
    //   selector: (data) => {

    //     if (data.title == null) {
    //       return (
    //       <Badge bg="danger">
    //       Not Submitted
    //     </Badge>)   
    //     }else{
    //       return data.title 
    //     }
    //   },
    //   sortable: false,
    // },
    // {
    //   name: "First Name",
    //   selector: (data) => {

    //     if (data.firstName == null) {
    //       return (
    //       <Badge bg="danger">
    //       Not Submitted
    //     </Badge>)   
    //     }else{
    //       return data.firstName 
    //     }
    //   },
    //   sortable: false,
    // },
    // {
    //   name: "Last Name",
    //   selector: (data) => {

    //     if (data.lastName == null) {
    //       return (
    //       <Badge bg="danger">
    //       Not Submitted
    //     </Badge>)   
    //     }else{
    //       return data.lastName 
    //     }
    //   },
    //   sortable: false,
    // },
    // {
    //   name: "Mobile Number",
    //   selector: (data) => {

    //     if (data.mobileNumber == null) {
    //       return (
    //       <Badge bg="danger">
    //       Not Submitted
    //     </Badge>)   
    //     }else{
    //       return data.mobileNumber 
    //     }
    //   },
    //   sortable: false,
    // },

    // {
    //     name: "Address",
    //     selector: (data) => {

    //       if (data.address == null) {
    //         return (
    //         <Badge bg="danger">
    //         Not Submitted
    //       </Badge>)   
    //       }else{
    //         return data.address 
    //       }
    //     },
    //     sortable: false,
    // },
    // {
    //   name: "Last Renewal Date",
    //   selector: (data) => {

    //     if (data.lastRenewalYear == null) {
    //       return (
    //       <Badge bg="danger">
    //       Not Submitted
    //     </Badge>)   
    //     }else{
    //       return data.lastRenewalYear 
    //     }
    //   },
    //   sortable: false,
    // },
    // {
    //   name: "Total Due Amount",
    //   selector: (data) => {

    //     if (data.totalDueAmount == null) {
    //       return (
    //       <Badge bg="danger">
    //       No Renewal Year
    //     </Badge>)   
    //     }else{
    //       return data.totalDueAmount 
    //     }
    //   },
    //   sortable: false,
    // },
    // {
    //     name: "Member Type",
    //     selector: (data) => {

    //       if (data.memberType == "associate") {
    //         return (
    //         <Badge bg="primary">
    //         Associate
    //       </Badge>)   
    //     }else if(data.memberType == "personal"){
    //       return (
    //         <Badge bg="warning">
    //         Personal
    //       </Badge>)  
    //     }

    //     },
    //     sortable: false,
    // },

    // {
    //   name: "Status",
    //   selector: (data) => {

    //     if (data.status == 3) {
    //         return (
    //         <Badge bg="success">
    //         Active
    //       </Badge>)   
    //     }else{
    //       return (
    //         <Badge bg="danger">
    //         Inactive
    //       </Badge>)  
    //     }
    //   },
    //   sortable: false,
    // },

  ];

  const paymentType = [
    { label: "Membership Renewal", value: "Membership Renewal" },
    { label: "New Membership", value: "New Membership" },
  ];

  const paymentMode = [
    { label: "Payment Gateway", value: "Payment Gateway" },
    { label: "Payment Slip", value: "Payment Slip" },
    { label: "To Silwath Office", value: "To Silwath Office" },
  ];

  const CONFIG = [

    {
      id: "fromDate",
      type: "datepicker",
      name: "fromDate",
      label: "From Date",
      calendarPopperPosition: "bottom",
      contianerProps: {
        xs: 12,
        sm: 3,
        md: 3,
      },
      maximumDate: utils().getToday(),
    },

    {
      id: "toDate",
      type: "datepicker",
      name: "toDate",
      label: "To Date",
      calendarPopperPosition: "bottom",
      contianerProps: {
        xs: 12,
        sm: 3,
        md: 3,
      },
      maximumDate: utils().getToday(),
    },

    {
      id: "paymentType",
      type: "selectOpImproved",
      name: "paymentType",
      label: "Payment Type *",
      
      options: paymentType,
      placeholder: "Select Payment Type",
      isClearable: true,
      isSearchable: true,

      contianerProps: {
        xs: 12,
        sm: 4,
        md: 4,
      },
    },
    
    {
      id: "paymentMode",
      type: "selectOpImproved",
      name: "paymentMode",
      label: "Payment Mode *",
      
      options: paymentMode,
      placeholder: "Select Payment Mode",
      isClearable: true,
      isSearchable: true,

      contianerProps: {
        xs: 12,
        sm: 4,
        md: 4,
      },
    },

  ]

  return (
    <Aux>
      <Row>
        <Col>
          <Card>
            <Card.Header>


              <Col>
                <Card.Title as="h5">Transaction View / Transaction Approve</Card.Title>
                {/* <Button
                  className="pull-right"
                  bsStyle="danger"
                  bsSize="small"
                  onClick={() => {
                    setIsModelView(true);
                  }}
                >
                  <IoAdd />
                  {' '}
                  Add New
                </Button> */}
              </Col>

            </Card.Header>
            <Card.Body>

              <Row>
                <Col>
                {/* <FormikForm
                    config={CONFIG}
                    validationSchema={schema}
                    SubmitTrigger={() => (
                      <Col
                        justify="end"
                        className="mt-3 d-flex w-60 justify-content-end mb-8"
                      >

                        <Col></Col>
                        <Button
                          variant="primary"
                          className="btn btn-primary"
                          size="shadow-2 mb-4"
                          type="submit"
                        >
                           Update Table
                        </Button>
                        
                      </Col>
                    )}

                    submitFunction={updateTransactionTable}
                  /> */}

                  <DataTable
                    // title="Role List"
                    columns={column}
                    data={data}
                    progressPending={loading}
                    highlightOnHover={true}
                    responsive={true}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onSort={handleSort}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationResetDefaultPage={resetPaginationToggle} 
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    customStyles={customDataTableStyle}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal
        {...props}
        show={isModelView}
        onHide={() => setIsModelView(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
      >
        {editModel ? <>
          <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Transaction Details and Authorization
          </Modal.Title>
        </Modal.Header>
        </>:<>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Transaction Details View
          </Modal.Title>
        </Modal.Header>
        </>}
        

        <Formik
          validationSchema={schema}
          onSubmit={(data) => {
            console.log("submit")
            setIsModelView(false);
            onSubmit(data);
          }}
          initialValues={editData || init}
        >
          {(props) => {
            // console.log("Props ************** ",props)
            const {
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            } = props
            console.log(values.nic);
            return (
              <Form noValidate onSubmit={handleSubmit}>
                <Modal.Body>
                  <Form.Control
                    type="hidden"
                    name="id"
                    value={values.id}
                    onChange={handleChange}
                    isValid={touched.id && !errors.id}
                    isInvalid={!!errors.id}
                  />
                  <Row className="mb-3">

                    <Form.Group as={Col} md="6" controlId="address-type">
                      <Form.Label>Member ID</Form.Label>
                      <Form.Control
                        type="text"
                        name="userid"
                        value={values.userID}
                        onChange={handleChange}
                        isValid={touched.userID && !errors.userID}
                        isInvalid={!!errors.userID}
                        disabled
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errors.userID}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="address-type">
                      <Form.Label>NIC/Passport No</Form.Label>
                      <Form.Control
                        type="text"
                        name="nic"
                        value={values.nic}
                        onChange={handleChange}
                        disabled
                      />
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="address-type">
                      <Form.Label>Last Renewal Year</Form.Label>
                      <Form.Control
                        type="text"
                        name="lastRenewalYear"
                        value={values.lastRenewalYear}
                        onChange={handleChange}
                        isValid={touched.lastRenewalYear && !errors.lastRenewalYear}
                        isInvalid={!!errors.lastRenewalYear}
                        disabled
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errors.lastRenewalYear}
                      </Form.Control.Feedback>
                    </Form.Group>

                    {/* <Form.Group as={Col} md="6" controlId="address-type">
                      <Form.Label>Last Renewal Year</Form.Label>
                      <Form.Control
                        type="text"
                        name="lastRenewalYear"
                        value={values.lastRenewalYear}
                        onChange={handleChange}
                        isValid={touched.lastRenewalYear && !errors.lastRenewalYear}
                        isInvalid={!!errors.lastRenewalYear}
                        disabled
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errors.lastRenewalYear}
                      </Form.Control.Feedback>
                    </Form.Group> */}

                    <Form.Group as={Col} md="6" controlId="address-type">
                      <Form.Label>Payment ID</Form.Label>
                      <Form.Control
                        type="text"
                        name="paymentId"
                        value={values.paymentId}
                        onChange={handleChange}
                        isValid={touched.paymentId && !errors.paymentId}
                        isInvalid={!!errors.paymentId}
                        disabled
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errors.paymentId}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="address-type">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        isValid={touched.firstName && !errors.firstName}
                        isInvalid={!!errors.firstName}
                        disabled
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errors.firstName}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="address-type">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        isValid={touched.lastName && !errors.lastName}
                        isInvalid={!!errors.lastName}
                        disabled
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errors.lastName}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="address-type">
                      <Form.Label>Payment Type</Form.Label>
                      <Form.Control
                        type="text"
                        name="paymentType"
                        value={values.paymentType}
                        onChange={handleChange}
                        isValid={touched.paymentType && !errors.paymentType}
                        isInvalid={!!errors.paymentType}
                        disabled
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errors.paymentType}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="address-type">
                      <Form.Label>Transaction Description</Form.Label>
                      <Form.Control
                        type="text"
                        name="transactionDes"
                        value={values.transactionDes}
                        onChange={handleChange}
                        isValid={touched.transactionDes && !errors.transactionDes}
                        isInvalid={!!errors.transactionDes}
                        disabled
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errors.transactionDes}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="address-type">
                      <Form.Label>Payment Mode</Form.Label>
                      <Form.Control
                        type="text"
                        name="paymentMode"
                        value={values.paymentMode}
                        onChange={handleChange}
                        isValid={touched.paymentMode && !errors.paymentMode}
                        isInvalid={!!errors.paymentMode}
                        disabled
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errors.paymentMode}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="address-type">
                      <Form.Label>Reference Number</Form.Label>
                      <Form.Control
                        type="text"
                        name="referenceNum"
                        value={values.referenceNum}
                        onChange={handleChange}
                        isValid={touched.referenceNum && !errors.referenceNum}
                        isInvalid={!!errors.referenceNum}
                        disabled
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errors.referenceNum}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="address-type">
                      <Form.Label>Paid Date</Form.Label>
                      <Form.Control
                        type="text"
                        name="payedDate"
                        value={values.payedDate}
                        onChange={handleChange}
                        isValid={touched.payedDate && !errors.payedDate}
                        isInvalid={!!errors.payedDate}
                        disabled
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errors.payedDate}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="address-type">
                      <Form.Label>Amount</Form.Label>
                      <Form.Control
                        type="text"
                        name="amount"
                        value={values.amount}
                        onChange={handleChange}
                        isValid={touched.amount && !errors.amount}
                        isInvalid={!!errors.amount}
                        disabled
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errors.amount}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="address-type">
                      <Form.Label>Bank Response</Form.Label>
                      <Form.Control
                        type="text"
                        name="responseText"
                        value={values.responseText}
                        onChange={handleChange}
                        isValid={touched.responseText && !errors.responseText}
                        isInvalid={!!errors.responseText}
                        disabled
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errors.responseText}
                      </Form.Control.Feedback>
                    </Form.Group>

                    {/* <Form.Group as={Col} md="6" controlId="address-type">
                      <Form.Label>Payment Slip</Form.Label>
                      <Image src={values.cashDepositsImgPath} />
                      
                      
                    </Form.Group> */}

                    {/* <Form.Group as={Col} md="6" controlId="address-type">
                      <Form.Label>Add a Comment</Form.Label>
                      <Form.Control
                        type="text"
                        name="comment"
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Please Add
                      </Form.Control.Feedback>
                    </Form.Group> */}

                  </Row>
                </Modal.Body>
                {editModel ? <>
                  <Modal.Footer>
                    <Button type="button" onClick={() => {
                      console.log(props)
                      props.setFieldValue('approve', 'approve')
                      props.handleSubmit()
                    }}>Approve</Button>{" "}
                    <Button type="button" variant="danger" onClick={() => {
                      props.setFieldValue('approve', 'reject')
                      props.submitForm()

                    }}>Reject</Button>{" "}
                    <Button onClick={() => {
                      setEditData(null)
                      setIsModelView(false)
                    }}>Close</Button>
                  </Modal.Footer>
                </> :
                  <>
                    <Modal.Footer>
                      <Button onClick={() => {
                        setEditData(null)
                        setIsModelView(false)
                      }}>Close</Button>
                    </Modal.Footer>
                  </>}
              </Form>
            )
          }}
        </Formik>
      </Modal>
    </Aux>
  );
}

export default ApproveTransactions;
